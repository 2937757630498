import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "rsuite";
import Flex from "../../../components/Flex";
import DMSeriffTag from "../../../components/Text/DMSeriff";
import InterTag from "../../../components/Text/Inter";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import { COLORS } from "../../../utils/colors";

const Unlock: React.FC = () => {
  const navigate = useNavigate();
  const { trans } = useLocalizationState();

  return (
    <Flex column middle gap={32} style={{ margin: "0 100px" }}>
      <DMSeriffTag
        size={36}
        color={COLORS.secondary}
        text={trans(
          "Optimize Your Laundry Operations for Maximum Efficiency and Sustainability"
        )}
        style={{ textWrap: "wrap", textAlign: "center" }}
        bold
      />
      {[
        "Streamline your hotel's laundry services with our 'Laundry' feature, exclusively designed to monitor and manage all aspects of your laundry operations.",
        "From tracking water and electriccity usage to analyzing the cost-effectiveness of outsourced vs in-house laundry services, this feature helps you make informed decisions that reduce costs and environmental impact.",
      ].map((t) => (
        <InterTag
          size={20}
          color={COLORS.secondary}
          text={trans(t)}
          style={{ textWrap: "wrap", textAlign: "center" }}
        />
      ))}
      <Button
        style={{
          backgroundColor: COLORS.primary_900,
          color: COLORS.white,
          fontWeight: "bold",
        }}
        onClick={() => navigate("/settings/billing/plan")}
      >
        {trans("Upgrade Now")}
      </Button>
      {[
        {
          title: "Reduced Environmental Footprint",
          description:
            "Monitor and monimize the water and electricity consumption of your laundry operations, supporting your hotel's sustainability goals.",
        },
        {
          title: "Cost Management",
          description:
            "Gain insights into the costs associated with laundry services, enabling you to identify savings opportunities and optimize goals.",
        },
        {
          title: "Operational efficiency",
          description:
            "Enhance operational practices by analyzing usage patterns and adjusting processes to increase efficiency and reduce overhead costs.",
        },
      ].map(({ title, description }) => (
        <Flex column gap={16}>
          <DMSeriffTag
            text={trans(title)}
            color={COLORS.secondary}
            size={24}
            style={{ textWrap: "wrap", textAlign: "center" }}
            bold
          />
          <InterTag
            text={trans(description)}
            color={COLORS.secondary}
            size={20}
            style={{ textWrap: "wrap", textAlign: "center" }}
          />
        </Flex>
      ))}
    </Flex>
  );
};

export default Unlock;
