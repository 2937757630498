import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "rsuite";
import Flex from "../../../components/Flex";
import DMSeriffTag from "../../../components/Text/DMSeriff";
import InterTag from "../../../components/Text/Inter";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import { COLORS } from "../../../utils/colors";

const Unlock: React.FC = () => {
  const navigate = useNavigate();
  const { trans } = useLocalizationState();

  return (
    <Flex column middle gap={32} style={{ margin: "0 100px" }}>
      <DMSeriffTag
        size={36}
        color={COLORS.secondary}
        text={trans("Engange and Inspire with Eco-Friendly Challenges")}
        style={{ textWrap: "wrap", textAlign: "center" }}
        bold
      />
      {[
        "Empower your guests to contribute to your hotel's sustainability goals with our 'Challenges' feature, exclusively available for premium users.",
        "This feature enables you to set up engaging competitions or cooperative challenges that guests and staff alike to reduce energy, water usage, and waste.",
        "Make sustainability a fun and rewarding part of the guest experience, enhancing your hotel's green credentials and guest satisfaction.",
      ].map((t) => (
        <InterTag
          size={20}
          color={COLORS.secondary}
          text={trans(t)}
          style={{ textWrap: "wrap", textAlign: "center" }}
        />
      ))}
      <Button
        style={{
          backgroundColor: COLORS.primary_900,
          color: COLORS.white,
          fontWeight: "bold",
        }}
        onClick={() => navigate("/settings/billing/plan")}
      >
        {trans("Upgrade Now")}
      </Button>
      {[
        {
          title: "Community Building",
          description:
            "Foster a sense of community and shared purpose among guests by involving them in sustainability challenges that make their stay more interactive and meaningful.",
        },
        {
          title: "Sustainability Goals",
          description:
            "Support your environmental objectives by making sustainability efforts tangible and trackable for guests, which can also enhance your hotel's reputation as an eco-friendly establishment.",
        },
        {
          title: "Guest Engagement",
          description:
            "Increase guest engagement and satisfaction by rewarding participants for their eco-friendlt actions, leading to positive experiences and reviews.",
        },
      ].map(({ title, description }) => (
        <Flex column gap={16}>
          <DMSeriffTag
            text={trans(title)}
            color={COLORS.secondary}
            size={24}
            style={{ textWrap: "wrap", textAlign: "center" }}
            bold
          />
          <InterTag
            text={trans(description)}
            color={COLORS.secondary}
            size={20}
            style={{ textWrap: "wrap", textAlign: "center" }}
          />
        </Flex>
      ))}
    </Flex>
  );
};

export default Unlock;
