import React from "react";
import { StepItemProps, Steps, StepsProps } from "rsuite";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";

interface iProps extends StepsProps {}

const StepsHeader: React.FC<iProps> = ({ current }) => {
  const { trans } = useLocalizationState();
  const steps: (StepItemProps & { key: string; step: number })[] = [
    {
      key: "email",
      title: trans("Email"),
      step: 0,
    },
    {
      key: "personal",
      title: trans("Yourself"),
      step: 1,
    },
    {
      key: "accommodation",
      title: trans("Accommodation"),
      step: 2,
    },
    // {
    //   key: "plan",
    //   title: trans("Plan"),
    //   step: 3,
    // },
  ];

  return (
    <Steps>
      {steps.map(({ key, step, title }) => {
        return (
          <Steps.Item
            {...{
              key,
              title,
              ...(current === step
                ? { status: "process" }
                : current
                ? step > current
                  ? { status: "wait" }
                  : { status: "finish" }
                : {}),
            }}
          />
        );
      })}
    </Steps>
  );
};

export default StepsHeader;
