import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Toggle } from "rsuite";
import Flex from "../../../components/Flex";
import PageBottomPadding from "../../../components/PageBottomPadding";
import InterTag from "../../../components/Text/Inter";
import TopBar from "../../../components/TopBar";
import useHotelSubscriptionState from "../../../context/Hotel/hooks/hotelState/useHotelSubscriptionState";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import WithContextProvider from "../../../hoc/withProvider";
import useProfilePermissions from "../../../hooks/useProfilePermissions";
import { nRequestStatus } from "../../../interfaces/apiCalls";
import { REQUEST_STATUS } from "../../../utils/apiCall";
import { COLORS } from "../../../utils/colors";
import ConsumptionContextProvider, {
  ConsumptionContext,
} from "../consumption/context";
import HistoricData from "./historicData";
import LiveData from "./liveData";

const Electricity: React.FC = () => {
  const navigate = useNavigate();
  const { trans } = useLocalizationState();
  const [dataTypeState, setDataTypeState] = useState<{
    status: nRequestStatus.tStatus;
    dataType: "archives" | "live" | null;
  }>({ status: REQUEST_STATUS.PENDING, dataType: null });
  const context = useContext(ConsumptionContext);
  const profilePermissions = useProfilePermissions();
  const { hotelLoading, activeSubscriptionIsEnterprise } =
    useHotelSubscriptionState();

  useEffect(() => {
    if (activeSubscriptionIsEnterprise) {
      setTimeout(() => {
        const get = () => {
          const stored = localStorage.getItem("__s_dt__");

          switch (stored) {
            case "archives":
              return "archives";
            case "live":
              return "live";
          }
          return "archives";
        };
        const dataType = get();
        setDataTypeState({ status: REQUEST_STATUS.RESOLVED, dataType });
      }, 200);
    } else {
      setDataTypeState({
        status: REQUEST_STATUS.RESOLVED,
        dataType: "archives",
      });
    }
  }, [activeSubscriptionIsEnterprise, hotelLoading]);

  const handleSetDataType = (dataType: "live" | "archives") => {
    setDataTypeState((prev) => ({ ...prev, dataType }));
    localStorage.setItem("__c_dt__", dataType);
  };

  const dataTypeToggle = activeSubscriptionIsEnterprise ? (
    <Toggle
      loading={
        dataTypeState.status === REQUEST_STATUS.PENDING ||
        Object.values(context.state.dataTypeToggleDisabled).some((v) => v)
      }
      className={"toggle-data-type"}
      onChange={(checked) => {
        if (
          !(
            dataTypeState.status === REQUEST_STATUS.PENDING ||
            Object.values(context.state.dataTypeToggleDisabled).some((v) => v)
          )
        )
          handleSetDataType(checked ? "live" : "archives");
      }}
      checked={dataTypeState.dataType === "live"}
      checkedChildren={trans("Live")}
      unCheckedChildren={trans("History")}
    />
  ) : null;

  const renderMain = () => {
    switch (dataTypeState.dataType) {
      case "archives":
        return (
          <div>
            <HistoricData {...{ dataTypeToggle, profilePermissions }} />
            <PageBottomPadding />
          </div>
        );
      case "live":
        return (
          <div>
            <LiveData {...{ dataTypeToggle }} />
            <PageBottomPadding />
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div>
      <TopBar>
        <Flex gap={8} column center style={{ height: "100%" }}>
          <InterTag
            hoverUnderline
            onClick={() => {
              navigate("/consumption");
            }}
            text={trans("Consumption")}
            size={12}
            color={COLORS.secondary}
          />
          <InterTag
            text={trans("Electricity")}
            size={24}
            bold
            color={COLORS.secondary}
          />
        </Flex>
      </TopBar>
      {renderMain()}
    </div>
  );
};

export default WithContextProvider(ConsumptionContextProvider, Electricity);
