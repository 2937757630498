import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "rsuite";
import Flex from "../../../components/Flex";
import DMSeriffTag from "../../../components/Text/DMSeriff";
import InterTag from "../../../components/Text/Inter";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import { COLORS } from "../../../utils/colors";

const Unlock: React.FC = () => {
  const navigate = useNavigate();
  const { trans } = useLocalizationState();

  return (
    <Flex column middle gap={32} style={{ margin: "0 100px" }}>
      <DMSeriffTag
        size={36}
        color={COLORS.secondary}
        text={trans(
          "Streamline Your sustainability Efforts with Advanced Waste Tracking"
        )}
        style={{ textWrap: "wrap", textAlign: "center" }}
        bold
      />
      {[
        "Enhance your hotel's environmental initiatives by meticulously tracking waste generation and disposal across your operations.",
        "Our waste Tracking feature enables you to monitor various types of waste - including recyclables, organics and non-recyclables - providing precise data to optimize waste management strategies and reduce environmental impact.",
      ].map((t) => (
        <InterTag
          size={20}
          color={COLORS.secondary}
          text={trans(t)}
          style={{ textWrap: "wrap", textAlign: "center" }}
        />
      ))}
      <Button
        style={{
          backgroundColor: COLORS.primary_900,
          color: COLORS.white,
          fontWeight: "bold",
        }}
        onClick={() => navigate("/settings/billing/plan")}
      >
        {trans("Upgrade Now")}
      </Button>
      {[
        {
          title: "Detailed Waste Insights",
          description:
            "Obtain granular data on waste generation by type and location within your hotel, enabling targeted intervantions to reduce overall waste.",
        },
        {
          title: "Cost Reduction",
          description:
            "By identifying key areas of waste reduction, implement more efficient disposal and recycling practices that can decrease operational costs.",
        },
        {
          title: "Regulatory Compliance",
          description:
            "Ensure compliance with local and international waste management regulations, helping to avoid fines and enhance the hotel's reputation for corporate responsability.",
        },
      ].map(({ title, description }) => (
        <Flex column gap={16}>
          <DMSeriffTag
            text={trans(title)}
            color={COLORS.secondary}
            size={24}
            style={{ textWrap: "wrap", textAlign: "center" }}
            bold
          />
          <InterTag
            text={trans(description)}
            color={COLORS.secondary}
            size={20}
            style={{ textWrap: "wrap", textAlign: "center" }}
          />
        </Flex>
      ))}
    </Flex>
  );
};

export default Unlock;
