import axios from "axios";
import { has, omit } from "lodash";
import React, { useState } from "react";
import { Button, useToaster } from "rsuite";
import Flex from "../../../../components/Flex";
import InterTag from "../../../../components/Text/Inter";
import useHotelDispatch from "../../../../context/Hotel/hooks/useHotelDispatch";
import useHotelState from "../../../../context/Hotel/hooks/useHotelState";
import useLocalizationState from "../../../../context/Localization/hooks/useLocalizationState";
import usePutRequest from "../../../../hooks/apiRequests/usePutRequest";
import {
  tHotelStaffPermissions,
  tHotelStaffRole,
  tHotelStaffRoleId,
} from "../../../../models/hotel";
import { apiAddressV2 } from "../../../../utils/apiCall";
import { COLORS } from "../../../../utils/colors";
import { getErrorMessage } from "../../../../utils/httpResponses/others";
import { notification } from "../../../../utils/notifications";
import useProfilePermissions from "../../../../hooks/useProfilePermissions";

interface iProps {
  rootOutletContainerHeight: number;
  outletContainerWidth: number;
}

const PermissionsTab: React.FC<iProps> = ({
  rootOutletContainerHeight,
  outletContainerWidth,
}) => {
  const toaster = useToaster();
  const { trans } = useLocalizationState();
  const { hotel, hotelId } = useHotelState();
  const { updateHotel } = useHotelDispatch();
  const [permissionsUpdates, setPermissionsUpdates] = useState<
    Record<tHotelStaffRoleId, Partial<tHotelStaffPermissions>>
  >({});
  const profilePermissions = useProfilePermissions();
  const putRequest = usePutRequest();

  const handleChange = (
    roleId: tHotelStaffRoleId,
    key: keyof tHotelStaffPermissions,
    value: boolean
  ) => {
    setPermissionsUpdates((prev) => {
      if (has(prev, `${roleId}.${key}`) && prev[roleId][key] !== value) {
        return Object.fromEntries(
          Object.entries({
            ...prev,
            [roleId]: { ...omit(prev[roleId], [key]) },
          })
            .filter((entry) => {
              const [, permissions] = entry as [
                tHotelStaffRoleId,
                tHotelStaffPermissions
              ];
              return Object.values(permissions).length;
            })
            .map((entry) => entry)
        );
      }
      return {
        ...prev,
        [roleId]: { ...prev[roleId], [key]: value },
      };
    });
  };

  const hasChanges = Object.keys(permissionsUpdates).length !== 0;

  const permissionsStructure: {
    key: string;
    label: string;
    children: { key: keyof tHotelStaffPermissions; label: string }[];
  }[] = [
    {
      key: "manualData",
      label: "Manual Data",
      children: [
        { key: "readManualData", label: "View" },
        { key: "writeManualData", label: "Create/Update" },
      ],
    },
    {
      key: "stays",
      label: "Reservations",
      children: [
        { key: "readStays", label: "View" },
        { key: "writeStays", label: "Create/Update/Remove" },
        { key: "giveAwardToStay", label: "Award Reservations" },
      ],
    },
    {
      key: "spaces",
      label: "Spaces",
      children: [
        { key: "readSpaces", label: "View" },
        { key: "writeSpaces", label: "Create/Update/Remove" },
      ],
    },
    {
      key: "space-aggregates",
      label: "Space Aggregates",
      children: [
        {
          key: "readSpaceAggregates",
          label: "View",
        },
        { key: "writeSpaceAggregates", label: "Create/Update/Remove" },
      ],
    },
    {
      key: "awards",
      label: "Awards",
      children: [
        { key: "readAwards", label: "View" },
        { key: "writeAwards", label: "Create/Update/Remove" },
      ],
    },
    {
      key: "challenges",
      label: "Challenges",
      children: [
        { key: "readChallenges", label: "View" },
        { key: "writeChallenges", label: "Create/Update/Remove" },
      ],
    },
    {
      key: "users",
      label: "Users",
      children: [
        { key: "writeUsers", label: "Create/Update/Remove" },
        { key: "readUsersLastAccess", label: "View Users' last access" },
        { key: "readUsersActivities", label: "View Users' activities" },
      ],
    },
    // {
    //   key: "roles",
    //   label: "Roles",
    //   children: [
    //     { key: "readRoles", label: "View" },
    //     { key: "writeRoles", label: "Create/Update/Remove" },
    //   ],
    // },
  ];

  const renderPermissionsLabels = () => {
    return (
      <Flex column gap={12}>
        {permissionsStructure.map(({ key, children, label }) => {
          return (
            <Flex column gap={8} key={key}>
              <InterTag
                style={{ marginLeft: "12px" }}
                size={14}
                text={trans(label)}
                color={COLORS.secondary}
              />
              <Flex column>
                {children.map(({ key, label }) => {
                  return (
                    <div
                      style={{
                        width: "100%",
                        borderBottom: `1px solid ${COLORS.gray_200}`,
                        padding: "4px 0",
                      }}
                      key={key}
                    >
                      <InterTag
                        style={{ marginLeft: "24px" }}
                        text={trans(label)}
                        size={12}
                        color={COLORS.secondary}
                      />
                    </div>
                  );
                })}
              </Flex>
            </Flex>
          );
        })}
      </Flex>
    );
  };

  const renderPermissionsCheckboxes = (staffRole: tHotelStaffRole) => {
    return (
      <Flex column gap={12}>
        {permissionsStructure.map(({ key, children, label }) => {
          return (
            <Flex column gap={8} key={key}>
              <InterTag
                size={14}
                text={trans(label)}
                color={COLORS.secondary}
                style={{ visibility: "hidden" }}
              />
              <Flex column>
                {children.map(({ key }) => {
                  return (
                    <div
                      key={key}
                      style={{
                        width: "100%",
                        borderBottom: `1px solid ${COLORS.gray_200}`,
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <span>
                        <input
                          style={{
                            ...(has(
                              permissionsUpdates,
                              `${staffRole._id}.${key}`
                            )
                              ? {
                                  outline: `2px solid ${COLORS.primary}`,
                                }
                              : {}),
                          }}
                          disabled={
                            staffRole.isAdmin ||
                            !profilePermissions.writeRoles ||
                            putRequest.isLoading
                          }
                          type="checkbox"
                          checked={
                            has(permissionsUpdates, `${staffRole._id}.${key}`)
                              ? permissionsUpdates[staffRole._id][key]
                              : staffRole.permissions[key]
                          }
                          onChange={(event) => {
                            !staffRole.isAdmin &&
                              handleChange(
                                staffRole._id,
                                key,
                                event.target.checked
                              );
                          }}
                        />
                      </span>
                    </div>
                  );
                })}
              </Flex>
            </Flex>
          );
        })}
      </Flex>
    );
  };

  const handleSave = () => {
    putRequest.pending();
    axios
      .put(
        `${apiAddressV2(false)}/v2/hotels/${hotelId}/staff-roles/permissions`,
        { staffRoles: permissionsUpdates }
      )
      .then((res) => {
        const {
          data: { hotel },
        } = res;
        updateHotel(hotelId, hotel);
        toaster.push(notification("success", trans("Permissions updated")), {
          placement: "topEnd",
        });
        putRequest.resolve();
        setPermissionsUpdates({});
      })
      .catch((err) => {
        const error = getErrorMessage(err, trans);
        putRequest.reject(error);
        toaster.push(notification("error", error), { placement: "topEnd" });
      });
  };

  return (
    <Flex column gap={8}>
      <div className="table-wrapper permissions-checkboxes">
        <div
          style={{
            maxHeight: `${rootOutletContainerHeight - 330}px`,
            overflow: "auto",
            backgroundColor: COLORS.gray_0,
          }}
        >
          <Flex row>
            <Flex column gap={8}>
              <Flex
                center
                style={{
                  backgroundColor: COLORS.gray_200,
                  padding: "8px",
                  borderTopLeftRadius: "4px",
                }}
              >
                <InterTag
                  size={14}
                  text={"permissions"}
                  style={{ visibility: "hidden" }}
                  color={COLORS.secondary}
                />
              </Flex>
              {renderPermissionsLabels()}
            </Flex>
            <Flex
              one
              row
              style={{
                maxWidth: `${outletContainerWidth - 185}px`,
                overflowX: "auto",
              }}
            >
              {hotel.staffRoles.map((staffRole, i) => {
                return (
                  <Flex column one gap={8} key={staffRole._id}>
                    <Flex
                      center
                      style={{
                        backgroundColor: COLORS.gray_200,
                        padding: "8px",
                        ...(i + 1 === hotel.staffRoles.length
                          ? { borderTopRightRadius: "4px" }
                          : {}),
                      }}
                    >
                      <InterTag
                        size={14}
                        bold
                        text={staffRole.name}
                        color={COLORS.secondary}
                      />
                    </Flex>
                    {renderPermissionsCheckboxes(staffRole)}
                  </Flex>
                );
              })}
            </Flex>
          </Flex>
        </div>
      </div>
      <Flex row gap={8} right>
        <Button
          disabled={!hasChanges || putRequest.isLoading}
          style={{ paddingTop: "4px", paddingBottom: "4px" }}
          appearance="primary"
          onClick={() => setPermissionsUpdates({})}
        >
          {trans("Reset")}
        </Button>
        <Button
          disabled={!hasChanges || putRequest.isLoading}
          style={{ paddingTop: "4px", paddingBottom: "4px" }}
          appearance="primary"
          onClick={handleSave}
        >
          {trans("Save")}
        </Button>
      </Flex>
    </Flex>
  );
};

export default PermissionsTab;
