import { has, min, round } from "lodash";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Table } from "rsuite";
import { ReactComponent as ContentCopyIcon } from "../../../assets/icons/content_copy.svg";
import { ReactComponent as EcoIcon } from "../../../assets/icons/eco.svg";
import { ReactComponent as ElectricBoltIcon } from "../../../assets/icons/electric_bolt.svg";
import { ReactComponent as EventLeavingIcon } from "../../../assets/icons/event_leaving.svg";
import { ReactComponent as EventUpcomingIcon } from "../../../assets/icons/event_upcoming.svg";
import { ReactComponent as GroupIcon } from "../../../assets/icons/group.svg";
import { ReactComponent as HotelIcon } from "../../../assets/icons/hotel.svg";
import { ReactComponent as PaymentsIcon } from "../../../assets/icons/payments.svg";
import { ReactComponent as VisibilityIcon } from "../../../assets/icons/visibility.svg";
import { ReactComponent as WaterDropIcon } from "../../../assets/icons/water_drop.svg";
import Flex from "../../../components/Flex";
import PageSectionTitle from "../../../components/PageSectionTitle";
import SimpleActionCell from "../../../components/RsuiteWrapper/SimpleTable/SimpleCells/SimpleActionCell";
import SimpleDateCell from "../../../components/RsuiteWrapper/SimpleTable/SimpleCells/SimpleDateCell";
import SimpleTextCell from "../../../components/RsuiteWrapper/SimpleTable/SimpleCells/SimpleTextCell";
import SimpleHeaderCell from "../../../components/RsuiteWrapper/SimpleTable/SimpleHeaderCell";
import { tSimpleWhisperPopoverDropdownOptions } from "../../../components/RsuiteWrapper/SimpleWhisperPopoverDropdown";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import { tUseGetRequest } from "../../../hooks/apiRequests/useGetRequest";
import useScreenSize from "../../../hooks/useScreenSize";
import { tReservation } from "../../../models/reservation";
import {
  convertEnergyUnit,
  convertMassUnit,
  convertVolumeUnit,
} from "../../../utils/convertUnits";
import { copyToClipboard } from "../../../utils/others";
import { tGuestRequest, tGuestReservations } from "./guest";

interface iPreviousStaysWrappedProps
  extends Pick<iProps, "staysRequest" | "guestRequest"> {
  outletContainerWidth: number;
}
type tTableItem = tReservation;

const PreviousStaysWrapped: React.FC<iPreviousStaysWrappedProps> = ({
  staysRequest,
  guestRequest,
  outletContainerWidth,
}) => {
  const navigate = useNavigate();
  const { trans } = useLocalizationState();

  const tableData: tTableItem[] = staysRequest.data.reservations.past.map(
    (r) => r
  );

  const options = (
    dataKey: string,
    item: tTableItem
  ): tSimpleWhisperPopoverDropdownOptions[] => {
    const options: tSimpleWhisperPopoverDropdownOptions[] = [];

    options.push({
      key: "view stay",
      label: trans("View Stay"),
      onClick() {
        navigate(`/reservations/${item._id}`, { state: { reservation: item } });
      },
      icon: VisibilityIcon,
    });

    options.push({
      key: "copykey",
      label: trans("Copy Id"),
      icon: ContentCopyIcon,
      onClick() {
        copyToClipboard(item._id);
      },
    });

    return options;
  };

  const rowHeight = 60;
  const headerHeight = 50;
  const tableHeight = staysRequest.isLoading
    ? rowHeight + headerHeight
    : min([400, tableData.length * rowHeight + headerHeight]);

  // const numberOfGuestsWidth = floor(0.15 * outletContainerWidth);
  // const checkInWidth = floor(0.15 * outletContainerWidth);
  // const checkOutWidth = floor(0.15 * outletContainerWidth);
  // const costsWidth = floor(0.15 * outletContainerWidth);
  // const ecoWidth = floor(0.15 * outletContainerWidth);

  return (
    <div className="table-wrapper">
      <Table
        id="TABLE.GUEST.PREVIOUS-STAYS"
        height={tableHeight}
        rowHeight={rowHeight}
        headerHeight={headerHeight}
        loading={staysRequest.isLoading}
        data={tableData}
      >
        <Table.Column flexGrow={1}>
          <SimpleHeaderCell icon={GroupIcon} name={trans("Guests")} />
          <SimpleTextCell dataKey="numberOfGuests" />
        </Table.Column>
        <Table.Column flexGrow={1}>
          <SimpleHeaderCell icon={EventUpcomingIcon} name={trans("Check-In")} />
          <SimpleDateCell dataKey="startAt" />
        </Table.Column>
        <Table.Column flexGrow={1}>
          <SimpleHeaderCell icon={EventLeavingIcon} name={trans("Check-Out")} />
          <SimpleDateCell dataKey="endAt" />
        </Table.Column>
        <Table.Column flexGrow={1}>
          <SimpleHeaderCell icon={PaymentsIcon} name={trans("Costs")} />
          <SimpleTextCell
            dataKey="consumption.costs"
            textFunction={(item: tTableItem) =>
              `${round(item.consumption.costs, 2)} €`
            }
          />
        </Table.Column>
        <Table.Column flexGrow={1}>
          <SimpleHeaderCell icon={EcoIcon} name={trans("Footprint")} />
          <SimpleTextCell
            dataKey="consumption.co2"
            textProps={() => ({ asHTML: true })}
            textFunction={(item: tTableItem) => {
              const converted = convertMassUnit(item.consumption.co2);
              return `${round(converted.value, 2)} ${converted.unitInHTML}`;
            }}
          />
        </Table.Column>
        <Table.Column flexGrow={1}>
          <SimpleHeaderCell
            icon={ElectricBoltIcon}
            name={trans("Electricity")}
          />
          <SimpleTextCell
            dataKey="consumption.measures.te"
            textProps={() => ({ asHTML: true })}
            textFunction={(item: tTableItem) => {
              if (!has(item.consumption.measures, "te")) return "Not measured";
              const converted = convertEnergyUnit(
                item.consumption.measures.te || 0
              );
              return `${round(converted.value, 2)} ${converted.unitInHTML}`;
            }}
          />
        </Table.Column>
        <Table.Column flexGrow={1}>
          <SimpleHeaderCell icon={WaterDropIcon} name={trans("Water")} />
          <SimpleTextCell
            dataKey="consumption.measures.tw"
            textProps={() => ({ asHTML: true })}
            textFunction={(item: tTableItem) => {
              if (!has(item.consumption.measures, "tw")) return "Not measured";
              const converted = convertVolumeUnit(
                item.consumption.measures.tw || 0
              );
              return `${round(converted.value, 2)} ${converted.unitInHTML}`;
            }}
          />
        </Table.Column>
        <Table.Column flexGrow={1} align="right">
          <SimpleHeaderCell name={{ text: "" }} />
          <SimpleActionCell options={options} dataKey="_id" />
        </Table.Column>
      </Table>
    </div>
  );
};

interface iProps {
  staysRequest: tUseGetRequest<tGuestReservations>;
  guestRequest: tGuestRequest;
}

const PreviousStays: React.FC<iProps> = ({ staysRequest, guestRequest }) => {
  const { trans } = useLocalizationState();
  const { outlet } = useScreenSize();

  if (!outlet) return null;

  return (
    <Flex column gap={16}>
      <PageSectionTitle
        title={trans("Previous stays")}
        description={trans(
          "A restrospective look at the guest's past stays and patterns"
        )}
        icon={HotelIcon}
      />
      <PreviousStaysWrapped
        {...{
          outletContainerWidth: outlet.width,
          staysRequest,
          guestRequest,
        }}
      />
    </Flex>
  );
};

export default PreviousStays;
