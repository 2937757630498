import React, { useContext, useEffect, useState } from "react";
import { Toggle } from "rsuite";
import InterTag from "../../../components/Text/Inter";
import TopBar from "../../../components/TopBar";
import useHotelState from "../../../context/Hotel/hooks/useHotelState";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import WithContextProvider from "../../../hoc/withProvider";
import useProfilePermissions from "../../../hooks/useProfilePermissions";
import { nRequestStatus } from "../../../interfaces/apiCalls";
import { REQUEST_STATUS } from "../../../utils/apiCall";
import { COLORS } from "../../../utils/colors";
import Archives from "./archives";
import OccupancyContextProvider, { OccupancyContext } from "./context";
import Manual from "./manual";

const Occupancy: React.FC = () => {
  const { trans } = useLocalizationState();
  const { activeSubscriptionType, hotelLoading } = useHotelState();
  const [dataTypeState, setDataTypeState] = useState<{
    status: nRequestStatus.tStatus;
    dataType: "archive" | "manual" | null;
  }>({ status: REQUEST_STATUS.PENDING, dataType: null });
  const context = useContext(OccupancyContext);
  const profilePermissions = useProfilePermissions();

  useEffect(() => {
    setTimeout(() => {
      const get = () => {
        const stored = localStorage.getItem("__o_dt__");

        switch (stored) {
          case "archive":
            return "archive";
          case "manual":
            return "manual";
        }
        return "archive";
      };
      if (hotelLoading) return;
      if (activeSubscriptionType === "starter")
        setDataTypeState({
          status: REQUEST_STATUS.RESOLVED,
          dataType: "manual",
        });

      const dataType = get();
      setDataTypeState({ status: REQUEST_STATUS.RESOLVED, dataType });
    }, 200);
  }, [activeSubscriptionType, hotelLoading]);

  const handleSetDataType = (dataType: "manual" | "archive") => {
    setDataTypeState((prev) => ({ ...prev, dataType }));
    localStorage.setItem("__o_dt__", dataType);
  };

  const dataTypeToggle =
    activeSubscriptionType === "starter" ? (
      <></>
    ) : (
      <Toggle
        loading={
          dataTypeState.status === REQUEST_STATUS.PENDING ||
          Object.values(context.state.dataTypeToggleDisabled).some((v) => v)
        }
        className={"toggle-data-type"}
        onChange={(checked) => {
          if (
            !(
              dataTypeState.status === REQUEST_STATUS.PENDING ||
              Object.values(context.state.dataTypeToggleDisabled).some((v) => v)
            )
          )
            handleSetDataType(checked ? "manual" : "archive");
        }}
        checked={dataTypeState.dataType === "manual"}
        checkedChildren={trans("Live")}
        unCheckedChildren={trans("History")}
      />
    );

  return (
    <div>
      <TopBar>
        <InterTag
          text={trans("Occupancy")}
          bold
          color={COLORS.secondary}
          size={24}
        />
      </TopBar>
      {dataTypeState.status === REQUEST_STATUS.RESOLVED ? (
        dataTypeState.dataType === "manual" ? (
          <Manual {...{ dataTypeToggle }} />
        ) : (
          <Archives {...{ dataTypeToggle, profilePermissions }} />
        )
      ) : null}
    </div>
  );
};

export default WithContextProvider(OccupancyContextProvider, Occupancy);
