import { tTrans } from "../context/Localization/utils";
import { tHotel } from "../models/hotel";
import { tHotelActionLog } from "../models/hotelActionLog";

export const translateHotelActionsAction = (
  action: tHotelActionLog["action"],
  trans: tTrans
) => {
  switch (action) {
    case "create":
      return trans("created");
    case "update":
      return trans("updated");
    case "delete":
      return trans("deleted");
    default:
      return "";
  }
};

export const translateHotelActionsHotelProperty = (
  hotelProperty: keyof tHotel | "stays",
  trans: tTrans,
  plural: boolean = false
) => {
  switch (hotelProperty) {
    case "awards":
      return plural ? trans("Awards") : trans("Award");

    case "challenges":
      return plural ? trans("Challenges") : trans("Challenge");

    case "stays":
      return plural ? trans("Stays") : trans("Stay");

    default:
      return "";
  }
};
