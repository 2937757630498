import { uniq } from "lodash";
import { useEffect, useState } from "react";

// const body = document.body,
//   html = document.documentElement;

//   ` body.scrollHeight,
//   body.offsetHeight,
//   html.clientHeight,
//   html.scrollHeight,
//   html.offsetHeight`,
//   body.scrollHeight,
//   body.offsetHeight,
//   html.clientHeight,
//   html.scrollHeight,
//   html.offsetHeight
// );

// const height = Math.max(
//   body.scrollHeight,
//   body.offsetHeight,
//   html.clientHeight,
//   html.scrollHeight,
//   html.offsetHeight
// );

const defaultIds = ["root-outlet-container", "outlet"];

const useScreenSize = (
  _ids: string[] = []
): Record<
  "root-outlet-container" | "outlet" | string,
  { height: number; width: number }
> => {
  const [screenSize, setScreenSize] = useState<
    Record<string, { width: number; height: number }>
  >(() => {
    return { window: { width: window.innerWidth, height: window.innerHeight } };
  });
  const [ids] = useState(uniq([..._ids, ...defaultIds]));

  useEffect(() => {
    const updateDimension = () => {
      const obj: Record<string, { width: number; height: number }> = {};

      ids.forEach((id) => {
        const elem = document.getElementById(id);

        if (elem) {
          const height = Math.max(
            elem.scrollHeight,
            elem.offsetHeight,
            elem.clientHeight,
            elem.scrollHeight,
            elem.offsetHeight
          );
          const width = Math.max(
            elem.scrollWidth,
            elem.offsetWidth,
            elem.clientWidth,
            elem.scrollWidth,
            elem.offsetWidth
          );
          obj[id] = { height, width };
        }
      });

      setScreenSize({
        window: {
          width: window.innerWidth,
          height: window.innerHeight,
        },
        ...obj,
      });
    };
    window.addEventListener("resize", updateDimension);

    return () => {
      window.removeEventListener("resize", updateDimension);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [screenSize, JSON.stringify(ids)]);

  useEffect(() => {
    const obj: Record<string, { width: number; height: number }> = {};

    ids.forEach((id) => {
      const elem = document.getElementById(id);

      if (elem) {
        const height = Math.max(
          elem.scrollHeight,
          elem.offsetHeight,
          elem.clientHeight,
          elem.scrollHeight,
          elem.offsetHeight
        );
        const width = Math.max(
          elem.scrollWidth,
          elem.offsetWidth,
          elem.clientWidth,
          elem.scrollWidth,
          elem.offsetWidth
        );
        obj[id] = { height, width };
      }
    });
    if (Object.keys(obj).length) setScreenSize((prev) => ({ ...prev, ...obj }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(ids)]);

  return { ...screenSize };
};

export default useScreenSize;
