import React from "react";
import { Loader } from "rsuite";
import { ReactComponent as ControllerIcon } from "../../../assets/icons/stadia_controller.svg";
import Flex from "../../../components/Flex";
import NoPermissions from "../../../components/NoPermissions";
import PageBottomPadding from "../../../components/PageBottomPadding";
import PageSectionTitle from "../../../components/PageSectionTitle";
import InterTag from "../../../components/Text/Inter";
import TopBar from "../../../components/TopBar";
import useHotelSubscriptionState from "../../../context/Hotel/hooks/hotelState/useHotelSubscriptionState";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import useProfilePermissions from "../../../hooks/useProfilePermissions";
import { COLORS } from "../../../utils/colors";
import ChallengesList from "./list";
import Unlock from "./unlock";

const Challenges: React.FC = () => {
  const { trans } = useLocalizationState();
  const { hotelLoading, hotelIsLoaded, activeSubscriptionIsStarter } =
    useHotelSubscriptionState();
  const profilePermissions = useProfilePermissions();

  const renderMain = () => {
    if (hotelLoading)
      return (
        <Flex middle center>
          <Loader size="lg" />
        </Flex>
      );

    if (hotelIsLoaded) {
      if (activeSubscriptionIsStarter)
        return (
          <>
            <Unlock />
            <PageBottomPadding />
          </>
        );

      if (!profilePermissions.readChallenges) {
        return <NoPermissions />;
      }

      return (
        <Flex column gap={40}>
          <Flex column gap={16}>
            <PageSectionTitle
              title={trans("Challenges List")}
              description={trans(
                "Browse all challenges created to inspire and engage your guests"
              )}
              icon={{ Element: ControllerIcon }}
            />
            <ChallengesList />
          </Flex>
        </Flex>
      );
    }
  };

  return (
    <>
      <TopBar>
        <InterTag
          text={trans("Challenges")}
          size={24}
          bold
          color={COLORS.secondary}
        />
      </TopBar>
      {renderMain()}
      <PageBottomPadding />
    </>
  );
};

export default Challenges;
