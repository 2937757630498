import { forwardRef } from "react";
import { tIcon, tIconElement } from "../../../interfaces/icon";
import Flex from "../../Flex";
import Icon from "../../Icon";
import { iTextProps } from "../../Text";
import InterTag from "../../Text/Inter";

import { Button, ButtonProps } from "rsuite";
import { toTextProps } from "../../Text/utils";
import { COLORS } from "../../../utils/colors";
import { toIconProps } from "../../Icon/utils";

interface iProps extends ButtonProps {
  label: string | iTextProps;
  icon?: tIcon | tIconElement;
}

const PrimaryButton = forwardRef<HTMLButtonElement, iProps>(
  ({ label, icon, ...props }, ref) => {
    return (
      <Button ref={ref} appearance="primary" {...{ ...props }}>
        <Flex
          row
          gap={8}
          middle
          style={{ ...(props.loading ? { visibility: "hidden" } : {}) }}
        >
          <InterTag
            {...{ color: COLORS.white, size: 12, ...toTextProps(label) }}
          />
          {icon && (
            <Icon {...{ fill: COLORS.white, size: 20, ...toIconProps(icon) }} />
          )}
        </Flex>
      </Button>
    );
  }
);

export default PrimaryButton;
