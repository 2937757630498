import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "rsuite";
import Flex from "../../../components/Flex";
import HistoricDataConsumptionGraphSection from "../../../components/HistoricDataConsumptionGraphSection";
import ManualDataModal from "../../../components/Modals/ManualDataModal";
import PageBottomPadding from "../../../components/PageBottomPadding";
import RoadmapTable from "../../../components/RoadmapTable";
import InterTag from "../../../components/Text/Inter";
import TopBar from "../../../components/TopBar";
import useHotelState from "../../../context/Hotel/hooks/useHotelState";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import useGetDefaultConverionFactors from "../../../hooks/useGetDefaultConverionFactors";
import useProfilePermissions from "../../../hooks/useProfilePermissions";
import { COLORS } from "../../../utils/colors";
import { ConsumptionContext } from "../consumption/context";
import Showcasing from "./showcasing";
import Unlock from "./unlock";

interface iFuelsWrappedProps {}

const FuelsWrapped2: React.FC<iFuelsWrappedProps> = () => {
  const { setDataTypeToggleDisabled } = useContext(ConsumptionContext);
  const { measuresTrackedInManualData } = useHotelState();
  const conversionFactorsRequest = useGetDefaultConverionFactors("PT", {
    doRequest: measuresTrackedInManualData.electricity,
  });

  useEffect(() => {
    setDataTypeToggleDisabled({
      conversionFactorsRequest: conversionFactorsRequest.isLoading,
    });
  }, [conversionFactorsRequest.isLoading, setDataTypeToggleDisabled]);

  return (
    <Flex column gap={40}>
      <HistoricDataConsumptionGraphSection
        {...{ conversionFactorsRequest, measure: "fuels" }}
      />
      {/* <Graph /> */}
      <Showcasing />
      <RoadmapTable filterByType="fuels" />
    </Flex>
  );
};

const FuelsWrapped: React.FC = () => {
  const { trans } = useLocalizationState();
  const { hotelIsLoaded, activeSubscriptionType } = useHotelState();
  const profilePermissions = useProfilePermissions();
  const [manualDataModal, setManualDataModal] = useState({ open: false });

  return (
    <>
      {!hotelIsLoaded ? null : activeSubscriptionType === "starter" ? (
        <Unlock />
      ) : (
        <div>
          {profilePermissions.writeManualData && (
            <ManualDataModal
              open={manualDataModal.open}
              initialStep="naturalGas"
              singleStep
              onClose={() => setManualDataModal({ open: false })}
            />
          )}
          <div
            style={{
              position: "fixed",
              top: 70,
              width: "100%",
              zIndex: 3,
              paddingBottom: "8px",
              backgroundColor: COLORS.app_background,
            }}
          >
            <Flex row middle gap={12} style={{ height: "36px" }}>
              {profilePermissions.writeManualData && (
                <Button
                  appearance="primary"
                  onClick={() => setManualDataModal({ open: true })}
                >
                  {trans("Add Entry")}
                </Button>
              )}
            </Flex>
          </div>
          <div style={{ marginTop: "40px" }}>
            <FuelsWrapped2 />
          </div>
        </div>
      )}
    </>
  );
};

const Fuels: React.FC = () => {
  const navigate = useNavigate();
  const { trans } = useLocalizationState();

  return (
    <div>
      <TopBar>
        <Flex gap={8} center column style={{ height: "100%" }}>
          <InterTag
            hoverUnderline
            onClick={() => {
              navigate("/consumption");
            }}
            text={trans("Consumption")}
            size={12}
            color={COLORS.secondary}
          />
          <InterTag
            text={trans("Fuels")}
            size={24}
            bold
            color={COLORS.secondary}
          />
        </Flex>
      </TopBar>
      <FuelsWrapped />
      <PageBottomPadding />
    </div>
  );
};

export default Fuels;
