import { omit } from "lodash";
import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Loader } from "rsuite";
import { ReactComponent as DeleteIcon } from "../../../../assets/icons/delete.svg";
import { ReactComponent as LaundryIcon } from "../../../../assets/icons/local_laundry_service.svg";
import { ReactComponent as DirectionsCarIcon } from "../../../../assets/icons/directions_car.svg";
import { ReactComponent as ElectricBoltIcon } from "../../../../assets/icons/electric_bolt.svg";
import { ReactComponent as ModeHeatIcon } from "../../../../assets/icons/mode_heat.svg";
import { ReactComponent as WaterDropIcon } from "../../../../assets/icons/water_drop.svg";
import Flex from "../../../../components/Flex";
import HistoricDataConsumptionGraphSection from "../../../../components/HistoricDataConsumptionGraphSection";
import Icon from "../../../../components/Icon";
import InterTag from "../../../../components/Text/Inter";
import useHotelManualDataState from "../../../../context/Hotel/hooks/hotelState/useHotelManualDataState";
import useLocalizationState from "../../../../context/Localization/hooks/useLocalizationState";
import useGetDefaultConverionFactors from "../../../../hooks/useGetDefaultConverionFactors";
import { tIcon } from "../../../../interfaces/icon";
import { COLORS } from "../../../../utils/colors";
import { ConsumptionContext } from "../context";
import WasteGraph from "../../waste/graph";
import LaundryGraph from "../../laundry/graph";
import FleetGraph from "../../fleet/vehicles/graph";
import PageSectionTitle from "../../../../components/PageSectionTitle";

interface iHistoricDataWrappedProps {
  dataTypeToggle: JSX.Element;
}

const HistoricDataWrapped: React.FC<iHistoricDataWrappedProps> = ({
  dataTypeToggle,
}) => {
  const navigate = useNavigate();
  const { setDataTypeToggleDisabled } = useContext(ConsumptionContext);
  const { trans } = useLocalizationState();
  const { measuresTrackedInManualData } = useHotelManualDataState();

  const conversionFactorsRequest = useGetDefaultConverionFactors("PT", {
    doRequest:
      Object.values(measuresTrackedInManualData).filter((v) => v).length > 0,
  });

  const measuresTrackedList: {
    label: string;
    icon: tIcon;
    component: { Component: React.FC<any>; props: any };
    linkTo: string;
  }[] = [
    {
      label: trans("Electricity"),
      icon: { Element: ElectricBoltIcon, fill: COLORS.energy },
      isMeasured: measuresTrackedInManualData.electricity,
      component: {
        Component: HistoricDataConsumptionGraphSection,
        props: { conversionFactorsRequest, measure: "electricity" },
      },
      linkTo: "/consumption/electricity",
    },
    {
      label: trans("Water"),
      icon: { Element: WaterDropIcon, fill: COLORS.water },
      isMeasured: measuresTrackedInManualData.water,
      component: {
        Component: HistoricDataConsumptionGraphSection,
        props: { conversionFactorsRequest, measure: "water" },
      },
      linkTo: "/consumption/water",
    },
    {
      label: trans("Fuels"),
      icon: { Element: ModeHeatIcon, fill: COLORS.fuels },
      isMeasured: measuresTrackedInManualData.fuels,
      component: {
        Component: HistoricDataConsumptionGraphSection,
        props: { conversionFactorsRequest, measure: "fuels" },
      },
      linkTo: "/consumption/fuels",
    },
    {
      label: trans("Waste"),
      icon: { Element: DeleteIcon, fill: COLORS.waste },
      isMeasured: true,
      component: { Component: WasteGraph, props: {} },
      linkTo: "/consumption/waste",
    },
    {
      label: trans("Laundry"),
      icon: { Element: LaundryIcon, fill: COLORS.secondary },
      isMeasured: true,
      component: {
        Component: () => (
          <Flex column gap={16}>
            <PageSectionTitle
              {...{
                title: trans("Laundry Data"),
                description: trans(
                  "Comprehensive data showcasing detailed laundry usage accross your property."
                ),
                icon: LaundryIcon,
              }}
            />

            <LaundryGraph />
          </Flex>
        ),
        props: {},
      },
      linkTo: "/consumption/laundry",
    },
    {
      label: trans("Fleet"),
      icon: { Element: DirectionsCarIcon, fill: COLORS.secondary },
      isMeasured: true,
      component: {
        Component: () => (
          <Flex column gap={16}>
            <PageSectionTitle
              title={trans("Fleet Overview")}
              description={trans(
                "A snapshot of your current and historical fleet usage patterns."
              )}
              icon={DirectionsCarIcon}
            />
            <FleetGraph />
          </Flex>
        ),

        props: {},
      },
      linkTo: "/consumption/fleet",
    },
  ].map((obj) => omit(obj, ["isMeasured"]));

  useEffect(() => {
    setDataTypeToggleDisabled({
      conversionFactorsRequest: conversionFactorsRequest.isLoading,
    });
  }, [conversionFactorsRequest.isLoading, setDataTypeToggleDisabled]);

  return (
    <div>
      <div
        style={{
          position: "fixed",
          zIndex: 3,
          top: 70,
          width: "100%",
          backgroundColor: COLORS.app_background,
          paddingBottom: "8px",
        }}
      >
        <Flex row gap={16}>
          <Flex middle>{dataTypeToggle}</Flex>
          {measuresTrackedList.map(({ label, icon, linkTo }) => {
            return (
              <Flex
                key={label}
                row
                gap={6}
                color={COLORS.white}
                middle
                className={"hover-box-shadow"}
                style={{
                  cursor: "pointer",
                  padding: "0 16px",
                  height: "34px",
                  borderRadius: "8px",
                }}
                onClick={() => navigate(linkTo)}
              >
                <Icon {...{ size: 24, ...icon }} />
                <InterTag size={14} color={COLORS.secondary} text={label} />
              </Flex>
            );
          })}
        </Flex>
      </div>
      <Flex column gap={40} style={{ marginTop: "40px" }}>
        {measuresTrackedList.map(
          ({ label, component: { Component, props } }) => (
            <Component key={label} {...props} />
          )
        )}
      </Flex>
    </div>
  );
};

interface iProps {
  dataTypeToggle: JSX.Element;
}

const HistoricData: React.FC<iProps> = ({ dataTypeToggle }) => {
  const { setDataTypeToggleDisabled } = useContext(ConsumptionContext);
  const { hotelLoading, manualDataOrdered } = useHotelManualDataState();

  useEffect(() => {
    setDataTypeToggleDisabled({ hotel: hotelLoading });
  }, [hotelLoading, setDataTypeToggleDisabled]);

  if (hotelLoading)
    return (
      <Flex center middle>
        <Loader size="lg" />
      </Flex>
    );
  if (manualDataOrdered.length === 0) return null;

  return <HistoricDataWrapped {...{ dataTypeToggle }} />;
};

export default HistoricData;
