import React, { useMemo } from "react";
import { ReactComponent as BuildYourHomeAmico } from "../../../assets/Build your home-amico 1.svg";
import Flex from "../../../components/Flex";
import Icon from "../../../components/Icon";
import InterTag from "../../../components/Text/Inter";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import { COLORS } from "../../../utils/colors";
import AddButton from "./addButton";
import { isArray } from "lodash";

const NoSpaces: React.FC<{
  category: "space" | "type" | "group" | "zone";
}> = ({ category }) => {
  const { trans } = useLocalizationState();

  const [title, firstDescription, addFirst, clickToAdd] = useMemo(() => {
    switch (category) {
      case "space":
        return [
          "Welcome to your Spaces!",
          "It seems you haven't set up any Spaces yet. Spaces are distinct areas within your accommodation like rooms, lobbies, or conference areas. Here's where you'll manage and view all areas of your accommodation. Adding Spaces will enable you to track and optimize energy and water usage efficiently.",
          "Add your first Space",
          "Click the 'Add' button below to create your first Space",
        ];
      case "type":
        return [
          "Welcome to your Types!",
          "It seems you haven't set up any Types yeat. Classify Spaces by their similar internal characteristics to ensure uniform standards of service and maintenance. Types might include categorizations like single rooms, suites, or event halls.",
          "Add your first Type",
          "Click the 'Add' button below to create your first Type",
        ];
      case "group":
        return [
          "Welcome to your Groups!",
          "It seems you haven't set up any Groups yet. Define Groups to classify Spaces that fullfil fulfill the same operational role within your accommodation, enhancing strategic management and performance analysis. For instance, you can group all restaurants, spas, or reception areas to monitor and manage their functionality.",
          "Add your first Group",
          "Click the 'Add' button below to create your first Group",
        ];
      case "zone":
        return [
          "Welcome to your Zones!",
          "It seems you haven't set up any Zones yet. Categorize Spaces based on their physical location within the accommodation, such as specific floors, wings, or buildings. Zoning helps in tailoring operations and sustainability initiatives to the unique characteristics of each area.",
          "Add your first Zone",
          "Click the 'Add' button below to create your first Zone",
        ];
    }
  }, [category]);

  return (
    <Flex column middle gap={40}>
      <Icon Element={BuildYourHomeAmico} size={250} />
      <Flex column middle gap={16} style={{ margin: "0 70px" }}>
        <InterTag size={36} text={trans(title)} color={COLORS.secondary} />
        {[
          trans(firstDescription),
          `<strong>${trans("Organize your Spaces")}:</strong>`,

          `<strong>${trans("Groups")}:</strong> ${trans(
            "Define Groups to classify Spaces that fulfill the same operational role within your accommodation, enhancing strategic management and performance analysis. For instance, you can graoup all restaurants, spas, or reception areas to monitor and manage their funcitonality collectively."
          )}`,
          `<strong>${trans("Zones")}:</strong> ${trans(
            "Categorize Spaces based on their physical location within the accommodation, such as specific floors, wings, or buildings. Zoning helps operations and sustainability initiatives to the unique characteristics of each area"
          )}.`,
          `<strong>${trans("Types")}:</strong> ${trans(
            "Classify Spaces by their similkar internal characteristics to ensure uniform standards of service and maintenance. Types might include categorizations like single rooms, suites, or event halls."
          )}`,
          `<strong>${trans(addFirst)}:</strong> ${trans(clickToAdd)}.`,
          `<strong>${trans("Need help?")}</strong> ${trans(
            "If you are not sure what to add or how to do it, our support team is here to walk you though the process"
          )}.`,
          trans(
            "Once you create your first Space, it will appear right here, ready for you to view and manage."
          ),
          trans(
            "Start shaping the way your hotel engages with sustainability today!"
          ),
        ].map((text, i) =>
          isArray(text) ? (
            <Flex row gap={5}>
              {text.map((t) => (
                <InterTag
                  key={i}
                  asHTML
                  size={16}
                  color={COLORS.secondary}
                  style={{
                    textWrap: "wrap",
                    textAlign: "center",
                    lineHeight: "22px",
                  }}
                  text={t}
                />
              ))}
            </Flex>
          ) : (
            <InterTag
              key={i}
              asHTML
              size={16}
              color={COLORS.secondary}
              style={{
                textWrap: "wrap",
                textAlign: "center",
                lineHeight: "22px",
              }}
              text={text}
            />
          )
        )}
      </Flex>
      <AddButton placement="top" />
    </Flex>
  );
};

export default NoSpaces;
