import { tHotel, tHotelSubscriptionModel } from "../../models/hotel";

export const BILLING_TYPES: tHotelSubscriptionModel["type"][] = [
  "starter",
  "business",
  "enterprise",
];

export const defaultHotel: tHotel = {
  _id: "",
  waterToCurrency: [],
  waterToFootprint: [],
  energyToCurrency: [],
  energyToFootprint: [],

  loyaltyProgram: [],
  performanceProgram: [],

  tourismRegister: { type: "", _id: 0 },

  awards: [],
  challenges: [],

  spaces: [],
  spaceAggregates: [],

  staffRoles: [],
  staff: [],

  roadmap: [],

  name: "",
  email: "",
  phoneNumber: "",

  accommodationType: "",
  accommodationNumberOfUnits: 0,
  bills: [],
  country: "",
  description: "",
  guestRecords: [],
  images: [],
  isDummyHotel: false,
  location: {
    address: "",
    mapsUrl: "",
    coordinates: [0, 0],
    country: "",
    county: "",
    district: "",
    postalCode: "",
  },
  readings: { energy: [], gas: [], water: [] },
  fleet: [],
  reports: [],
  website: "",
  timestreamDBName: "",
  hasTimestreamDB: false,

  manualData: [],
  isSetup: false,

  settings: { roadmap: true },

  subscriptions: [
    // {
    //   type: "starter",
    //   startAt: moment().toDate(),
    //   active: true,
    //   timestamp: moment().toDate(),
    // },
  ],
  firstSubscriptionIsSet: false,
  billing: [],
  invoices: [],
  paymentMethods: [],

  stripe: { customerId: "", defaultCard: "", invoices: [] },
  stripeLive: { customerId: "", defaultCard: "", invoices: [] },
  stripeTest: { customerId: "", defaultCard: "", invoices: [] },
};
