import React, { useContext } from "react";
import { ReactComponent as ElectricBoltIcon } from "../../../../assets/icons/electric_bolt.svg";
import { ReactComponent as QueryStatsIcon } from "../../../../assets/icons/query_stats.svg";
import ConsumptionPageSpecific from "../../../../components/ConsumptionPageSpecific";
import Flex from "../../../../components/Flex";
import PageBottomPadding from "../../../../components/PageBottomPadding";
import SimpleDateRangePicker from "../../../../components/RsuiteWrapper/SimpleDateRangePicker";
import InterTag from "../../../../components/Text/Inter";
import useHotelState from "../../../../context/Hotel/hooks/useHotelState";
import useLocalizationState from "../../../../context/Localization/hooks/useLocalizationState";
import usePeriodState from "../../../../hooks/usePeriodState";
import { TE } from "../../../../models/measures";
import { REQUEST_STATUS } from "../../../../utils/apiCall";
import { COLORS } from "../../../../utils/colors";
import {
  convertEnergyUnit,
  FORCE_ENERGY_MEASURE_UNIT,
} from "../../../../utils/convertUnits/";
import { ConsumptionContext } from "../../consumption/context";

const LiveData: React.FC<{ dataTypeToggle: JSX.Element | null }> = ({
  dataTypeToggle,
}) => {
  const { setDataTypeToggleDisabled } = useContext(ConsumptionContext);
  const { trans } = useLocalizationState();
  const {
    getMeasuresTracked,
    hotel: { spaces, energyToCurrency, energyToFootprint },
    hotelIsLoaded,
  } = useHotelState();
  const [period, setPeriod] = usePeriodState();

  const trackedMeasures = getMeasuresTracked(spaces.map((s) => s._id));

  return (
    <div>
      <div
        style={{
          position: "fixed",
          top: 70,
          width: "100%",
          zIndex: 3,
          paddingBottom: "8px",
          backgroundColor: COLORS.app_background,
        }}
      >
        <Flex row gap={12} middle>
          {dataTypeToggle}
          <SimpleDateRangePicker value={period} onChange={setPeriod} />
        </Flex>
      </div>
      <div style={{ marginTop: "40px" }}>
        {hotelIsLoaded ? (
          trackedMeasures.te ? (
            <ConsumptionPageSpecific
              mainMeasureRequestStatusTracker={(status) => {
                setDataTypeToggleDisabled({
                  electricityMainMeasureRequest:
                    status === REQUEST_STATUS.PENDING,
                });
              }}
              specificMeasuresRequestStatusTracker={(status) =>
                setDataTypeToggleDisabled({
                  electricitySpecificMeasuresRequest:
                    status === REQUEST_STATUS.PENDING,
                })
              }
              {...{
                period,
                mainMeasureKey: TE,
                overviewSection: {
                  title: trans("Electricity Overview"),
                  description: trans(
                    "A snapshot of your current and historical electricity usage patterns."
                  ),
                  icon: QueryStatsIcon,
                },
                spaceListSection: {
                  title: trans("Electricity Data"),
                  description: trans(
                    "Comprehensive data showcasing detailed electricity usage across your property"
                  ),
                  icon: ElectricBoltIcon,
                },
                converter: convertEnergyUnit,
                roadmapFilter: "electricity",
                tableHeader: {
                  icon: ElectricBoltIcon,
                  name: trans("Electricity"),
                },
                toCurrency: energyToCurrency,
                toFootprint: energyToFootprint,
                mainUnit: FORCE_ENERGY_MEASURE_UNIT,
              }}
            />
          ) : (
            <Flex>
              <InterTag
                text={trans("Electricity is not being measured")}
                size={16}
                color={COLORS.secondary}
              />
            </Flex>
          )
        ) : null}
      </div>
      <PageBottomPadding />
    </div>
  );
};

export default LiveData;
