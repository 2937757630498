import axios from "axios";
import { cloneDeep, isNumber } from "lodash";
import React, { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Loader, Toggle, useToaster } from "rsuite";
import { ReactComponent as CheckBoxFilledIcon } from "../../../assets/icons/check_box_filled.svg";
import { ReactComponent as NoytrallSymbol } from "../../../assets/noytrall_symbol.svg";
import Flex from "../../../components/Flex";
import Icon from "../../../components/Icon";
import InterTag from "../../../components/Text/Inter";
import useAuthDispatch from "../../../context/Auth/hooks/useAuthDispatch";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import useGetRequest from "../../../hooks/apiRequests/useGetRequest";
import usePostRequest from "../../../hooks/apiRequests/usePostRequest";
import useEffectSafe from "../../../hooks/useEffectSafe";
import tHotel, { tHotelSubscriptionModel } from "../../../models/hotel";
import { apiAddressV2 } from "../../../utils/apiCall";
import { COLORS } from "../../../utils/colors";
import { defaultHotel } from "../../../utils/hotels";
import { getErrorMessage } from "../../../utils/httpResponses/others";
import { notification } from "../../../utils/notifications";
import BusinessPaymentModal from "./businessPaymentModal";

export type tBillingTimePeriod = "monthly" | "annualy";

interface iProps {}

const SelectPlan: React.FC<iProps> = () => {
  const toaster = useToaster();
  const navigate = useNavigate();
  const { trans } = useLocalizationState();
  const { login } = useAuthDispatch();
  const [billingTimePeriod, setBillingTimePeriod] =
    useState<tBillingTimePeriod>("monthly");
  const getLocalState = useGetRequest<{
    planToken: string;
    hotel: tHotel;
  }>({
    planToken: "",
    hotel: cloneDeep(defaultHotel),
  });
  const [businessPaymentModal, setBusinessPaymentModal] = useState({
    open: false,
  });
  const starterRequest = usePostRequest();

  useEffectSafe(() => {
    if (getLocalState.isLoading) {
      const planToken = sessionStorage.getItem("plan-token");
      const hotel = sessionStorage.getItem("plan-hotel");
      if (planToken && hotel) {
        getLocalState.resolve({ planToken, hotel: JSON.parse(hotel) });
      } else {
        navigate("/");
      }
    }
  }, [getLocalState.isLoading]);

  const handleSelectStarter = () => {
    starterRequest.pending();
    axios.defaults.headers.common["token"] = getLocalState.data.planToken;
    axios
      .post(
        `${apiAddressV2(false)}/v2/hotels/${
          getLocalState.data.hotel._id
        }/confirm-first-plan/starter`
      )
      .then((res) => {
        login(getLocalState.data.planToken);
      })
      .catch((err) => {
        const error = getErrorMessage(err, trans);
        starterRequest.reject(error);
        toaster.push(notification("error", error), { placement: "topEnd" });
      });
  };

  if (getLocalState.isLoading)
    return (
      <Flex center middle>
        <Flex
          center
          middle
          style={{
            backgroundColor: COLORS.light_white,
            borderRadius: "16px",
            padding: "32px 64px",
            width: "80%",
          }}
        >
          <Loader size="lg" />
        </Flex>
      </Flex>
    );

  const list: {
    key: tHotelSubscriptionModel["type"];
    title: string;
    description: string;
    price: Record<tBillingTimePeriod, number | string>;
    priceDescription: Record<tBillingTimePeriod, string>;
    perks: string[];
    perksCheckColor: string;
    button: JSX.Element;
  }[] = [
    {
      key: "starter",
      title: "Starter",
      description: "Begin your sustainability journey",
      price: { monthly: 0, annualy: 0 },
      priceDescription: { monthly: "Free", annualy: "Free" },
      perks: [
        "Monthly tracking of water and energy consumpiton.",
        "Basic environmental footprint assessment to help identify areas for improvement.",
        "Single user access, perfect for small properties ot those just beginning their sustainability path.",
      ],
      perksCheckColor: COLORS.secondary,
      button: (
        <Button
          appearance="primary"
          style={{ width: "80%" }}
          loading={starterRequest.isLoading}
          onClick={handleSelectStarter}
        >
          <strong style={{ fontSize: "16px" }}>{trans("Select")}</strong>
        </Button>
      ),
    },
    {
      key: "business",
      title: "Business",
      description: "Advanced tools for sustainable growth",
      price: { monthly: 50, annualy: 45 },
      priceDescription: {
        monthly: "Billed monthly",
        annualy: "Per month, billed annualy",
      },
      perks: [
        "Daily tracking of water, energy, fuels, waste and more, allowing for more precise management and quicker response to sustainability challenges.",
        "Access to the Sustainability Roadmap, providing actionable steps to enhance eco-friendly operations.",
        "Advanced analytics to monitor the effectiveness of sustainability initiatives and tailor future actions.",
        "Access for three users.",
      ],
      perksCheckColor: COLORS.primary,
      button: (
        <Button
          appearance="primary"
          style={{ width: "80%" }}
          disabled={starterRequest.isLoading}
          onClick={() => setBusinessPaymentModal({ open: true })}
        >
          <strong style={{ fontSize: "16px" }}>{trans("Select")}</strong>
        </Button>
      ),
    },
    {
      key: "enterprise",
      title: "Enterprise",
      description: "Achieve sustainability leadership",
      price: { monthly: "Custom", annualy: "Custom" },
      priceDescription: {
        monthly: "Billed monthly",
        annualy: "Per month, billed annualy",
      },
      perks: [
        "Scalable infrastructure for multi-property management",
        "Gamification features to engage staff and guests in sustainability efforts.",
        "Custom integrations and API access for seamless operation accross systems.",
        "Dedicated account manager and 24/7 premium support.",
        "Advanced security features and comprehensive data access controls.",
      ],
      perksCheckColor: COLORS.primary,
      button: (
        <Button
          onClick={() => {
            window.open(
              "https://supercal.io/miguelrolinhoclemente/noytrall",
              "_blank",
              "noreferrer"
            );
          }}
          appearance="ghost"
          style={{ width: "80%" }}
          disabled={starterRequest.isLoading}
        >
          <strong style={{ fontSize: "16px" }}>{trans("Contact Us")}</strong>
        </Button>
      ),
    },
  ];
  const renderCards = () => {
    return list.map(
      ({
        key,
        description,
        perks,
        perksCheckColor,
        price,
        priceDescription,
        title,
        button,
      }) => {
        return (
          <Flex key={key} column between gap={24} className="card-m" one>
            <Flex column gap={24}>
              <Flex column gap={8}>
                <Flex row gap={8} middle>
                  <Icon Element={NoytrallSymbol} size={30} />
                  <InterTag
                    text={trans(title)}
                    size={20}
                    bold
                    color={COLORS.secondary}
                  />
                </Flex>
                <InterTag
                  text={trans(description)}
                  color={COLORS.gray}
                  size={14}
                  style={{ textWrap: "wrap", textAlign: "left" }}
                />
              </Flex>
              <Flex column gap={16} middle>
                <Flex row gap={3} bottom>
                  <InterTag
                    size={48}
                    text={price[billingTimePeriod]}
                    color={COLORS.secondary}
                  />
                  {isNumber(price[billingTimePeriod]) && (
                    <InterTag size={18} text={"€"} color={COLORS.secondary} />
                  )}
                </Flex>
                <InterTag
                  text={priceDescription[billingTimePeriod]}
                  size={14}
                  color={COLORS.gray}
                />
              </Flex>
              <Flex column gap={12}>
                {perks.map((perk) => (
                  <Flex row gap={16} middle key={perk}>
                    <div>
                      <Icon
                        Element={CheckBoxFilledIcon}
                        fill={perksCheckColor}
                        size={20}
                      />
                    </div>
                    <InterTag
                      size={14}
                      colro={COLORS.secondary}
                      text={trans(perk)}
                      style={{ textWrap: "wrap", lineHeight: "18px" }}
                    />
                  </Flex>
                ))}
              </Flex>
            </Flex>
            <Flex center middle>
              {button}
            </Flex>
          </Flex>
        );
      }
    );
  };

  return (
    <Fragment>
      <BusinessPaymentModal
        billingTimePeriod={billingTimePeriod}
        open={businessPaymentModal.open}
        onClose={() => setBusinessPaymentModal({ open: false })}
      />
      <Flex
        center
        middle
        style={{ backgroundColor: COLORS.secondary, paddingBottom: "24px" }}
      >
        <Flex
          column
          gap={16}
          style={{
            backgroundColor: COLORS.light_white,
            borderRadius: "16px",
            padding: "32px 64px",
            width: "80%",
            maxWidth: "1152px",
          }}
        >
          <Flex column middle>
            <InterTag
              text={trans("Confirm your subscription plan")}
              size={20}
              color={COLORS.secondary}
              bold
            />
          </Flex>
          <Flex row gap={8} middle center>
            <InterTag
              size={14}
              color={COLORS.secondary}
              text={trans("Billed monthly")}
            />
            <Toggle
              checked={billingTimePeriod === "annualy"}
              onChange={(checked) =>
                setBillingTimePeriod(checked ? "annualy" : "monthly")
              }
            />
            <InterTag
              size={14}
              color={COLORS.secondary}
              text={trans("Billed annualy (10% off)")}
            />
          </Flex>
          <Flex row gap={24}>
            {renderCards()}
          </Flex>
        </Flex>
      </Flex>
    </Fragment>
  );
};

export default SelectPlan;
