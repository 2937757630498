import axios from "axios";
import { findIndex, floor, min, orderBy, remove } from "lodash";
import React, { useCallback, useRef, useState } from "react";
import { Table, TableProps, useToaster } from "rsuite";
import { ReactComponent as GiftAmico } from "../../../assets/Gift-amico 2.svg";
import { ReactComponent as ApprovalDelegationIcon } from "../../../assets/icons/approval_delegation.svg";
import { ReactComponent as CalendarClockIcon } from "../../../assets/icons/calendar_clock.svg";
import { ReactComponent as CategoryIcon } from "../../../assets/icons/category.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/icons/delete.svg";
import { ReactComponent as SellIcon } from "../../../assets/icons/sell.svg";
import { ReactComponent as SettingsIcon } from "../../../assets/icons/settings.svg";
import { ReactComponent as ToggleOffIcon } from "../../../assets/icons/toggle_off.svg";
import { ReactComponent as ToggleOnIcon } from "../../../assets/icons/toggle_on.svg";
import { ReactComponent as VerifiedIcon } from "../../../assets/icons/verified.svg";
import { ReactComponent as VisibilityIcon } from "../../../assets/icons/visibility.svg";
import { ReactComponent as VolunteerActivismIcon } from "../../../assets/icons/volunteer_activism.svg";
import Flex from "../../../components/Flex";
import Icon from "../../../components/Icon";
import { toIconProps } from "../../../components/Icon/utils";
import PageSectionTitle from "../../../components/PageSectionTitle";
import BaseCell from "../../../components/RsuiteWrapper/SimpleTable/SimpleCells/BaseCell";
import SimpleActionCell from "../../../components/RsuiteWrapper/SimpleTable/SimpleCells/SimpleActionCell";
import SimpleTextCell from "../../../components/RsuiteWrapper/SimpleTable/SimpleCells/SimpleTextCell";
import SimpleHeaderCell from "../../../components/RsuiteWrapper/SimpleTable/SimpleHeaderCell";
import { tSimpleWhisperPopoverDropdownOptions } from "../../../components/RsuiteWrapper/SimpleWhisperPopoverDropdown";
import Segmentation from "../../../components/Segmentation";
import InterTag from "../../../components/Text/Inter";
import useHotelDispatch from "../../../context/Hotel/hooks/useHotelDispatch";
import useHotelState from "../../../context/Hotel/hooks/useHotelState";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import useDeleteRequest from "../../../hooks/apiRequests/useDeleteRequest";
import useGetRequest from "../../../hooks/apiRequests/useGetRequest";
import usePutRequest from "../../../hooks/apiRequests/usePutRequest";
import useEffectSafe from "../../../hooks/useEffectSafe";
import { tHotelAward, tHotelAwardId } from "../../../models/hotel";
import { apiAddressV2 } from "../../../utils/apiCall";
import { getAwardDescription, getAwardTypeInfo } from "../../../utils/awards";
import { COLORS } from "../../../utils/colors";
import { getValidity } from "../../../utils/dates";
import { getErrorMessage } from "../../../utils/httpResponses/others";
import { notification } from "../../../utils/notifications";
import { TABLE_HEADER_HEIGHT, TABLE_ROW_HEIGHT } from "../../../utils/tables";
import AddAward from "./add";
import CreateEditAwardModal, { tDataOnSubmit } from "./createEditAwardModal";
import useProfilePermissions from "../../../hooks/useProfilePermissions";

const TOP_LEVEL_FILTERS: { key: "active" | "inactive"; label: string }[] = [
  {
    key: "active",
    label: "Active",
  },
  {
    key: "inactive",
    label: "Inactive",
  },
];

interface iAwardsListWrappedProps {}

const AwardsListWrapped: React.FC<iAwardsListWrappedProps> = () => {
  const { language } = useLocalizationState();
  const toaster = useToaster();
  const { trans } = useLocalizationState();
  const [editAwardModal, setEditAwardModal] = useState<{
    open: boolean;
    awardId?: tHotelAward["_id"];
  }>({
    open: false,
    awardId: undefined,
  });
  const tableRef = useRef<any>();
  const { hotel, hotelIsLoaded, hotelId } = useHotelState();
  const { updateHotel } = useHotelDispatch();
  const profilePermissions = useProfilePermissions();

  const getRequest = useGetRequest<{
    active: tHotelAward[];
    inactive: tHotelAward[];
  }>({ active: [], inactive: [] });
  const putRequest = usePutRequest();
  const deleteRequest = useDeleteRequest();

  const [{ sortColumn, sortType }, setSort] = useState<
    Pick<TableProps<tHotelAward, string>, "sortType" | "sortColumn">
  >({ sortColumn: "type", sortType: "asc" });
  const [topLevelFilterIndex, setTopLevelFilterIndex] = useState<number>(0);

  const splitAwards = useCallback((awards: tHotelAward[]) => {
    const availableAwards = awards.filter((a) => !a.removed);
    const active = [...availableAwards];
    const inactive = remove(active, (a) => !a.active);
    return { active, inactive };
  }, []);

  useEffectSafe(() => {
    if (hotelIsLoaded) {
      const { active, inactive } = splitAwards(hotel.awards);
      getRequest.resolve({ active, inactive });
    }
  }, [hotel.awards, hotelIsLoaded, topLevelFilterIndex]);

  const isLoading =
    getRequest.isLoading || deleteRequest.isLoading || putRequest.isLoading;

  const onSelectTopLevelFilter = (key: string) => {
    if (!isLoading) {
      setTopLevelFilterIndex(
        findIndex(TOP_LEVEL_FILTERS, (f) => f.key === key)
      );
    }
  };

  const segmentation = () => {
    return (
      <Flex row between>
        <Flex grow={1}>
          <Segmentation
            appearance="subtle"
            active={TOP_LEVEL_FILTERS[topLevelFilterIndex].key}
            onSelect={onSelectTopLevelFilter}
            options={TOP_LEVEL_FILTERS.map((f) => {
              const { key, label } = f;
              return {
                key,
                label: { text: trans(label) },
                count: getRequest.data[key].length,
                disabled: isLoading,
              };
            })}
          />
        </Flex>
        {profilePermissions.writeAwards && (
          <Flex
            middle
            style={{
              borderBottom: `2px ${COLORS.gray} solid`,
            }}
          >
            <AddAward />
          </Flex>
        )}
      </Flex>
    );
  };

  const toggleAwardActive = (
    awardId: tHotelAwardId,
    body: Pick<tHotelAward, "active">
  ) => {
    putRequest.pending();
    axios
      .put(
        `${apiAddressV2(false)}/v2/hotels/${hotelId}/awards/${awardId}`,
        body
      )
      .then((res) => {
        const {
          data: { hotel },
        } = res;
        updateHotel(hotelId, hotel);
        putRequest.resolve();
        toaster.push(
          notification(
            "success",
            body.active ? trans("Award inactivated") : trans("Award Activated")
          ),
          {
            placement: "topEnd",
          }
        );
      })
      .catch((err) => {
        const error = getErrorMessage(err, trans);
        putRequest.reject(error);
        toaster.push(notification("error", error), { placement: "topEnd" });
      });
  };

  const deleteAward = (awardId: tHotelAwardId) => {
    deleteRequest.pending();
    axios
      .delete(`${apiAddressV2(false)}/v2/hotels/${hotelId}/awards/${awardId}`)
      .then((res) => {
        const {
          data: { hotel },
        } = res;
        updateHotel(hotelId, hotel);
        deleteRequest.resolve();
        toaster.push(notification("success", trans("Award removed")), {
          placement: "topEnd",
        });
      })
      .catch((err) => {
        const error = getErrorMessage(err, trans);
        deleteRequest.reject(error);
        toaster.push(notification("error", error), { placement: "topEnd" });
      });
  };

  const options = (
    dataKey: string,
    award: tHotelAward
  ): tSimpleWhisperPopoverDropdownOptions[] => {
    const options: tSimpleWhisperPopoverDropdownOptions[] = [
      {
        key: "view",
        label: trans("View Award"),
        icon: { Element: VisibilityIcon },
        onClick() {},
      },
    ];

    options.push({
      key: "configure",
      label: trans("Configure Award"),
      icon: { Element: SettingsIcon },
      onClick() {
        setEditAwardModal({ open: true, awardId: award._id });
      },
    });

    if (award.active) {
      options.push({
        key: "inactivate",
        label: { text: trans("Deactivate"), color: COLORS.info },
        icon: { Element: ToggleOffIcon, fill: COLORS.info },
        onClick() {
          toggleAwardActive(award._id, { active: false });
        },
      });
    } else
      options.push({
        key: "activate",
        label: { text: trans("Activate"), color: COLORS.info },
        icon: { Element: ToggleOnIcon, fill: COLORS.info },
        onClick() {
          toggleAwardActive(award._id, { active: true });
        },
      });

    options.push({
      key: "delete",
      label: { text: trans("Delete award"), color: COLORS.error },
      icon: { Element: DeleteIcon, fill: COLORS.error },
      onClick() {
        deleteAward(award._id);
      },
    });

    return options;
  };

  const selectedTopLevelFilter = TOP_LEVEL_FILTERS[topLevelFilterIndex];

  const tableHeight = isLoading
    ? TABLE_ROW_HEIGHT.M + TABLE_HEADER_HEIGHT
    : (min([5, getRequest.data[selectedTopLevelFilter.key].length]) as number) *
        TABLE_ROW_HEIGHT.M +
      TABLE_HEADER_HEIGHT;

  const tableWidth = tableRef.current?.body?.offsetWidth || 0;
  const noActionWidth = 0.92;
  const typeWidth = floor(noActionWidth * 0.15 * tableWidth);
  const timesUsedWidth = floor(noActionWidth * 0.1 * tableWidth);
  const timesGivenWidth = floor(noActionWidth * 0.1 * tableWidth);
  const validityAfterAwardingWidth = floor(noActionWidth * 0.2 * tableWidth);
  const discountWidth = floor(noActionWidth * 0.15 * tableWidth);
  const descriptionWidth = floor(noActionWidth * 0.3 * tableWidth);

  const closeEditAwardModal = useCallback(() => {
    setEditAwardModal({ open: false, awardId: undefined });
  }, []);

  const onConfirmEditAward =
    (awardId: tHotelAward["_id"]) => (award: tDataOnSubmit) => {
      putRequest.pending();
      axios
        .put(`${apiAddressV2(false)}/v2/hotels/${hotelId}/awards/${awardId}`, {
          ...award,
        })
        .then((res) => {
          const {
            data: { hotel },
          } = res;
          updateHotel(hotelId, hotel);
          putRequest.resolve();
          setEditAwardModal({ open: false, awardId: undefined });
          toaster.push(notification("success", trans("Award updated")), {
            placement: "topEnd",
          });
        })
        .catch((err) => {
          const error = getErrorMessage(err, trans);
          putRequest.reject(error);
          toaster.push(notification("error", error), { placement: "topEnd" });
        });
    };

  return (
    <>
      {editAwardModal.open && editAwardModal.awardId !== undefined && (
        <CreateEditAwardModal
          open={true}
          awardId={editAwardModal.awardId}
          onClose={closeEditAwardModal}
          requestError={putRequest.error}
          requestStatus={putRequest.status}
          onRequest={onConfirmEditAward(editAwardModal.awardId)}
        />
      )}
      <Flex column gap={16}>
        {segmentation()}
        <div className="table-wrapper">
          <Table
            ref={tableRef}
            height={tableHeight}
            data={getRequest.data[selectedTopLevelFilter.key]}
            id={`AWARDS.TABLE`}
            rowHeight={TABLE_ROW_HEIGHT.M}
            headerHeight={TABLE_HEADER_HEIGHT}
            loading={isLoading}
            onSortColumn={(dataKey: string, sortType?: "desc" | "asc") => {
              if (sortType) {
                let _sortType = sortType;
                if (dataKey !== sortColumn) {
                  _sortType = "asc";
                }
                setSort({ sortColumn: dataKey, sortType: _sortType });
                getRequest.resolve(
                  splitAwards(orderBy(hotel.awards, [dataKey], [_sortType]))
                );
              } else {
                const { active, inactive } = splitAwards(hotel.awards);
                setSort({ sortColumn: undefined, sortType: undefined });
                getRequest.resolve({ active, inactive });
              }
            }}
            {...{
              sortColumn,
              sortType,
            }}
          >
            <Table.Column sortable={!isLoading} width={typeWidth}>
              <SimpleHeaderCell icon={CategoryIcon} name={trans("Type")} />
              <BaseCell>
                {(rowData: tHotelAward) => {
                  const { type } = rowData;
                  const info = getAwardTypeInfo(type);
                  return (
                    <Flex
                      style={{
                        padding: "4px",
                        width: "fit-content",
                        borderRadius: "4px",
                      }}
                      middle
                      gap={4}
                    >
                      <Icon
                        {...{
                          fill: COLORS.secondary,
                          size: 14,
                          ...toIconProps(info.icon),
                        }}
                      />
                      <InterTag
                        size={12}
                        text={trans(type)}
                        color={COLORS.secondary}
                      />
                    </Flex>
                  );
                }}
              </BaseCell>
            </Table.Column>
            <Table.Column sortable={!isLoading} width={discountWidth}>
              <SimpleHeaderCell icon={SellIcon} name={trans("Discount")} />
              <SimpleTextCell
                dataKey="discount"
                textFunction={(rowData: tHotelAward) => {
                  const { discount, discountType } = rowData;
                  if (discountType === "nominal") return `${discount}€`;

                  if (discount === 100) return trans("Offer");
                  return `${discount}%`;
                }}
              />
            </Table.Column>
            <Table.Column width={timesUsedWidth} sortable={!isLoading}>
              <SimpleHeaderCell
                icon={ApprovalDelegationIcon}
                name={trans("Used")}
              />
              <SimpleTextCell dataKey="timesUsed" />
            </Table.Column>
            <Table.Column width={timesGivenWidth} sortable={!isLoading}>
              <SimpleHeaderCell
                icon={VolunteerActivismIcon}
                name={trans("Given")}
              />
              <SimpleTextCell dataKey="timesAwarded" />
            </Table.Column>
            <Table.Column width={validityAfterAwardingWidth}>
              <SimpleHeaderCell
                icon={CalendarClockIcon}
                name={trans("Validity")}
                tooltip={trans(
                  "Amount of time someone has to use this award after receiving it."
                )}
              />
              <SimpleTextCell
                dataKey="validityAfterAwarding"
                textFunction={(rowData, dataKey: string) => {
                  const { years, months, days } = getValidity(rowData[dataKey]);
                  return [
                    { value: years, label: ["$0 year", "$0 years"] },
                    { value: months, label: ["$0 month", "$0 months"] },
                    { value: days, label: ["$0 day", "$0 days"] },
                  ]
                    .filter(({ value }) => value)
                    .map(({ value, label }) =>
                      trans(value === 1 ? label[0] : label[1], {
                        parameters: [value],
                      })
                    )
                    .join(", ");
                }}
              />
            </Table.Column>
            <Table.Column width={descriptionWidth}>
              <SimpleHeaderCell icon={VerifiedIcon} name={trans("Award")} />
              <SimpleTextCell
                tooltipDisplay
                tooltipTextFunction={(rowData) =>
                  `${getAwardDescription(rowData, language)?.longDescription}`
                }
                dataKey="shortDescription"
                textFunction={(rowData) =>
                  `${getAwardDescription(rowData, language)?.shortDescription}`
                }
              />
            </Table.Column>
            <Table.Column flexGrow={1} align="right">
              <SimpleHeaderCell name={{ text: "" }} />
              <SimpleActionCell options={options} dataKey="_id" />
            </Table.Column>
          </Table>
          {getRequest.data[selectedTopLevelFilter.key].length === 0 &&
            hotelIsLoaded &&
            !getRequest.isLoading && (
              <Flex column middle gap={40}>
                <Icon Element={GiftAmico} size={250} />
                <Flex column middle gap={16} style={{ margin: "0 70px" }}>
                  <InterTag
                    size={36}
                    text={trans("Welcome to your Awards!")}
                    color={COLORS.secondary}
                  />
                  {[
                    trans(
                      "There are currently no Awards listed. This is where you'll create and manage Awards to offer your Guests, enhancing their experience and boosting loyalty. Awards can be offered manualyy or automatically linked to Challenges and Loyalty Programs."
                    ),
                    trans(
                      "<strong>Create your First Awards:</strong> Begin by creafting an Award to delight and incentivize your Guests. Set up exclusive offers or discounts for dining, spa services, room upgrades, or any other hotel amenities. Tailor these Awards to enhance Guest stays and promote frequent use of your services."
                    ),
                    trans(
                      "<strong>Automate Awards with Challenges and Loyalty:</strong> Integrate Awards with your Challenges and Loyalty programs to offer them automatically based on Guest activities and milestones. Set up your first Challenge or Loyalty rule to automate Awards."
                    ),
                    trans(
                      "<strong>Reach out for Help:</strong> Need assistance or have ideas for your Awards program? Our support team is here to help you craft the perfect Awards strategy."
                    ),
                    trans(
                      "Ready to enhance your Guests' experiences? Start by creating an Award and watch this space transform into a dynamic dashboard of Guest appreciation and engagement."
                    ),
                  ].map((text) => (
                    <InterTag
                      asHTML
                      size={16}
                      color={COLORS.secondary}
                      style={{
                        textWrap: "wrap",
                        textAlign: "center",
                        lineHeight: "22px",
                      }}
                      text={text}
                    />
                  ))}
                </Flex>
                {profilePermissions.writeAwards && <AddAward />}
              </Flex>
            )}
        </div>
      </Flex>
    </>
  );
};
interface iProps {}

const AwardsList: React.FC<iProps> = () => {
  const { trans } = useLocalizationState();

  return (
    <Flex column gap={16}>
      <PageSectionTitle
        title={{ text: trans("Awards List") }}
        description={{
          text: trans(
            "Browse through the awards currently on offer and those retired."
          ),
        }}
        icon={{ Element: VerifiedIcon }}
      />
      <AwardsListWrapped />
    </Flex>
  );
};

export default AwardsList;
