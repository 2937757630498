import { Badge, Nav } from "rsuite";
import { COLORS } from "../../utils/colors";
import Flex from "../Flex";
import { iTextProps } from "../Text";
import { isNumber } from "lodash";
import InterTag from "../Text/Inter";

const Segmentation = ({
  active,
  onSelect,
  options,
  ...props
}: {
  active: string;
  onSelect(eventKey: string, event: any): void;
  options: {
    key: string;
    label: iTextProps;
    count?: number | null;
    disabled: boolean;
  }[];
  [key: string]: any;
}) => {
  return (
    <Nav
      {...{ ...props, className: `${props.className} width-100` }}
      activeKey={active}
      onSelect={onSelect}
    >
      <Flex row>
        {options.map(({ label, key, count, disabled }, i) => {
          return (
            <Nav.Item
              eventKey={key}
              key={key}
              disabled={disabled}
              style={{ paddingBottom: "8px" }}
            >
              <Flex row gap={8}>
                <p className="inter">{label.text}</p>
                {isNumber(count) && (
                  <Badge
                    style={{
                      backgroundColor:
                        active === key ? COLORS.primary : COLORS.gray_300,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    content={
                      <InterTag
                        text={`${count}`}
                        color={active === key ? COLORS.white : COLORS.secondary}
                      />
                    }
                  />
                )}
              </Flex>
            </Nav.Item>
          );
        })}
      </Flex>
    </Nav>
  );
};

export default Segmentation;
