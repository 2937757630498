import axios from "axios";
import { floor, min } from "lodash";
import React, { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Table, useToaster } from "rsuite";
import { ReactComponent as CategoryIcon } from "../../../../assets/icons/category.svg";
import { ReactComponent as DeleteIcon } from "../../../../assets/icons/delete.svg";
import { ReactComponent as MeetingRoomIcon } from "../../../../assets/icons/meeting_room.svg";
import { ReactComponent as SettingsIcon } from "../../../../assets/icons/settings.svg";
import { ReactComponent as SquareFootIcon } from "../../../../assets/icons/square_foot.svg";
import { ReactComponent as StacksIcon } from "../../../../assets/icons/stacks.svg";
import { ReactComponent as VisibilityIcon } from "../../../../assets/icons/visibility.svg";
import { ReactComponent as WorkSpacesIcon } from "../../../../assets/icons/workspaces.svg";
import Flex from "../../../../components/Flex";
import BaseCell from "../../../../components/RsuiteWrapper/SimpleTable/SimpleCells/BaseCell";
import SimpleActionCell from "../../../../components/RsuiteWrapper/SimpleTable/SimpleCells/SimpleActionCell";
import SimpleTextCell from "../../../../components/RsuiteWrapper/SimpleTable/SimpleCells/SimpleTextCell";
import SimpleHeaderCell from "../../../../components/RsuiteWrapper/SimpleTable/SimpleHeaderCell";
import { tSimpleWhisperPopoverDropdownOptions } from "../../../../components/RsuiteWrapper/SimpleWhisperPopoverDropdown";
import InterTag from "../../../../components/Text/Inter";
import useHotelDispatch from "../../../../context/Hotel/hooks/useHotelDispatch";
import useHotelState from "../../../../context/Hotel/hooks/useHotelState";
import useLocalizationState from "../../../../context/Localization/hooks/useLocalizationState";
import useDeleteRequest from "../../../../hooks/apiRequests/useDeleteRequest";
import useProfilePermissions from "../../../../hooks/useProfilePermissions";
import { tHotelSpace, tHotelSpaceId } from "../../../../models/hotel";
import { apiAddressV2 } from "../../../../utils/apiCall";
import { COLORS } from "../../../../utils/colors";
import { getErrorMessage } from "../../../../utils/httpResponses/others";
import { notification } from "../../../../utils/notifications";
import {
  TABLE_HEADER_HEIGHT,
  TABLE_ROW_HEIGHT,
} from "../../../../utils/tables";
import NoSpaces from "../noSpaces";
import SpaceModal from "../spaceModal";

interface iProps {
  outletContainerWidth: number;
}

const SpacesTab: React.FC<iProps> = ({ outletContainerWidth }) => {
  const toaster = useToaster();
  const navigate = useNavigate();
  const deleteRequest = useDeleteRequest();
  const [spaceModal, setSpaceModal] = useState<
    | {
        open: false;
        spaceId: null;
        spaceName: null;
      }
    | {
        open: true;
        spaceId: tHotelSpaceId;
        spaceName: string;
      }
  >({ open: false, spaceId: null, spaceName: null });
  const { trans } = useLocalizationState();
  const {
    hotelId,
    hotel: { spaces },
    inFinalState,
    hotelIsLoaded,
    findSpaceSpaceAggregates,
  } = useHotelState();
  const { updateHotel, updatingHotel, resolveHotel } = useHotelDispatch();
  const profilePermissions = useProfilePermissions();

  const options = (
    dataKey: string,
    space: tHotelSpace
  ): tSimpleWhisperPopoverDropdownOptions[] => {
    const options: tSimpleWhisperPopoverDropdownOptions[] = [];

    if (profilePermissions.writeSpaces)
      options.push({
        key: "configure",
        icon: { Element: SettingsIcon, fill: COLORS.secondary },
        label: { text: trans("Configure") },
        onClick() {
          setSpaceModal({
            open: true,
            spaceId: space._id,
            spaceName: space.name,
          });
        },
      });

    if (profilePermissions.readSpaces)
      options.push({
        key: "view space",
        icon: { Element: VisibilityIcon, fill: COLORS.secondary },
        label: { text: trans("View Space") },
        onClick() {
          navigate(`/spaces/${space._id}`);
        },
      });

    if (profilePermissions.writeSpaces)
      options.push({
        key: "delete",
        icon: { Element: DeleteIcon, fill: COLORS.error },
        label: { text: trans("Delete"), color: COLORS.error },
        onClick() {
          updatingHotel();
          deleteRequest.pending();
          axios
            .delete(
              `${apiAddressV2(false)}/v2/hotels/${hotelId}/spaces/${space._id}`
            )
            .then((res) => {
              const {
                data: { hotel },
              } = res;
              updateHotel(hotelId, hotel);
              toaster.push(
                notification("success", `${trans("Space removed")}`),
                {
                  placement: "topEnd",
                }
              );
              deleteRequest.resolve();
            })
            .catch((err) => {
              const error = getErrorMessage(err, trans);
              toaster.push(notification("error", error), {
                placement: "topEnd",
              });
              resolveHotel();
              deleteRequest.reject(error);
            });
        },
      });

    return options;
  };

  const tableHeight = !hotelIsLoaded
    ? TABLE_ROW_HEIGHT.M + TABLE_HEADER_HEIGHT
    : (min([5, spaces.length]) as number) * TABLE_ROW_HEIGHT.M +
      TABLE_HEADER_HEIGHT;

  const spaceWidth = floor(0.15 * outletContainerWidth);
  const groupWidth = floor(0.2 * outletContainerWidth);
  const typeWidth = floor(0.2 * outletContainerWidth);
  const zoneWidth = floor(0.2 * outletContainerWidth);
  const areaWidth = floor(0.1 * outletContainerWidth);

  return (
    <Fragment>
      {spaceModal.open && (
        <SpaceModal
          onClose={() => {
            setSpaceModal({ open: false, spaceId: null, spaceName: null });
          }}
          {...{ ...spaceModal }}
        />
      )}
      <div className="table-wrapper">
        <Table
          id={"TABLE.SPACES"}
          height={tableHeight}
          data={spaces}
          rowHeight={TABLE_ROW_HEIGHT.M}
          headerHeight={TABLE_HEADER_HEIGHT}
          loading={!inFinalState || deleteRequest.isLoading}
          renderEmpty={(info: any) => {
            return null;
          }}
        >
          <Table.Column width={spaceWidth}>
            <SimpleHeaderCell icon={MeetingRoomIcon} name={trans("Space")} />
            <SimpleTextCell
              textProps={(space) => ({
                hoverUnderline: true,
                onClick: () => navigate(`/spaces/${space._id}`),
              })}
              dataKey="name"
            />
          </Table.Column>
          <Table.Column width={groupWidth}>
            <SimpleHeaderCell icon={WorkSpacesIcon} name={trans("Group")} />

            <BaseCell dataKey="group">
              {(rowData: tHotelSpace) => {
                const groups = findSpaceSpaceAggregates(rowData._id, "group");

                if (!groups)
                  return (
                    <InterTag
                      size={12}
                      color={COLORS.error}
                      text={trans("No groups assigned")}
                    />
                  );

                return (
                  <Flex row>
                    {groups
                      .flatMap((group) => [
                        <InterTag
                          key={group._id}
                          asSpan
                          hoverUnderline
                          size={12}
                          text={group.name}
                          onClick={() =>
                            navigate(`/space-aggregates/${group._id}`)
                          }
                        />,
                        <InterTag key={`${group._id},`} size={12} text=",  " />,
                      ])
                      .slice(0, groups.length * 2 - 1)}
                  </Flex>
                );
              }}
            </BaseCell>
          </Table.Column>
          <Table.Column width={typeWidth}>
            <SimpleHeaderCell icon={CategoryIcon} name={trans("Type")} />

            <BaseCell dataKey="group">
              {(rowData: tHotelSpace) => {
                const types = findSpaceSpaceAggregates(rowData._id, "type");

                if (!types)
                  return (
                    <InterTag
                      size={12}
                      color={COLORS.error}
                      text={trans("No types assigned")}
                    />
                  );

                return (
                  <Flex row>
                    {types
                      .flatMap((group) => [
                        <InterTag
                          key={group._id}
                          asSpan
                          hoverUnderline
                          size={12}
                          text={group.name}
                          onClick={() =>
                            navigate(`/space-aggregates/${group._id}`)
                          }
                        />,
                        <InterTag key={`${group._id},`} size={12} text=",  " />,
                      ])
                      .slice(0, types.length * 2 - 1)}
                  </Flex>
                );
              }}
            </BaseCell>
          </Table.Column>
          <Table.Column width={zoneWidth}>
            <SimpleHeaderCell icon={StacksIcon} name={trans("Zone")} />

            <BaseCell dataKey="zone">
              {(rowData: tHotelSpace) => {
                const zones = findSpaceSpaceAggregates(rowData._id, "zone");
                if (!zones)
                  return (
                    <InterTag
                      size={12}
                      color={COLORS.error}
                      text={trans("No zones assigned")}
                    />
                  );
                return (
                  <Flex row>
                    {zones
                      .flatMap((zone) => [
                        <InterTag
                          key={zone._id}
                          asSpan
                          hoverUnderline
                          size={12}
                          text={zone.name}
                          onClick={() =>
                            navigate(`/space-aggregates/${zone._id}`)
                          }
                        />,
                        <InterTag key={`${zone._id},`} size={12} text=",  " />,
                      ])
                      .slice(0, zones.length * 2 - 1)}
                  </Flex>
                );
              }}
            </BaseCell>
          </Table.Column>
          <Table.Column width={areaWidth}>
            <SimpleHeaderCell icon={SquareFootIcon} name={trans("Area")} />
            <SimpleTextCell
              textProps={() => ({ asHTML: true })}
              dataKey="areaInSquareMeters"
              textFunction={(rowData) =>
                rowData["areaInSquareMeters"]
                  ? `${rowData["areaInSquareMeters"]} m<sup>2</sup>`
                  : null
              }
            />
          </Table.Column>
          <Table.Column flexGrow={1} align="right">
            <SimpleHeaderCell name={{ text: "" }} />
            <SimpleActionCell options={options} dataKey="_id" />
          </Table.Column>
        </Table>
        {spaces.length === 0 && <NoSpaces category="space" />}
      </div>
    </Fragment>
  );
};

export default SpacesTab;
