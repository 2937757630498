import { has, min, sum } from "lodash";
import moment from "moment";
import React, { useState } from "react";
import { Table } from "rsuite";
import { ReactComponent as CompostIcon } from "../../../assets/icons/compost.svg";
import { ReactComponent as DateRangeIcon } from "../../../assets/icons/date_range.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/icons/delete.svg";
import { ReactComponent as RecyclingIcon } from "../../../assets/icons/recycling.svg";
import Flex from "../../../components/Flex";
import ManualDataModal from "../../../components/Modals/ManualDataModal";
import PageSectionTitle from "../../../components/PageSectionTitle";
import SimpleActionCell from "../../../components/RsuiteWrapper/SimpleTable/SimpleCells/SimpleActionCell";
import SimpleTextCell from "../../../components/RsuiteWrapper/SimpleTable/SimpleCells/SimpleTextCell";
import SimpleHeaderCell from "../../../components/RsuiteWrapper/SimpleTable/SimpleHeaderCell";
import { tSimpleWhisperPopoverDropdownOptions } from "../../../components/RsuiteWrapper/SimpleWhisperPopoverDropdown";
import useHotelManualDataState from "../../../context/Hotel/hooks/hotelState/useHotelManualDataState";
import useHotelStatusState from "../../../context/Hotel/hooks/hotelState/useHotelStatusState";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import useProfilePermissions from "../../../hooks/useProfilePermissions";
import { tIconElement } from "../../../interfaces/icon";
import { WASTE_TYPES } from "../../../interfaces/manualData";
import { tWithRequired } from "../../../interfaces/others";
import { tHotelManualData } from "../../../models/hotel";
import { TABLE_HEADER_HEIGHT, TABLE_ROW_HEIGHT } from "../../../utils/tables";

type tTableItem = tWithRequired<tHotelManualData, "waste">;

const ListWrapped: React.FC<{
  manualData: tTableItem[];
}> = ({ manualData }) => {
  const { trans } = useLocalizationState();
  const [manualDataModal, setManualDataModal] = useState<
    | { open: false; manualDataId: undefined }
    | { open: true; manualDataId: string }
  >({ open: false, manualDataId: undefined });
  const profilePermissions = useProfilePermissions();
  const { hotelLoading } = useHotelStatusState();

  const tableData: tTableItem[] = manualData;

  const tableHeight =
    (min([12, tableData.length]) as number) * TABLE_ROW_HEIGHT.SM +
    TABLE_HEADER_HEIGHT;

  const options = (
    dataKey: string,
    item: tTableItem
  ): tSimpleWhisperPopoverDropdownOptions[] => {
    const options: tSimpleWhisperPopoverDropdownOptions[] = [];

    if (profilePermissions.writeManualData) {
      options.push({
        key: "edit",
        label: trans("Edit entry"),
        onClick() {
          setManualDataModal({ open: true, manualDataId: item._id });
        },
      });
    }

    return options;
  };

  const wasteTypeColumn = (
    label: string,
    key: keyof Required<tHotelManualData>["waste"],
    icon: tIconElement
  ) => (
    <Table.Column flexGrow={1}>
      <SimpleHeaderCell name={label} icon={icon} />
      <SimpleTextCell
        dataKey={`waste.${key}`}
        textFunction={(item: tTableItem) => {
          return `${item.waste![key].totalKg} kg`;
        }}
      />
    </Table.Column>
  );

  return (
    <>
      <ManualDataModal
        singleStep
        initialStep="waste"
        open={manualDataModal.open}
        onClose={() =>
          setManualDataModal({ open: false, manualDataId: undefined })
        }
        manualDataId={manualDataModal.manualDataId}
      />
      <div className="table-wrapper">
        <Table
          loading={hotelLoading}
          data={tableData}
          height={tableHeight}
          rowHeight={TABLE_ROW_HEIGHT.SM}
          headerHeight={TABLE_HEADER_HEIGHT}
        >
          <Table.Column flexGrow={2}>
            <SimpleHeaderCell name={trans("Period")} icon={DateRangeIcon} />
            <SimpleTextCell
              dataKey="period"
              textFunction={({ from, to }: tTableItem) =>
                trans("$0 to $1", {
                  parameters: [
                    moment(from).format("DD MMM YYYY"),
                    moment(to).format("DD MMM YYYY"),
                  ],
                })
              }
            />
          </Table.Column>
          <Table.Column flexGrow={1}>
            <SimpleHeaderCell name={trans("Total")} icon={DeleteIcon} />
            <SimpleTextCell
              dataKey="waste"
              textFunction={(item: tTableItem) => {
                const totalKg = sum(
                  WASTE_TYPES.map((t) => item.waste![t].totalKg)
                );

                return `${totalKg} kg`;
              }}
            />
          </Table.Column>
          {wasteTypeColumn(trans("Mixed"), "mixed", DeleteIcon)}
          {wasteTypeColumn(trans("Organic"), "organic", CompostIcon)}
          {wasteTypeColumn(trans("Plastic"), "plastic", RecyclingIcon)}
          {wasteTypeColumn(trans("Paper"), "paper", RecyclingIcon)}
          {wasteTypeColumn(trans("Glass"), "glass", RecyclingIcon)}
          <Table.Column flexGrow={1} align="right">
            <SimpleHeaderCell name={{ text: "" }} />
            <SimpleActionCell options={options} dataKey="_id" />
          </Table.Column>
        </Table>
      </div>
    </>
  );
};

interface iListProps {}

const List: React.FC<iListProps> = () => {
  const { trans } = useLocalizationState();
  const { manualDataOrdered } = useHotelManualDataState();

  const filteredManualData: tWithRequired<tHotelManualData, "waste">[] =
    manualDataOrdered.filter(
      (item) =>
        has(item, "waste") &&
        sum(WASTE_TYPES.map((t) => item.waste![t].totalKg))
    ) as tWithRequired<tHotelManualData, "waste">[];

  return (
    <Flex column gap={16}>
      <PageSectionTitle
        title={trans("Waste Data")}
        icon={DeleteIcon}
        description={trans(
          "Comprehensive data showcasing detailed waste production across your property."
        )}
      />
      <ListWrapped {...{ manualData: filteredManualData }} />
    </Flex>
  );
};

export default React.memo(List);
