import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "rsuite";
import Flex from "../../../components/Flex";
import DMSeriffTag from "../../../components/Text/DMSeriff";
import InterTag from "../../../components/Text/Inter";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import { COLORS } from "../../../utils/colors";

const Unlock: React.FC = () => {
  const navigate = useNavigate();
  const { trans } = useLocalizationState();

  return (
    <Flex column middle gap={32} style={{ margin: "0 100px" }}>
      <DMSeriffTag
        size={36}
        color={COLORS.secondary}
        text={trans("Enhance Guest Management with comprehensive Insights")}
        style={{ textWrap: "wrap", textAlign: "center" }}
        bold
      />
      {[
        "Unlock the full potential of your hospitality management with our premium 'Guests' page.",
        "Designed exclusively for premium users, this feature offers a detailed overview of all guest intercations, from historic, onigoing, to upcoming reservations.",
        "Gain access to a wealth of data that allows for enhanced guest service, personalized experiences, and streamlined operations.",
      ].map((t) => (
        <InterTag
          size={20}
          color={COLORS.secondary}
          text={trans(t)}
          style={{ textWrap: "wrap", textAlign: "center" }}
        />
      ))}
      <Button
        style={{
          backgroundColor: COLORS.primary_900,
          color: COLORS.white,
          fontWeight: "bold",
        }}
        onClick={() => navigate("/settings/billing/plan")}
      >
        {trans("Upgrade Now")}
      </Button>
      {[
        {
          title: "Holistic Reservation Management",
          description:
            "Manage all aspects of guest reservations in one place, from check-in to check-out, and cross-reference guests' data with consumption details, for powerful analytics",
        },
        {
          title: "Data-Driven Insights",
          description:
            "Utilize detailed analytics on guest behavior, preferences, and booking patterns to tailor marketing and operational strategies.",
        },
        {
          title: "Enhanced Personalization",
          description:
            "Leverage guest data to provide personalized experiences, improving guest loyalty and repeat bookings.",
        },
      ].map(({ title, description }) => (
        <Flex column gap={16}>
          <DMSeriffTag
            text={trans(title)}
            color={COLORS.secondary}
            size={24}
            style={{ textWrap: "wrap", textAlign: "center" }}
            bold
          />
          <InterTag
            text={trans(description)}
            color={COLORS.secondary}
            size={20}
            style={{ textWrap: "wrap", textAlign: "center" }}
          />
        </Flex>
      ))}
    </Flex>
  );
};

export default Unlock;
