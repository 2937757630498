import React, { useMemo } from "react";
import { HotelStateContext } from "../..";
import { REQUEST_STATUS } from "../../../../utils/apiCall";
import { defaultHotel } from "../../../../utils/hotels";

const useHotelState = () => {
  const state = React.useContext(HotelStateContext);

  if (!state)
    throw Error("useHotelState must be used within HotelStateContext");

  const {
    data: { hotels, selectedHotelIndex },
    status,
  } = state;

  const hotelLoading = status === REQUEST_STATUS.PENDING;
  const hotelIsLoaded = status === REQUEST_STATUS.RESOLVED;
  const errorLoading = status === REQUEST_STATUS.REJECTED;

  const hotel = useMemo(() => {
    return { ...defaultHotel, ...hotels[selectedHotelIndex] };
  }, [hotels, selectedHotelIndex]);

  const hotelId = hotel._id;

  return {
    hotelIsLoaded,
    hotelLoading,
    errorLoading,
    hotel,
    hotelId,
  };
};

export default useHotelState;
