import axios, { isAxiosError } from "axios";
import { findIndex, has } from "lodash";
import React, { useCallback, useReducer } from "react";
import useEffectSafe from "../../hooks/useEffectSafe";
import { apiAddressV2 } from "../../utils/apiCall";
import { RUN_CONTEXT } from "../../utils/context";
import { getErrorMessage } from "../../utils/httpResponses/others";
import { sleep } from "../../utils/others";
import useLocalizationState from "../Localization/hooks/useLocalizationState";
import { nHotel } from "./interfaces";
import { useToaster } from "rsuite";
import { notification } from "../../utils/notifications";
import { useNavigate } from "react-router-dom";
import useAuthState from "../Auth/hooks/useAuthState";
import useAuthDispatch from "../Auth/hooks/useAuthDispatch";

const HotelDispatchContext = React.createContext<
  nHotel.tDispatchContext | undefined
>(undefined);
HotelDispatchContext.displayName = "HotelDispatchContext";
const HotelStateContext = React.createContext<nHotel.tStateContext | undefined>(
  undefined
);
HotelStateContext.displayName = "HotelStateContext";

const LS_DATA = "__h__";

const initialState: nHotel.tState = {
  data: {
    hotels: [],
    selectedHotelIndex: 0,
  },
  status: "pending",
  error: null,
};

const reducer = (
  state: nHotel.tState,
  action: nHotel.tAction
): nHotel.tState => {
  switch (action.type) {
    case "set data": {
      const { data } = action;
      localStorage.setItem(LS_DATA, JSON.stringify(data));
      return { ...state, status: "resolved", error: null, data };
    }
    case "set hotels": {
      const { hotels } = action;
      const data = { ...state.data, hotels };
      localStorage.setItem(LS_DATA, JSON.stringify(data));

      return { ...state, status: "resolved", error: null, data };
    }
    case "update hotel": {
      const { hotelId, hotel } = action;
      const index = findIndex(state.data.hotels, (h) => h._id === hotelId);
      if (index >= 0) {
        state.data.hotels[index] = { ...state.data.hotels[index], ...hotel };
        state.data.hotels = [...state.data.hotels];
      }
      localStorage.setItem(LS_DATA, JSON.stringify(state.data));

      return { ...state, status: "resolved", error: null };
    }
    case "resolved": {
      return { ...state, status: "resolved", error: null };
    }
    case "rejected": {
      const { error } = action;
      return { ...state, status: "rejected", error };
    }
    case "pending": {
      return { ...state, status: "pending", error: null };
    }
    default:
      return { ...state };
  }
};

const HotelContextProvider: React.FC<nHotel.iContextProps> = ({ children }) => {
  const toaster = useToaster();
  const { trans } = useLocalizationState();
  const [state, dispatch]: [nHotel.tState, React.Dispatch<nHotel.tAction>] =
    useReducer(reducer, initialState);
  const { logout } = useAuthDispatch();

  const run = useCallback(async () => {
    dispatch({ type: "pending" });

    let error: any = null;
    for (let tries = 0; tries < RUN_CONTEXT.HOTEL.max_tries; tries++) {
      try {
        const result = await axios.get(
          `${apiAddressV2(false)}/v2/managers/manager/hotels`
        );
        const {
          data: { hotels },
        } = result;
        dispatch({ type: "set hotels", hotels });
        return;
      } catch (err: any) {
        error = err;
        if (has(err, "response.status") && err.response.status === 403) {
          toaster.push(notification("error", trans("Account does not exist")));
          localStorage.clear();
          // window.location.reload();
          logout();
          // navigate("/login");
        }
        await sleep(3000);
      }
    }
    if (error)
      dispatch({ type: "rejected", error: getErrorMessage(error, trans) });
  }, [toaster, trans]);

  useEffectSafe(() => {
    function main() {
      if (
        process.env.NODE_ENV === "production" ||
        RUN_CONTEXT.HOTEL.run_always
      ) {
        return run();
      } else {
        const stored = localStorage.getItem(LS_DATA);

        if (!stored) return run();

        const data = JSON.parse(stored);

        dispatch({ type: "set data", data });
      }
    }
    main();
  }, [run]);

  return (
    <HotelStateContext.Provider value={state}>
      <HotelDispatchContext.Provider value={dispatch}>
        {children}
      </HotelDispatchContext.Provider>
    </HotelStateContext.Provider>
  );
};

export { HotelContextProvider, HotelDispatchContext, HotelStateContext };
