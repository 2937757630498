import { find, floor, min, reverse, round, sum } from "lodash";
import moment from "moment";
import React from "react";
import { Loader, Table } from "rsuite";
import { ReactComponent as DateRangeIcon } from "../../../assets/icons/date_range.svg";
import { ReactComponent as EventRepeatIcon } from "../../../assets/icons/event_repeat.svg";
import { ReactComponent as ModeHeatIcon } from "../../../assets/icons/mode_heat.svg";
import { ReactComponent as PaymentsIcon } from "../../../assets/icons/payments.svg";
import TrendingComparison from "../../../components/Comparions/trendingComparison";
import Flex from "../../../components/Flex";
import PageSectionTitle from "../../../components/PageSectionTitle";
import BaseCell from "../../../components/RsuiteWrapper/SimpleTable/SimpleCells/BaseCell";
import SimpleActionCell from "../../../components/RsuiteWrapper/SimpleTable/SimpleCells/SimpleActionCell";
import SimpleTextCell from "../../../components/RsuiteWrapper/SimpleTable/SimpleCells/SimpleTextCell";
import SimpleHeaderCell from "../../../components/RsuiteWrapper/SimpleTable/SimpleHeaderCell";
import { tSimpleWhisperPopoverDropdownOptions } from "../../../components/RsuiteWrapper/SimpleWhisperPopoverDropdown";
import InterTag from "../../../components/Text/Inter";
import useHotelState from "../../../context/Hotel/hooks/useHotelState";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import useScreenSize from "../../../hooks/useScreenSize";
import { FUEL_TYPES } from "../../../interfaces/manualData";
import { tHotelManualData } from "../../../models/hotel";
import { COLORS } from "../../../utils/colors";
import { calcNumberOfNights } from "../../../utils/dates";
import { TABLE_HEADER_HEIGHT, TABLE_ROW_HEIGHT } from "../../../utils/tables";

type tTableItem = Pick<
  tHotelManualData,
  "from" | "to"
  // | "biomass"
  // | "butane"
  // | "diesel"
  // | "ethanol"
  // | "gasoline"
  // | "naturalGas"
  // | "propane"
> & {
  index: number;
  // year: number;
  totalPrice: number;
  totalKWh: number;
};

interface iShowcasingWrappedProps {
  outletContainerWidth: number;
}

const ShowcasingWrapped: React.FC<iShowcasingWrappedProps> = ({
  outletContainerWidth,
}) => {
  const { trans } = useLocalizationState();
  const { manualDataOrdered } = useHotelState();
  // const [selectedYears] = useState<number[]>([
  //   ...Object.keys(manualDataByYear).map((y) => toInteger(y)),
  // ]);

  const options = (
    dataKey: string,
    space: tTableItem
  ): tSimpleWhisperPopoverDropdownOptions[] => {
    const options: tSimpleWhisperPopoverDropdownOptions[] = [];
    return options;
  };

  const tableData: tTableItem[] = manualDataOrdered
    .map((item) => {
      const { from, to } = item;
      const totalKWh = sum(FUEL_TYPES.map((key) => item[key]?.totalKWh || 0));
      const totalPrice = sum(FUEL_TYPES.map((key) => item[key]?.price || 0));

      return { from, to, totalKWh, totalPrice };
    })
    .filter((item) => item.totalKWh || item.totalPrice)
    .map((item, i) => ({ ...item, index: i }));

  const tableHeight =
    (min([12, tableData.length]) as number) * TABLE_ROW_HEIGHT.SM +
    TABLE_HEADER_HEIGHT;

  const periodWidth = floor(0.3 * outletContainerWidth);
  const fuelsWidth = floor(0.15 * outletContainerWidth);
  const costsWidth = floor(0.15 * outletContainerWidth);
  const lastPeriodWidth = floor(0.15 * outletContainerWidth);
  const homologueWidth = floor(0.15 * outletContainerWidth);

  return (
    <Flex column>
      <div className="table-wrapper">
        <Table
          id="TABLE.FUELS.SHOWCASING"
          data={tableData}
          height={tableHeight}
          rowHeight={TABLE_ROW_HEIGHT.SM}
          headerHeight={TABLE_HEADER_HEIGHT}
        >
          <Table.Column width={periodWidth}>
            <SimpleHeaderCell name={trans("Period")} icon={DateRangeIcon} />
            <SimpleTextCell
              dataKey="period"
              textFunction={({ from, to }: tTableItem) =>
                trans("$0 to $1", {
                  parameters: [
                    moment(from).format("DD MMM YYYY"),
                    moment(to).format("DD MMM YYYY"),
                  ],
                })
              }
            />
          </Table.Column>
          <Table.Column width={fuelsWidth}>
            <SimpleHeaderCell name={trans("Fuels")} icon={ModeHeatIcon} />
            <SimpleTextCell
              textFunction={(item: tTableItem) => {
                return `${item.totalKWh} kWh`;
              }}
              descriptionFunction={(item: tTableItem) => {
                const numberOfNights = calcNumberOfNights(item.from, item.to);
                return `${round(item.totalKWh / numberOfNights, 2)} kWh/${trans(
                  "day"
                )}`;
              }}
              dataKey="fuels"
            />
          </Table.Column>
          <Table.Column width={costsWidth}>
            <SimpleHeaderCell name={trans("Costs")} icon={PaymentsIcon} />
            <SimpleTextCell
              textProps={() => ({ asHTML: true })}
              textFunction={(item: tTableItem) => {
                return `${item.totalPrice} €`;
              }}
              descriptionFunction={(item: tTableItem) => {
                const numberOfNights = calcNumberOfNights(item.from, item.to);
                return `${round(item.totalPrice / numberOfNights, 2)} €/${trans(
                  "day"
                )}`;
              }}
              dataKey="price"
            />
          </Table.Column>
          <Table.Column width={lastPeriodWidth}>
            <SimpleHeaderCell
              name={trans("Last Period")}
              icon={EventRepeatIcon}
            />
            <BaseCell dataKey="lastPeriod">
              {(rowData: tTableItem) => {
                const { index } = rowData;
                if (index === 0)
                  return <InterTag text="-" size={12} color={COLORS.gray} />;
                const previousEntry = tableData[index - 1];

                return (
                  <TrendingComparison
                    value={rowData.totalKWh}
                    baseValue={previousEntry.totalKWh}
                    size={12}
                  />
                );
              }}
            </BaseCell>
          </Table.Column>
          <Table.Column width={homologueWidth}>
            <SimpleHeaderCell
              name={trans("Homologous")}
              icon={EventRepeatIcon}
            />
            <BaseCell>
              {(rowData: tTableItem) => {
                const homologous = find(
                  reverse(tableData),
                  (md) =>
                    moment(md.from).year() < moment(rowData.from).year() &&
                    moment(md.from).month() === moment(rowData.from).month()
                );

                if (!homologous)
                  return <InterTag text="-" size={12} color={COLORS.gray} />;

                return (
                  <TrendingComparison
                    value={rowData.totalKWh}
                    baseValue={homologous.totalKWh}
                    size={12}
                  />
                );
              }}
            </BaseCell>
          </Table.Column>
          <Table.Column flexGrow={1} align="right">
            <SimpleHeaderCell name={{ text: "" }} />
            <SimpleActionCell options={options} dataKey="_id" />
          </Table.Column>
        </Table>
      </div>
    </Flex>
  );
};

interface iProps {}

const Showcasing: React.FC<iProps> = () => {
  const { outlet } = useScreenSize();
  const { trans } = useLocalizationState();
  const { hotelIsLoaded } = useHotelState();

  return (
    <Flex column gap={16}>
      <PageSectionTitle
        title={trans("Fuels Showcasing")}
        description={trans(
          "Comprehensive data showcasing detailed fuels usage accross your property."
        )}
        icon={ModeHeatIcon}
      />
      {!outlet || !hotelIsLoaded ? (
        <Flex>
          <Loader size="lg" />
        </Flex>
      ) : (
        <ShowcasingWrapped outletContainerWidth={outlet.width} />
      )}
    </Flex>
  );
};

export default React.memo(Showcasing);
