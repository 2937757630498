import React, { Fragment, useState } from "react";
import { Button, CheckPicker, Drawer, DrawerProps } from "rsuite";
import { tRoadmapFilter } from ".";
import { ReactComponent as FilterListIcon } from "../../assets/icons/filter_list.svg";
import useLocalizationState from "../../context/Localization/hooks/useLocalizationState";
import { COLORS } from "../../utils/colors";
import Flex from "../Flex";
import Icon from "../Icon";
import InterTag from "../Text/Inter";
import InputWrapper from "../InputWrapper";
import {
  HOTEL_ROADMAP_STATUSES,
  ROADMAP_DIFFICULTIES,
  ROADMAP_PHASES,
  ROADMAP_TYPES,
} from "../../utils/hotels/roadmap";

interface iFiltersDrawerWrappedProps
  extends Pick<
    iProps,
    "onConfirm" | "onClose" | "initialValues" | "showTypeFilter"
  > {}

const FiltersDrawerWrapped: React.FC<iFiltersDrawerWrappedProps> = ({
  onClose,
  onConfirm,
  initialValues,
  showTypeFilter,
}) => {
  const { trans } = useLocalizationState();

  const [filtersState, setFiltersState] = useState<tRoadmapFilter>({
    difficulty: [...initialValues.difficulty],
    order: [...initialValues.order],
    phase: [...initialValues.phase],
    status: [...initialValues.status],
    type: [...initialValues.type],
  });

  const handleConfirm = () => {
    onConfirm(filtersState);
  };

  return (
    <Fragment>
      <Drawer.Header>
        <Flex row middle gap={8}>
          <InterTag
            text={trans("Filters")}
            color={COLORS.secondary}
            size={18}
          />
          <Icon Element={FilterListIcon} size={20} fill={COLORS.secondary} />
        </Flex>
        <Drawer.Actions>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            onClick={handleConfirm}
            appearance="primary"
            // disabled={!changesMade}
          >
            Confirm
          </Button>
        </Drawer.Actions>
      </Drawer.Header>
      <Drawer.Body>
        <Flex column gap={20}>
          {showTypeFilter && (
            <InputWrapper label={trans("Type")}>
              <CheckPicker
                value={filtersState.type}
                data={ROADMAP_TYPES.map((t) => ({ label: t, value: t }))}
                onChange={(values) => {
                  setFiltersState((prev) => ({ ...prev, type: values }));
                }}
              />
            </InputWrapper>
          )}
          <InputWrapper label={trans("Phase")}>
            <CheckPicker
              value={filtersState.phase}
              data={ROADMAP_PHASES.map((t) => ({ label: t, value: t }))}
              onChange={(values) => {
                setFiltersState((prev) => ({ ...prev, phase: values }));
              }}
            />
          </InputWrapper>
          <InputWrapper label={trans("Difficulty")}>
            <CheckPicker
              value={filtersState.difficulty}
              data={ROADMAP_DIFFICULTIES.map((t) => ({ label: t, value: t }))}
              onChange={(values) => {
                setFiltersState((prev) => ({ ...prev, difficulty: values }));
              }}
            />
          </InputWrapper>
          <InputWrapper label={trans("Status")}>
            <CheckPicker
              value={filtersState.status}
              data={HOTEL_ROADMAP_STATUSES.map((t) => ({ label: t, value: t }))}
              onChange={(values) => {
                setFiltersState((prev) => ({ ...prev, status: values }));
              }}
            />
          </InputWrapper>
        </Flex>
      </Drawer.Body>
    </Fragment>
  );
};

interface iProps extends Omit<DrawerProps, "onClose"> {
  onClose(): void;
  onConfirm(filters: tRoadmapFilter): void;
  initialValues: tRoadmapFilter;
  showTypeFilter: boolean;
}

const FitlersDrawer: React.FC<iProps> = ({
  open,
  onClose,
  onConfirm,
  initialValues,
  showTypeFilter,
  ...props
}) => {
  return (
    <Drawer
      {...{
        open,
        onClose,
        placement: "right",
        size: "xs",
        className: "drawer-secondary-bg",
        ...props,
      }}
    >
      <FiltersDrawerWrapped
        {...{ onClose, initialValues, onConfirm, showTypeFilter }}
      />
    </Drawer>
  );
};

export default FitlersDrawer;
