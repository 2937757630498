import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { IconButton, Input, InputGroup } from "rsuite";
import { ReactComponent as SearchIcon } from "../../assets/icons/search.svg";
import { ReactComponent as TuneIcon } from "../../assets/icons/tune.svg";
import useHotelState from "../../context/Hotel/hooks/useHotelState";
import useLocalizationState from "../../context/Localization/hooks/useLocalizationState";
import useScreenSize from "../../hooks/useScreenSize";
import { COLORS } from "../../utils/colors";
import Flex from "../Flex";
import Icon from "../Icon";

interface iProps {
  children: React.ReactNode;
  searchbar?: boolean;
}

const TopBar: React.FC<iProps> = ({ children, searchbar = true }) => {
  const { trans } = useLocalizationState();
  const navigate = useNavigate();
  const {
    findSpace,
    findSpaceByName,
    findSpaceAggregate,
    findSpaceAggregateByName,
    activeSubscriptionType,
    hotelIsLoaded,
  } = useHotelState();
  const [searchText, setSearchText] = useState("");

  const { outlet } = useScreenSize();
  if (!outlet) return null;

  const { width } = outlet;

  const icons = () => {
    return [
      {
        label: "Settings",
        Element: TuneIcon,
        onClick: () => {
          navigate("/settings");
        },
      },
    ].map(({ Element, onClick, label }) => (
      <IconButton
        key={label}
        onClick={onClick}
        size="xs"
        appearance="subtle"
        circle
        icon={<Icon Element={Element} size={24} fill={COLORS.secondary} />}
      />
    ));
  };

  const onSearch = (e: any) => {
    e.preventDefault();
    const text = searchText.trim();
    const space = findSpace(text) || findSpaceByName(text);
    if (space) {
      navigate(`/spaces/${space._id}`);
    }

    const spaceAggregate =
      findSpaceAggregate(text) || findSpaceAggregateByName(text);
    if (spaceAggregate) {
      navigate(`/space-aggregates/${spaceAggregate._id}`);
    }
  };

  return (
    <div style={{ height: "80px" }}>
      <Flex
        row
        between
        middle
        style={{
          width: `${width}px`,
          height: "70px",
          position: "fixed",
          zIndex: 10,
          backgroundColor: COLORS.app_background,
        }}
      >
        {children}
        <Flex row gap={20} middle>
          {searchbar &&
            hotelIsLoaded &&
            activeSubscriptionType !== "starter" && (
              <div style={{ width: "350px" }}>
                <form>
                  <InputGroup size="md">
                    <Input
                      onChange={setSearchText}
                      value={searchText}
                      placeholder={trans("Search for spaces")}
                    />
                    <InputGroup.Button onClick={onSearch}>
                      <Icon
                        Element={SearchIcon}
                        fill={COLORS.secondary}
                        size={24}
                      />
                    </InputGroup.Button>
                  </InputGroup>
                  <input
                    type="submit"
                    onClick={onSearch}
                    style={{ display: "none" }}
                  />
                </form>
              </div>
            )}
          <Flex row gap={40}>
            {icons()}
          </Flex>
        </Flex>
      </Flex>
    </div>
  );
};

export default TopBar;
