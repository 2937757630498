import { MomentInput } from "moment";
import React from "react";
import useHotelState from "../../context/Hotel/hooks/useHotelState";
import { tHotelSpaceId } from "../../models/hotel";
import { CW, GW, HW, tMeasureWaterSpecific, TW } from "../../models/measures";
import {
  convertVolumeUnit,
  DEFAULT_WATER_MEASURE_UNIT,
} from "../../utils/convertUnits/";
import MeasureConsumption, {
  iMeasureConsumptionProps,
} from "../MeasureConsumption";

interface iProps
  extends Pick<
    iMeasureConsumptionProps,
    "mainMeasureRequestStatusTracker" | "specificMeasuresRequestStatusTracker"
  > {
  from?: MomentInput;
  to?: MomentInput;
  spaces?: tHotelSpaceId[];
}

const MeasureConsumptionWater: React.FC<iProps> = ({
  from,
  to,
  spaces,
  mainMeasureRequestStatusTracker,
  specificMeasuresRequestStatusTracker,
}) => {
  const { getMeasuresTracked } = useHotelState();

  const trackedMeasures = getMeasuresTracked(spaces);

  const specificMeasures: { measureKey: tMeasureWaterSpecific }[] = [];

  if (trackedMeasures.hw) specificMeasures.push({ measureKey: HW });
  if (trackedMeasures.gw) specificMeasures.push({ measureKey: GW });
  if (trackedMeasures.cw) specificMeasures.push({ measureKey: CW });

  return (
    <MeasureConsumption
      {...{
        mainMeasureRequestStatusTracker,
        specificMeasuresRequestStatusTracker,
      }}
      spaces={spaces}
      mainMeasureKey={TW}
      specificMeasures={specificMeasures}
      converter={convertVolumeUnit}
      binUnit="day"
      binValue={1}
      mainUnit={DEFAULT_WATER_MEASURE_UNIT}
      from={from}
      to={to}
    />
  );
};

export default MeasureConsumptionWater;
