import axios from "axios";
import { has, min } from "lodash";
import moment from "moment";
import React, { Fragment, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Table, useToaster } from "rsuite";
import { ReactComponent as BadgeIcon } from "../../../../assets/icons/badge.svg";
import { ReactComponent as ContentCopyIcon } from "../../../../assets/icons/content_copy.svg";
import { ReactComponent as DeleteIcon } from "../../../../assets/icons/delete.svg";
import { ReactComponent as GroupsIcon } from "../../../../assets/icons/groups.svg";
import { ReactComponent as PersonIcon } from "../../../../assets/icons/person.svg";
import { ReactComponent as PunchClockIcon } from "../../../../assets/icons/punch_clock.svg";
import { ReactComponent as VisibilityIcon } from "../../../../assets/icons/visibility.svg";
import Flex from "../../../../components/Flex";
import BaseCell from "../../../../components/RsuiteWrapper/SimpleTable/SimpleCells/BaseCell";
import SimpleActionCell from "../../../../components/RsuiteWrapper/SimpleTable/SimpleCells/SimpleActionCell";
import SimpleTextCell from "../../../../components/RsuiteWrapper/SimpleTable/SimpleCells/SimpleTextCell";
import SimpleHeaderCell from "../../../../components/RsuiteWrapper/SimpleTable/SimpleHeaderCell";
import { tSimpleWhisperPopoverDropdownOptions } from "../../../../components/RsuiteWrapper/SimpleWhisperPopoverDropdown";
import SimpleWhisperTooltip from "../../../../components/RsuiteWrapper/SimpleWhisperTooltip";
import InterTag from "../../../../components/Text/Inter";
import useHotelDispatch from "../../../../context/Hotel/hooks/useHotelDispatch";
import useHotelState from "../../../../context/Hotel/hooks/useHotelState";
import useLocalizationState from "../../../../context/Localization/hooks/useLocalizationState";
import useProfileState from "../../../../context/Profile/hooks/useProfileState";
import useDeleteRequest from "../../../../hooks/apiRequests/useDeleteRequest";
import useListStaff from "../../../../hooks/useListStaff";
import useProfilePermissions from "../../../../hooks/useProfilePermissions";
import { tManager, tManagerId } from "../../../../models/manager";
import { apiAddressV2, LOCAL_NODE_SERVER_V2 } from "../../../../utils/apiCall";
import { COLORS } from "../../../../utils/colors";
import { getErrorMessage } from "../../../../utils/httpResponses/others";
import { notification } from "../../../../utils/notifications";
import { copyToClipboard } from "../../../../utils/others";
import {
  TABLE_HEADER_HEIGHT,
  TABLE_ROW_HEIGHT,
} from "../../../../utils/tables";
import { tStaffData } from "../users";
import CreateEditUserModal from "./createEditUserModal";
import { tHotelStaffRole } from "../../../../models/hotel";

interface iProps {
  staffRequest: ReturnType<typeof useListStaff>;
}

const UsersTab: React.FC<iProps> = ({ staffRequest }) => {
  const toaster = useToaster();
  const navigate = useNavigate();
  const { trans } = useLocalizationState();
  const { profile } = useProfileState();
  const { getStaffRoles, hotelId } = useHotelState();
  const { updateHotel, updatingHotel } = useHotelDispatch();
  const [editUserModal, setEditUserModal] = useState<
    | { open: false }
    | { open: true; staffMember: Pick<tManager, "name" | "email" | "_id"> }
  >({ open: false });
  const deleteRequest = useDeleteRequest();
  const profilePermissions = useProfilePermissions();

  const options = (
    dataKey: string,
    staffMember: tStaffData
  ): tSimpleWhisperPopoverDropdownOptions[] => {
    const options: tSimpleWhisperPopoverDropdownOptions[] = [
      {
        key: "show",
        label: trans("View Staff Member"),
        icon: VisibilityIcon,
        onClick() {
          navigate(`/users/${staffMember._id}`);
        },
      },
      {
        key: "copy-id",
        label: trans("Copy user ID"),
        icon: ContentCopyIcon,
        onClick() {
          copyToClipboard(staffMember._id);
        },
        show: apiAddressV2(false) === LOCAL_NODE_SERVER_V2,
      },
    ];

    if (staffMember._id === profile._id) {
      options.push({
        key: "update",
        label: trans("Edit user"),
        icon: PersonIcon,
        onClick(value) {
          setEditUserModal({ open: true, staffMember: staffMember });
        },
      });
    }

    if (staffMember._id !== profile._id) {
      options.push({
        key: "delete",
        label: { text: trans("Remove user"), color: COLORS.error },
        icon: { Element: DeleteIcon, fill: COLORS.error },
        onClick(value) {
          deleteRequest.pending();
          updatingHotel();
          axios
            .delete(
              `${apiAddressV2(false)}/v2/hotels/${hotelId}/staff/${
                staffMember._id
              }`
            )
            .then((res) => {
              const {
                data: { hotel },
              } = res;
              updateHotel(hotelId, hotel);
              deleteRequest.resolve();
              toaster.push(notification("success", trans("User removed")), {
                placement: "topEnd",
              });
            })
            .catch((err) => {
              const error = getErrorMessage(err, trans);
              deleteRequest.reject(error);
              toaster.push(notification("error", error), {
                placement: "topEnd",
              });
            });
        },
      });
    }

    return options;
  };

  const tableHeight = staffRequest.isLoading
    ? TABLE_ROW_HEIGHT.M + TABLE_HEADER_HEIGHT
    : (min([10, staffRequest.data.staff.length]) || 1) * TABLE_ROW_HEIGHT.M +
      TABLE_HEADER_HEIGHT;

  const rolesPerUser = useMemo(() => {
    const rolesPerUser: Record<tManagerId, tHotelStaffRole[]> = {};

    staffRequest.data.staff.forEach((s) => {
      const roles = getStaffRoles(s._id);

      rolesPerUser[s._id] = roles;
    });
    return rolesPerUser;
  }, [getStaffRoles, staffRequest.data.staff]);

  return (
    <Fragment>
      {editUserModal.open && (
        <CreateEditUserModal
          open={editUserModal.open}
          staffMember={editUserModal.staffMember}
          onClose={() => setEditUserModal({ open: false })}
        />
      )}
      <div className="table-wrapper">
        <Table
          id={`USERS.TABLE`}
          data={staffRequest.data.staff}
          height={tableHeight}
          rowHeight={TABLE_ROW_HEIGHT.M}
          headerHeight={TABLE_HEADER_HEIGHT}
          loading={staffRequest.isLoading}
        >
          <Table.Column flexGrow={1}>
            <SimpleHeaderCell
              icon={{ Element: BadgeIcon }}
              name={trans("User")}
            />
            <BaseCell>
              {(staffMember: tStaffData) => {
                const { name, email } = staffMember;
                return (
                  <Flex column gap={4}>
                    <InterTag text={name} size={12} color={COLORS.secondary} />
                    <InterTag
                      text={email}
                      size={12}
                      color={COLORS.primary_400}
                    />
                  </Flex>
                );
              }}
            </BaseCell>
          </Table.Column>
          <Table.Column flexGrow={1}>
            <SimpleHeaderCell
              icon={{ Element: GroupsIcon }}
              name={trans("Role")}
            />
            <BaseCell>
              {(staffMember: tStaffData) => {
                const { _id } = staffMember;
                const roles = rolesPerUser[_id];

                if (roles.length === 0) return null;
                if (roles.length === 1)
                  return (
                    <InterTag
                      size={12}
                      color={COLORS.secondary}
                      text={roles[0].name}
                    />
                  );

                return (
                  <SimpleWhisperTooltip
                    id={`USERS.TAB.${staffMember._id}.ROLES`}
                    tooltip={roles.map((r) => r.name).join(", ")}
                  >
                    <InterTag
                      size={12}
                      color={COLORS.secondary}
                      text={`${roles.map((r) => r.name).join(", ")}`}
                    />
                  </SimpleWhisperTooltip>
                );
              }}
            </BaseCell>
          </Table.Column>
          <Table.Column flexGrow={1}>
            <SimpleHeaderCell
              name={trans("Last Access")}
              icon={{
                Element: PunchClockIcon,
                size: 18,
                fill: COLORS.secondary,
              }}
            />
            <SimpleTextCell
              dataKey="lastAccess"
              textFunction={(staffMember: tStaffData) => {
                if (
                  !profilePermissions.readUsersLastAccess &&
                  staffMember._id !== profile._id
                )
                  return null;

                return has(staffMember, "lastAccess")
                  ? moment(staffMember.lastAccess).format(
                      "dddd, MMMM DD, YYYY, HH:mm:ss"
                    )
                  : trans("No user activities");
              }}
            />
          </Table.Column>
          <Table.Column flexGrow={1} align="right">
            <SimpleHeaderCell name={{ text: "" }} />
            <SimpleActionCell options={options} dataKey="_id" />
          </Table.Column>
        </Table>
      </div>
    </Fragment>
  );
};

export default UsersTab;
