import { tGuest } from "../../models/guest";

export const defaultGuest: tGuest = {
  _id: "",
  email: "",
  name: "",
  username: "",
  images: [],
  country: "",
  active: true,
  facebook: { id: "" },
  google: { id: "" },
  friends: [],
  isAdmin: false,
  isDummy: false,
  local: { password: "" },
  method: "local",
  noytrallId: "",
  phoneNumber: "",
  referralCode: "",
  registered: true,
  reservations: [],
};
