export const measureTextWidth = (
  text: string,
  fontSize: number,
  fontWeight: number | string,
  lineHeight?: number,
  fontFamily: string = "Inter"
) => {
  // Create a canvas element for measurement
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  // Set the font properties
  if (context) {
    context.font = `normal normal ${fontWeight} ${fontSize}px/${
      lineHeight || fontSize
    }px ${fontFamily}`;
    // Measure the text width
    const metrics = context.measureText(text);
    return metrics.width;
  }
  return 0;
};
