import { Table, Tooltip, Whisper } from "rsuite";
import { ReactComponent as HelpIcon } from "../../../../assets/icons/help.svg";
import { tIcon, tIconElement } from "../../../../interfaces/icon";
import { COLORS } from "../../../../utils/colors";
import Flex from "../../../Flex";
import Icon from "../../../Icon";
import { toIconProps } from "../../../Icon/utils";
import { iTextProps } from "../../../Text";
import InterTag from "../../../Text/Inter";
import { toTextProps } from "../../../Text/utils";

export interface iSimpleHeaderCellProps {
  icon?: tIcon | tIconElement;
  name?: string | iTextProps;
  tooltip?: any;
  [key: string]: any;
}

const SimpleHeaderCell = ({
  name,
  icon,
  tooltip,
  ...rest
}: iSimpleHeaderCellProps) => {
  const child = name ? (
    <Flex gap={4} middle>
      {icon && (
        <Icon {...{ size: 18, fill: COLORS.secondary, ...toIconProps(icon) }} />
      )}
      <InterTag {...{ ...toTextProps(name), bold: true }} />
    </Flex>
  ) : null;

  return (
    <Table.HeaderCell
      {...{
        ...rest,
        style: { display: "flex", alignItems: "center" },
      }}
    >
      {tooltip ? (
        <Whisper
          placement="topStart"
          trigger="hover"
          speaker={<Tooltip>{tooltip}</Tooltip>}
        >
          <Flex row gap={0}>
            {child}
            <Icon Element={HelpIcon} fill={COLORS.secondary} size={10} />
          </Flex>
        </Whisper>
      ) : (
        child
      )}
    </Table.HeaderCell>
  );
};

export default SimpleHeaderCell;
