import React from "react";
import { Loader } from "rsuite";
import Flex from "../../../components/Flex";
import NoPermissions from "../../../components/NoPermissions";
import PageBottomPadding from "../../../components/PageBottomPadding";
import InterTag from "../../../components/Text/Inter";
import TopBar from "../../../components/TopBar";
import useHotelSubscriptionState from "../../../context/Hotel/hooks/hotelState/useHotelSubscriptionState";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import useProfilePermissions from "../../../hooks/useProfilePermissions";
import { COLORS } from "../../../utils/colors";
import AwardsList from "./list";
import AwardsStatistics from "./statistics";
import Unlock from "./unlock";

const Awards: React.FC = () => {
  const { trans } = useLocalizationState();
  const { hotelLoading, hotelIsLoaded, activeSubscriptionIsStarter } =
    useHotelSubscriptionState();
  const profilePermissions = useProfilePermissions();

  const renderMain = () => {
    if (hotelLoading)
      return (
        <Flex middle center>
          <Loader size="lg" />
        </Flex>
      );
    if (hotelIsLoaded) {
      if (activeSubscriptionIsStarter)
        return (
          <>
            <Unlock />
            <PageBottomPadding />
          </>
        );

      if (!profilePermissions.readAwards) {
        return <NoPermissions />;
      }

      return (
        <>
          <Flex column gap={40}>
            <AwardsStatistics />
            <AwardsList />
          </Flex>
          <PageBottomPadding />
        </>
      );
    }
    return null;
  };

  return (
    <>
      <TopBar>
        <InterTag
          text={`${trans("Awards")}`}
          bold
          color={COLORS.secondary}
          size={24}
        />
      </TopBar>
      {renderMain()}
    </>
  );
};

export default Awards;
