import { ceil } from "lodash";
import moment, { MomentInput } from "moment";
import { tBinUnitSingular } from "../interfaces/sensorData";

export const generateGraphCategories = (
  binUnit: tBinUnitSingular,
  binValue: number,
  from: MomentInput,
  to: MomentInput
) => {
  const categories: any[] = [];
  const numberOfLabels = ceil(
    (moment(to).endOf(binUnit).diff(moment(from).startOf(binUnit), binUnit) +
      1) /
      binValue
  );

  const iter = moment(from).startOf(binUnit);
  for (let i = 0; i < numberOfLabels; i++) {
    categories.push(moment(iter).toDate());
    iter.add(binValue, binUnit);
  }

  return categories;
};
