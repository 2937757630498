import { find } from "lodash";
import { tHotelAward, tHotelAwardType } from "../models/hotel";
import { tLanguageCode } from "./languages";
import { DEFAULT_LANGAUGE } from "../context/Localization/utils";
import { ReactComponent as HikingIcon } from "../assets/icons/hiking.svg";
import { ReactComponent as RestaurantIcon } from "../assets/icons/restaurant.svg";
import { ReactComponent as HotelIcon } from "../assets/icons/hotel.svg";
import { ReactComponent as AttractionsIcon } from "../assets/icons/attractions.svg";
import { ReactComponent as ShoppingBasketIcon } from "../assets/icons/shopping_basket.svg";
import { ReactComponent as WorkIcon } from "../assets/icons/work.svg";
import { ReactComponent as SpaIcon } from "../assets/icons/spa.svg";
import { ReactComponent as TheaterComedyIcon } from "../assets/icons/theater_comedy.svg";

export const getAwardDescription = (
  award: tHotelAward,
  language: tLanguageCode | string
): { shortDescription: string; longDescription: string } | undefined => {
  return (
    find(award.descriptions, (d) => d.language === language) ||
    find(award.descriptions, (d) => d.language === DEFAULT_LANGAUGE) ||
    award.descriptions[0]
  );
};

export const getAwardTypeInfo = (type: tHotelAwardType) => {
  switch (type) {
    case "Adventure":
      return { icon: HikingIcon };
    case "Food & Drinks":
      return { icon: RestaurantIcon };
    case "Hospitality":
      return { icon: HotelIcon };
    case "Leisure":
      return { icon: AttractionsIcon };
    case "Products":
      return { icon: ShoppingBasketIcon };
    case "Services":
      return { icon: WorkIcon };
    case "Wellness":
      return { icon: SpaIcon };
    case "Cultural":
      return { icon: TheaterComedyIcon };
  }
};
