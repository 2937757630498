import axios from "axios";
import { find, toNumber, uniqueId } from "lodash";
import React, { useEffect, useReducer } from "react";
import {
  Button,
  IconButton,
  InputNumber,
  Modal,
  ModalProps,
  SelectPicker,
  Toggle,
  Tooltip,
  Whisper,
} from "rsuite";
import { ReactComponent as AddIcon } from "../../../assets/icons/add.svg";
import { ReactComponent as ArrowForwardIcon } from "../../../assets/icons/arrow_forward.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/icons/delete.svg";
import { ReactComponent as TargetIcon } from "../../../assets/icons/target.svg";
import { ReactComponent as VerifiedIcon } from "../../../assets/icons/verified.svg";
import Modal2ActionButtons from "../../../components/Buttons/modal2ActionButtons";
import Flex from "../../../components/Flex";
import Icon from "../../../components/Icon";
import InputWrapper from "../../../components/InputWrapper";
import useHotelDispatch from "../../../context/Hotel/hooks/useHotelDispatch";
import useHotelState from "../../../context/Hotel/hooks/useHotelState";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import usePostRequest from "../../../hooks/apiRequests/usePostRequest";
import { tWithKey } from "../../../interfaces/others";
import { tHotelLoyaltyProgramItem } from "../../../models/hotel";
import { apiAddressV2 } from "../../../utils/apiCall";
import { getAwardDescription } from "../../../utils/awards";
import { COLORS } from "../../../utils/colors";
import { triDevPrd } from "../../../utils/environment";
import { getErrorMessage } from "../../../utils/httpResponses/others";
import { DEFAULT_MODAL_PROPS } from "../../../utils/rsuite/modals";

type tData = Omit<
  tHotelLoyaltyProgramItem,
  "_id" | "timesAwarded" | "awardingHistory" | "awards"
> & {
  awards: tWithKey<tHotelLoyaltyProgramItem["awards"][0]>[];
};

type tErrors = Record<keyof tData, string>;

type tState = {
  data: tData;
  errors: tErrors;
};

type tAction =
  | { type: "reset" }
  | { type: "errors"; errors: Partial<tErrors> }
  | { type: "data"; data: Partial<tData> }
  | {
      type: "awards";
      award: Pick<tData["awards"][0], "key"> &
        Partial<Omit<tData["awards"][0], "key">>;
    }
  | { type: "key value"; key: keyof tData; value: any };

const initialState: tState = {
  data: { awards: [], goal: 1, status: "active", category: "nights" },
  errors: { awards: "", goal: "", status: "", category: "" },
};

const reducer = (state: tState, action: tAction): tState => {
  switch (action.type) {
    case "reset": {
      return { ...initialState };
    }
    case "errors": {
      const { errors } = action;
      return { ...state, errors: { ...state.errors, ...errors } };
    }
    case "awards": {
      const { award } = action;
      for (const [index, a] of Object.entries(state.data.awards)) {
        if (a.key === award.key) {
          state.data.awards[toNumber(index)] = { ...a, ...award };
          break;
        }
      }
      return {
        ...state,
        errors: { ...state.errors, awards: "" },
        data: { ...state.data, awards: [...state.data.awards] },
      };
    }
    case "data": {
      const { data } = action;
      return {
        ...state,
        errors: {
          ...state.errors,
          ...Object.fromEntries(Object.keys(data).map((key) => [key, ""])),
        },
        data: { ...state.data, ...data },
      };
    }
    case "key value": {
      const { key, value } = action;
      return {
        ...state,
        errors: { ...state.errors, [key]: "" },
        data: { ...state.data, [key]: value },
      };
    }
    default: {
      return { ...state };
    }
  }
};

interface iCreateEditLoyaltyProgramWrappedProps
  extends Pick<iProps, "onClose" | "programId"> {}

const CreateEditLoyaltyProgramWrapped: React.FC<
  iCreateEditLoyaltyProgramWrappedProps
> = ({ onClose, programId }) => {
  const { trans, language } = useLocalizationState();
  const { hotel, hotelIsLoaded, hotelLoading, hotelId } = useHotelState();
  const { updateHotel } = useHotelDispatch();
  const [state, dispatch] = useReducer(reducer, initialState);
  const request = usePostRequest();

  useEffect(() => {
    if (programId) {
      if (hotelIsLoaded) {
        const program = find(
          hotel.loyaltyProgram,
          (lp) => lp._id === programId
        );

        if (!program) {
          onClose();
          return;
        }

        dispatch({
          type: "data",
          data: {
            awards: program.awards.map(({ awardId, count }) => ({
              awardId,
              count,
              key: uniqueId(),
            })),
            category: program.category,
            goal: program.goal,
            status: program.status,
          },
        });
      }
    }
  }, [hotel.loyaltyProgram, hotelIsLoaded, onClose, programId]);

  const handleConfirm = () => {
    const awardsToSubmit: tHotelLoyaltyProgramItem["awards"] = state.data.awards
      .filter((a) => a.count > 0 && a.awardId && a.awardId.length > 0)
      .map(({ awardId, count }) => ({ awardId, count }));

    const requestBody: Pick<
      tHotelLoyaltyProgramItem,
      "awards" | "category" | "goal" | "status"
    > = {
      awards: awardsToSubmit,
      category: state.data.category,
      goal: state.data.goal,
      status: state.data.status,
    };

    request.pending();
    (programId
      ? axios.put(
          `${apiAddressV2(false)}/v2/hotels/${hotelId}/loyalty/${programId}`,
          requestBody
        )
      : axios.post(
          `${apiAddressV2(false)}/v2/hotels/${hotelId}/loyalty`,
          requestBody
        )
    )
      .then((res) => {
        const {
          data: { hotel },
        } = res;
        updateHotel(hotelId, hotel);
        request.resolve();
        onClose();
      })
      .catch((err) => {
        const error = getErrorMessage(err, trans);
        request.reject(error);
      });
  };

  const availableAwards = hotel.awards.filter((a) => a.active && !a.removed);

  const handleChange = (key: keyof tData) => (value: any) => {
    dispatch({ type: "key value", key, value });
  };

  return (
    <>
      <Modal.Header></Modal.Header>
      <Modal.Body className="modal-body-pb0">
        <Flex column gap={20}>
          <InputWrapper label={trans("Goal")} icon={TargetIcon}>
            <Flex row gap={8}>
              <InputNumber
                disabled={request.isLoading}
                style={{ flexBasis: "20%" }}
                value={state.data.goal}
                onChange={handleChange("goal")}
                min={1}
              />
              <SelectPicker
                disabled={request.isLoading}
                onChange={(value) => {
                  if (value) handleChange("category")(value);
                }}
                cleanable={false}
                searchable={false}
                style={{ flexBasis: "20%" }}
                data={[
                  { label: trans("Nights"), value: "nights" },
                  { label: trans("Stays"), value: "stays" },
                ].map(({ label, value }) => ({
                  label,
                  value,
                }))}
                value={state.data.category}
              />
            </Flex>
          </InputWrapper>
          <InputWrapper label={trans("Status")}>
            <Toggle
              disabled={request.isLoading}
              style={{ width: "fit-content" }}
              checked={state.data["status"] === "active"}
              onChange={(checked) =>
                dispatch({
                  type: "key value",
                  key: "status",
                  value: checked ? "active" : "inactive",
                })
              }
              checkedChildren={trans("Active")}
              unCheckedChildren={trans("Inactive")}
              size="md"
            />
          </InputWrapper>
          <InputWrapper
            icon={VerifiedIcon}
            label={trans("Awards")}
            error={state.errors["awards"]}
          >
            {state.data.awards.map(({ awardId, count, key }, i) => {
              return (
                <Flex row gap={16} key={key}>
                  <SelectPicker
                    cleanable={false}
                    disabled={hotelLoading || request.isLoading}
                    placement="right"
                    menuMaxHeight={200}
                    style={{ flexBasis: triDevPrd("50%", "85%") }}
                    value={awardId}
                    onChange={(value) => {
                      dispatch({
                        type: "awards",
                        award: { awardId: value || "", key },
                      });
                      const awards = state.data.awards;
                      awards[i] = {
                        ...state.data.awards[i],
                        awardId: value || "",
                      };
                      dispatch({
                        type: "key value",
                        key: "awards",
                        value: awards,
                      });
                    }}
                    data={availableAwards.map((a) => {
                      const description = getAwardDescription(a, language);
                      return {
                        label: (
                          <Whisper
                            trigger="hover"
                            placement="topStart"
                            speaker={
                              <Tooltip>{description?.longDescription}</Tooltip>
                            }
                          >
                            <div>{description?.shortDescription}</div>
                          </Whisper>
                        ),
                        value: a._id,
                      };
                    })}
                  />
                  <InputNumber
                    disabled={hotelLoading || request.isLoading}
                    style={{ flexBasis: "15%" }}
                    value={count}
                    min={1}
                    onChange={(value) => {
                      dispatch({
                        type: "awards",
                        award: { awardId, count: toNumber(value) || 1, key },
                      });
                    }}
                  />
                  <IconButton
                    disabled={request.isLoading}
                    circle
                    icon={
                      <Icon
                        Element={DeleteIcon}
                        fill={
                          request.isLoading ? COLORS.gray_200 : COLORS.error
                        }
                        size={18}
                      />
                    }
                    onClick={() => {
                      const awards = state.data.awards.filter(
                        (a) => a.key !== key
                      );
                      dispatch({
                        type: "key value",
                        key: "awards",
                        value: awards,
                      });
                    }}
                  />
                </Flex>
              );
            })}
            <Button
              disabled={hotelLoading || request.isLoading}
              style={{ width: "fit-content" }}
              className="button-gray"
              onClick={() =>
                dispatch({
                  type: "key value",
                  key: "awards",
                  value: [
                    ...state.data.awards,
                    { awardId: "", count: 1, key: uniqueId() },
                  ],
                })
              }
              appearance="default"
            >
              {trans("Add new award")}
            </Button>
          </InputWrapper>

          <Modal2ActionButtons
            onClose={onClose}
            onConfirm={handleConfirm}
            disabled={request.isLoading}
            isLoading={request.isLoading}
            {...{
              ...(programId
                ? {
                    confirmIcon: ArrowForwardIcon,
                    confirmLabel: trans("Update Program"),
                  }
                : {
                    confirmIcon: AddIcon,
                    confirmLabel: trans("Create Program"),
                  }),
            }}
          />
        </Flex>
      </Modal.Body>
    </>
  );
};

interface iProps extends Omit<ModalProps, "onClose"> {
  programId?: tHotelLoyaltyProgramItem["_id"];
  onClose(): void;
}

const CreateEditLoyaltyProgramItem: React.FC<iProps> = ({
  onClose,
  programId,
  ...rest
}) => {
  return (
    <Modal {...{ ...DEFAULT_MODAL_PROPS, onClose, ...rest }}>
      <CreateEditLoyaltyProgramWrapped {...{ onClose, programId }} />
    </Modal>
  );
};

export default CreateEditLoyaltyProgramItem;
