import React from "react";
import { Outlet } from "react-router-dom";
import { ReactComponent as NoytrallSymbol } from "../../../assets/noytrall_symbol.svg";
import { ReactComponent as NoytrallWord } from "../../../assets/noytrall_word.svg";
import Flex from "../../../components/Flex";
import Icon from "../../../components/Icon";
import { COLORS } from "../../../utils/colors";

const Root: React.FC = () => {
  return (
    <Flex className="height-100 bg-secondary" column gap={24}>
      <Flex row gap={10} center middle style={{ marginTop: "24px" }}>
        <Icon Element={NoytrallWord} width={100} />
        <Icon Element={NoytrallSymbol} height={50} />
      </Flex>
      <div style={{ backgroundColor: COLORS.secondary, paddingBottom: "24px" }}>
        <Outlet />
      </div>
    </Flex>
  );
};

export default Root;
