import React from "react";
import { ReactComponent as ArrowUpwardIcon } from "../../assets/icons/arrow_upward.svg";
import { ReactComponent as ArrowDownwardIcon } from "../../assets/icons/arrow_downward.svg";
import Flex from "../Flex";
import InterTag from "../Text/Inter";
import { COLORS } from "../../utils/colors";
import Icon from "../Icon";
import { round } from "lodash";
import { thousandsSeparation } from "../../utils/numbers";

interface iProps {
  value: number;
  baseValue: number;
  moreIsGood?: boolean;
}

const StraightComparison: React.FC<iProps> = ({
  baseValue,
  value,
  moreIsGood = false,
}) => {
  const diff = baseValue - value;
  const variation = (Math.abs(diff) / Math.abs(baseValue)) * 100;

  const [upColor, downColor] = moreIsGood
    ? [COLORS.emissions, COLORS.error]
    : [COLORS.error, COLORS.emissions];

  if (diff < 0)
    return (
      <Flex row gap={4} middle>
        <Icon Element={ArrowUpwardIcon} fill={upColor} size={24} />
        <Flex row bottom gap={4}>
          <InterTag
            size={36}
            color={upColor}
            text={thousandsSeparation(round(variation, 2).toFixed(2))}
          />
          <InterTag size={16} color={upColor} text={"%"} />
        </Flex>
      </Flex>
    );

  if (diff > 0) {
    return (
      <Flex row gap={4} middle>
        <Icon Element={ArrowDownwardIcon} fill={downColor} size={24} />
        <Flex row bottom gap={4}>
          <InterTag
            size={36}
            color={downColor}
            text={`${thousandsSeparation(round(variation, 2).toFixed(2))}`}
          />
          <InterTag size={16} color={downColor} text={"%"} />
        </Flex>
      </Flex>
    );
  }

  const number0 = 0;
  return (
    <Flex row gap={4} middle>
      <InterTag size={16} color={COLORS.gray} text={number0.toFixed(2)} />
      <InterTag size={16} color={COLORS.gray} text={"%"} />
    </Flex>
  );
};

export default StraightComparison;
