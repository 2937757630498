import axios from "axios";
import { floor, min } from "lodash";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Table, useToaster } from "rsuite";
import { ReactComponent as CategoryIcon } from "../../../assets/icons/category.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/icons/delete.svg";
import { ReactComponent as MeetingRoomIcon } from "../../../assets/icons/meeting_room.svg";
import { ReactComponent as SettingsIcon } from "../../../assets/icons/settings.svg";
import { ReactComponent as StacksIcon } from "../../../assets/icons/stacks.svg";
import { ReactComponent as VisibilityIcon } from "../../../assets/icons/visibility.svg";
import { ReactComponent as WorkSpacesIcon } from "../../../assets/icons/workspaces.svg";
import BaseCell from "../../../components/RsuiteWrapper/SimpleTable/SimpleCells/BaseCell";
import SimpleActionCell from "../../../components/RsuiteWrapper/SimpleTable/SimpleCells/SimpleActionCell";
import SimpleHeaderCell from "../../../components/RsuiteWrapper/SimpleTable/SimpleHeaderCell";
import InterTag from "../../../components/Text/Inter";
import useHotelDispatch from "../../../context/Hotel/hooks/useHotelDispatch";
import useHotelState from "../../../context/Hotel/hooks/useHotelState";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import useDeleteRequest from "../../../hooks/apiRequests/useDeleteRequest";
import useClicker from "../../../hooks/useClicker";
import {
  tHotelSpace,
  tHotelSpaceAggregate,
  tHotelSpaceAggregateId,
} from "../../../models/hotel";
import { apiAddressV2 } from "../../../utils/apiCall";
import { COLORS } from "../../../utils/colors";
import { getErrorMessage } from "../../../utils/httpResponses/others";
import { notification } from "../../../utils/notifications";
import { TABLE_HEADER_HEIGHT, TABLE_ROW_HEIGHT } from "../../../utils/tables";
import NoSpaces from "./noSpaces";
import SpaceAggregateModal from "./spaceAggregateModal";

type tDataItem = {
  id: string;
  name: string;
} & ({ type: "agg"; children: tDataItem[] } | { type: "space" });

const TABLE_ID = "TABLE.SPACES.AGG";

interface iProps {
  outletContainerWidth: number;
  category: tHotelSpaceAggregate["category"];
}

const SpaceAggregateTab: React.FC<iProps> = ({
  outletContainerWidth,
  category,
}) => {
  const toaster = useToaster();
  const navigate = useNavigate();
  const { trans } = useLocalizationState();
  const [clicked, click] = useClicker();
  const {
    hotelId,
    hotel: { spaceAggregates },
    inFinalState,
    hotelIsLoaded,
    findSpace,
  } = useHotelState();
  const { updateHotel, updatingHotel, resolveHotel } = useHotelDispatch();

  const filteredSpaceAggregates = spaceAggregates.filter(
    (s) => s.category === category
  );

  const [label, IconElement] = (() => {
    switch (category) {
      case "group":
        return ["Group", WorkSpacesIcon];
      case "zone":
        return ["Zone", StacksIcon];
      case "type":
        return ["Type", CategoryIcon];
    }
  })();

  const [aggModal, setAggModal] = useState<
    | {
        open: false;
        aggId: null;
        aggName: null;
      }
    | {
        open: true;
        aggId: tHotelSpaceAggregateId;
        aggName: string;
      }
  >({ open: false, aggId: null, aggName: null });
  const deleteRequest = useDeleteRequest();

  const options = (dataKey: string, dataItem: tDataItem) => {
    return [
      {
        key: "configure",
        icon: { Element: SettingsIcon, fill: COLORS.secondary },
        label: { text: trans("Configure") },
        onClick() {
          setAggModal({
            open: true,
            aggId: dataItem.id,
            aggName: dataItem.name,
          });
        },
      },
      {
        key: "view",
        icon: { Element: VisibilityIcon, fill: COLORS.secondary },
        label: { text: trans(`View ${label}`) },
        onClick() {
          navigate(`/space-aggregates/${dataItem.id}`);
        },
      },
      {
        key: "delete",
        icon: { Element: DeleteIcon, fill: COLORS.error },
        label: { text: trans("Delete"), color: COLORS.error },
        onClick() {
          updatingHotel();
          deleteRequest.pending();
          axios
            .delete(
              `${apiAddressV2(false)}/v2/hotels/${hotelId}/space-aggregates/${
                dataItem.id
              }`
            )
            .then((res) => {
              const {
                data: { hotel },
              } = res;
              updateHotel(hotelId, hotel);
              toaster.push(
                notification("success", `${trans("Space removed")}`),
                { placement: "topEnd" }
              );
              deleteRequest.resolve();
            })
            .catch((err) => {
              const error = getErrorMessage(err, trans);
              toaster.push(notification("error", error), {
                placement: "topEnd",
              });
              resolveHotel();
              deleteRequest.reject(error);
            });
        },
      },
    ];
  };

  const data: tDataItem[] = useMemo(() => {
    return filteredSpaceAggregates.map((agg) => {
      const { name, _id } = agg;
      return {
        id: _id,
        name,
        type: "agg",
        children: (
          agg.spaces
            .map((spaceId) => {
              return findSpace(spaceId);
            })
            .filter((s) => s !== undefined) as tHotelSpace[]
        ).map((space) => {
          const { name } = space;
          return { id: `${agg._id}:${space._id}`, name, type: "space" };
        }),
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [findSpace, hotelIsLoaded]);

  const [tableHeight, setTableHeight] = useState<number>(
    !hotelIsLoaded
      ? TABLE_ROW_HEIGHT.M + TABLE_HEADER_HEIGHT
      : (min([5, data.length]) as number) * TABLE_ROW_HEIGHT.M +
          TABLE_HEADER_HEIGHT
  );

  useEffect(() => {
    const table = document.getElementsByClassName(
      "rs-table-body-wheel-area"
    )[0];
    setTimeout(() => {
      const height = !hotelIsLoaded
        ? TABLE_ROW_HEIGHT.M + TABLE_HEADER_HEIGHT
        : (min([5, table.children.length]) as number) * TABLE_ROW_HEIGHT.M +
          TABLE_HEADER_HEIGHT;
      if (height !== undefined) setTableHeight(height);
    }, 50);
  }, [data, hotelIsLoaded, clicked]);

  const aggWidth = floor(0.25 * outletContainerWidth);
  const spaceWidth = floor(0.65 * outletContainerWidth);

  return (
    <Fragment>
      {aggModal.open && (
        <SpaceAggregateModal
          onClose={() =>
            setAggModal({ open: false, aggId: null, aggName: null })
          }
          {...{ ...aggModal, category }}
        />
      )}
      <div className="table-wrapper">
        <Table
          rowKey="id"
          isTree
          id={TABLE_ID}
          height={tableHeight}
          onClick={() => {
            click();
          }}
          data={data}
          rowHeight={TABLE_ROW_HEIGHT.M}
          headerHeight={TABLE_HEADER_HEIGHT}
          loading={!inFinalState || deleteRequest.isLoading}
          renderEmpty={() => null}
        >
          <Table.Column width={aggWidth}>
            <SimpleHeaderCell icon={IconElement} name={trans(label)} />
            <BaseCell dataKey="agg">
              {(rowData: tDataItem) => {
                const { name, type } = rowData;

                if (type === "space") return null;

                return (
                  <InterTag
                    onClick={() => {
                      navigate(`/space-aggregates/${rowData.id}`);
                    }}
                    hoverUnderline
                    asSpan
                    size={12}
                    color={COLORS.secondary}
                    text={name}
                  />
                );
              }}
            </BaseCell>
          </Table.Column>
          <Table.Column width={spaceWidth}>
            <SimpleHeaderCell icon={MeetingRoomIcon} name={trans("Space")} />
            <BaseCell dataKey="name">
              {(rowData: tDataItem) => {
                const { name, type } = rowData;

                if (type === "agg")
                  return (
                    <InterTag
                      asSpan
                      size={12}
                      color={COLORS.secondary}
                      text={`${rowData.children.length} ${
                        rowData.children.length === 1
                          ? trans("space")
                          : trans("spaces")
                      }`}
                    />
                  );

                return (
                  <InterTag
                    asSpan
                    size={12}
                    color={COLORS.secondary}
                    text={name}
                  />
                );
              }}
            </BaseCell>
          </Table.Column>
          <Table.Column flexGrow={1} align="right">
            <SimpleHeaderCell name={{ text: "" }} />
            <SimpleActionCell
              propsFunction={(dataKey: string, rowData: tDataItem) => {
                if (rowData.type === "space")
                  return { style: { visibility: "hidden" } };
                return {};
              }}
              options={options}
              dataKey="_id"
            />
          </Table.Column>
        </Table>
        {data.length === 0 && <NoSpaces category={category} />}
      </div>
    </Fragment>
  );
};

export default SpaceAggregateTab;
