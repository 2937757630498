import React from "react";
import Flex from "../../../../components/Flex";
import Graph from "./graph";
import List from "./list";
import PageSectionTitle from "../../../../components/PageSectionTitle";
import useLocalizationState from "../../../../context/Localization/hooks/useLocalizationState";
import { ReactComponent as DirectionsCarIcon } from "../../../../assets/icons/directions_car.svg";
import { ReactComponent as QueryStatsIcon } from "../../../../assets/icons/query_stats.svg";

interface iProps {}

const Vehicles: React.FC<iProps> = () => {
  const { trans } = useLocalizationState();
  return (
    <Flex column gap={40}>
      <Flex column gap={16}>
        <PageSectionTitle
          title={trans("Fleet Overview")}
          description={trans(
            "A snapshot of your current and historical fleet usage patterns."
          )}
          icon={QueryStatsIcon}
        />
        <Graph />
      </Flex>
      <Flex column gap={16}>
        <PageSectionTitle
          title={trans("Fleet Data")}
          description={trans(
            "Comprehensive data showcasing detailed fleet usage accross your property."
          )}
          icon={DirectionsCarIcon}
        />
        <List />
      </Flex>
    </Flex>
  );
};

export default Vehicles;
