import axios from "axios";
import { min } from "lodash";
import React, { useState } from "react";
import { Table, useToaster } from "rsuite";
import { ReactComponent as AssignmentIcon } from "../../../../assets/icons/assignment.svg";
import { ReactComponent as DeleteIcon } from "../../../../assets/icons/delete.svg";
import { ReactComponent as GroupIcon } from "../../../../assets/icons/group.svg";
import SimpleActionCell from "../../../../components/RsuiteWrapper/SimpleTable/SimpleCells/SimpleActionCell";
import SimpleTextCell from "../../../../components/RsuiteWrapper/SimpleTable/SimpleCells/SimpleTextCell";
import SimpleHeaderCell from "../../../../components/RsuiteWrapper/SimpleTable/SimpleHeaderCell";
import { tSimpleWhisperPopoverDropdownOptions } from "../../../../components/RsuiteWrapper/SimpleWhisperPopoverDropdown";
import useHotelDispatch from "../../../../context/Hotel/hooks/useHotelDispatch";
import useHotelState from "../../../../context/Hotel/hooks/useHotelState";
import useLocalizationState from "../../../../context/Localization/hooks/useLocalizationState";
import useDeleteRequest from "../../../../hooks/apiRequests/useDeleteRequest";
import useListStaff from "../../../../hooks/useListStaff";

import { tHotelStaffRole, tHotelStaffRoleId } from "../../../../models/hotel";
import { apiAddressV2 } from "../../../../utils/apiCall";
import { COLORS } from "../../../../utils/colors";
import { getErrorMessage } from "../../../../utils/httpResponses/others";
import { notification } from "../../../../utils/notifications";
import {
  TABLE_HEADER_HEIGHT,
  TABLE_ROW_HEIGHT,
} from "../../../../utils/tables";
import CreateEditRole from "./createEditRole";
import useProfilePermissions from "../../../../hooks/useProfilePermissions";

type tTableItem = tHotelStaffRole;

interface iProps {
  rootOutletContainerHeight: number;
  staffRequest: ReturnType<typeof useListStaff>;
}

const RolesTab: React.FC<iProps> = ({
  rootOutletContainerHeight,
  staffRequest,
}) => {
  const toaster = useToaster();
  const { trans } = useLocalizationState();
  const {
    hotel: { staffRoles },
    hotelId,
    hotelLoading,
  } = useHotelState();
  const { updateHotel } = useHotelDispatch();
  const [roleModal, setRoleModal] = useState<{
    open: boolean;
    roleId?: tHotelStaffRoleId;
  }>({ open: false, roleId: undefined });
  const deleteRequest = useDeleteRequest();
  const profilePermissions = useProfilePermissions();

  const tableData: tTableItem[] = staffRoles;

  const removeStaffRole = (roleId: tHotelStaffRoleId) => {
    deleteRequest.pending();
    axios
      .delete(
        `${apiAddressV2(false)}/v2/hotels/${hotelId}/staff-roles/${roleId}`
      )
      .then((res) => {
        const {
          data: { hotel },
        } = res;
        updateHotel(hotelId, hotel);
        deleteRequest.resolve();
        toaster.push(notification("success", trans("Role Removed")), {
          placement: "topEnd",
        });
      })
      .catch((err) => {
        const error = getErrorMessage(err, trans);
        deleteRequest.reject(error);
        toaster.push(notification("error", error), { placement: "topEnd" });
      });
  };

  const options = (
    dataKey: string,
    item: tTableItem
  ): tSimpleWhisperPopoverDropdownOptions[] => {
    const options: tSimpleWhisperPopoverDropdownOptions[] = [];

    if (profilePermissions.writeRoles)
      options.push({
        key: "edit",
        label: trans("Edit Role"),
        icon: AssignmentIcon,
        onClick() {
          setRoleModal({
            open: true,
            roleId: item._id,
          });
        },
      });

    if (!item.isAdmin && profilePermissions.writeRoles)
      options.push({
        key: "remove",
        label: { color: COLORS.error, text: trans("Remove Role") },
        icon: { Element: DeleteIcon, fill: COLORS.error },
        onClick() {
          removeStaffRole(item._id);
        },
      });
    return options;
  };

  const tableHeight =
    hotelLoading || tableData.length === 0
      ? TABLE_ROW_HEIGHT.M * TABLE_HEADER_HEIGHT
      : min([
          tableData.length * TABLE_ROW_HEIGHT.M + TABLE_HEADER_HEIGHT,
          rootOutletContainerHeight - 350,
        ]);

  return (
    <>
      <CreateEditRole
        open={roleModal.open}
        roleId={roleModal.roleId}
        onClose={() => {
          setRoleModal({ open: false });
        }}
        staffRequest={staffRequest}
      />
      <div className="table-wrapper">
        <Table
          height={tableHeight}
          id="STAFF.TEAMS.TABLE"
          headerHeight={TABLE_HEADER_HEIGHT}
          rowHeight={TABLE_ROW_HEIGHT.M}
          data={tableData}
          loading={hotelLoading || deleteRequest.isLoading}
        >
          <Table.Column flexGrow={1}>
            <SimpleHeaderCell icon={AssignmentIcon} name={trans("Roles")} />
            <SimpleTextCell dataKey="name" />
          </Table.Column>
          <Table.Column flexGrow={1}>
            <SimpleHeaderCell icon={GroupIcon} name={trans("Users")} />
            <SimpleTextCell
              dataKey="users"
              textFunction={(item: tTableItem) => item.staff.length}
            />
          </Table.Column>
          <Table.Column flexGrow={1} align="right">
            <SimpleHeaderCell name={{ text: "" }} />
            <SimpleActionCell options={options} dataKey="_id" />
          </Table.Column>
        </Table>
      </div>
    </>
  );
};

export default RolesTab;
