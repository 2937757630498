import axios from "axios";
import moment from "moment";
import React, { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, SelectPicker, useToaster } from "rsuite";
import Flex from "../../../../components/Flex";
import InterTag from "../../../../components/Text/Inter";
import useHotelDispatch from "../../../../context/Hotel/hooks/useHotelDispatch";
import useHotelState from "../../../../context/Hotel/hooks/useHotelState";
import useLocalizationState from "../../../../context/Localization/hooks/useLocalizationState";
import usePutRequest from "../../../../hooks/apiRequests/usePutRequest";
import {
  tHotelBilling,
  tHotelSubscriptionModel,
} from "../../../../models/hotel";
import { apiAddressV2 } from "../../../../utils/apiCall";
import { COLORS } from "../../../../utils/colors";
import { BILLING_TYPES } from "../../../../utils/hotels";
import { getErrorMessage } from "../../../../utils/httpResponses/others";
import { notification } from "../../../../utils/notifications";
import styles from "../styles.module.css";
import EditBillingDetailsModal from "./editBillingDetailsModal";
import PaymentMethods from "./paymentMethods";

interface iProps {}

const BillingSettings: React.FC<iProps> = () => {
  const navigate = useNavigate();
  const toaster = useToaster();
  const { trans } = useLocalizationState();
  const {
    hotelLoading,
    hotelId,
    activeSubscriptionType,
    activeSubscription,
    activeBillingInfo,
    hotel,
  } = useHotelState();
  const { updateHotel } = useHotelDispatch();
  const [modalBillingDetails, setModalBillingDetails] = useState(false);

  const request = usePutRequest();

  const updateSubscriptionModel = (type: tHotelSubscriptionModel["type"]) => {
    if (request.isLoading) return;
    request.pending();
    axios
      .put(`${apiAddressV2(false)}/v2/hotels/${hotelId}/subscription-model`, {
        type,
      })
      .then((res) => {
        const {
          data: { hotel },
        } = res;
        updateHotel(hotelId, hotel);
        request.resolve();
      })
      .catch((err) => {
        const error = getErrorMessage(err, trans);
        request.reject(error);
        toaster.push(notification("error", error), { placement: "topEnd" });
      });
  };

  const translateBillingType: Record<tHotelSubscriptionModel["type"], string> =
    {
      business: trans("Business"),
      enterprise: trans("Enterprise"),
      starter: trans("Starter"),
    };

  const renderBillingInfo = () => {
    const entries: {
      label: string;
      key: keyof tHotelBilling;
    }[] = [
      { label: trans("Name"), key: "name" },
      { label: trans("E-mail"), key: "email" },
      { label: trans("Address"), key: "address" },
      { label: trans("Phone"), key: "phone" },
      { label: trans("Tax ID"), key: "taxId" },
    ];
    return (
      <Fragment>
        <EditBillingDetailsModal
          open={modalBillingDetails}
          onClose={() => setModalBillingDetails(false)}
        />
        <Flex
          id="settings-billing-details"
          row
          between
          middle
          className={[
            styles["settings-item"],
            styles["settings-item--not-last"],
          ].join(" ")}
        >
          <InterTag
            size={14}
            bold
            text={trans("Billing Details")}
            color={COLORS.secondary}
          />

          <Button
            onClick={() => setModalBillingDetails(true)}
            appearance="subtle"
            className="inter"
            style={{ color: COLORS.primary }}
          >
            {trans("Edit")}
          </Button>
        </Flex>
        {entries.map(({ key, label }) => {
          return (
            <Flex
              key={key}
              row
              between
              middle
              className={[
                styles["settings-item"],
                styles["settings-item-text"],
                styles["settings-item--not-last"],
              ].join(" ")}
            >
              <InterTag size={14} color={COLORS.secondary} text={label} />
              <InterTag
                size={14}
                color={COLORS.secondary}
                text={
                  activeBillingInfo && activeBillingInfo[key]
                    ? activeBillingInfo[key]
                    : "-"
                }
              />
            </Flex>
          );
        })}
      </Fragment>
    );
  };

  const renderCurentPlans = () => {
    return (
      <Fragment>
        <Flex
          id="settings-billing-plan"
          row
          between
          middle
          className={[
            styles["settings-item"],
            styles["settings-item--not-last"],
          ].join(" ")}
        >
          <InterTag
            size={14}
            bold
            text={trans("Current Plan & Extras")}
            color={COLORS.secondary}
          />

          <Button
            appearance="subtle"
            className="inter"
            style={{ color: COLORS.primary }}
            onClick={() => navigate("/settings/billing/plan")}
          >
            {trans("Manage")}
          </Button>
        </Flex>
        {activeSubscription && (
          <Flex
            row
            between
            middle
            className={[
              styles["settings-item"],
              styles["settings-item-text"],
              styles["settings-item--not-last"],
            ].join(" ")}
          >
            <InterTag
              text={translateBillingType[activeSubscription.type]}
              size={14}
              color={COLORS.secondary}
            />
            <Flex column gap={4} right>
              <InterTag
                text={
                  activeSubscription.endAt
                    ? trans("Renews on $0", {
                        parameters: [
                          moment(activeSubscription.endAt).format(
                            "MMMM DD, YYYY"
                          ),
                        ],
                      })
                    : "-"
                }
                size={14}
                color={COLORS.secondary}
              />
            </Flex>
          </Flex>
        )}
      </Fragment>
    );
  };

  const renderPaymentMethods = () => {
    return <PaymentMethods />;
  };

  const renderInvoices = () => {
    return (
      <Fragment>
        <Flex
          id="settings-billing-invoices"
          row
          between
          middle
          className={[
            styles["settings-item"],
            styles["settings-item--not-last"],
          ].join(" ")}
        >
          <InterTag
            size={14}
            bold
            text={trans("Invoices")}
            color={COLORS.secondary}
          />

          <Button
            appearance="subtle"
            className="inter"
            style={{ color: COLORS.primary }}
            onClick={() => navigate("/settings/billing/invoices")}
          >
            {trans("See all")}
          </Button>
        </Flex>
      </Fragment>
    );
  };
  const renderQuickUpdateSubscriptionModel = () => {
    if (hotel.name === "noytrall Hotel")
      return (
        <Flex
          row
          between
          middle
          className={[styles["settings-item"]].join(" ")}
        >
          <InterTag
            size={14}
            bold
            text={trans("Subscription model")}
            color={COLORS.secondary}
          />
          <SelectPicker
            menuAutoWidth
            placement="left"
            searchable={false}
            loading={hotelLoading || request.isLoading}
            data={BILLING_TYPES.map((t) => ({
              label: translateBillingType[t],
              value: t,
            }))}
            value={activeSubscriptionType}
            onChange={(t) => {
              if (
                t &&
                BILLING_TYPES.includes(t) &&
                t !== activeSubscriptionType
              ) {
                updateSubscriptionModel(t);
              }
            }}
            cleanable={false}
            disabled={request.isLoading || hotelLoading}
          />
        </Flex>
      );
    return null;
  };

  return (
    <div>
      {renderBillingInfo()}
      {renderCurentPlans()}
      {renderPaymentMethods()}
      {renderInvoices()}
      {renderQuickUpdateSubscriptionModel()}
    </div>
  );
};

export default BillingSettings;
