export const sleep = async (ms: number) =>
  new Promise((resolve) => {
    setTimeout(() => {
      resolve(false);
    }, ms);
  });

export const copyToClipboard = (text: string) => {
  navigator.clipboard.writeText(text);
};

export const NOYTRALL_0 = "∅";
