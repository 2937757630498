import axios from "axios";
import useLocalizationState from "../context/Localization/hooks/useLocalizationState";
import { apiAddressV2, REQUEST_STATUS } from "../utils/apiCall";
import { getErrorMessage } from "../utils/httpResponses/others";
import useGetRequest from "./apiRequests/useGetRequest";
import useEffectSafe from "./useEffectSafe";

export type tBenchmarkData = Record<
  "perGuest" | "perAccommodation",
  {
    co2_kg: number;
    electricity_kWh: number;
    water_m3: number;
    fuels_kWh: number;
    waste_kg: number;
  }
> & { perM2: { electricity_kWh: number } };

export const useGetBenchmarkData = (
  { doRequest = true }: { doRequest?: boolean } = { doRequest: true }
) => {
  const { trans } = useLocalizationState();
  const benchmarkRequest = useGetRequest<tBenchmarkData>(
    {
      perGuest: {
        co2_kg: 0,
        electricity_kWh: 0,
        water_m3: 0,
        fuels_kWh: 0,
        waste_kg: 0,
      },
      perAccommodation: {
        co2_kg: 0,
        electricity_kWh: 0,
        water_m3: 0,
        fuels_kWh: 0,
        waste_kg: 0,
      },
      perM2: { electricity_kWh: 0 },
    },
    { ...(doRequest ? {} : { status: REQUEST_STATUS.IDLE }) }
  );

  useEffectSafe(() => {
    if (doRequest) {
      benchmarkRequest.pending();
      axios
        .get(`${apiAddressV2(false)}/v2/hotels/benchmark`)
        .then((res) => {
          const {
            data: { perAccommodation, perGuest, perM2 },
          } = res;
          benchmarkRequest.resolve({ perAccommodation, perGuest, perM2 });
        })
        .catch((err) => {
          benchmarkRequest.reject(getErrorMessage(err, trans));
        });
    }
  }, [trans]);

  return benchmarkRequest;
};
