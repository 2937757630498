import React from "react";
import { tReservation } from "../../../models/reservation";
import Flex from "../../../components/Flex";
import PageSectionTitle from "../../../components/PageSectionTitle";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import { ReactComponent as VerifiedIcon } from "../../../assets/icons/verified.svg";

interface iProps {
  reservation: tReservation;
}

const Awards: React.FC<iProps> = ({ reservation }) => {
  const { trans } = useLocalizationState();
  return (
    <Flex column gap={16}>
      <PageSectionTitle
        title={trans("Awards")}
        description={trans(
          "Track discounts and perks earned, redeemable, or enjoyed by the guest"
        )}
        icon={VerifiedIcon}
      />
      {reservation.awards.length}
    </Flex>
  );
};

export default Awards;
