import axios from "axios";
import { find, reverse } from "lodash";
import moment from "moment";
import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Loader, Timeline } from "rsuite";
import ReloadButton from "../../../components/Buttons/ReloadButton";
import Flex from "../../../components/Flex";
import InterTag from "../../../components/Text/Inter";
import useHotelState from "../../../context/Hotel/hooks/useHotelState";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import useGetRequest from "../../../hooks/apiRequests/useGetRequest";
import useEffectSafe from "../../../hooks/useEffectSafe";
import { tHotelId } from "../../../models/hotel";
import { tHotelActionLog } from "../../../models/hotelActionLog";
import { apiAddressV2 } from "../../../utils/apiCall";
import { COLORS } from "../../../utils/colors";
import {
  translateHotelActionsAction,
  translateHotelActionsHotelProperty,
} from "../../../utils/hotelActions";
import { getErrorMessage } from "../../../utils/httpResponses/others";
import { tStaffData } from "./users";

interface iProps {
  staff: tStaffData[];
  loading: boolean;
}

const RecentUserActivities: React.FC<iProps> = ({ staff }) => {
  const navigate = useNavigate();
  const { trans } = useLocalizationState();
  const { hotelId } = useHotelState();
  const getRequest = useGetRequest<{ actions: tHotelActionLog[] }>({
    actions: [],
  });

  const run = useCallback(
    (hotelId: tHotelId) => {
      getRequest.pending();
      axios
        .get(`${apiAddressV2(false)}/v2/hotels/${hotelId}/hotel-actions`, {
          params: { limit: 4 },
        })
        .then((res) => {
          const {
            data: { actions },
          } = res;
          getRequest.resolve({ actions });
        })
        .catch((err) => {
          const error = getErrorMessage(err, trans);
          getRequest.reject(error);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [trans]
  );

  useEffectSafe(() => {
    if (hotelId) run(hotelId);
  }, [hotelId, trans]);

  return (
    <Flex column gap={8}>
      {getRequest.isLoading ? (
        <Loader
          size="lg"
          style={{
            marginLeft: "180px",
            marginBottom: "10px",
            marginTop: "5px",
          }}
        />
      ) : (
        <Flex column>
          <div style={{ marginLeft: "178px" }}>
            <ReloadButton onClick={() => run(hotelId)} />
          </div>
          {getRequest.data.actions.length ? (
            <Timeline
              align="left"
              endless
              isItemActive={(index, totalITemsCount) => {
                return false;
              }}
              style={{ width: "fit-content" }}
            >
              {reverse(getRequest.data.actions).map(
                ({
                  _id,
                  action,
                  managerId,
                  timestamp,
                  details: { hotelProperty, hotelPropertyId },
                }) => {
                  const staffMember = find(staff, (s) => s._id === managerId);
                  return (
                    <Timeline.Item
                      key={_id}
                      time={moment(timestamp).format("MMMM DD, YYYY HH:mm")}
                      style={{ paddingBottom: "12px" }}
                    >
                      <Flex style={{ paddingTop: "3px" }} row gap={4}>
                        <InterTag
                          hoverUnderline
                          style={{ cursor: "pointer" }}
                          size={14}
                          color={COLORS.primary}
                          text={staffMember?.name}
                          onClick={() => {
                            navigate(`/users/${managerId}`);
                          }}
                        />
                        <InterTag
                          size={14}
                          color={COLORS.secondary}
                          text={translateHotelActionsAction(action, trans)}
                        />
                        <InterTag
                          hoverUnderline
                          style={{ cursor: "pointer" }}
                          size={14}
                          color={COLORS.primary}
                          text={translateHotelActionsHotelProperty(
                            hotelProperty,
                            trans
                          )}
                          onClick={() => {
                            switch (hotelProperty) {
                              case "awards":
                                return navigate(`/awards/${hotelPropertyId}`);
                              case "challenges":
                                return navigate(
                                  `/challenges/${hotelPropertyId}`
                                );
                              default:
                                break;
                            }
                          }}
                        />
                      </Flex>
                    </Timeline.Item>
                  );
                }
              )}
            </Timeline>
          ) : (
            <div>
              <InterTag
                text={trans("No recent activities")}
                size={12}
                color={COLORS.secondary}
                style={{ marginLeft: "130px", marginTop: "10px" }}
              />
            </div>
          )}
        </Flex>
      )}
    </Flex>
  );
};

export default RecentUserActivities;
