import React from "react";
import Flex from "../Flex";
import InterTag from "../Text/Inter";
import { COLORS } from "../../utils/colors";
import { Button } from "rsuite";
import { omit } from "lodash";
import useLocalizationState from "../../context/Localization/hooks/useLocalizationState";
import { useNavigate } from "react-router-dom";

interface iProps {
  image: string;
  title: string;
  description: string[];
  onClick: () => void;
  [key: string]: any;
}

const Unlock: React.FC<iProps> = ({
  image,
  title,
  description,
  onClick,
  ...props
}) => {
  const navigate = useNavigate();
  const { trans } = useLocalizationState();

  return (
    <Flex
      column
      center
      middle
      style={{
        background:
          "linear-gradient(117.16deg, rgba(64, 191, 180, 0.5) -3.01%, rgba(255, 255, 255, 0.4) 103.91%)",
        borderRadius: "8px",
        position: "relative",
        // height: "500px",
        padding: "80px 0",
        ...props.style,
      }}
      {...{ ...omit(props, "style") }}
    >
      <div
        style={{
          backgroundImage: `url(${image})`,
          backgroundRepeat: "no-repeat",
          opacity: "50%",
          filter: "blur(4px)",
          top: 0,
          //   left: 0,
          bottom: 0,
          //   right: 0,
          //   height: "500px",
          width: "864px",
          position: "absolute",
        }}
      />
      <Flex column middle gap={32} style={{ width: "80%", zIndex: 0 }}>
        <InterTag
          {...{
            size: 36,
            color: COLORS.gray_800,
            bold: true,
            text: title,
            style: { textWrap: "wrap", textAlign: "center" },
          }}
        />
        {description.map((text) => {
          return (
            <InterTag
              {...{
                size: 20,
                color: COLORS.gray_800,
                text,
                style: { textWrap: "wrap", textAlign: "center" },
              }}
            />
          );
        })}
        <Button
          style={{
            backgroundColor: COLORS.primary_900,
            color: COLORS.white,
            fontWeight: "bold",
          }}
          onClick={() => {
            navigate("/settings/billing/plan");
          }}
        >
          {trans("Upgrade Now")}
        </Button>
      </Flex>
    </Flex>
  );
};

export default Unlock;
