import { useState } from "react";
import { nRequestStatus, tGetRequest } from "../../interfaces/apiCalls";
import {
  REQUEST_STATUS,
  setGetRequestStateResolved,
  setRequestStatePending,
  setRequestStateRejected,
} from "../../utils/apiCall";

export type tUseGetRequest<T> = {
  data: T;
  error: string | null;
  status: nRequestStatus.tStatus;
  reject(error?: string | null): void;
  resolve(data?: Partial<T>): void;
  pending(): void;
  isIdle: boolean;
  isLoading: boolean;
  isResolved: boolean;
  isRejected: boolean;
  isFinal: boolean;
};

function useGetRequest<T>(
  initialData: T,
  {
    status = REQUEST_STATUS.PENDING,
    error = null,
  }: { status?: nRequestStatus.tStatus; error?: string | null } = {
    status: REQUEST_STATUS.PENDING,
    error: null,
  }
): tUseGetRequest<T> {
  const [state, setState] = useState<tGetRequest<T>>({
    data: initialData,
    status,
    error,
  });

  const reject = (error?: string | null) =>
    setRequestStateRejected(setState, error);
  const resolve = (data: Partial<T> = {}) =>
    setGetRequestStateResolved(setState, { ...state.data, ...data });
  const pending = () => setRequestStatePending(setState);

  const isIdle = state.status === REQUEST_STATUS.IDLE;
  const isLoading = state.status === REQUEST_STATUS.PENDING;
  const isResolved = state.status === REQUEST_STATUS.RESOLVED;
  const isRejected = state.status === REQUEST_STATUS.REJECTED;
  const isFinal = isRejected || isResolved;

  return {
    data: state.data,
    error: state.error,
    status: state.status,
    reject,
    resolve,
    pending,
    isIdle,
    isLoading,
    isResolved,
    isRejected,
    isFinal,
  };
}

export default useGetRequest;
