import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import { cloneDeep } from "lodash";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Loader, Modal, ModalProps } from "rsuite";
import { ReactComponent as PaymentsIcon } from "../../../assets/icons/payments.svg";
import Flex from "../../../components/Flex";
import PageSectionTitle from "../../../components/PageSectionTitle";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import useGetRequest from "../../../hooks/apiRequests/useGetRequest";
import usePostRequest from "../../../hooks/apiRequests/usePostRequest";
import useEffectSafe from "../../../hooks/useEffectSafe";
import tHotel from "../../../models/hotel";
import { apiAddressV2 } from "../../../utils/apiCall";
import { defaultHotel } from "../../../utils/hotels";
import { DEFAULT_ERROR_MESSAGE } from "../../../utils/httpResponses/consts";
import { getErrorMessage } from "../../../utils/httpResponses/others";
import { DEFAULT_MODAL_PROPS } from "../../../utils/rsuite/modals";
import { STRIPE_ENV, STRIPE_PK } from "../../../utils/stripe";

const stripePromise = loadStripe(STRIPE_PK);

const SetupForm: React.FC<{ onClose(action?: "create"): void }> = ({
  onClose,
}) => {
  const postRequest = usePostRequest();
  const stripe = useStripe();
  const elements = useElements();

  const [errorMessage, setErrorMessage] = useState<string | undefined | null>(
    null
  );

  const handleSubmit = async (event: any) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return null;
    }
    postRequest.pending();

    const result = await stripe.confirmSetup({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/settings`,
      },
      redirect: "if_required",
    });

    if (result.error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      setErrorMessage(result.error.message);
      postRequest.reject(result.error.message || DEFAULT_ERROR_MESSAGE);
    } else {
      onClose("create");
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Flex column left gap={20}>
        <PaymentElement />
        <div>
          <Button
            loading={postRequest.isLoading}
            appearance="primary"
            type="submit"
            disabled={!stripe}
          >
            Submit
          </Button>
        </div>
        {/* Show error message to your customers */}
        {errorMessage && <div>{errorMessage}</div>}
      </Flex>
    </form>
  );
};

interface iAddPaymentMethodModalWrappedProps extends Pick<iProps, "onClose"> {}

const AddPaymentMethodModalWrapped: React.FC<
  iAddPaymentMethodModalWrappedProps
> = ({ onClose }) => {
  const navigate = useNavigate();
  const { trans } = useLocalizationState();
  const sessionState = useGetRequest<{ hotel: tHotel; token: string }>({
    hotel: cloneDeep(defaultHotel),
    token: "",
  });
  const clientSecretRequest = useGetRequest<{
    clientSecret: string;
    done: boolean;
  }>({
    clientSecret: "",
    done: false,
  });

  useEffect(() => {
    if (!sessionState.isFinal) {
      const token = sessionStorage.getItem("plan-token");
      const hotel = sessionStorage.getItem("plan-hotel");
      if (token && hotel) {
        axios.defaults.headers.common["token"] = token;
        sessionState.resolve({ hotel: JSON.parse(hotel), token });
      } else {
        sessionStorage.clear();
        localStorage.clear();
        navigate("/login");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate]);

  useEffectSafe(() => {
    if (
      sessionState.isResolved &&
      sessionState.data.hotel._id.length &&
      !clientSecretRequest.data.done
    ) {
      clientSecretRequest.pending();
      axios
        .get(
          `${apiAddressV2(false)}/v2/hotels/${
            sessionState.data.hotel._id
          }/stripe/payment-methods/setup-future-payments/client-secret`,
          { params: { stripeEnv: STRIPE_ENV } }
        )
        .then((res) => {
          const {
            data: { clientSecret },
          } = res;
          clientSecretRequest.resolve({ clientSecret, done: true });
        })
        .catch((err) => {
          const error = getErrorMessage(err, trans);
          clientSecretRequest.reject(error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionState.data.hotel._id, sessionState.isResolved, trans]);

  if (sessionState.isLoading || clientSecretRequest.isLoading)
    return (
      <Flex center middle>
        <Loader size="lg" />
      </Flex>
    );

  const options = {
    // passing the SetupIntent's client secret
    clientSecret: clientSecretRequest.data.clientSecret,
    // Fully customizable with appearance API.
    appearance: {
      /*...*/
    },
  };

  return (
    <Elements stripe={stripePromise} options={options}>
      <Flex center>
        <div style={{ width: "90%" }}>
          <SetupForm {...{ onClose }} />
        </div>
      </Flex>
    </Elements>
  );
};

interface iProps extends Omit<ModalProps, "onClose"> {
  onClose(action?: "create"): void;
}

const AddPaymentMethodModal: React.FC<iProps> = ({ onClose, ...props }) => {
  const { trans } = useLocalizationState();
  return (
    <Modal
      {...{
        ...DEFAULT_MODAL_PROPS,
        onClose: () => onClose(),
        size: "md",
        ...props,
      }}
    >
      {props.open && (
        <>
          <Modal.Header></Modal.Header>
          <Modal.Body>
            <Flex column gap={20}>
              <PageSectionTitle
                title={trans("Add Payment Method")}
                icon={PaymentsIcon}
              />
              <AddPaymentMethodModalWrapped {...{ onClose }} />
            </Flex>
          </Modal.Body>
        </>
      )}
    </Modal>
  );
};

export default AddPaymentMethodModal;
