import React from "react";
import { Badge, BadgeProps, Drawer, DrawerProps, IconButton } from "rsuite";
import { ReactComponent as AvgPaceIcon } from "../../assets/icons/avg_pace.svg";
import { ReactComponent as CategoryIcon } from "../../assets/icons/category.svg";
import { ReactComponent as EditCalendarIcon } from "../../assets/icons/edit_calendar.svg";
import { ReactComponent as ArrowDownIcon } from "../../assets/icons/keyboard_arrow_down.svg";
import { ReactComponent as ArrowUpIcon } from "../../assets/icons/keyboard_arrow_up.svg";
import { ReactComponent as DoubleArrowRightIcon } from "../../assets/icons/keyboard_double_arrow_right.svg";
import { ReactComponent as NumbersIcon } from "../../assets/icons/numbers.svg";
import { ReactComponent as SpeedIcon } from "../../assets/icons/speed.svg";
import { ReactComponent as TargetIcon } from "../../assets/icons/target.svg";
import useLocalizationState from "../../context/Localization/hooks/useLocalizationState";
import { tHotelRoadmap } from "../../models/hotel";
import { COLORS } from "../../utils/colors";
import Flex from "../Flex";
import Icon from "../Icon";
import InterTag from "../Text/Inter";
import {
  difficultyTranslation,
  statusTranslation,
  typeTranslation,
} from "./utils";

interface iRoadmapDetailsDrawerWrappedProps
  extends Pick<DrawerProps, "onClose">,
    Pick<
      iProps,
      "onNext" | "onPrevious" | "disabledOnNext" | "disabledOnPrevious"
    > {
  item: tHotelRoadmap;
}

const RoadmapDetailsDrawerWrapped: React.FC<
  iRoadmapDetailsDrawerWrappedProps
> = ({
  item,
  onClose,
  onNext,
  onPrevious,
  disabledOnNext,
  disabledOnPrevious,
}) => {
  const { trans } = useLocalizationState();
  const { name, type, difficulty, status, order, phase } = item;
  const typeTranslated = typeTranslation(type);
  const difficultyTranslated = difficultyTranslation(difficulty);
  const statusTranslated = statusTranslation(status);

  const height = 30;

  return (
    <>
      <Drawer.Body>
        <Flex style={{ margin: "-15px -40px" }} row middle gap={8}>
          <IconButton onClick={onClose} size="xs" style={{ padding: 0 }}>
            <Icon
              Element={DoubleArrowRightIcon}
              size={24}
              fill={COLORS.secondary}
            />
          </IconButton>
          <div
            style={{
              height: "16px",
              width: "1px",
              backgroundColor: COLORS.gray_400,
            }}
          />
          <IconButton
            disabled={disabledOnPrevious}
            onClick={onPrevious}
            size="xs"
            style={{ padding: 0 }}
          >
            <Icon
              Element={ArrowUpIcon}
              size={24}
              fill={disabledOnPrevious ? COLORS.gray_600 : COLORS.secondary}
            />
          </IconButton>
          <IconButton
            disabled={disabledOnNext}
            onClick={onNext}
            size="xs"
            style={{ padding: 0 }}
          >
            <Icon
              Element={ArrowDownIcon}
              size={24}
              fill={disabledOnNext ? COLORS.gray_600 : COLORS.secondary}
            />
          </IconButton>
        </Flex>
        <Flex style={{ marginTop: "52px" }} column gap={32}>
          <InterTag
            text={name}
            size={24}
            color={COLORS.secondary}
            style={{ textWrap: "wrap", lineHeight: "30px" }}
            bold
          />
          <Flex row gap={30}>
            <Flex column gap={8}>
              <Flex style={{ height: `${height}px` }} row gap={8} middle>
                <Icon
                  Element={CategoryIcon}
                  size={24}
                  fill={COLORS.secondary}
                />
                <InterTag
                  text={trans("Type")}
                  size={14}
                  fontWeight={700}
                  color={COLORS.secondary}
                />
              </Flex>
              <Flex style={{ height: `${height}px` }} row gap={8} middle>
                <Icon Element={NumbersIcon} size={24} fill={COLORS.secondary} />
                <InterTag
                  text={trans("Order")}
                  size={14}
                  fontWeight={700}
                  color={COLORS.secondary}
                />
              </Flex>
              <Flex style={{ height: `${height}px` }} row gap={8} middle>
                <Icon Element={TargetIcon} size={24} fill={COLORS.secondary} />
                <InterTag
                  text={trans("Phase")}
                  size={14}
                  fontWeight={700}
                  color={COLORS.secondary}
                />
              </Flex>
              <Flex style={{ height: `${height}px` }} row gap={8} middle>
                <Icon Element={SpeedIcon} size={24} fill={COLORS.secondary} />
                <InterTag
                  text={trans("Difficulty")}
                  size={14}
                  fontWeight={700}
                  color={COLORS.secondary}
                />
              </Flex>
              <Flex style={{ height: `${height}px` }} row gap={8} middle>
                <Icon Element={AvgPaceIcon} size={24} fill={COLORS.secondary} />
                <InterTag
                  text={trans("Status")}
                  size={14}
                  fontWeight={700}
                  color={COLORS.secondary}
                />
              </Flex>
              <Flex style={{ height: `${height}px` }} row gap={8} middle>
                <Icon
                  Element={EditCalendarIcon}
                  size={24}
                  fill={COLORS.secondary}
                />
                <InterTag
                  text={trans("Last Edited")}
                  size={14}
                  fontWeight={700}
                  color={COLORS.secondary}
                />
              </Flex>
            </Flex>
            <Flex column gap={8}>
              <Flex
                style={{
                  height: `${height}px`,
                  paddingLeft: "12px",
                  paddingRight: "12px",
                  borderRadius: "6px",
                  width: "fit-content",
                }}
                middle
                row
                gap={4}
                color={typeTranslated[0]}
              >
                {typeTranslated[2] && (
                  <Icon
                    {...{ size: 16, fill: COLORS.white, ...typeTranslated[2] }}
                  />
                )}
                <InterTag
                  size={14}
                  color={COLORS.white}
                  text={`${typeTranslated[1]}`}
                />
              </Flex>
              <Flex middle style={{ height: `${height}px` }}>
                <InterTag text={order} size={12} color={COLORS.secondary} />
              </Flex>
              <Flex middle style={{ height: `${height}px` }}>
                <InterTag text={phase} size={12} color={COLORS.secondary} />
              </Flex>
              <Flex middle style={{ height: `${height}px` }} row gap={8}>
                <Badge color={difficultyTranslated[0] as BadgeProps["color"]} />
                <InterTag
                  size={14}
                  color={COLORS.secondary}
                  text={`${difficultyTranslated[1]}`}
                />
              </Flex>
              <Flex
                style={{
                  borderRadius: "6px",
                  height: `${height}px`,
                  width: "fit-content",
                  paddingLeft: "12px",
                  paddingRight: "12px",
                }}
                middle
                color={statusTranslated[2]}
              >
                <InterTag
                  size={14}
                  {...{
                    text: statusTranslated[0],
                    color: statusTranslated[1],
                  }}
                />
              </Flex>
              <Flex middle style={{ height: `${height}px` }}>
                <InterTag
                  text={"Not edited"}
                  size={14}
                  color={COLORS.secondary}
                />
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Drawer.Body>
    </>
  );
};

interface iProps extends DrawerProps {
  item: tHotelRoadmap | null;
  onNext(): void;
  disabledOnNext: boolean;
  onPrevious(): void;
  disabledOnPrevious: boolean;
}

const RoadmapDetailsDrawer: React.FC<iProps> = ({
  item,
  onClose,
  onNext,
  onPrevious,
  disabledOnNext,
  disabledOnPrevious,
  ...props
}) => {
  const isOpen = item !== null;
  return (
    <Drawer
      placement="right"
      open={isOpen}
      onClose={onClose}
      size="sm"
      className="drawer-secondary-bg"
      closeButton={false}
      {...{ ...props }}
    >
      {isOpen && (
        <RoadmapDetailsDrawerWrapped
          {...{
            item,
            onClose,
            onNext,
            onPrevious,
            disabledOnNext,
            disabledOnPrevious,
          }}
        />
      )}
    </Drawer>
  );
};

export default RoadmapDetailsDrawer;
