import React, { Fragment } from "react";
import { Button, Input, InputGroup, InputNumber, useToaster } from "rsuite";
import { ReactComponent as ImgIcon } from "../../../assets/bros/Scaffold-amico 1.svg";
import { ReactComponent as CallIcon } from "../../../assets/icons/call.svg";
import { ReactComponent as HotelIcon } from "../../../assets/icons/hotel.svg";
import { ReactComponent as LanguageIcon } from "../../../assets/icons/language.svg";
import { ReactComponent as LocationIcon } from "../../../assets/icons/location_on.svg";
import { ReactComponent as MailIcon } from "../../../assets/icons/mail.svg";
import Flex from "../../../components/Flex";
import Icon from "../../../components/Icon";
import InputWrapper from "../../../components/InputWrapper";
import InterTag from "../../../components/Text/Inter";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import usePostRequest from "../../../hooks/apiRequests/usePostRequest";
import { tIcon } from "../../../interfaces/icon";
import { COLORS } from "../../../utils/colors";
import { getErrorMessage } from "../../../utils/httpResponses/others";
import { notification } from "../../../utils/notifications";
import { tRegisterState2 } from "./register";

type tDataKeys = keyof Pick<
  tRegisterState2["data"],
  | "accommodationName"
  | "accommodationType"
  | "accommodationNumberOfUnits"
  | "accommodationWebsite"
  | "accommodationAddress"
  | "accommodationEmail"
  | "accommodationPhoneNumber"
  | "accommodationPostalCode"
  | "accommodationDistrict"
  | "accommodationCounty"
>;

interface iProps {
  state: Pick<tRegisterState2["data"], tDataKeys>;
  errors: Pick<tRegisterState2["errors"], tDataKeys>;
  onChange(key: tDataKeys, value: any): any;
  setErrors(errors: Partial<Pick<tRegisterState2["errors"], tDataKeys>>): any;
  onConfirm(onError: (err: any) => void): any;
}

const Step3: React.FC<iProps> = ({
  errors,
  onChange,
  onConfirm,
  setErrors,
  state,
}) => {
  const toaster = useToaster();
  const { trans } = useLocalizationState();

  const postRequest = usePostRequest();

  const handleContinue = () => {
    postRequest.pending();
    onConfirm((err) => {
      const error = getErrorMessage(err, trans);
      toaster.push(notification("success", error), {
        placement: "topEnd",
      });
      postRequest.reject(error);
    });
  };

  const handleChange = (key: tDataKeys) => (value: any) => {
    onChange(key, value);
  };

  const inputs = {
    accommodationName: {
      key: "accommodationName",
      name: "Accommodation Name",
      placeholder: "Your accommodation name",
      icon: { Element: HotelIcon, fill: COLORS.secondary, size: 24 },
    },
    accommodationType: {
      key: "accommodationType",
      name: "Accommodation Type",
      placeholder: "Your accommodation type",
      icon: { Element: HotelIcon, fill: COLORS.secondary, size: 24 },
    },
    accommodationNumberOfUnits: {
      key: "accommodationNumberOfUnits",
      name: "Accommodation Units",
      placeholder: "Your accommodation units",
      icon: { Element: HotelIcon, fill: COLORS.secondary, size: 24 },
    },
    accommodationEmail: {
      key: "accommodationEmail",
      name: "Accommodation Email",
      placeholder: "Your accommodation email",
      icon: { Element: MailIcon, fill: COLORS.secondary, size: 24 },
    },
    accommodationPhoneNumber: {
      key: "accommodationPhoneNumber",
      name: "Accommodation Phone Number",
      placeholder: "Your accommodation phone number",
      icon: { Element: CallIcon, fill: COLORS.secondary, size: 24 },
    },
    accommodationWebsite: {
      key: "accommodationWebsite",
      name: "Accommodation Website",
      placeholder: "Your accommodation website",
      icon: { Element: LanguageIcon, fill: COLORS.secondary, size: 24 },
    },
    accommodationAddress: {
      key: "accommodationAddress",
      name: "Accommodation Address",
      placeholder: "Your accommodation address",
      icon: { Element: LocationIcon, fill: COLORS.secondary, size: 24 },
    },
    accommodationCounty: {
      key: "accommodationCounty",
      name: "Accommodation City",
      placeholder: "Your accommodation city",
      icon: { Element: LocationIcon, fill: COLORS.secondary, size: 24 },
    },
    accommodationDistrict: {
      key: "accommodationDistrict",
      name: "Accommodation District",
      placeholder: "Your accommodation district",
      icon: { Element: LocationIcon, fill: COLORS.secondary, size: 24 },
    },
    accommodationPostalCode: {
      key: "accommodationPostalCode",
      name: "Accommodation Postal Code",
      placeholder: "Your accommodation postal code",
      icon: { Element: LocationIcon, fill: COLORS.secondary, size: 24 },
    },
  } as Record<
    tDataKeys,
    { key: tDataKeys; name: string; placeholder: string; icon: tIcon }
  >;

  const renderTextInputs = (keys: tDataKeys[]) => {
    return keys.map((key) => {
      const obj = inputs[key as tDataKeys];
      const { name, placeholder, icon } = obj;

      return (
        <InputWrapper key={key} label={trans(name)}>
          <InputGroup disabled={postRequest.isLoading}>
            <InputGroup.Addon>
              <Icon {...{ ...icon }} />
            </InputGroup.Addon>
            <Input
              value={state[key]}
              onChange={handleChange(key)}
              type="text"
              placeholder={trans(placeholder)}
              size="lg"
            />
          </InputGroup>
        </InputWrapper>
      );
    });
  };

  const renderNumberInput = () => {
    return (
      <InputWrapper label={trans("Accommodation Units")}>
        <InputGroup disabled={postRequest.isLoading}>
          <InputGroup.Addon>
            <Icon Element={HotelIcon} fill={COLORS.secondary} size={24} />
          </InputGroup.Addon>
          <InputNumber
            value={state["accommodationNumberOfUnits"]}
            onChange={handleChange("accommodationNumberOfUnits")}
            type="text"
            placeholder={trans("Your accommodation units")}
            size="lg"
            min={1}
          />
        </InputGroup>
      </InputWrapper>
    );
  };

  if (postRequest.isLoading) {
    return (
      <Fragment>
        <Flex column center middle gap={8}>
          <InterTag
            size={20}
            color={COLORS.secondary}
            text={trans("Creating your account")}
            bold
          />
          <Flex column middle center gap={4}>
            <InterTag
              size={16}
              color={COLORS.secondary}
              text={trans(
                "Almost there! We're setting up your dashboard and getting"
              )}
            />
            <InterTag
              size={16}
              color={COLORS.secondary}
              text={trans("everything ready for you.")}
            />
          </Flex>
        </Flex>
        <Flex center>
          <Icon Element={ImgIcon} size={400} />
        </Flex>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <Flex column middle gap={8}>
        <InterTag
          size={20}
          color={COLORS.secondary}
          text={trans("Create your account")}
          bold
        />
        <InterTag
          size={16}
          color={COLORS.secondary}
          text={trans("Just a few details to get you on board.")}
        />
      </Flex>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleContinue();
        }}
      >
        <Flex column gap={16}>
          {renderTextInputs(["accommodationName", "accommodationType"])}
          {renderNumberInput()}
          {renderTextInputs([
            "accommodationWebsite",
            "accommodationEmail",
            "accommodationPhoneNumber",
            "accommodationAddress",
            "accommodationCounty",
            "accommodationDistrict",
            "accommodationPostalCode",
          ])}
        </Flex>
        <input type="submit" hidden />
      </form>
      <Button
        onClick={handleContinue}
        block
        appearance="primary"
        disabled={postRequest.isLoading}
        loading={postRequest.isLoading}
        style={{ fontSize: "20px" }}
      >
        <strong>{trans("Continue")}</strong>
      </Button>
    </Fragment>
  );
};

export default Step3;
