import React, { useContext, useEffect, useState } from "react";
import { Button } from "rsuite";
import Flex from "../../../../components/Flex";
import HistoricDataConsumptionGraphSection from "../../../../components/HistoricDataConsumptionGraphSection";
import ManualDataModal from "../../../../components/Modals/ManualDataModal";
import Roadmap from "../../../../components/RoadmapTable";
import useHotelState from "../../../../context/Hotel/hooks/useHotelState";
import useLocalizationState from "../../../../context/Localization/hooks/useLocalizationState";
import useGetDefaultConverionFactors from "../../../../hooks/useGetDefaultConverionFactors";
import { tProfilePermissions } from "../../../../hooks/useProfilePermissions";
import { COLORS } from "../../../../utils/colors";
import { ConsumptionContext } from "../../consumption/context";
import Showcasing from "./showcasing";

interface iProps {
  dataTypeToggle: JSX.Element | null;
  profilePermissions: tProfilePermissions;
}

const HistoricData: React.FC<iProps> = ({
  dataTypeToggle,
  profilePermissions,
}) => {
  const { trans } = useLocalizationState();
  const [manualDataModal, setManualDataModal] = useState({ open: false });
  const { setDataTypeToggleDisabled } = useContext(ConsumptionContext);
  const { measuresTrackedInManualData, hotelIsLoaded } = useHotelState();
  const conversionFactorsRequest = useGetDefaultConverionFactors("PT", {
    doRequest: measuresTrackedInManualData.electricity && hotelIsLoaded,
  });

  useEffect(() => {
    setDataTypeToggleDisabled({
      conversionFactorsRequest: conversionFactorsRequest.isLoading,
    });
  }, [conversionFactorsRequest.isLoading, setDataTypeToggleDisabled]);

  return (
    <>
      {profilePermissions.writeManualData && (
        <ManualDataModal
          open={manualDataModal.open}
          initialStep="electricity"
          singleStep
          onClose={() => setManualDataModal({ open: false })}
        />
      )}
      <div>
        <div
          style={{
            position: "fixed",
            top: 70,
            width: "100%",
            zIndex: 3,
            paddingBottom: "8px",
            backgroundColor: COLORS.app_background,
          }}
        >
          <Flex row middle gap={12} style={{ height: "36px" }}>
            {dataTypeToggle}
            {profilePermissions.writeManualData && (
              <Button
                appearance="primary"
                onClick={() => setManualDataModal({ open: true })}
              >
                {trans("Add Entry")}
              </Button>
            )}
          </Flex>
        </div>
        <Flex column gap={40} style={{ marginTop: "40px" }}>
          {hotelIsLoaded && (
            <>
              <HistoricDataConsumptionGraphSection
                {...{ conversionFactorsRequest, measure: "electricity" }}
              />
              <Showcasing />
            </>
          )}
          <Roadmap filterByType="electricity" />
        </Flex>
      </div>
    </>
  );
};

export default HistoricData;
