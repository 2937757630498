import { tHotelStaffPermissions } from "../../models/hotel";

export const STAFF_PERMISSIONS: tHotelStaffPermissions = {
  isAdmin: false,

  // Dashboard
  dashboardPage: false,

  // Readings
  readingsPage: false,

  // Challenges
  readChallenges: false,
  writeChallenges: false,

  // Awards
  readAwards: false,
  writeAwards: false,

  // Stay
  readStays: false,
  writeStays: false,
  giveAwardToStay: false,

  // Rooms
  roomsPage: false,

  // Room
  roomPage: false,

  // Space
  readSpaces: false,
  writeSpaces: false,

  // Space Aggregates
  readSpaceAggregates: false,
  writeSpaceAggregates: false,

  // Loyalty
  readLoyalty: false,
  writeLoyalty: false,
  loyaltyPage: false,
  createLoyaltyProgram: false,
  updateCreatedLoyaltyProgramStatus: false,
  updateCreatedLoyaltyProgramAwards: false,
  updateLoyaltyProgramStatus: false,
  updateLoyaltyProgramAwards: false,
  deleteLoyaltyProgram: false,
  deleteCreatedLoyaltyProgram: false,

  // Performance
  readPerformance: false,
  writePerformance: false,

  // Staff
  staffPage: false,

  // Guests
  guestPage: false,

  // Users
  readUsers: false,
  writeUsers: false,
  readUsersActivities: false,
  readUsersLastAccess: false,
  updateStaffMemberPassword: false,

  // Permissions
  permissionsPage: false,

  // Roles
  readRoles: false,
  writeRoles: false,
  readRolesPermissions: false,

  // Manual Data
  writeManualData: false,
  readManualData: false,

  canSetupHotel: false,

  canSelectSubscriptionPlan: false,
};

export const STAFF_PERMISSIONS_KEYS: (keyof tHotelStaffPermissions)[] =
  Object.keys(STAFF_PERMISSIONS) as (keyof tHotelStaffPermissions)[];

export const permissionsInfo: Partial<
  Record<keyof tHotelStaffPermissions, { label: string }>
> = {
  // awardsPage: { label: "View Awards Page" },
  // canSetupHotel: { label: "Setup Hotel" },
  // challengesPage: { label: "View Challenges Page" },
  // createAward: { label: "Create Awards" },
  // createChallenge: { label: "Create Challenges" },
  // createStaffMemberAccount: { label: "Create Staff Accounts" },
  // dashboardPage: { label: "View Dashboard Page" },
  // deleteAward: { label: "Delete Awards" },
  // deleteAwardCreated: { label: "Delete Awards Created By User" },
  // deleteChallenge: { label: "Delete Challenges" },
  // deleteChallengeCreated: { label: "Delete Challenges Created By User" },
  // deleteStaffMemberAccount: { label: "Delete Staff Account" },
  // guestPage: { label: "View Guests Page" },
  // loyaltyPage: { label: "View Loyalty Page" },
  // readingsPage: { label: "View Readings Page" },
  // reservationsPage: { label: "View Reservations Page" },
  // roomPage: { label: "View Room Page" },
  // roomsPage: { label: "View Rooms Page" },
  // spacePage: { label: "View Space Page" },
  // _spacesPage: { label: "View Spaces Page" },
  // staffPage: { label: "View Users Page" },
  // stayPage: { label: "View Reservation Page" },
  // staysPage: { label: "View Reservations Page" },
  // updateAward: { label: "Update Awards" },
  // updateAwardCreated: { label: "Update Created Awards" },
  // updateChallenge: { label: "Update Challenges" },
  // updateChallengeCreated: { label: "Update Created Challenges" },
  // updateStaffMemberPassword: { label: "Update Staff Member's Password" },
  // createLoyaltyProgram: { label: "Create Loyalty Program" },
  // createSpace: { label: "Create Space" },
  // createSpaceAggregate: { label: "Create Space Aggregate" },
  // createSpaceAggregateGroup: { label: "Create Space Group" },
  // createSpaceAggregateType: { label: "Create Space Type" },
  // createSpaceAggregateZone: { label: "Create Space Zone" },
  // createStay: { label: "Create Reservation" },
  // deleteCreatedLoyaltyProgram: {
  //   label: "Delete Loyalty Program Created By User",
  // },
  // spaceAggregateGroupsPage: { label: "View Space Groups Page" },
  // spaceAggregatesPage: { label: "View Space Aggregates Page" },
  // spaceAggregateTypesPage: { label: "View Space Types Page" },
  // spaceAggregateZonesPage: { label: "View Space Zones Page" },
  // deleteLoyaltyProgram: { label: "Delete Loyalty Program" },
  // deleteSpace: { label: "Delete Space" },
  // deleteSpaceAggregate: { label: "Delete Space Aggregate" },
  // deleteSpaceAggregateGroup: { label: "Delete Space Group" },
  // deleteSpaceAggregateType: { label: "Delete Space Type" },
  // deleteSpaceAggregateZone: { label: "Delete Space Zone" },
  // deleteStay: { label: "Delete Reservation" },
  // editManualDataEntry: { label: "Edit Manual Data Entry" },
  // editManualDataEntryInserted: {
  //   label: "Edit Manual Data Entry Inserted By User",
  // },
  // writeManualData: { label: "Insert Manual Data Entry" },
  // isAdmin: { label: "Is Admin" },
  // updateSpace: { label: "Update Space" },
  // updateSpaceAggregateZone: { label: "Update Space Zone" },
  // updateSpaceAggregateType: { label: "Update Space Type" },
  // spaceAggregateGroupPage: { label: "View Space Group Page" },
  // spaceAggregateTypePage: { label: "View Space Type Page" },
  // spaceAggregateZonePage: { label: "View Space Zone Page" },
  // updateCreatedLoyaltyProgramAwards: {
  //   label: "Update Loyalty Program Awards Created By User",
  // },
  // updateCreatedLoyaltyProgramStatus: {
  //   label: "Update Loyalty Program Status Created By User",
  // },
  // createPerformanceProgram: {
  //   label: "Create Performance Program",
  // },
  // deleteCreatedPerformanceProgram: {
  //   label: "Delete Performance Program Created By User",
  // },
  // deletePerformanceProgram: { label: "Delete Performance Program" },
  // updateCreatedPerformanceProgramAwards: {
  //   label: "Update Performance Program Awards Created By User",
  // },
  // updateCreatedPerformanceProgramStatus: {
  //   label: "Update Performance Program Status Created By User",
  // },
  // updateLoyaltyProgramAwards: { label: "Update Loyalty Program Awards" },
  // updateLoyaltyProgramStatus: { label: "Update Loyalty Program Status" },
  // updatePerformanceProgramAwards: {
  //   label: "Update Performance Program Awards",
  // },
  // updatePerformanceProgramStatus: {
  //   label: "Update Performance Program Status",
  // },
  // updateSpaceAggregate: { label: "Update Space Aggregate" },
  // updateSpaceAggregateGroup: { label: "Update Space Group" },
  // createStaffRole: { label: "Create Staff Role" },
  // deleteStaffRole: { label: "Delete Staff Role" },
  // updateStaffRole: { label: "Update Staff Role" },
  // updateStaffRoleName: { label: "Update Staff Role Name" },
  // updateStaffRolePermissions: { label: "Update Staff Role Permissions" },
  // updateStaffRoleStaff: { label: "Update Staff Role Staff" },
  // updateStay: { label: "Update Reservation" },
  // spaceAggregatePage: { label: "Space Aggregate Page" },
  // createRole: { label: "Create Role" },
  // permissionsPage: { label: "View Permissions Tab" },
  // rolesPage: { label: "View Roles Tab" },
  // updateRole: { label: "Update Role" },
  // updateRolePermissions: { label: "Update Role's Permissions" },
  // viewUserLastAccess: { label: "View User's Last Access" },
  // viewUsersActivities: { label: "View User Activities" },
  // giveAwardToStay: { label: "Give Award to Stay" },
};
