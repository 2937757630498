import React, { useEffect, useReducer } from "react";
import { nProfile } from "./interfaces";
import { defaultManager } from "../../utils/managers";
import axios from "axios";
import { apiAddressV2 } from "../../utils/apiCall";
import { getErrorMessage } from "../../utils/httpResponses/others";

const ProfileDispatchContext = React.createContext<
  nProfile.tDispatchContext | undefined
>(undefined);
ProfileDispatchContext.displayName = "ProfileDispatchContext";
const ProfileStateContext = React.createContext<
  nProfile.tStateContext | undefined
>(undefined);
ProfileStateContext.displayName = "ProfileStateContext";

const LS_DATA = "__p__";

const initialState: nProfile.tState = {
  data: {
    profile: null,
  },
  status: "idle",
  error: null,
};

const reducer = (
  state: nProfile.tState,
  action: nProfile.tAction
): nProfile.tState => {
  switch (action.type) {
    case "set data": {
      const { data } = action;
      localStorage.setItem(LS_DATA, JSON.stringify(data));
      return { ...state, data, status: "resolved", error: null };
    }
    case "set profile": {
      const { profile } = action;
      const data = { ...state.data, profile };
      localStorage.setItem(LS_DATA, JSON.stringify(data));

      return { ...state, data, status: "resolved", error: null };
    }
    case "update profile": {
      const { profile } = action;
      const data = {
        ...state.data,
        profile: {
          ...defaultManager,
          ...(state.data.profile || {}),
          ...profile,
        },
      };
      localStorage.setItem(LS_DATA, JSON.stringify(data));

      return { ...state, data, status: "resolved", error: null };
    }
    case "resolved": {
      return { ...state, status: "resolved", error: null };
    }
    case "rejected": {
      const { error } = action;
      return { ...state, status: "rejected", error };
    }
    case "pending": {
      return { ...state, status: "pending", error: null };
    }
    default:
      return { ...state };
  }
};

const ProfileContextProvider: React.FC<nProfile.iContextProps> = ({
  children,
}) => {
  const [state, dispatch]: [nProfile.tState, React.Dispatch<nProfile.tAction>] =
    useReducer(reducer, initialState);

  useEffect(() => {
    const stored = localStorage.getItem(LS_DATA);

    if (!stored) {
      dispatch({ type: "pending" });
      axios
        .get(`${apiAddressV2(false)}/managers/manager`)
        .then((res) => {
          const {
            data: { account },
          } = res;
          dispatch({ type: "set profile", profile: account });
        })
        .catch((err) => {
          const error = getErrorMessage(err);
          dispatch({ type: "rejected", error });
        });
      return;
    }
    const data = JSON.parse(stored);
    dispatch({ type: "set data", data });
  }, []);

  return (
    <ProfileStateContext.Provider value={state}>
      <ProfileDispatchContext.Provider value={dispatch}>
        {children}
      </ProfileDispatchContext.Provider>
    </ProfileStateContext.Provider>
  );
};

export { ProfileContextProvider, ProfileDispatchContext, ProfileStateContext };
