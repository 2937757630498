import {
  every,
  extend,
  filter,
  find,
  groupBy,
  isArray,
  isString,
  isUndefined,
  orderBy,
  pick,
} from "lodash";
import moment, { MomentInput } from "moment";
import React, { useCallback, useMemo } from "react";
import { HotelStateContext } from "../";
import tHotel, {
  tHotelAward,
  tHotelManualData,
  tHotelSpace,
  tHotelSpaceAggregate,
  tHotelSpaceAggregateId,
  tHotelSpaceId,
  tHotelSpaceName,
  tHotelStaffPermissions,
  tHotelStaffRole,
  tHotelSubscriptionModel,
} from "../../../models/hotel";
import { tManagerId } from "../../../models/manager";
import { tMeasure } from "../../../models/measures";
import { REQUEST_STATUS } from "../../../utils/apiCall";
import { defaultHotel } from "../../../utils/hotels";

const useHotelState = () => {
  const state = React.useContext(HotelStateContext);

  if (!state)
    throw Error("useHotelState must be used within HotelStateContext");

  const {
    data: { hotels, selectedHotelIndex },
    status,
  } = state;

  const hotelLoading = status === REQUEST_STATUS.PENDING;
  const hotelIsLoaded = status === REQUEST_STATUS.RESOLVED;
  const errorLoading = status === REQUEST_STATUS.REJECTED;

  const hotel = useMemo(() => {
    return { ...defaultHotel, ...hotels[selectedHotelIndex] };
  }, [hotels, selectedHotelIndex]);

  const hotelId = hotel._id;

  //** Returns measures being tracked in specific spaces
  // * If no spaceIds are provided, returns information about all spaces
  // */
  const getMeasuresTracked = useCallback(
    (_spaceIds?: string | string[]) => {
      const spaceNames = isString(_spaceIds) ? [_spaceIds] : _spaceIds;
      const spaces = hotel
        ? spaceNames
          ? hotel.spaces.filter((r) => spaceNames.includes(r._id))
          : hotel.spaces
        : [];

      let measureIsMeasured: Record<tMeasure, boolean> = {
        te: false,
        es: false,
        el: false,
        ac: false,
        wh: false,
        tw: false,
        cw: false,
        hw: false,
        gw: false,
      };

      spaces.forEach((space) => {
        Object.keys(space.measures).forEach((key) => {
          const measureName = key as tMeasure;
          measureIsMeasured[measureName] ||=
            space.measures[measureName].isMeasured;
        });
      });

      return measureIsMeasured;
    },
    [hotel]
  );

  const listSpacesThatMeasure = (measures: tMeasure[]) => {
    return hotel.spaces.filter((s) => {
      for (const m of measures) {
        if (s.measures[m].isMeasured) return true;
      }
      return false;
    });
  };

  const inFinalState = ["resolved", "rejected"].includes(status);

  const getStaffRoles = useCallback(
    (staffId: tManagerId) => {
      return hotel.staffRoles.filter((r) => r.staff.includes(staffId));
    },
    [hotel.staffRoles]
  );

  const staffMemberHasPermissions = (
    staffId: tManagerId,
    permissions: (keyof tHotelStaffRole["permissions"])[]
  ) => {
    const roles = getStaffRoles(staffId);
    if (roles.length === 0) return false;

    const fullPermissions: Partial<tHotelStaffPermissions> = {};

    roles.forEach((r) => {
      extend(fullPermissions, r.permissions);
    });

    return every(
      Object.values(pick(fullPermissions, permissions)),
      (p) => p === true
    );
  };

  const findSpace = useCallback(
    (space: tHotelSpaceId | tHotelSpaceName): tHotelSpace | undefined => {
      return find(hotel.spaces, (s) => s._id === space || s.name === space);
    },
    [hotel.spaces]
  );

  const findSpaces = useCallback(
    (spaceIds: tHotelSpaceId[]): tHotelSpace[] => {
      return hotel.spaces.filter((s) => spaceIds.includes(s._id));
    },
    [hotel.spaces]
  );
  const findSpaceByName = useCallback(
    (spaceName: string): tHotelSpace | undefined => {
      return find(hotel.spaces, (s) => s.name === spaceName);
    },
    [hotel.spaces]
  );

  const findSpaceAggregate = useCallback(
    (aggregateId: tHotelSpaceAggregateId): tHotelSpaceAggregate | undefined => {
      return find(hotel.spaceAggregates, (g) => g._id === aggregateId);
    },
    [hotel.spaceAggregates]
  );
  const findSpaceAggregates = useCallback(
    (
      aggregateId: tHotelSpaceAggregateId | tHotelSpaceAggregateId[]
    ): tHotelSpaceAggregate[] => {
      const finder = isArray(aggregateId) ? aggregateId : [aggregateId];
      return filter(hotel.spaceAggregates, (g) => finder.includes(g._id));
    },
    [hotel.spaceAggregates]
  );
  const findSpaceAggregateByName = useCallback(
    (name: string): tHotelSpaceAggregate | undefined => {
      return find(hotel.spaceAggregates, (g) => g.name === name);
    },
    [hotel.spaceAggregates]
  );

  const spaceAggregatesByCategory = useMemo(() => {
    const obj = {
      group: [],
      type: [],
      zone: [],
      ...groupBy(hotel.spaceAggregates, "category"),
    } as Record<"type" | "group" | "zone", tHotelSpaceAggregate[]>;
    return obj;
  }, [hotel.spaceAggregates]);

  const findSpaceSpaceAggregates = useCallback(
    (
      spaceId: tHotelSpaceId,
      categories?:
        | tHotelSpaceAggregate["category"][]
        | tHotelSpaceAggregate["category"]
    ): tHotelSpaceAggregate[] => {
      const spaceAggregatesToUse = isUndefined(categories)
        ? hotel.spaceAggregates
        : (isArray(categories) ? categories : [categories]).flatMap(
            (category) => spaceAggregatesByCategory[category]
          );

      const spaceAggregates = spaceAggregatesToUse.filter((g) =>
        g.spaces.includes(spaceId)
      );
      return spaceAggregates;
    },
    [hotel.spaceAggregates, spaceAggregatesByCategory]
  );

  const findAward = useCallback(
    (awardId: tHotelAward["_id"]): tHotelAward | undefined => {
      return find(hotel.awards, (a) => a._id === awardId);
    },
    [hotel.awards]
  );

  const spacesMapById = Object.fromEntries(hotel.spaces.map((s) => [s._id, s]));

  const manualDataOrdered = useMemo(() => {
    return orderBy(
      hotel.manualData.map((md) => ({
        ...md,
        from: moment(md.from).valueOf(),
        to: moment(md.to).valueOf(),
      })),
      ["from", "to"],
      ["asc", "asc"]
    );
  }, [hotel]);

  const manualDataByYear: Record<string, tHotelManualData[]> = useMemo(
    () => groupBy(manualDataOrdered, (mdo) => moment(mdo.from).year()),
    [manualDataOrdered]
  );

  const measuresTrackedInManualData = useMemo(() => {
    const measuresTracked = { electricity: false, water: false, fuels: false };
    for (const { electricity, water, naturalGas } of manualDataOrdered) {
      if (electricity) {
        measuresTracked.electricity = true;
      }
      if (water) {
        measuresTracked.water = true;
      }
      if (naturalGas) {
        measuresTracked.fuels = true;
      }
      if (Object.values(measuresTracked).filter((v) => !v).length === 0) {
        break;
      }
    }
    return measuresTracked;
  }, [manualDataOrdered]);

  const getHotelDimensionsInTimeframe = useCallback(
    (date: MomentInput) => {
      let i = 0;
      for (; i < manualDataOrdered.length; i++) {
        const entry = manualDataOrdered[i];
        if (!moment(date).isBetween(moment(entry.from), moment(entry.to)))
          continue;

        let dimensions = entry.space;
        if (dimensions) return dimensions;

        let j = i - 1;
        while (!dimensions && j >= 0) {
          dimensions = manualDataOrdered[j].space;
          j--;
        }
        if (dimensions) return dimensions;
        j = i + 1;
        while (!dimensions && j < manualDataOrdered.length) {
          dimensions = manualDataOrdered[j].space;
          j++;
        }
        if (dimensions) return dimensions;
        break;
      }

      i = manualDataOrdered.length - 1;
      for (; i >= 0; i--) {
        if (
          manualDataOrdered[i].space &&
          manualDataOrdered[i].space!.totalAreaM2
        )
          return manualDataOrdered[i].space;
      }

      return undefined;
    },
    [manualDataOrdered]
  );
  const getManualDataEntryWithDimensionsInTimeframe = useCallback(
    (date: MomentInput) => {
      let i = 0;
      for (; i < manualDataOrdered.length; i++) {
        const entry = manualDataOrdered[i];
        if (!moment(date).isBetween(moment(entry.from), moment(entry.to)))
          continue;

        let dimensions = entry.space;
        if (dimensions) return { ...entry };

        let j = i - 1;
        while (j >= 0) {
          dimensions = manualDataOrdered[j].space;
          if (dimensions) return { ...manualDataOrdered[j] };
          j--;
        }
        j = i + 1;
        while (j < manualDataOrdered.length) {
          dimensions = manualDataOrdered[j].space;
          if (dimensions) return { ...manualDataOrdered[j] };
          j++;
        }
        break;
      }

      i = manualDataOrdered.length - 1;
      for (; i >= 0; i--) {
        if (
          manualDataOrdered[i].space &&
          manualDataOrdered[i].space!.totalAreaM2
        ) {
          return { ...manualDataOrdered[i] };
        }
      }

      return undefined;
    },
    [manualDataOrdered]
  );

  const activeSubscription: tHotelSubscriptionModel | undefined = find(
    hotel.subscriptions,
    (s) => s.active
  );
  const activeSubscriptionType: tHotelSubscriptionModel["type"] =
    activeSubscription?.type || "starter";

  const activeBillingInfo: tHotel["billing"][0] | undefined = useMemo(() => {
    return find(hotel.billing, (b) => b.active);
  }, [hotel.billing]);

  return {
    hotelIsLoaded,
    hotelLoading,
    errorLoading,
    hotel,
    hotelId,
    getMeasuresTracked,
    listSpacesThatMeasure,
    getStaffRoles,
    staffMemberHasPermissions,
    inFinalState,
    findSpace,
    findSpaces,
    findSpaceByName,
    findAward,
    spacesMapById,
    findSpaceAggregate,
    findSpaceAggregates,
    findSpaceAggregateByName,
    findSpaceSpaceAggregates,
    spaceAggregatesByCategory,
    manualDataOrdered,
    getHotelDimensionsInTimeframe,
    measuresTrackedInManualData,
    manualDataByYear,
    activeSubscription,
    activeSubscriptionType,
    activeBillingInfo,
    getManualDataEntryWithDimensionsInTimeframe,
  };
};

export default useHotelState;
