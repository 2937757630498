import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "rsuite";
import Flex from "../../../components/Flex";
import DMSeriffTag from "../../../components/Text/DMSeriff";
import InterTag from "../../../components/Text/Inter";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import { COLORS } from "../../../utils/colors";

const Unlock: React.FC = () => {
  const navigate = useNavigate();
  const { trans } = useLocalizationState();

  return (
    <Flex column middle gap={32} style={{ margin: "0 100px" }}>
      <DMSeriffTag
        size={36}
        color={COLORS.secondary}
        text={trans("Manage Your Hotel's Fleet Efficiently and Responsible")}
        style={{ textWrap: "wrap", textAlign: "center" }}
        bold
      />
      {[
        "Take control of your hotel's vehicle fleet with our comprehensive 'Fleet' feature.",
        "Designed to streamline the management of your fleet, track vehicle mileage, and calculate emissions accurately, this feature helps you enhance operational efficiency and reduce your environmental impact. Make informed decisions to maintain sustainability in your transportation services.",
      ].map((t) => (
        <InterTag
          size={20}
          color={COLORS.secondary}
          text={trans(t)}
          style={{ textWrap: "wrap", textAlign: "center", lineHeight: "22px" }}
        />
      ))}
      <Button
        style={{
          backgroundColor: COLORS.primary_900,
          color: COLORS.white,
          fontWeight: "bold",
        }}
        onClick={() => navigate("/settings/billing/plan")}
      >
        {trans("Upgrade Now")}
      </Button>
      {[
        {
          title: "Emissions Tracking",
          description:
            "Accuratelky calculate emissions based on tracked mileage to help your hotel measure and manage its environmental impact effectively.",
        },
        {
          title: "Mileage Tracking",
          description:
            "Keep precise records of vehicle mileage to ensure detailed reporting and monitoring of your fleet's operational use.",
        },
        {
          title: "Sustainability Reporting",
          description:
            "Utilize the data from mileage and emissions calculations to enhance your sustainability reports, aiding in compliance and environmental stewardhip.",
        },
      ].map(({ title, description }) => (
        <Flex column gap={16}>
          <DMSeriffTag
            text={trans(title)}
            color={COLORS.secondary}
            size={24}
            style={{ textWrap: "wrap", textAlign: "center" }}
            bold
          />
          <InterTag
            text={trans(description)}
            color={COLORS.secondary}
            size={20}
            style={{
              textWrap: "wrap",
              textAlign: "center",
              lineHeight: "22px",
            }}
          />
        </Flex>
      ))}
    </Flex>
  );
};

export default Unlock;
