import { createContext, useCallback, useState } from "react";

type tState = {
  dataTypeToggleDisabled: Record<
    | "hotel"
    | "electricityMainMeasureRequest"
    | "electricitySpecificMeasuresRequest"
    | "waterMainMeasureRequest"
    | "waterSpecificMeasuresRequest"
    | "conversionFactorsRequest",
    boolean
  >;
};

const initialState: tState = {
  dataTypeToggleDisabled: {
    hotel: false,
    electricityMainMeasureRequest: false,
    electricitySpecificMeasuresRequest: false,
    waterMainMeasureRequest: false,
    waterSpecificMeasuresRequest: false,
    conversionFactorsRequest: false,
  },
};

const ConsumptionContext = createContext<{
  state: tState;
  setDataTypeToggleDisabled: (
    arg: Partial<tState["dataTypeToggleDisabled"]>
  ) => void;
}>({ state: { ...initialState }, setDataTypeToggleDisabled(arg) {} });

const ConsumptionContextProvider: React.FC<{ children: any }> = ({
  children,
}) => {
  const [state, setState] = useState({ ...initialState });

  const setDataTypeToggleDisabled = useCallback(
    (arg: Partial<tState["dataTypeToggleDisabled"]>) => {
      setState((prev) => ({
        ...prev,
        dataTypeToggleDisabled: { ...prev.dataTypeToggleDisabled, ...arg },
      }));
    },
    []
  );

  return (
    <ConsumptionContext.Provider value={{ state, setDataTypeToggleDisabled }}>
      {children}
    </ConsumptionContext.Provider>
  );
};

export { ConsumptionContext };

export default ConsumptionContextProvider;
