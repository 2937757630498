import React from "react";
import { Button, Modal, ModalProps } from "rsuite";
import { ReactComponent as HighFiveBro } from "../../assets/bros/high five hands-bro 2.svg";
import { ReactComponent as DashboardIcon } from "../../assets/icons/dashboard.svg";
import { ReactComponent as EnergyProgramSavingIcon } from "../../assets/icons/energy_program_saving.svg";
import useLocalizationState from "../../context/Localization/hooks/useLocalizationState";
import { COLORS, hex2hexA } from "../../utils/colors";
import Flex from "../Flex";
import Icon from "../Icon";
import InterTag from "../Text/Inter";
import styles from "./styles.module.css";
import { useNavigate } from "react-router-dom";

interface iGettingStartedFinalModalWrappedProps
  extends Pick<iProps, "onClose"> {}

const GettingStartedFinalModalWrapped: React.FC<
  iGettingStartedFinalModalWrappedProps
> = ({ onClose }) => {
  const { trans } = useLocalizationState();
  const navigate = useNavigate();

  const renderActionButtons = () => {
    return [
      {
        label: trans("Go to Dashboard"),
        Element: DashboardIcon,
        link: "/overview",
      },
      {
        label: trans("Check your Scores"),
        Element: EnergyProgramSavingIcon,
        link: "/sustainability",
      },
    ].map(({ label, Element, link }) => {
      return (
        <Button
          key={link}
          className={styles["all-done-button"]}
          onClick={() => {
            navigate(link);
            onClose();
          }}
        >
          <Flex row gap={16} middle>
            <Flex
              center
              middle
              style={{
                borderRadius: "8px",
                backgroundColor: hex2hexA(COLORS.primary, 30),
                padding: "12px",
              }}
            >
              <Icon Element={Element} size={40} fill={COLORS.secondary} />
            </Flex>
            <InterTag text={label} size={20} color={COLORS.secondary} />
          </Flex>
        </Button>
      );
    });
  };

  return (
    <Modal.Body>
      <Flex column gap={16}>
        <Flex column gap={8}>
          <InterTag
            text={trans("All Done!")}
            bold
            size={20}
            color={COLORS.secondary}
          />
          <InterTag
            style={{ textWrap: "wrap", lineHeight: "18px" }}
            text={trans(
              "Great job! You've successfully completed the setup. You accommodation is now primed for sustainability tracking and improvement."
            )}
            size={14}
            color={COLORS.secondary}
          />
        </Flex>
        <Flex center>
          <Icon Element={HighFiveBro} size={250} />
        </Flex>
        <InterTag
          style={{ textWrap: "wrap", lineHeight: "18px" }}
          text={trans(
            "Now it's time to dive into the data and insights. Explore your dashboard, check out your sustainability scores, or start setting goals."
          )}
          size={14}
          color={COLORS.secondary}
        />
        <Flex row gap={16} center>
          {renderActionButtons()}
        </Flex>
        <div
          style={{
            width: "100%",
            height: "1px",
            backgroundColor: COLORS.gray_400,
          }}
        />
        <Flex row gap={40} middle>
          <div>
            <InterTag
              size={14}
              color={COLORS.secondary}
              style={{ textWrap: "wrap", lineHeight: "18px" }}
              text={trans(
                "Need a hand or have feedback? Our support team is here to help you make the most out of your noytralll experience."
              )}
            />
          </div>
          <div>
            <Button appearance="link">
              <InterTag
                size={14}
                color={COLORS.primary}
                text={trans("Contact Support").toUpperCase()}
              />
            </Button>
          </div>
        </Flex>
        <div
          style={{
            width: "100%",
            height: "1px",
            backgroundColor: COLORS.gray_400,
          }}
        />
        <InterTag
          size={14}
          color={COLORS.secondary}
          style={{ textWrap: "wrap", lineHeight: "18px" }}
          text={trans(
            "Thank your for taking this important step with noytrall. together, we're paving the way for a more sustainable future in hospitality."
          )}
        />
      </Flex>
    </Modal.Body>
  );
};

interface iProps extends Omit<ModalProps, "onClose"> {
  onClose(): void;
}

const GettingStartedFinalModal: React.FC<iProps> = ({ onClose, ...rest }) => {
  return (
    <Modal
      {...{
        onClose,
        size: "md",
        overflow: false,
        keyboard: false,
        backdrop: "static",
        ...rest,
      }}
    >
      <GettingStartedFinalModalWrapped {...{ onClose }} />
    </Modal>
  );
};

export default GettingStartedFinalModal;
