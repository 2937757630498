import React from "react";
import Flex from "../Flex";
import { ReactComponent as DoNotEnterBro } from "../../assets/bros/Do not enter sign-amico 1.svg";
import Icon from "../Icon";
import InterTag from "../Text/Inter";
import useLocalizationState from "../../context/Localization/hooks/useLocalizationState";
import { COLORS } from "../../utils/colors";

interface iProps {}

const NoPermissions: React.FC<iProps> = () => {
  const { trans } = useLocalizationState();
  return (
    <Flex column gap={28} middle>
      <Icon Element={DoNotEnterBro} size={250} />
      <Flex column gap={20} middle>
        {[
          "Access Restricted",
          "You do not have permission to view this page",
        ].map((t) => (
          <InterTag size={36} text={trans(t)} color={COLORS.secondary} />
        ))}
      </Flex>
      <Flex column gap={16}>
        {[
          "It seems you currently do not have the necessary permissions to access thi page. This may be due to your user role or account settings.",
          "If you believe this is a mistake, please contact your account administrator or support team.",
          "If you require access to this content, please contact your account administrator.",
        ].map((t) => (
          <InterTag
            style={{ textWrap: "wrap", textAlign: "center" }}
            size={16}
            color={COLORS.secondary}
            text={trans(t)}
          />
        ))}
      </Flex>
    </Flex>
  );
};

export default NoPermissions;
