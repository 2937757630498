import { find } from "lodash";
import { DEFAULT_LANGAUGE } from "../context/Localization/utils";
import { tHotelRoadmap } from "../models/hotel";
import { tLanguageCode } from "./languages";

export const getRoadmapDescription = (
  roadmap: tHotelRoadmap,
  language: tLanguageCode | string
): { description: string } | undefined => {
  return (
    find(roadmap.descriptions, (d) => d.language === language) ||
    find(roadmap.descriptions, (d) => d.language === DEFAULT_LANGAUGE) ||
    roadmap.descriptions[0]
  );
};
