import React from "react";
import { Col, Grid, Row } from "rsuite";
import { ReactComponent as DashboardIcon } from "../../../../assets/icons/dashboard.svg";
import Flex from "../../../../components/Flex";
import PageSectionTitle from "../../../../components/PageSectionTitle";
import SimpleDateRangePicker from "../../../../components/RsuiteWrapper/SimpleDateRangePicker";
import useLocalizationState from "../../../../context/Localization/hooks/useLocalizationState";
import usePeriodState from "../../../../hooks/usePeriodState";
import CheckInCheckOut from "../checkInCheckOut";
import OccupancyCard from "./occupancyCard";
import ConsumptionCards from "./consumptionCards";

interface iProps {
  dataTypeToggle: JSX.Element | null;
}

const Live: React.FC<iProps> = ({ dataTypeToggle }) => {
  const { trans } = useLocalizationState();
  const [period, setPeriod] = usePeriodState();

  return (
    <Flex column gap={16}>
      <Flex row middle gap={12}>
        {dataTypeToggle}
        <SimpleDateRangePicker
          value={period}
          onChange={setPeriod}
          style={{ paddingLeft: "8px" }}
        />
      </Flex>
      <PageSectionTitle icon={DashboardIcon} title={trans("Summary")} />
      <div>
        <Grid fluid>
          <Row gutter={16}>
            <Col sm={12} xxl={8}>
              <CheckInCheckOut period={period} />
            </Col>
            <Col sm={12} xxl={8}>
              <OccupancyCard period={period} />
            </Col>
          </Row>
          <ConsumptionCards period={period} />
        </Grid>
      </div>
    </Flex>
  );
};

export default Live;
