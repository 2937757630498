import React from "react";
import { ProfileStateContext } from "../";
import { defaultManager } from "../../../utils/managers";

const useProfileState = () => {
  const state = React.useContext(ProfileStateContext);

  if (!state)
    throw Error("useProfileState must be used within ProfileStateContext");

  const { data } = state;

  const profile = data.profile || defaultManager;
  const profileLoaded = data.profile !== null;

  return { profileLoaded, profile };
};

export default useProfileState;
