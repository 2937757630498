import { findIndex } from "lodash";
import React, { useState } from "react";
import Flex from "../../../../components/Flex";
import Segmentation from "../../../../components/Segmentation";
import useHotelState from "../../../../context/Hotel/hooks/useHotelState";
import useLocalizationState from "../../../../context/Localization/hooks/useLocalizationState";
import useProfileState from "../../../../context/Profile/hooks/useProfileState";
import useListStaff from "../../../../hooks/useListStaff";
import useScreenSize from "../../../../hooks/useScreenSize";
import { COLORS } from "../../../../utils/colors";
import AddButton from "./addButton";
import PermissionsTab from "./permissionsTab";
import RolesTab from "./rolesTab";
import UsersTab from "./usersTab";

type tSegment = {
  key: string;
  label: string;
  count?: number;
};

interface iUserManagementWrappedProps extends iUserManagementProps {
  rootOutletContainerHeight: number;
  outletContainerWidth: number;
}

const UserManagementWrapped: React.FC<iUserManagementWrappedProps> = ({
  rootOutletContainerHeight,
  outletContainerWidth,
  staffRequest,
}) => {
  const { trans } = useLocalizationState();
  const {
    hotel: { staffRoles, staff },
    hotelIsLoaded,
  } = useHotelState();
  const { profile } = useProfileState();
  const [selectedSegmentIndex, setSelectedSegmentIndex] = useState<number>(0);

  const onSelectSegment = (key: string) => {
    if (staffRequest.isResolved) {
      setSelectedSegmentIndex(findIndex(segments, (f) => f.key === key));
    }
  };

  const segments: tSegment[] = [
    {
      key: "users",
      label: trans("Users"),
      count: staff.filter((s) => s.staffId === profile._id || !s.shadowAccount)
        .length,
    },
    {
      key: "roles",
      label: trans("Roles"),
      count: staffRoles.length,
    },
    {
      key: "permissions",
      label: trans("Permissions"),
    },
  ];

  const renderTable = () => {
    switch (segments[selectedSegmentIndex].key) {
      case "users":
        return (
          <UsersTab
            {...{
              staffRequest,
              rootOutletContainerHeight,
            }}
          />
        );
      case "roles":
        return (
          <RolesTab
            {...{
              staffRequest,
              rootOutletContainerHeight,
            }}
          />
        );
      case "permissions":
        return (
          <PermissionsTab
            {...{ rootOutletContainerHeight, outletContainerWidth }}
          />
        );
      default:
        break;
    }
  };

  const segment = segments[selectedSegmentIndex];

  return (
    <Flex column gap={16}>
      <Flex row between>
        <Flex grow={1}>
          <Segmentation
            appearance="subtle"
            active={segment.key}
            onSelect={onSelectSegment}
            options={segments.map((f) => {
              const { key, label, count } = f;
              return {
                key,
                label: { text: label },
                count: count,
                disabled: !hotelIsLoaded,
              };
            })}
          />
        </Flex>
        <Flex
          style={{
            borderBottom: `2px ${COLORS.gray} solid`,
          }}
          middle
        >
          <AddButton {...{ staffRequest }} />
        </Flex>
      </Flex>
      <div>{renderTable()}</div>
    </Flex>
  );
};

interface iUserManagementProps {
  staffRequest: ReturnType<typeof useListStaff>;
}

const UserManagement: React.FC<iUserManagementProps> = ({ ...props }) => {
  const { "root-outlet-container": roc, outlet: oc } = useScreenSize();

  if (!roc || !oc) return null;

  return (
    <UserManagementWrapped
      rootOutletContainerHeight={roc.height}
      outletContainerWidth={oc.width}
      {...{ ...props }}
    />
  );
};

export default UserManagement;
