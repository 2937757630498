import React from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { Loader } from "rsuite";
import { ReactComponent as CategoryIcon } from "../../../assets/icons/category.svg";
import { ReactComponent as StacksIcon } from "../../../assets/icons/stacks.svg";
import { ReactComponent as WorkspacesIcon } from "../../../assets/icons/workspaces.svg";
import Flex from "../../../components/Flex";
import Icon from "../../../components/Icon";
import MeasureConsumptionElectricity from "../../../components/MeasureConsumptionElectricity";
import MeasureConsumptionWater from "../../../components/MeasureConsumptionWater";
import PageBottomPadding from "../../../components/PageBottomPadding";
import SimpleDateRangePicker from "../../../components/RsuiteWrapper/SimpleDateRangePicker";
import InterTag from "../../../components/Text/Inter";
import TopBar from "../../../components/TopBar";
import useHotelState from "../../../context/Hotel/hooks/useHotelState";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import usePeriodState from "../../../hooks/usePeriodState";
import { tHotelSpace } from "../../../models/hotel";
import { COLORS } from "../../../utils/colors";
import SimpleWhisperPopoverDropdown from "../../../components/RsuiteWrapper/SimpleWhisperPopoverDropdown";

interface iProps {
  space: tHotelSpace;
}

const SpaceWrapped: React.FC<iProps> = ({ space }) => {
  const navigate = useNavigate();
  const { trans } = useLocalizationState();
  const { findSpaceSpaceAggregates, getMeasuresTracked } = useHotelState();

  const [period, setPeriod] = usePeriodState();

  const spaceGroups = findSpaceSpaceAggregates(space._id, "group");
  const spaceZones = findSpaceSpaceAggregates(space._id, "zone");
  const spaceTypes = findSpaceSpaceAggregates(space._id, "type");

  const trackedMeasures = getMeasuresTracked(space._id);

  return (
    <Flex column gap={40}>
      <Flex row gap={8}>
        <SimpleDateRangePicker value={period} onChange={setPeriod} />
        {spaceGroups.length > 0 && (
          <SimpleWhisperPopoverDropdown
            options={spaceGroups.map((sg) => ({
              key: sg._id,
              label: sg.name,
              onClick() {
                navigate(`/space-aggregates/${sg._id}`);
              },
            }))}
          >
            <Flex
              row
              gap={4}
              middle
              color={COLORS.white}
              style={{
                borderRadius: "8px",
                width: "fit-content",
                padding: "9px",
                cursor: "pointer",
              }}
              className="hover-box-shadow"
            >
              <Icon
                Element={WorkspacesIcon}
                size={16}
                fill={COLORS.secondary}
              />
              <InterTag
                size={14}
                text={`${trans("Groups: $0", {
                  parameters: [spaceGroups.map(({ name }) => name).join(", ")],
                })}`}
              />
            </Flex>
          </SimpleWhisperPopoverDropdown>
        )}
        {spaceZones.length > 0 && (
          <SimpleWhisperPopoverDropdown
            options={spaceZones.map((sz) => ({
              key: sz._id,
              label: sz.name,
              onClick() {
                navigate(`/space-aggregates/${sz._id}`);
              },
            }))}
          >
            <Flex
              row
              gap={4}
              middle
              color={COLORS.white}
              style={{
                borderRadius: "8px",
                width: "fit-content",
                padding: "9px",
                cursor: "pointer",
              }}
              className="hover-box-shadow"
            >
              <Icon Element={StacksIcon} size={16} fill={COLORS.secondary} />
              <InterTag
                size={14}
                text={`${trans("Zones: $0", {
                  parameters: [spaceZones.map(({ name }) => name).join(", ")],
                })}`}
              />
            </Flex>
          </SimpleWhisperPopoverDropdown>
        )}
        {spaceTypes.length > 0 && (
          <SimpleWhisperPopoverDropdown
            options={spaceTypes.map((st) => ({
              key: st._id,
              label: st.name,
              onClick() {
                navigate(`/space-aggregates/${st._id}`);
              },
            }))}
          >
            <Flex
              row
              gap={4}
              middle
              color={COLORS.white}
              style={{
                borderRadius: "8px",
                width: "fit-content",
                padding: "9px",
                cursor: "pointer",
              }}
              className="hover-box-shadow"
            >
              <Icon Element={CategoryIcon} size={16} fill={COLORS.secondary} />
              <InterTag
                size={14}
                text={`${trans("Types: $0", {
                  parameters: [spaceTypes.map(({ name }) => name).join(", ")],
                })}`}
              />
            </Flex>
          </SimpleWhisperPopoverDropdown>
        )}
      </Flex>
      {trackedMeasures.te && (
        <MeasureConsumptionElectricity
          {...{
            ...(period ? { from: period[0], to: period[1] } : {}),
            spaces: [space._id],
          }}
        />
      )}
      {trackedMeasures.tw && (
        <MeasureConsumptionWater
          {...{
            ...(period ? { from: period[0], to: period[1] } : {}),
            spaces: [space._id],
          }}
        />
      )}
    </Flex>
  );
};

const Space: React.FC = () => {
  const { spaceId } = useParams<{ spaceId: string }>();
  const navigate = useNavigate();
  const { trans } = useLocalizationState();
  const { findSpace, hotelIsLoaded } = useHotelState();

  if (!spaceId) return <Navigate to="/spaces" />;

  const space = findSpace(spaceId);

  if (!hotelIsLoaded)
    return (
      <Flex middle center style={{ height: "100%" }}>
        <Loader size="lg" />
      </Flex>
    );

  if (!space) return <Navigate to="/spaces" />;

  return (
    <>
      <TopBar>
        <Flex gap={8} column style={{ height: "100%" }}>
          <InterTag
            hoverUnderline
            onClick={() => {
              navigate("/spaces");
            }}
            style={{ marginTop: "10px" }}
            text={trans("Spaces")}
            size={12}
            color={COLORS.secondary}
          />
          <InterTag
            text={`${space.name}`}
            bold
            color={COLORS.secondary}
            size={24}
          />
        </Flex>
      </TopBar>
      <SpaceWrapped {...{ space }} />
      <PageBottomPadding />
    </>
  );
};

export default Space;
