import axios from "axios";
import React, { Fragment, useState } from "react";
import { useToaster } from "rsuite";
import { ReactComponent as AddIcon } from "../../../assets/icons/add.svg";
import PrimaryButton from "../../../components/Buttons/PrimaryButton";
import useHotelDispatch from "../../../context/Hotel/hooks/useHotelDispatch";
import useHotelState from "../../../context/Hotel/hooks/useHotelState";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import usePostRequest from "../../../hooks/apiRequests/usePostRequest";
import { apiAddressV2 } from "../../../utils/apiCall";
import { COLORS } from "../../../utils/colors";
import { getErrorMessage } from "../../../utils/httpResponses/others";
import { notification } from "../../../utils/notifications";
import CreateEditAwardModal, { tDataOnSubmit } from "./createEditAwardModal";

interface iProps {}

const AddAward: React.FC<iProps> = () => {
  const toaster = useToaster();
  const { trans } = useLocalizationState();
  const { updateHotel } = useHotelDispatch();
  const { hotelId } = useHotelState();
  const [modal, setModal] = useState<{ open: boolean }>({ open: false });
  const postRequest = usePostRequest();

  const createAward = (award: tDataOnSubmit) => {
    postRequest.pending();
    const {
      descriptions,
      discount,
      discountType,
      type,
      validityAfterAwarding,
    } = award;

    axios
      .post(`${apiAddressV2(false)}/v2/hotels/${hotelId}/awards/`, {
        type,
        discount,
        discountType,
        validityAfterAwarding,
        descriptions,
      })
      .then((res) => {
        const {
          data: { hotel },
        } = res;
        updateHotel(hotelId, hotel);
        toaster.push(notification("success", trans("Award created")), {
          placement: "topEnd",
        });
        postRequest.resolve();
        setModal({ open: false });
      })
      .catch((err) => {
        const error = getErrorMessage(err, trans);
        postRequest.reject(error);
        toaster.push(notification("error", error), { placement: "topEnd" });
      });
  };

  return (
    <Fragment>
      <CreateEditAwardModal
        onRequest={createAward}
        requestStatus={postRequest.status}
        requestError={postRequest.error}
        open={modal.open}
        onClose={() => setModal({ open: false })}
      />
      <PrimaryButton
        label={{
          text: trans("Add").toUpperCase(),
          color: COLORS.white,
          size: 12,
        }}
        icon={{ Element: AddIcon, fill: COLORS.white, size: 20 }}
        size="sm"
        onClick={() => setModal({ open: true })}
      />
    </Fragment>
  );
};

export default AddAward;
