import React, { useEffect, useState } from "react";
import { Loader, Toggle } from "rsuite";
import Flex from "../../../components/Flex";
import GettingStarted, {
  GettingStartedFinalModal,
} from "../../../components/GettingStarted";
import InterTag from "../../../components/Text/Inter";
import TopBar from "../../../components/TopBar";
import useHotelState from "../../../context/Hotel/hooks/hotelState/useHotelState";
import useHotelSubscriptionState from "../../../context/Hotel/hooks/hotelState/useHotelSubscriptionState";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import useProfileState from "../../../context/Profile/hooks/useProfileState";
import useProfilePermissions from "../../../hooks/useProfilePermissions";
import { nRequestStatus } from "../../../interfaces/apiCalls";
import { REQUEST_STATUS } from "../../../utils/apiCall";
import { COLORS } from "../../../utils/colors";
import Archives from "./archives";
import Live from "./live";

const DashboardWrapped2: React.FC = () => {
  const { trans } = useLocalizationState();
  const { activeSubscriptionIsEnterprise } = useHotelSubscriptionState();
  const [dataTypeState, setDataTypeState] = useState<{
    status: nRequestStatus.tStatus;
    dataType: "live" | "archives" | null;
  }>({ status: REQUEST_STATUS.PENDING, dataType: null });

  useEffect(() => {
    if (activeSubscriptionIsEnterprise) {
      setTimeout(() => {
        const get = () => {
          const stored = localStorage.getItem("__dashboard_dt__");

          switch (stored) {
            case "archives":
              return "archives";
            case "live":
              return "live";
          }
          return "archives";
        };
        const dataType = get();
        setDataTypeState({ status: REQUEST_STATUS.RESOLVED, dataType });
      }, 200);
    } else {
      setDataTypeState({
        status: REQUEST_STATUS.RESOLVED,
        dataType: "archives",
      });
    }
  }, [activeSubscriptionIsEnterprise]);

  const handleSetDataType = (dataType: "live" | "archives") => {
    setDataTypeState((prev) => ({ ...prev, dataType }));
    localStorage.setItem("__dashboard_dt__", dataType);
  };

  const dataTypeToggle = activeSubscriptionIsEnterprise ? (
    <Toggle
      // loading={
      //   dataTypeState.status === REQUEST_STATUS.PENDING ||
      //   Object.values(context.state.dataTypeToggleDisabled).some((v) => v)
      // }
      className={"toggle-data-type"}
      onChange={(checked) => {
        // if (
        //   !(
        //     dataTypeState.status === REQUEST_STATUS.PENDING ||
        //     Object.values(context.state.dataTypeToggleDisabled).some((v) => v)
        //   )
        // )
        handleSetDataType(checked ? "live" : "archives");
      }}
      checked={dataTypeState.dataType === "live"}
      checkedChildren={trans("Live")}
      unCheckedChildren={trans("Archives")}
    />
  ) : null;

  if (dataTypeState.status !== REQUEST_STATUS.RESOLVED) return null;

  if (dataTypeState.dataType === "live")
    return <Live {...{ dataTypeToggle }} />;

  return <Archives {...{ dataTypeToggle }} />;
};

const DashboardWrapped: React.FC = () => {
  const {
    hotel: { isSetup },
    hotelIsLoaded,
  } = useHotelState();
  const profilePermissions = useProfilePermissions();
  const [finalModal, setFinalModal] = useState(false);

  if (!hotelIsLoaded)
    return (
      <Flex center middle style={{ height: "100%" }}>
        <Loader size="lg" />
      </Flex>
    );

  if (isSetup)
    return (
      <>
        <GettingStartedFinalModal
          open={finalModal}
          onClose={() => setFinalModal(false)}
        />
        <DashboardWrapped2 />
      </>
    );

  if (profilePermissions.canSetupHotel)
    return (
      <GettingStarted
        startModalOpen={true}
        onInsert={() => setFinalModal(true)}
      />
    );

  return null;
};

const Dashboard: React.FC = () => {
  const { trans } = useLocalizationState();
  const { profile } = useProfileState();

  const names = profile.name.split(" ");

  return (
    <>
      <TopBar>
        <InterTag
          text={`${trans("Welcome, $0", {
            parameters: [
              names.length > 1 ? names.slice(0, -1).join(" ") : names[0],
            ],
          })} &#128075;`}
          asHTML
          bold
          color={COLORS.secondary}
          size={24}
        />
      </TopBar>
      <DashboardWrapped />
      {/* <PageBottomPadding /> */}
    </>
  );
};

export default Dashboard;
