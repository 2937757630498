import axios from "axios";
import { camelCase } from "lodash";
import React, { useEffect, useReducer, useState } from "react";
import { CheckPicker, Input, Modal, ModalProps, useToaster } from "rsuite";
import { ReactComponent as AddIcon } from "../../../../assets/icons/add.svg";
import { ReactComponent as KeyboardArrowRightIcon } from "../../../../assets/icons/keyboard_arrow_right.svg";
import Modal2ActionButtons from "../../../../components/Buttons/modal2ActionButtons";
import Flex from "../../../../components/Flex";
import InputWrapper from "../../../../components/InputWrapper";
import useHotelDispatch from "../../../../context/Hotel/hooks/useHotelDispatch";
import useHotelState from "../../../../context/Hotel/hooks/useHotelState";
import useLocalizationState from "../../../../context/Localization/hooks/useLocalizationState";
import useApiRequest from "../../../../hooks/apiRequests/useApiRequest";
import { tHotelStaffRoleId } from "../../../../models/hotel";
import { tManager } from "../../../../models/manager";
import { apiAddressV2 } from "../../../../utils/apiCall";
import { compareArrays } from "../../../../utils/arrays";
import { getErrorMessage } from "../../../../utils/httpResponses/others";
import { notification } from "../../../../utils/notifications";
import { DEFAULT_MODAL_PROPS } from "../../../../utils/rsuite/modals";
import PageSectionTitle from "../../../../components/PageSectionTitle";

type tData = {
  name: string;
  email: string;
  phoneNumber: string;
  staffRoles: tHotelStaffRoleId[];
};

type tErrors = Record<keyof tData, string>;

type tState = {
  data: tData;
  errors: tErrors;
};

type tAction =
  | { type: "reset" }
  | { type: "errors"; errors: Partial<tErrors> }
  | { type: "data"; data: Partial<tData> }
  | { type: "key value"; key: keyof tData; value: any };

const initialState: tState = {
  data: {
    email: "",
    name: "",
    phoneNumber: "",
    staffRoles: [],
  },
  errors: {
    email: "",
    name: "",
    phoneNumber: "",
    staffRoles: "",
  },
};

const reducer = (state: tState, action: tAction): tState => {
  switch (action.type) {
    case "reset": {
      return { ...initialState };
    }
    case "errors": {
      const { errors } = action;
      return { ...state, errors: { ...state.errors, ...errors } };
    }
    case "data": {
      const { data } = action;
      return {
        ...state,
        errors: {
          ...state.errors,
          ...Object.fromEntries(Object.keys(data).map((key) => [key, ""])),
        },
        data: { ...state.data, ...data },
      };
    }
    case "key value": {
      const { key, value } = action;
      return {
        ...state,
        errors: { ...state.errors, [key]: "" },
        data: { ...state.data, [key]: value },
      };
    }
    default: {
      return { ...state };
    }
  }
};

interface iCreateEditUserModalWrappedProps
  extends Pick<iProps, "onClose" | "staffMember"> {}

const CreateEditUserModalWrapped: React.FC<
  iCreateEditUserModalWrappedProps
> = ({ onClose, staffMember }) => {
  const toaster = useToaster();
  const { hotel, hotelId, getStaffRoles } = useHotelState();
  const { updateHotel, updatingHotel } = useHotelDispatch();
  const { trans } = useLocalizationState();
  const apiRequest = useApiRequest();
  const [initialRoles, setInitialRoles] = useState<tHotelStaffRoleId[]>([]);

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (staffMember) {
      const roles = getStaffRoles(staffMember._id);
      if (roles.length) setInitialRoles(roles.map((r) => r._id));

      dispatch({
        type: "data",
        data: {
          email: staffMember.email,
          name: staffMember.name,
          staffRoles: roles.map((r) => r._id),
        },
      });
    }
  }, [getStaffRoles, staffMember]);

  const canSubmit = () => {
    if (!state.data.email) return false;
    if (state.data.staffRoles.length === 0) return false;

    if (staffMember) {
      if (!compareArrays(initialRoles, state.data.staffRoles)) return true;
    }
    return true;
  };

  const handleConfirm = () => {
    apiRequest.pending();
    const body = {
      email: state.data.email,
      staffRoles: state.data.staffRoles,
    };
    (staffMember
      ? axios.put(
          `${apiAddressV2(false)}/v2/hotels/${hotelId}/staff/${
            staffMember._id
          }/`,
          { staffRoles: state.data.staffRoles }
        )
      : axios.post(`${apiAddressV2(false)}/v2/hotels/${hotelId}/staff`, body)
    )
      .then((res) => {
        const {
          data: { hotel },
        } = res;
        updatingHotel();
        setTimeout(() => {
          updateHotel(hotelId, hotel);
          toaster.push(
            notification(
              "success",
              staffMember ? trans("User updated") : trans("User created")
            ),
            {
              placement: "topEnd",
            }
          );
          apiRequest.resolve();
          onClose();
        }, 100);
      })
      .catch((err) => {
        const error = getErrorMessage(err, trans);
        toaster.push(notification("error", error), { placement: "topEnd" });
        apiRequest.reject(error);
      });
  };

  const handleChange = (key: keyof tData) => (value: any) => {
    dispatch({ type: "key value", key, value });
  };

  return (
    <Modal.Body className="modal-body-pb0">
      <Flex column gap={30}>
        <PageSectionTitle title={trans("Add Staff Member")} />
        <Flex column gap={20}>
          <InputWrapper label={trans("Email")}>
            <Input
              disabled={apiRequest.isLoading || staffMember !== undefined}
              value={state.data.email}
              onChange={handleChange("email")}
              placeholder={trans("jd@$0.com", {
                parameters: [camelCase(hotel.name.trim())],
              })}
            />
          </InputWrapper>
          <InputWrapper label={trans("Role")}>
            <CheckPicker
              disabled={apiRequest.isLoading}
              value={state.data.staffRoles}
              onChange={handleChange("staffRoles")}
              data={hotel.staffRoles
                .filter((sr) => !sr.isAdmin)
                .map(({ _id, name }) => ({
                  label: name,
                  value: _id,
                }))}
            />
          </InputWrapper>
        </Flex>
        <Modal2ActionButtons
          isLoading={apiRequest.isLoading}
          confirmButtonDisabled={!canSubmit()}
          onClose={onClose}
          onConfirm={handleConfirm}
          {...{
            ...(staffMember
              ? {
                  confirmIcon: KeyboardArrowRightIcon,
                  confirmLabel: trans("Update"),
                }
              : {
                  confirmIcon: AddIcon,
                  confirmLabel: trans("Add"),
                }),
          }}
        />
      </Flex>
    </Modal.Body>
  );
};

interface iProps extends ModalProps {
  onClose(): void;
  staffMember?: Pick<tManager, "name" | "email" | "_id">;
}

const CreateEditUserModal: React.FC<iProps> = ({
  open,
  onClose,
  staffMember,
  ...props
}) => {
  return (
    <Modal {...{ ...DEFAULT_MODAL_PROPS, open, onClose, ...props }}>
      <CreateEditUserModalWrapped {...{ onClose, staffMember }} />
    </Modal>
  );
};

export default CreateEditUserModal;
