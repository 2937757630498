import { chunk, round, sum } from "lodash";
import moment, { MomentInput } from "moment";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Col, Grid, Row } from "rsuite";
import useLocalizationState from "../../context/Localization/hooks/useLocalizationState";
import useGetMeasures from "../../hooks/apiRequests/useGetMeasures";
import { nRequestStatus } from "../../interfaces/apiCalls";
import { tIcon } from "../../interfaces/icon";
import { tBinUnitSingular } from "../../interfaces/sensorData";
import { tHotelSpaceId } from "../../models/hotel";
import { tMeasure, tMeasureTotal } from "../../models/measures";
import { COLORS } from "../../utils/colors";
import {
  convertMassUnit,
  DEFAULT_MASS_MEASURE_UNIT,
} from "../../utils/convertUnits/";
import { nUnitConverter } from "../../utils/convertUnits/interfaces";
import { calcNumberOfNights } from "../../utils/dates";
import { generateGraphCategories } from "../../utils/graphs";
import { getMeasureInfo } from "../../utils/measures";
import { NOYTRALL_0 } from "../../utils/others";
import Flex from "../Flex";
import ApexChart from "../Graphs/Apex/Chart";
import Icon from "../Icon";
import SkeletonText from "../Skeleton/SkeletonText";
import InterTag from "../Text/Inter";

export interface iMeasureConsumptionProps {
  mainMeasureKey: tMeasureTotal;
  specificMeasures: {
    measureKey: tMeasure;
  }[];
  converter: nUnitConverter.tFunction;
  from: MomentInput;
  to: MomentInput;
  binUnit: tBinUnitSingular;
  binValue: number;
  mainUnit: string;
  spaces?: tHotelSpaceId[];
  mainMeasureRequestStatusTracker?(status: nRequestStatus.tStatus): void;
  specificMeasuresRequestStatusTracker?(status: nRequestStatus.tStatus): void;
}

const MeasureConsumption: React.FC<iMeasureConsumptionProps> = ({
  converter,
  from,
  mainMeasureKey,
  specificMeasures,
  to,
  binUnit,
  binValue,
  mainUnit,
  spaces,
  mainMeasureRequestStatusTracker,
  specificMeasuresRequestStatusTracker,
}) => {
  const graphRef = useRef<any>();
  const { trans } = useLocalizationState();
  const [selectedSegment, setSelectedSegment] = useState(0);
  const [selectedMeasures, setSelectedMeasures] = useState<number[]>(
    [mainMeasureKey, ...specificMeasures].map((e, i) => i)
  );
  const mainMeasureRequest = useGetMeasures(
    {
      from: moment(from).toISOString(),
      to: moment(to).toISOString(),
      binUnit,
      binValue,
      measures: [mainMeasureKey],
      spaces,
    },
    { useLocalApi: true }
  );

  const specificMeasuresRequest = useGetMeasures(
    {
      from: moment(from).toISOString(),
      to: moment(to).toISOString(),
      binUnit,
      binValue,
      measures: specificMeasures.map(({ measureKey }) => measureKey),
      spaces,
    },
    { doRequest: specificMeasures.length > 0, useLocalApi: true }
  );

  useEffect(() => {
    mainMeasureRequestStatusTracker &&
      mainMeasureRequestStatusTracker(mainMeasureRequest.status);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainMeasureRequest.status]);

  useEffect(() => {
    specificMeasuresRequestStatusTracker &&
      specificMeasuresRequestStatusTracker(specificMeasuresRequest.status);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [specificMeasuresRequest.status]);

  const numberOfNights = calcNumberOfNights(from, to);

  const dataSeries = useMemo(() => {
    const dataSeries: Record<
      "measurements" | "costs" | "co2",
      { name: string; data: number[]; color: string }[]
    > = { measurements: [], costs: [], co2: [] };

    if (mainMeasureRequest.isResolved) {
      const d = mainMeasureRequest.data.grouped[mainMeasureKey];
      const { color } = getMeasureInfo(mainMeasureKey);
      dataSeries.measurements.push({
        name: trans("Total"),
        data: d?.measurements || [],
        color: color,
      });
      dataSeries.costs.push({
        name: trans("Total"),
        data: d?.costs || [],
        color: color,
      });
      dataSeries.co2.push({
        name: trans("Total"),
        data: d?.co2 || [],
        color: color,
      });
    } else {
      const { color } = getMeasureInfo(mainMeasureKey);
      dataSeries.measurements.push({
        name: trans("Total"),
        data: [],
        color: color,
      });
      dataSeries.costs.push({
        name: trans("Total"),
        data: [],
        color: color,
      });
      dataSeries.co2.push({
        name: trans("Total"),
        data: [],
        color: color,
      });
    }

    if (specificMeasuresRequest.isResolved) {
      specificMeasures.forEach(({ measureKey }) => {
        const d = specificMeasuresRequest.data.grouped[measureKey];
        const measureInfo = getMeasureInfo(measureKey, trans);

        dataSeries.measurements.push({
          name: measureInfo.label,
          data: d?.measurements || [],
          color: measureInfo.color,
        });
        dataSeries.costs.push({
          name: measureInfo.label,
          data: d?.costs || [],
          color: measureInfo.color,
        });
        dataSeries.co2.push({
          name: measureInfo.label,
          data: d?.co2 || [],
          color: measureInfo.color,
        });
      });
    } else {
      specificMeasures.forEach(({ measureKey }) => {
        const measureInfo = getMeasureInfo(measureKey, trans);

        dataSeries.measurements.push({
          name: measureInfo.label,
          data: [],
          color: measureInfo.color,
        });
        dataSeries.costs.push({
          name: measureInfo.label,
          data: [],
          color: measureInfo.color,
        });
        dataSeries.co2.push({
          name: measureInfo.label,
          data: [],
          color: measureInfo.color,
        });
      });
    }
    // TODO bin data
    // if (numberOfNights > 31) {
    //   Object.values(dataSeries).forEach((it1) => {
    //     it1.forEach((it2) => {
    //       const weeklyData: number[] = [];
    //     });
    //   });
    // }

    return dataSeries;
  }, [
    mainMeasureKey,
    mainMeasureRequest.data.grouped,
    mainMeasureRequest.isResolved,
    specificMeasures,
    specificMeasuresRequest.data.grouped,
    specificMeasuresRequest.isResolved,
    trans,
  ]);

  const summedConvertedData = (() => {
    if (selectedMeasures.includes(0)) {
      return {
        consumption: converter(
          sum(dataSeries.measurements.slice(0, 1).flatMap((s) => s.data) || [])
        ),
        costs: {
          value: sum(dataSeries.costs.slice(0, 1).flatMap((s) => s.data) || []),
          unit: "€",
        },
        footprint: convertMassUnit(
          sum(dataSeries.co2.slice(0, 1).flatMap((s) => s.data) || [])
        ),
      };
    } else {
      return {
        consumption: converter(
          sum(
            dataSeries.measurements
              .slice(1)
              .filter((e, i) => selectedMeasures.includes(i + 1))
              .flatMap((series) => series.data)
          )
        ),
        costs: {
          value: sum(
            dataSeries.costs
              .slice(1)
              .filter((e, i) => selectedMeasures.includes(i + 1))
              .flatMap((series) => series.data)
          ),
          unit: "€",
        },
        footprint: convertMassUnit(
          sum(
            dataSeries.co2
              .slice(1)
              .filter((e, i) => selectedMeasures.includes(i + 1))
              .flatMap((series) => series.data)
          )
        ),
      };
    }
  })();

  const renderSegments = () => {
    return (
      <Flex row gap={12}>
        {[
          {
            label: "Consumption",
            value: round(summedConvertedData.consumption.value, 2),
            unit: summedConvertedData.consumption.unit,
          },
          {
            label: "CO<sub>2</sub> Emissions",
            value: round(summedConvertedData.footprint.value, 2),
            unit: summedConvertedData.footprint.unit,
          },
          {
            label: "Costs",
            value: round(summedConvertedData.costs.value, 2),
            unit: summedConvertedData.costs.unit,
          },
        ].map(({ label, value, unit }, i) => {
          let labelColor = COLORS.gray;
          let mainColor = COLORS.secondary;
          const loading = selectedMeasures.includes(0)
            ? mainMeasureRequest.isLoading
            : specificMeasuresRequest.isLoading;

          if (selectedSegment === i) {
            labelColor = COLORS.primary;
            mainColor = COLORS.primary;
          }

          return (
            <Flex
              key={i}
              column
              gap={8}
              basis={15}
              style={{ cursor: "pointer", padding: "6px", borderRadius: "8px" }}
              className="hover-darken-white-bg"
              onClick={() => {
                if (selectedSegment !== i) setSelectedSegment(i);
              }}
            >
              <InterTag
                color={labelColor}
                text={trans(label)}
                size={12}
                asHTML
              />
              {loading ? (
                <Flex>
                  <SkeletonText style={{ width: "70%", height: "36px" }} />
                </Flex>
              ) : (
                <Flex row gap={4} bottom>
                  <InterTag text={value} size={36} color={mainColor} />
                  <InterTag text={unit} asHTML size={16} color={mainColor} />
                </Flex>
              )}
            </Flex>
          );
        })}
      </Flex>
    );
  };

  const cards = () => {
    const mainMeasureInfo = getMeasureInfo(mainMeasureKey, trans);

    const convertedMainMeasure =
      selectedSegment === 0
        ? converter(
            sum(
              mainMeasureRequest.data.grouped[mainMeasureKey]?.measurements ||
                []
            )
          )
        : selectedSegment === 1
        ? convertMassUnit(
            sum(mainMeasureRequest.data.grouped[mainMeasureKey]?.co2 || [])
          )
        : {
            value: sum(
              mainMeasureRequest.data.grouped[mainMeasureKey]?.costs || []
            ),
            unitInHTML: "€",
          };

    const list: {
      label: string;
      icon: tIcon;
      value: number;
      unit: string;
      color: string;
      loading: boolean;
    }[] = [
      {
        loading: !mainMeasureRequest.isFinal,
        label: trans("Total"),
        icon: mainMeasureInfo.icon,
        value: convertedMainMeasure.value,
        unit: convertedMainMeasure.unitInHTML,
        color: mainMeasureInfo.color,
      },
    ];

    specificMeasures.forEach(({ measureKey }) => {
      const info = getMeasureInfo(measureKey, trans);

      const converted =
        selectedSegment === 0
          ? converter(
              sum(
                specificMeasuresRequest.data.grouped[measureKey]
                  ?.measurements || []
              )
            )
          : selectedSegment === 1
          ? convertMassUnit(
              sum(specificMeasuresRequest.data.grouped[measureKey]?.co2 || [])
            )
          : {
              value: sum(
                specificMeasuresRequest.data.grouped[measureKey]?.costs || []
              ),
              unitInHTML: "€",
            };

      list.push({
        loading: !specificMeasuresRequest.isFinal,
        label: info.label,
        icon: info.icon,
        value: converted.value,
        unit: converted.unitInHTML,
        color: info.color,
      });
    });

    return list.map(({ label, icon, unit, value, color, loading }, i) => {
      return (
        <div
          key={label}
          style={{
            cursor: "pointer",
            borderRadius: "8px",
            padding: "8px",
            backgroundColor: selectedMeasures.includes(i)
              ? color
              : COLORS.white,
          }}
          onClick={() => {
            if (selectedMeasures.includes(i)) {
              if (selectedMeasures.length > 1) {
                if (graphRef.current)
                  graphRef.current.chart.toggleSeries(label);
                setSelectedMeasures((prev) => [...prev.filter((v) => v !== i)]);
              }
            } else {
              if (graphRef.current) graphRef.current.chart.toggleSeries(label);
              setSelectedMeasures((prev) => [...prev, i]);
            }
          }}
        >
          <Flex
            column
            gap={16}
            color={COLORS.white}
            style={{ borderRadius: "8px", padding: "12px" }}
          >
            <Flex row left gap={8} middle>
              <Icon size={20} {...{ ...icon }} />
              <InterTag size={16} text={label} />
            </Flex>
            {loading ? (
              <Flex>
                <SkeletonText style={{ width: "50%", height: "36px" }} />
              </Flex>
            ) : (
              <Flex row gap={4} bottom>
                <InterTag size={36} text={round(value, 2)} />
                <InterTag size={16} text={unit} asHTML />
              </Flex>
            )}
          </Flex>
        </div>
      );
    });
  };

  const graph = useCallback(() => {
    const categories = generateGraphCategories(binUnit, binValue, from, to);

    const [series, unit] = (() => {
      if (selectedSegment === 1)
        return [dataSeries.co2, DEFAULT_MASS_MEASURE_UNIT];
      if (selectedSegment === 2) return [dataSeries.costs, "€"];
      return [dataSeries.measurements, mainUnit];
    })();

    return (
      <ApexChart
        {...{
          ref: graphRef,
          type: "line",
          series,
          stroke: { width: 2 },
          yaxis: {
            labels: {
              show: true,
              formatter(val: any, opts: any) {
                return `${val === 0 ? NOYTRALL_0 : round(val, 2)} ${unit}`;
              },
            },
          },
          xaxis: {
            categories,
            type: "category",
            tickAmount: 5,
            labels: {
              rotate: 0,
              show: true,
              formatter(value: any, timestamp: any, opts: any) {
                return moment(value).format("DD/MM/YYYY");
              },
            },
          },
          tooltip: {
            enabled: true,
            followCursor: false,
            x: {
              formatter(val: any, opts: any) {
                const date = categories[opts.dataPointIndex];
                return moment(date).format("DD MMM YYYY");
              },
            },
          },
          legend: {
            show: true,
            position: "bottom",
            showForSingleSeries: true,
            onItemClick: {
              toggleDataSeries: false,
            },
          },
          chart: {
            // stacked: true,
            events: {
              mounted(chart, options) {
                if (graphRef.current)
                  [mainMeasureKey, ...specificMeasures].forEach((e, i) => {
                    if (selectedMeasures.includes(i)) {
                      graphRef.current.chart.showSeries(
                        options.globals.seriesNames[i]
                      );
                    } else {
                      graphRef.current.chart.hideSeries(
                        options.globals.seriesNames[i]
                      );
                    }
                  });
              },
              legendClick(chart, seriesIndex, options) {
                if (seriesIndex !== undefined) {
                  if (graphRef.current) {
                    if (selectedMeasures.includes(seriesIndex)) {
                      if (selectedMeasures.length > 1) {
                        graphRef.current.chart.toggleSeries(
                          options.globals.seriesNames[seriesIndex]
                        );
                        setSelectedMeasures((prev) => [
                          ...prev.filter((v) => v !== seriesIndex),
                        ]);
                      }
                    } else {
                      graphRef.current.chart.toggleSeries(
                        options.globals.seriesNames[seriesIndex]
                      );
                      setSelectedMeasures((prev) => [...prev, seriesIndex]);
                    }
                  }
                }
              },
            },
          },
        }}
      />
    );
  }, [
    binUnit,
    binValue,
    from,
    to,
    selectedSegment,
    dataSeries.co2,
    dataSeries.costs,
    dataSeries.measurements,
    mainUnit,
    mainMeasureKey,
    specificMeasures,
    selectedMeasures,
  ]);

  return (
    <Flex column gap={16}>
      <Flex
        style={{ padding: "24px 24px 0 24px", borderRadius: "8px" }}
        color={COLORS.white}
        column
        gap={20}
      >
        {renderSegments()}
        {mainMeasureRequest.isResolved || specificMeasuresRequest.isResolved ? (
          graph()
        ) : (
          <div style={{ paddingBottom: "24px" }}>
            <SkeletonText width={"100%"} height={285} />
          </div>
        )}
      </Flex>
      <div>
        <Grid fluid>
          {chunk(cards(), 4).map((batch, i) => (
            <Row key={i} gutter={16} style={{ marginTop: "16px" }}>
              {batch.map((elem, k) => (
                <Col key={k} sm={6}>
                  {elem}
                </Col>
              ))}
            </Row>
          ))}
        </Grid>
      </div>
    </Flex>
  );
};

export default MeasureConsumption;
