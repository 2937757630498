import React from "react";
import { ReactComponent as ManageAccountIcon } from "../../../assets/icons/manage_accounts.svg";
import { ReactComponent as QueryStatsIcon } from "../../../assets/icons/query_stats.svg";
import Flex from "../../../components/Flex";
import PageBottomPadding from "../../../components/PageBottomPadding";
import PageSectionTitle from "../../../components/PageSectionTitle";
import InterTag from "../../../components/Text/Inter";
import TopBar from "../../../components/TopBar";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import useListStaff from "../../../hooks/useListStaff";
import { tManager } from "../../../models/manager";
import { requestStatusIsFinal } from "../../../utils/apiCall";
import { COLORS } from "../../../utils/colors";
import UserManagement from "./management";
import RecentUserActivities from "./recentActivities";

export type tStaffData = Pick<
  tManager,
  "_id" | "email" | "images" | "name" | "lastAccess" | "lastLogin"
>;

const UsersWrapped: React.FC = () => {
  const state = useListStaff();
  const { trans } = useLocalizationState();

  return (
    <Flex column gap={40}>
      <Flex column gap={16}>
        <PageSectionTitle
          icon={{ Element: QueryStatsIcon }}
          title={trans("Recent User Activities")}
          description={trans(
            "Monitor login instances, setting changes, and other key actions taken by users."
          )}
        />
        <RecentUserActivities
          {...{
            staff: state.data.staff,
            loading: !requestStatusIsFinal(state.status),
          }}
        />
      </Flex>
      <Flex column gap={16}>
        <PageSectionTitle
          icon={{ Element: ManageAccountIcon }}
          title={trans("User Management")}
          description={trans(
            "Create, modify and organize user profiles, roles and permissions."
          )}
        />
        <UserManagement
          {...{
            staffRequest: state,
          }}
        />
      </Flex>
    </Flex>
  );
};

const Users: React.FC = () => {
  const { trans } = useLocalizationState();

  return (
    <div>
      <TopBar>
        <InterTag
          size={24}
          bold
          color={COLORS.secondary}
          text={trans("Users")}
        />
      </TopBar>
      <UsersWrapped />
      <PageBottomPadding />
    </div>
  );
};

export default Users;
