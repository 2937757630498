import { toNumber, uniqueId } from "lodash";
import React, { Fragment, useState } from "react";
import {
  Button,
  IconButton,
  InputNumber,
  Modal,
  ModalProps,
  SelectPicker,
  Tooltip,
  Whisper,
} from "rsuite";
import { ReactComponent as ArrowForwardIcon } from "../../../assets/icons/arrow_forward.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/icons/delete.svg";
import { ReactComponent as VerifiedIcon } from "../../../assets/icons/verified.svg";
import PrimaryButton from "../../../components/Buttons/PrimaryButton";
import Flex from "../../../components/Flex";
import Icon from "../../../components/Icon";
import InputWrapper from "../../../components/InputWrapper";
import PageSectionTitle from "../../../components/PageSectionTitle";
import InterTag from "../../../components/Text/Inter";
import useHotelState from "../../../context/Hotel/hooks/useHotelState";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import { tHotelAwardId } from "../../../models/hotel";
import { getAwardDescription } from "../../../utils/awards";
import { COLORS } from "../../../utils/colors";

interface iAwardGuestModalWrappedProps
  extends Pick<iProps, "onClose" | "onConfirm" | "isLoading"> {}

const AwardGuestModalWrapped: React.FC<iAwardGuestModalWrappedProps> = ({
  onClose,
  onConfirm,
  isLoading,
}) => {
  const { trans, language } = useLocalizationState();
  const {
    hotel: { awards },
  } = useHotelState();
  const [awardedAwards, setAwardedAwards] = useState<
    { awardId: tHotelAwardId | null; count: number; key: string }[]
  >([{ awardId: null, count: 1, key: uniqueId() }]);

  const activeAwards = awards.filter((a) => a.active && !a.removed);

  const handleConfirm = () => {
    onConfirm(
      (
        awardedAwards.filter((a) => a.awardId !== null) as {
          awardId: tHotelAwardId;
          count: number;
        }[]
      ).map((a) => ({ awardId: a.awardId, count: a.count }))
    );
  };

  return (
    <Fragment>
      <Modal.Body>
        <Flex column gap={32}>
          <PageSectionTitle
            title={trans("Award Guest")}
            description={trans("Choose awards to give to your guest")}
            icon={VerifiedIcon}
          />
          <Flex column gap={12}>
            <InputWrapper label={trans("Awards")}>
              {awardedAwards.map(({ awardId, count, key }, i) => {
                return (
                  <Flex row key={key} gap={4}>
                    <SelectPicker
                      disabled={isLoading}
                      value={awardId}
                      style={{ flexBasis: "70%" }}
                      onChange={(value) => {
                        const awards = awardedAwards;
                        awards[i] = { ...awards[i], awardId: value };
                        setAwardedAwards([...awards]);
                      }}
                      data={activeAwards.map((a) => {
                        const description = getAwardDescription(a, language);
                        return {
                          label: (
                            <Whisper
                              trigger="hover"
                              placement="topStart"
                              speaker={
                                <Tooltip>
                                  {description?.longDescription}
                                </Tooltip>
                              }
                            >
                              <div>{description?.shortDescription}</div>
                            </Whisper>
                          ),
                          value: a._id,
                        };
                      })}
                    />
                    <InputNumber
                      disabled={isLoading}
                      style={{ flexBasis: "20%" }}
                      value={count}
                      min={1}
                      onChange={(value) => {
                        const awards = awardedAwards;
                        awards[i] = {
                          ...awards[i],
                          count: value ? toNumber(value) : 1,
                        };
                        setAwardedAwards([...awards]);
                      }}
                    />
                    <IconButton
                      circle
                      icon={
                        <Icon
                          Element={DeleteIcon}
                          fill={COLORS.error}
                          size={18}
                        />
                      }
                      onClick={() => {
                        const awards = awardedAwards.filter(
                          (a) => a.key !== key
                        );
                        setAwardedAwards([...awards]);
                      }}
                    />
                  </Flex>
                );
              })}
            </InputWrapper>
            <Button
              disabled={isLoading}
              style={{ width: "fit-content" }}
              className="button-gray"
              onClick={() => {
                setAwardedAwards((prev) => [
                  ...prev,
                  { awardId: "", count: 1, key: uniqueId() },
                ]);
              }}
              appearance="default"
            >
              {trans("Add new award")}
            </Button>
          </Flex>
          <Flex row middle center gap={12}>
            <Button onClick={onClose} appearance="link" disabled={isLoading}>
              <InterTag
                text={trans("Cancel")}
                color={COLORS.primary}
                size={14}
              />
            </Button>
            <PrimaryButton
              loading={isLoading}
              disabled={isLoading}
              onClick={handleConfirm}
              label={{
                text: trans("Confirm"),
                color: COLORS.white,
              }}
              icon={{
                Element: ArrowForwardIcon,
                fill: COLORS.white,
                size: 20,
              }}
            />
          </Flex>
        </Flex>
      </Modal.Body>
    </Fragment>
  );
};

interface iProps extends ModalProps {
  onClose(): void;
  onConfirm(awards: { awardId: tHotelAwardId; count: number }[]): void;
  isLoading: boolean;
}

const AwardGuestModal: React.FC<iProps> = ({
  onClose,
  onConfirm,
  isLoading,
  ...rest
}) => {
  return (
    <Modal
      {...{
        size: "sm",
        id: "award-guest-modal",
        overflow: false,
        keyboard: false,
        backdrop: "static",
        onClose,
        ...rest,
      }}
    >
      <AwardGuestModalWrapped {...{ onClose, onConfirm, isLoading }} />
    </Modal>
  );
};

export default AwardGuestModal;
