import { findIndex } from "lodash";
import React, { Fragment, useState } from "react";
import { Loader } from "rsuite";
import { ReactComponent as CategoryIcon } from "../../../assets/icons/category.svg";
import { ReactComponent as MeetingRoomIcon } from "../../../assets/icons/meeting_room.svg";
import { ReactComponent as RoomPreferencesIcon } from "../../../assets/icons/room_preferences.svg";
import { ReactComponent as StacksIcon } from "../../../assets/icons/stacks.svg";
import { ReactComponent as WorkspaceIcon } from "../../../assets/icons/workspaces.svg";
import bySpacePlaceholder from "../../../assets/placeholders/consumption-by-space.png";
import spacesManagementPlaceholder from "../../../assets/placeholders/spaces-management.png";
import Flex from "../../../components/Flex";
import PageBottomPadding from "../../../components/PageBottomPadding";
import PageSectionTitle, {
  iPageSectionTitleProps,
} from "../../../components/PageSectionTitle";
import Segmentation from "../../../components/Segmentation";
import InterTag from "../../../components/Text/Inter";
import TopBar from "../../../components/TopBar";
import Unlock from "../../../components/Unlock";
import useHotelState from "../../../context/Hotel/hooks/useHotelState";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import useProfilePermissions from "../../../hooks/useProfilePermissions";
import useScreenSize from "../../../hooks/useScreenSize";
import { COLORS } from "../../../utils/colors";
import AccommodationMeasures from "./accommodationMeasures";
import AddButton from "./addButton";
import SpaceAggregateConsumption, {
  iSpaceAggregateConsumptionProps,
} from "./spaceAggregateConsumption";
import SpaceAggregateTab from "./spaceAggregateTab";
import SpacesConsumption, {
  iSpacesConsumptionProps,
} from "./spacesConsumption";
import SpacesTab from "./spacesTab";

type tTopLevelFilter = {
  key: string;
  label: string;
  count: number;
  pageSectionTitleProps: iPageSectionTitleProps;
  tableComponent: {
    Element: React.FC<any>;
    props: Record<string, any>;
  };
  consumptionComponent:
    | {
        Element: React.FC<iSpaceAggregateConsumptionProps>;
        props: iSpaceAggregateConsumptionProps;
      }
    | {
        Element: React.FC<iSpacesConsumptionProps>;
        props: iSpacesConsumptionProps;
      };
  extraComponents: { Component: React.FC<any>; key: string }[];
};

interface iProps {
  outletContainerWidth: number;
}

const SpacesWrapped: React.FC<iProps> = ({ outletContainerWidth }) => {
  const { trans } = useLocalizationState();
  const [topLevelFilterIndex, setTopLevelFilterIndex] = useState<number>(0);
  const {
    hotel: { spaces },
    hotelIsLoaded,
    spaceAggregatesByCategory,
  } = useHotelState();
  const profilePermissions = useProfilePermissions();

  const TOP_LEVEL_FILTERS: tTopLevelFilter[] = [];

  if (profilePermissions.readSpaces) {
    TOP_LEVEL_FILTERS.push({
      key: "spaces",
      label: trans("Spaces"),
      count: spaces.length,
      pageSectionTitleProps: {
        title: trans("Spaces Management"),
        description: trans("Organize and oversee your spaces."),
        icon: RoomPreferencesIcon,
      },
      tableComponent: {
        Element: SpacesTab,
        props: { outletContainerWidth },
      },
      consumptionComponent: {
        Element: SpacesConsumption,
        props: {},
      },
      extraComponents: [
        { Component: AccommodationMeasures, key: "accommodation-measures" },
      ],
    });
  }

  if (profilePermissions.readSpaceAggregates)
    TOP_LEVEL_FILTERS.push(
      {
        key: "groups",
        label: trans("Groups"),
        count: spaceAggregatesByCategory.group.length,
        pageSectionTitleProps: {
          title: trans("Groups Management"),
          description: trans("Organize and oversee your groups."),
          icon: RoomPreferencesIcon,
        },
        tableComponent: {
          Element: SpaceAggregateTab,
          props: {
            outletContainerWidth,
            category: "group",
          },
        },
        consumptionComponent: {
          Element: SpaceAggregateConsumption,
          props: {
            category: "group",
            pageSectionTitleProps: {
              title: trans("Consumption by Group"),
              description: trans(
                "Track resource usage across different groups"
              ),
              icon: WorkspaceIcon,
            },
          },
        },
        extraComponents: [],
      },
      {
        key: "zones",
        label: trans("Zones"),
        count: spaceAggregatesByCategory.zone.length,
        pageSectionTitleProps: {
          title: trans("Zones Management"),
          description: trans("Organize and oversee your zones."),
          icon: RoomPreferencesIcon,
        },
        tableComponent: {
          Element: SpaceAggregateTab,
          props: {
            outletContainerWidth,
            category: "zone",
          },
        },
        consumptionComponent: {
          Element: SpaceAggregateConsumption,
          props: {
            category: "zone",
            pageSectionTitleProps: {
              title: trans("Consumption by Zone"),
              description: trans("Track resource usage across different zones"),
              icon: StacksIcon,
            },
          },
        },
        extraComponents: [],
      },
      {
        key: "types",
        label: trans("Types"),
        count: spaceAggregatesByCategory.type.length,
        pageSectionTitleProps: {
          title: trans("Types Management"),
          description: trans("Organize and oversee your types."),
          icon: RoomPreferencesIcon,
        },
        tableComponent: {
          Element: SpaceAggregateTab,
          props: {
            outletContainerWidth,
            category: "type",
          },
        },
        consumptionComponent: {
          Element: SpaceAggregateConsumption,
          props: {
            category: "type",
            pageSectionTitleProps: {
              title: trans("Consumption by Type"),
              description: trans("Track resource usage across different types"),
              icon: CategoryIcon,
            },
          },
        },
        extraComponents: [],
      }
    );

  if (TOP_LEVEL_FILTERS.length === 0)
    return (
      <InterTag
        text={trans(
          "Not authorized to see spaces, space groups, space zones and space types"
        )}
      />
    );

  const segment = TOP_LEVEL_FILTERS[topLevelFilterIndex];
  const {
    pageSectionTitleProps,
    tableComponent: { Element: TableElement, props: tableProps },
    consumptionComponent: {
      Element: ConsumptionElement,
      props: consumptionProps,
    },
    extraComponents,
  } = segment;

  const onSelectTopLevelFilter = (key: string) => {
    setTopLevelFilterIndex(findIndex(TOP_LEVEL_FILTERS, (f) => f.key === key));
  };

  return (
    <Fragment>
      <Flex column gap={16}>
        <Flex row between>
          <Flex grow={1}>
            <Segmentation
              appearance="subtle"
              active={segment.key}
              onSelect={onSelectTopLevelFilter}
              options={TOP_LEVEL_FILTERS.map((f) => {
                const { key, label, count } = f;
                return {
                  key,
                  label: { text: label },
                  count: count,
                  disabled: !hotelIsLoaded,
                };
              })}
            />
          </Flex>
          <Flex
            middle
            style={{
              borderBottom: `2px ${COLORS.gray} solid`,
            }}
          >
            <AddButton />
          </Flex>
        </Flex>
        <Flex column gap={40}>
          {/* @ts-ignore */}
          <ConsumptionElement key={segment.key} {...consumptionProps} />
          <Flex column gap={16}>
            <PageSectionTitle {...pageSectionTitleProps} />
            <TableElement key={segment.key} {...tableProps} />
          </Flex>
          {extraComponents.map(({ Component, key }) => (
            <Component {...{ key }} />
          ))}
        </Flex>
      </Flex>
    </Fragment>
  );
};

const Spaces: React.FC = () => {
  const { trans } = useLocalizationState();
  const { outlet: oc } = useScreenSize();
  const { hotelLoading, hotelIsLoaded, activeSubscriptionType } =
    useHotelState();

  if (!oc) return null;

  const renderMain = () => {
    if (hotelLoading)
      return (
        <Flex middle center>
          <Loader size="lg" />
        </Flex>
      );

    if (hotelIsLoaded) {
      if (activeSubscriptionType === "starter")
        return (
          <>
            <Flex column gap={40}>
              <AccommodationMeasures />
              <Flex column gap={16}>
                <PageSectionTitle
                  title={trans("Consumption By Space")}
                  description={trans(
                    "Track resource usage accross different spaces."
                  )}
                  icon={MeetingRoomIcon}
                />
                <Unlock
                  image={bySpacePlaceholder}
                  title={trans("Streamline Your Space Management")}
                  description={[
                    trans(
                      "Efficiently organize, track, and manage all spaces within your hotel with our comprehensive Space Management feature. This powerful tool allows you to categorize spaces by Group, Zone, and Type, providing a holistic view of your property's layout and usage. Enhance operational efficiency by ensuring every area is optimally monitored and maintained."
                    ),
                  ]}
                  onClick={() => {}}
                  style={{ paddingBottom: "200px" }}
                />
              </Flex>
              <Flex column gap={16}>
                <PageSectionTitle
                  title={trans("Spaces Management")}
                  description={trans("Organize and oversee your spaces")}
                  icon={RoomPreferencesIcon}
                />
                <Unlock
                  image={spacesManagementPlaceholder}
                  title={trans(
                    "Enhance Your Sustainability Strategy with Advanced Visual Insights"
                  )}
                  description={[
                    trans(
                      "Deepen your understanding with our full-suite of dynamic graphical KPIs. See trends, pinpoint efficiencies, and make informed decisions with precision to elevate your hotel's environmental performance"
                    ),
                  ]}
                  onClick={() => {}}
                />
              </Flex>
            </Flex>
            <PageBottomPadding />
          </>
        );

      if (!oc) return null;
      return (
        <>
          <SpacesWrapped outletContainerWidth={oc.width} />
          <PageBottomPadding />
        </>
      );
    }
  };

  return (
    <div>
      <TopBar>
        <InterTag
          size={24}
          bold
          color={COLORS.secondary}
          text={trans("Spaces")}
        />
      </TopBar>
      {renderMain()}
    </div>
  );
};

export default Spaces;
