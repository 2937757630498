import axios from "axios";
import { findIndex, floor, min, omit, orderBy } from "lodash";
import React, { useEffect, useState } from "react";
import { Badge, BadgeProps, Table, TableProps, useToaster } from "rsuite";
import { ReactComponent as AvgPaceIcon } from "../../assets/icons/avg_pace.svg";
import { ReactComponent as CategoryIcon } from "../../assets/icons/category.svg";
import { ReactComponent as ConversionPathIcon } from "../../assets/icons/conversion_path.svg";
import { ReactComponent as FilterListIcon } from "../../assets/icons/filter_list.svg";
import { ReactComponent as NotesIcon } from "../../assets/icons/notes.svg";
import { ReactComponent as NumbersIcon } from "../../assets/icons/numbers.svg";
import { ReactComponent as SpeedIcon } from "../../assets/icons/speed.svg";
import { ReactComponent as TargetIcon } from "../../assets/icons/target.svg";
import roadmapBackground from "../../assets/placeholders/roadmap.png";
import useHotelSubscriptionState from "../../context/Hotel/hooks/hotelState/useHotelSubscriptionState";
import useHotelDispatch from "../../context/Hotel/hooks/useHotelDispatch";
import useHotelState from "../../context/Hotel/hooks/useHotelState";
import useLocalizationState from "../../context/Localization/hooks/useLocalizationState";
import useGetRequest from "../../hooks/apiRequests/useGetRequest";
import usePutRequest from "../../hooks/apiRequests/usePutRequest";
import useScreenSize from "../../hooks/useScreenSize";
import { tHotelRoadmap } from "../../models/hotel";
import { apiAddressV2 } from "../../utils/apiCall";
import { COLORS } from "../../utils/colors";
import {
  HOTEL_ROADMAP_STATUSES,
  hotelRoadmapStatusToLocalKey,
  ROADMAP_PHASES,
  ROADMAP_TYPES,
} from "../../utils/hotels/roadmap";
import { getErrorMessage } from "../../utils/httpResponses/others";
import { notification } from "../../utils/notifications";
import { getRoadmapDescription } from "../../utils/roadmap";
import { TABLE_HEADER_HEIGHT, TABLE_ROW_HEIGHT } from "../../utils/tables";
import PrimaryButton from "../Buttons/PrimaryButton";
import Flex from "../Flex";
import Icon from "../Icon";
import ModalSelect from "../Modals/ModalSelect";
import PageSectionTitle from "../PageSectionTitle";
import BaseCell from "../RsuiteWrapper/SimpleTable/SimpleCells/BaseCell";
import SimpleActionCell from "../RsuiteWrapper/SimpleTable/SimpleCells/SimpleActionCell";
import SimpleTextCell from "../RsuiteWrapper/SimpleTable/SimpleCells/SimpleTextCell";
import SimpleHeaderCell from "../RsuiteWrapper/SimpleTable/SimpleHeaderCell";
import InterTag from "../Text/Inter";
import Unlock from "../Unlock";
import FitlersDrawer from "./filtersDrawer";
import RoadmapDetailsDrawer from "./roadmapDetailsDrawer";
import {
  difficultyTranslation,
  statusTranslation,
  typeTranslation,
} from "./utils";

export type tRoadmapFilter = {
  type: tHotelRoadmap["type"][];
  order: [number | null, number | null];
  phase: tHotelRoadmap["phase"][];
  difficulty: tHotelRoadmap["difficulty"][];
  status: tHotelRoadmap["status"][];
};

interface iProps {
  outletContainerWidth: number;
  rootOutletContainerHeight: number;
  filterByType?: tHotelRoadmap["type"];
  tableHeight?(
    rootOutletContainerHeight: number,
    rowHeight: number,
    headerHeight: number,
    numberOfElements: number
  ): number;
}

const RoadmapWrapped: React.FC<iProps> = ({
  outletContainerWidth,
  rootOutletContainerHeight,
  filterByType: topLevelFilter,
  tableHeight = (
    rootOutletContainerHeight: number,
    rowHeight: number,
    headerHeight: number,
    numberOfElements: number
  ) =>
    min([
      450,
      (numberOfElements || 1) * TABLE_ROW_HEIGHT.M + TABLE_HEADER_HEIGHT,
    ]),
}) => {
  const toaster = useToaster();
  const { trans, language } = useLocalizationState();
  const { hotel, hotelId } = useHotelState();
  const { updateHotel } = useHotelDispatch();
  const [updateStatusModal, setUpdateStatusModal] = useState<
    | { open: false }
    | { open: true; roadmapItemId: string; options: tHotelRoadmap["status"][] }
  >({ open: false });
  const statusRequest = usePutRequest();
  const [hotelRoadmapDrawer, setHotelRoadmapDrawer] = useState<{
    index: number;
  }>({ index: -1 });
  const [roadmapTopLevelFiltered] = useState(
    topLevelFilter
      ? hotel.roadmap.filter((r) => r.type === topLevelFilter)
      : hotel.roadmap
  );

  const roadmapFiltersRequest = useGetRequest<{ list: tHotelRoadmap[] }>({
    list: [],
  });
  const orderedRoadmapRequest = useGetRequest<{ list: tHotelRoadmap[] }>({
    list: [],
  });

  const [{ sortColumn, sortType }, setSort] = useState<
    Pick<TableProps<tHotelRoadmap, string>, "sortType" | "sortColumn">
  >({ sortColumn: "order", sortType: "asc" });
  const [filtersState, setFiltersState] = useState<tRoadmapFilter>({
    type: topLevelFilter ? [topLevelFilter] : [...ROADMAP_TYPES],
    order: [null, null],
    phase: [...ROADMAP_PHASES],
    difficulty: ["low", "medium", "high"],
    status: [...HOTEL_ROADMAP_STATUSES],
  });
  const [filtersDrawer, setFiltersDrawer] = useState(false);

  const options = (dataKey: string, rowData: tHotelRoadmap) => {
    return [
      {
        key: "update",
        label: { text: trans("Update Status") },
        onClick() {
          setUpdateStatusModal({
            open: true,
            roadmapItemId: rowData._id,
            options: [...HOTEL_ROADMAP_STATUSES].filter(
              (v) => v !== rowData.status
            ),
          });
        },
      },
      {
        key: "details",
        label: { text: trans("View Details") },
        onClick() {
          const index = findIndex(
            orderedRoadmapRequest.data.list,
            (o) => o._id === rowData._id
          );
          setHotelRoadmapDrawer((prev) => ({ ...prev, index }));
        },
      },
    ];
  };

  useEffect(() => {
    new Promise<tHotelRoadmap[]>((resolve, reject) => {
      resolve(
        roadmapTopLevelFiltered.filter(
          ({ type, difficulty, status, phase }) => {
            return (
              filtersState.type.includes(type) &&
              filtersState.difficulty.includes(difficulty) &&
              filtersState.status.includes(status) &&
              filtersState.phase.includes(phase)
            );
          }
        )
      );
    }).then((list) => {
      roadmapFiltersRequest.resolve({ list });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    filtersState.difficulty,
    filtersState.phase,
    filtersState.status,
    filtersState.type,
    roadmapTopLevelFiltered,
  ]);

  useEffect(() => {
    orderedRoadmapRequest.pending();
    new Promise<tHotelRoadmap[]>((resolve, reject) => {
      if (sortColumn && sortType) {
        if (["type", "order", "phase"].includes(sortColumn))
          resolve(
            orderBy(roadmapFiltersRequest.data.list, [sortColumn], [sortType])
          );

        if (sortColumn === "difficulty") {
          resolve(
            orderBy(
              roadmapFiltersRequest.data.list.map((r) => ({
                ...r,
                difficultyNumbered:
                  r.difficulty === "low"
                    ? 0
                    : r.difficulty === "medium"
                    ? 1
                    : 2,
              })),
              ["difficultyNumbered"],
              [sortType]
            ).map((r) => omit(r, ["difficultyNumbered"]))
          );
        }
        if (sortColumn === "status") {
          resolve(
            orderBy(
              roadmapFiltersRequest.data.list.map((r) => ({
                ...r,
                statusNumbered:
                  r.status === "idle" ? 0 : r.status === "in progress" ? 1 : 2,
              })),
              ["statusNumbered"],
              [sortType]
            ).map((r) => omit(r, ["statusNumbered"]))
          );
        }
      }
      resolve(roadmapFiltersRequest.data.list);
    })
      .then((list) => {
        orderedRoadmapRequest.resolve({ list });
      })
      .catch((err) => {
        orderedRoadmapRequest.reject(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roadmapFiltersRequest.data.list, sortColumn, sortType]);

  const typeWidth = floor(0.15 * outletContainerWidth);
  const orderWidth = floor(0.1 * outletContainerWidth);
  const phaseWidth = floor(0.1 * outletContainerWidth);
  const difficultyWidth = floor(0.13 * outletContainerWidth);
  const statusWidth = floor(0.1 * outletContainerWidth);
  const actionWidth = floor(0.05 * outletContainerWidth);

  return (
    <>
      <FitlersDrawer
        showTypeFilter={topLevelFilter === undefined}
        initialValues={filtersState}
        open={filtersDrawer}
        onClose={() => setFiltersDrawer(false)}
        onConfirm={(filters) => {
          setFiltersState(filters);
          setFiltersDrawer(false);
        }}
      />
      <RoadmapDetailsDrawer
        item={
          hotelRoadmapDrawer.index >= 0
            ? orderedRoadmapRequest.data.list[hotelRoadmapDrawer.index]
            : null
        }
        onClose={() => {
          setHotelRoadmapDrawer((prev) => ({ ...prev, index: -1 }));
        }}
        onNext={() => {
          if (
            hotelRoadmapDrawer.index + 1 <
            orderedRoadmapRequest.data.list.length
          )
            setHotelRoadmapDrawer((prev) => ({
              ...prev,
              index: prev.index + 1,
            }));
        }}
        onPrevious={() => {
          if (hotelRoadmapDrawer.index > 0)
            setHotelRoadmapDrawer((prev) => ({
              ...prev,
              index: prev.index - 1,
            }));
        }}
        disabledOnPrevious={hotelRoadmapDrawer.index === 0}
        disabledOnNext={
          hotelRoadmapDrawer.index + 1 >= orderedRoadmapRequest.data.list.length
        }
      />
      {updateStatusModal.open && (
        <ModalSelect
          beforeConfirm={(value) => {
            if (!value) return trans("Select a status");
            return null;
          }}
          open={updateStatusModal.open}
          options={updateStatusModal.options.map((v) => ({
            label: trans(hotelRoadmapStatusToLocalKey(v)),
            value: v,
          }))}
          onClose={() => {
            setUpdateStatusModal({ open: false });
          }}
          onConfirm={(newStatus: tHotelRoadmap["status"]) => {
            const roadmapId = updateStatusModal.roadmapItemId;
            setUpdateStatusModal({ open: false });
            statusRequest.pending();

            axios
              .put(
                `${apiAddressV2(
                  false
                )}/v2/hotels/${hotelId}/roadmap/${roadmapId}/status`,
                { status: newStatus }
              )
              .then((res) => {
                const {
                  data: { hotel },
                } = res;
                updateHotel(hotelId, hotel);
                statusRequest.resolve();
              })
              .catch((err) => {
                const error = getErrorMessage(err, trans);
                statusRequest.reject(error);
                toaster.push(notification("error", error), {
                  placement: "topEnd",
                });
              });
          }}
          label={trans("Select Status")}
        />
      )}
      <Flex row right>
        <PrimaryButton
          onClick={() => setFiltersDrawer(true)}
          appearance="primary"
          label={{
            text: trans("Filter").toUpperCase(),
            color: COLORS.white,
            size: 12,
          }}
          icon={{
            Element: FilterListIcon,
            fill: COLORS.white,
            size: 20,
          }}
          size="sm"
        />
      </Flex>
      <div className="table-wrapper">
        <Table
          id="SUSTAINABILITY.TABLE"
          rowHeight={TABLE_ROW_HEIGHT.M}
          headerHeight={TABLE_HEADER_HEIGHT}
          data={orderedRoadmapRequest.data.list}
          height={tableHeight(
            rootOutletContainerHeight,
            TABLE_ROW_HEIGHT.M,
            TABLE_HEADER_HEIGHT,
            orderedRoadmapRequest.data.list.length
          )}
          loading={statusRequest.isLoading || orderedRoadmapRequest.isLoading}
          onSortColumn={(dataKey: string, sortType?: "desc" | "asc") => {
            setSort({ sortColumn: dataKey, sortType });
          }}
          {...{
            sortColumn,
            sortType,
          }}
        >
          <Table.Column sortable width={typeWidth}>
            <SimpleHeaderCell icon={CategoryIcon} name={trans("Type")} />
            <BaseCell dataKey="type">
              {(rowData: tHotelRoadmap) => {
                const { type } = rowData;
                const [color, label, icon] = typeTranslation(type);
                return (
                  <Flex
                    style={{
                      padding: "8px 12px",
                      borderRadius: "6px",
                      width: "fit-content",
                    }}
                    middle
                    row
                    gap={4}
                    color={color}
                  >
                    {icon && (
                      <Icon {...{ size: 14, fill: COLORS.white, ...icon }} />
                    )}
                    <InterTag
                      size={12}
                      color={COLORS.white}
                      text={`${label}`}
                    />
                  </Flex>
                );
              }}
            </BaseCell>
          </Table.Column>
          <Table.Column width={orderWidth} sortable>
            <SimpleHeaderCell icon={NumbersIcon} name={trans("Order")} />
            <SimpleTextCell dataKey="order" />
          </Table.Column>
          <Table.Column width={phaseWidth} sortable>
            <SimpleHeaderCell icon={TargetIcon} name={trans("Phase")} />
            <SimpleTextCell
              tooltipDisplay
              tooltipTextFunction={(rowData: tHotelRoadmap) => rowData.phase}
              dataKey="phase"
              textFunction={(rowData: tHotelRoadmap) =>
                rowData.phase.slice(0, rowData.phase.indexOf("."))
              }
            />
          </Table.Column>
          <Table.Column flexGrow={1}>
            <SimpleHeaderCell icon={NotesIcon} name={trans("Description")} />
            <SimpleTextCell
              textProps={() => ({
                style: { textWrap: "wrap" },
              })}
              dataKey="description"
              textFunction={(rowData: tHotelRoadmap) =>
                getRoadmapDescription(rowData, language)?.description
              }
            />
          </Table.Column>
          <Table.Column sortable width={difficultyWidth}>
            <SimpleHeaderCell icon={SpeedIcon} name={trans("Difficulty")} />
            <BaseCell dataKey="difficulty">
              {(rowData: tHotelRoadmap) => {
                const { difficulty } = rowData;
                const [color, label] = difficultyTranslation(difficulty);
                return (
                  <Flex middle row gap={8} style={{ height: "100%" }}>
                    <Badge color={color as BadgeProps["color"]} />
                    <InterTag
                      size={12}
                      color={COLORS.secondary}
                      text={`${label}`}
                    />
                  </Flex>
                );
              }}
            </BaseCell>
          </Table.Column>
          <Table.Column sortable width={statusWidth}>
            <SimpleHeaderCell icon={AvgPaceIcon} name={trans("Status")} />
            <BaseCell dataKey="status">
              {(rowData: tHotelRoadmap) => {
                const { status } = rowData;
                const [label, labelColor, color] = statusTranslation(status);
                return (
                  <Flex middle style={{ height: "100%" }}>
                    <Flex
                      style={{
                        borderRadius: "6px",
                        height: "fit-content",
                        padding: "8px 12px",
                      }}
                      color={color}
                    >
                      <InterTag
                        size={12}
                        {...{ text: label, color: labelColor }}
                      />
                    </Flex>
                  </Flex>
                );
              }}
            </BaseCell>
          </Table.Column>
          <Table.Column width={actionWidth} align="right">
            <SimpleHeaderCell name={{ text: "" }} />
            <SimpleActionCell options={options} dataKey="_id" />
          </Table.Column>
        </Table>
      </div>
    </>
  );
};

interface iRoadmapProps extends Pick<iProps, "filterByType" | "tableHeight"> {}

const Roadmap: React.FC<iRoadmapProps> = ({ filterByType, tableHeight }) => {
  const { trans } = useLocalizationState();
  const { outlet: o, "root-outlet-container": roc } = useScreenSize();
  const { activeSubscriptionIsStarter, hotelIsLoaded } =
    useHotelSubscriptionState();

  if (!hotelIsLoaded) return null;

  return (
    <Flex column gap={8}>
      <PageSectionTitle
        icon={ConversionPathIcon}
        title={trans("Roadmap")}
        description={trans(
          "Follow a structured path with actionable steps to enhance your accommodation's environmental stewardship."
        )}
      />
      {activeSubscriptionIsStarter ? (
        <Unlock
          image={roadmapBackground}
          title={trans("Unlock Your Path to Sustainability")}
          description={[
            trans(
              "The Roadmap feature provides a structured, step-by-step guide to achieving sustainability goals"
            ),
            trans(
              "Drawing from industry best practices, this actionable plan is designed to help streamline your sustainability efforts, ensuring you not only meet but exceed your environmental performance targets."
            ),
            trans(
              "To truly fit your unique operational needs, the roadmap allows you to create and customize actions based on your hotel's specific characteristics and priorities."
            ),
            trans(
              "Whether adjusting to seasonal variations, guest demographics, or specific sustainability challenges, our platform enables you to modify and adapt each step of your sustainability journey, ensuring maximum effectiveness and relevance."
            ),
          ]}
          onClick={() => {}}
        />
      ) : o ? (
        <RoadmapWrapped
          outletContainerWidth={o.width}
          rootOutletContainerHeight={roc.height}
          {...{ filterByType, tableHeight }}
        />
      ) : null}
    </Flex>
  );
};

export default React.memo(Roadmap);
