import { first, last, max } from "lodash";
import moment, { MomentInput } from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { Col, Grid, Row } from "rsuite";
import Flex from "../../../../components/Flex";
import SimpleDateRangePicker from "../../../../components/RsuiteWrapper/SimpleDateRangePicker";
import useHotelManualDataState from "../../../../context/Hotel/hooks/hotelState/useHotelManualDataState";
import ConsumptionCards from "./consumptionCards";
import OccupancyCard from "./occupancyCard";
import useHotelSubscriptionState from "../../../../context/Hotel/hooks/hotelState/useHotelSubscriptionState";
import CheckInCheckOut from "../checkInCheckOut";

interface iProps {
  dataTypeToggle: JSX.Element | null;
}

const Archives: React.FC<iProps> = ({ dataTypeToggle }) => {
  const {
    manualDataOrdered,
    hotel: { manualData },
  } = useHotelManualDataState();
  const { activeSubscriptionIsStarter } = useHotelSubscriptionState();
  const [manualDataIndex, setManualDataIndex] = useState(manualData.length - 1);

  useEffect(() => {
    if (manualDataIndex + 1 > manualData.length) setManualDataIndex(0);
  }, [manualData.length, manualDataIndex]);

  const selectedManualData = manualData[manualDataIndex];

  const availablePeriods = useMemo(() => {
    const periods: [MomentInput, MomentInput][] = [];

    for (const { from, to } of manualDataOrdered) {
      if (periods.length) {
        if (
          moment(last(periods)![1]).add(1, "day").format("DD/MM/YYYY") ===
          moment(from).format("DD/MM/YYYY")
        ) {
          periods[periods.length - 1][1] = to;
          continue;
        }
      }

      periods.push([from, to]);
    }

    return periods;
  }, [manualDataOrdered]);

  return (
    <Flex column gap={16}>
      <Flex row middle gap={12}>
        {dataTypeToggle}
        <SimpleDateRangePicker
          {...{
            ...(manualDataOrdered.length
              ? {
                  limitEndYear: max([
                    moment(last(manualDataOrdered)!.to).year() -
                      moment().year() +
                      1,
                    0,
                  ]),
                  limitStartYear:
                    moment().year() -
                    moment(first(manualDataOrdered)!.from).year() +
                    1,
                }
              : {}),
          }}
          showOneCalendar
          oneTap
          hoverRange={"month"}
          shouldDisableDate={(date, selectedDate, selectedDone, target) => {
            for (const [from, to] of availablePeriods) {
              if (
                moment(date).isSameOrAfter(moment(from)) &&
                moment(date).isSameOrBefore(moment(to))
              ) {
                return false;
              }
            }
            return true;
          }}
          onChange={(range) => {
            if (range) {
              for (const [i, { from, to }] of manualData.entries()) {
                if (
                  moment(range[0])
                    .startOf("day")
                    .isSame(moment(from).startOf("day")) &&
                  moment(range[1]).endOf("day").isSame(moment(to).endOf("day"))
                ) {
                  setManualDataIndex(i);
                }
              }
            }
          }}
          value={[selectedManualData.from, selectedManualData.to]}
          ranges={[]}
          cleanable={false}
        />
      </Flex>
      <div>
        <Grid fluid>
          <Row gutter={16}>
            {!activeSubscriptionIsStarter && (
              <Col sm={12} xxl={8}>
                <CheckInCheckOut
                  period={[
                    moment(selectedManualData.from).toDate(),
                    moment(selectedManualData.to).toDate(),
                  ]}
                />
              </Col>
            )}
            <Col sm={12} xxl={8}>
              <OccupancyCard manualDataEntry={selectedManualData} />
            </Col>
          </Row>
          <ConsumptionCards manualDataEntry={selectedManualData} />
        </Grid>
      </div>
    </Flex>
  );
};

export default Archives;
