import React, { useState } from "react";
import { Input, InputGroup, InputProps } from "rsuite";
import Icon from "../../Icon";
import { COLORS } from "../../../utils/colors";
import { ReactComponent as PasswordIcon } from "../../../assets/icons/password.svg";
import { ReactComponent as VisibilityIcon } from "../../../assets/icons/visibility.svg";
import { ReactComponent as VisibilityOffIcon } from "../../../assets/icons/visibility_off.svg";

interface iProps extends InputProps {}

const SimpleInputPassword: React.FC<iProps> = ({ ...props }) => {
  const [visibility, setVisibility] = useState(false);
  return (
    <InputGroup>
      <InputGroup.Addon>
        <Icon
          Element={PasswordIcon}
          width={24}
          height={24}
          fill={COLORS.secondary}
        />
      </InputGroup.Addon>
      <Input type={visibility ? "text" : "password"} {...{ ...props }} />
      <InputGroup.Button
        className={`hover-gray-100 bg-white`}
        onClick={() => setVisibility((prev) => !prev)}
      >
        {visibility ? (
          <Icon
            Element={VisibilityIcon}
            width={24}
            height={24}
            fill={COLORS.gray}
          />
        ) : (
          <Icon
            Element={VisibilityOffIcon}
            width={24}
            height={24}
            fill={COLORS.gray}
          />
        )}
      </InputGroup.Button>
    </InputGroup>
  );
};

export default SimpleInputPassword;
