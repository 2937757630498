import React from "react";
import Flex from "../../../components/Flex";
import PageBottomPadding from "../../../components/PageBottomPadding";
import InterTag from "../../../components/Text/Inter";
import TopBar from "../../../components/TopBar";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import { COLORS } from "../../../utils/colors";
import LoyaltyProgram from "./loyaltyProgram";
import PerformanceProgram from "./performanceProgram";
import useHotelState from "../../../context/Hotel/hooks/useHotelState";
import { Loader } from "rsuite";
import Unlock from "./unlock";

const LoyaltyWrapped: React.FC = () => {
  return (
    <Flex column gap={40}>
      <PerformanceProgram />
      <LoyaltyProgram />
    </Flex>
  );
};

const Loyalty: React.FC = () => {
  const { trans } = useLocalizationState();
  const { hotelLoading, hotelIsLoaded, activeSubscriptionType } =
    useHotelState();

  const renderMain = () => {
    if (hotelLoading)
      return (
        <Flex middle center>
          <Loader size="lg" />
        </Flex>
      );

    if (hotelIsLoaded) {
      if (activeSubscriptionType === "starter")
        return (
          <>
            <Unlock />
            <PageBottomPadding />
          </>
        );
      return (
        <>
          <LoyaltyWrapped />
          <PageBottomPadding />
        </>
      );
    }
  };

  return (
    <>
      <TopBar>
        <InterTag
          bold
          size={24}
          color={COLORS.secondary}
          text={trans("Loyalty")}
        />
      </TopBar>
      {renderMain()}
    </>
  );
};

export default Loyalty;
