import axios from "axios";
import { has, isUndefined, omit, sum, zip } from "lodash";
import moment, { MomentInput } from "moment";
import useHotelState from "../../../context/Hotel/hooks/hotelState/useHotelState";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import { tBinUnitSingular } from "../../../interfaces/sensorData";
import { tHotelSpaceId } from "../../../models/hotel";
import { MEASURES, tMeasure } from "../../../models/measures";
import { apiAddressV2, REQUEST_STATUS } from "../../../utils/apiCall";
import { calcNumberOfNights } from "../../../utils/dates";
import { getErrorMessage } from "../../../utils/httpResponses/others";
import {
  getCO2AndCostsPerMeasure,
  populateMeasuresObj,
} from "../../../utils/measures";
import useEffectSafe from "../../useEffectSafe";
import useGetRequest from "../useGetRequest";
import nUseGetMeasures from "./interfaces";

function useGetMeasures<T extends tMeasure[] = []>(
  {
    measures = MEASURES,
    spaces = [],
    from,
    to,
    binValue,
    binUnit,
    autoBinUnit = false,
  }: {
    measures: tMeasure[];
    spaces?: tHotelSpaceId[];
    from?: MomentInput;
    to?: MomentInput;
    binUnit?: tBinUnitSingular;
    binValue?: number;
    autoBinUnit?: boolean;
  } = {
    measures: MEASURES,
    spaces: [],
  },
  {
    doRequest = true,
    useLocalApi = false,
    id = undefined,
  }: {
    doRequest?: boolean;
    useLocalApi?: boolean;
    id?: string;
  } = {
    doRequest: true,
    useLocalApi: false,
    id: undefined,
  }
): nUseGetMeasures.tFunctionReturn<T> {
  const { trans } = useLocalizationState();
  const { hotelIsLoaded, hotel } = useHotelState();

  const measuresRequest = useGetRequest<
    nUseGetMeasures.tFunctionReturn<T>["data"]
  >(
    {
      // @ts-expect-error
      grouped: {},
      bySpace: {},
      co2: [],
      costs: [],
    },
    {
      ...(doRequest && measures.length ? {} : { status: REQUEST_STATUS.IDLE }),
    }
  );

  const measuresStringified = JSON.stringify(measures.sort());
  const spacesStringified = JSON.stringify(spaces.sort());

  useEffectSafe(() => {
    if (hotelIsLoaded && doRequest && measures.length) {
      const calcBinUnit = autoBinUnit
        ? (() => {
            const numebrOfNights = calcNumberOfNights(from, to);

            // if (numebrOfNights < )
          })()
        : binUnit;

      const params: Record<string, any> = {
        spaces,
        measures,
        binnedData: !isUndefined(binValue) && !isUndefined(binUnit),
        binValue,
        binUnit,
      };
      if (from) params.from = moment(from).toISOString();
      if (to) params.to = moment(to).toISOString();

      measuresRequest.pending();

      axios
        .get(
          `${apiAddressV2(useLocalApi || true)}/v2/hotels/${
            hotel._id
          }/consumption`,
          {
            params,
          }
        )
        .then((res) => {
          const {
            data: { dataBySpace },
          } = res;

          const groupedMeasurements: Partial<Record<tMeasure, number[]>> = {};
          Object.values(
            dataBySpace as Record<tHotelSpaceId, Record<tMeasure, number[]>>
          ).forEach((measures) => {
            Object.entries(measures).forEach((entry) => {
              const [measure, values] = entry as [tMeasure, number[]];
              if (!has(groupedMeasurements, measure))
                groupedMeasurements[measure] = [...values];
              else {
                groupedMeasurements[measure] = groupedMeasurements[
                  measure
                ]!.map((value, i) => value + values[i]);
              }
            });
          });

          const populated = populateMeasuresObj(
            omit(groupedMeasurements, ["co2", "costs"])
          );
          const co2AndCostsPerMeasure = getCO2AndCostsPerMeasure(
            hotel,
            { ...populated },
            from,
            to
          );

          const bySpace: nUseGetMeasures.tFunctionReturn<T>["data"]["bySpace"] =
            {};
          Object.keys(dataBySpace).forEach((spaceId: tHotelSpaceId) => {
            const co2AndCostsPerMeasure = getCO2AndCostsPerMeasure(
              hotel,
              { ...dataBySpace[spaceId] },
              from,
              to
            );

            // @ts-expect-error
            bySpace[spaceId] = Object.fromEntries(
              MEASURES.filter((m) => has(dataBySpace[spaceId], m)).map((m) => [
                m,
                {
                  ...co2AndCostsPerMeasure[m],
                  measurements: dataBySpace[spaceId][m],
                },
              ])
            );
          });

          const updatedState = {
            bySpace,
            grouped: Object.fromEntries(
              MEASURES.filter((m) => has(groupedMeasurements, m)).map((m) => [
                m,
                {
                  ...co2AndCostsPerMeasure[m],
                  measurements: groupedMeasurements[m],
                },
              ])
            ),
            costs: zip(
              co2AndCostsPerMeasure.te?.costs || [],
              co2AndCostsPerMeasure.tw?.costs || []
            ).map(([te, tw]) => sum([te || 0, tw || 0])),
            co2: zip(
              co2AndCostsPerMeasure.te?.co2 || [],
              co2AndCostsPerMeasure.tw?.co2 || []
            ).map(([te, tw]) => sum([te || 0, tw || 0])),
          };

          // @ts-expect-error
          measuresRequest.resolve(updatedState);
        })
        .catch((err) => {
          measuresRequest.reject(getErrorMessage(err, trans));
        });
    }
  }, [
    from,
    hotelIsLoaded,
    measuresStringified,
    spacesStringified,
    to,
    trans,
    binUnit,
    binValue,
    doRequest,
  ]);

  const { data, error, status, isFinal, isLoading, isRejected, isResolved } =
    measuresRequest;

  return { data, error, status, isFinal, isLoading, isRejected, isResolved };
}

export default useGetMeasures;
