import React from "react";
import { Panel, PanelGroup } from "rsuite";
import { ReactComponent as ApartmentIcon } from "../../../assets/icons/apartment.svg";
import { ReactComponent as LogoutIcon } from "../../../assets/icons/logout.svg";
import { ReactComponent as PaymentsIcon } from "../../../assets/icons/payments.svg";
import { ReactComponent as SettingsIcon } from "../../../assets/icons/settings.svg";
import { ReactComponent as SettingsAccountBoxIcon } from "../../../assets/icons/settings_account_box.svg";
import { ReactComponent as NoytrallSymbol } from "../../../assets/noytrall_symbol.svg";
import Flex from "../../../components/Flex";
import Icon from "../../../components/Icon";
import PageBottomPadding from "../../../components/PageBottomPadding";
import PageSectionTitle from "../../../components/PageSectionTitle";
import InterTag from "../../../components/Text/Inter";
import TopBar from "../../../components/TopBar";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import { tIconElement } from "../../../interfaces/icon";
import { COLORS } from "../../../utils/colors";
import AccommodationSettings from "./accommodation";
import BillingSettings from "./billing";
import GeneralSettings from "./general";
import PersonalSettings from "./personal";
import SessionSettings from "./session";
import styles from "./styles.module.css";

const Settings: React.FC = () => {
  const { trans } = useLocalizationState();

  const renderPanel = (
    title: string,
    description: string,
    icon: tIconElement,
    Component: React.FC,
    id: string
  ) => {
    return (
      <Panel
        id={id}
        defaultExpanded
        header={
          <PageSectionTitle
            style={{ width: "100%" }}
            title={title}
            description={description}
            icon={icon}
          />
        }
      >
        <div className={styles["settings-panel"]}>
          <Component />
        </div>
      </Panel>
    );
  };

  return (
    <div className={styles.settings}>
      <TopBar>
        <InterTag
          text={trans("Settings")}
          bold
          color={COLORS.secondary}
          size={24}
        />
      </TopBar>
      <Flex column gap={40}>
        <PanelGroup accordion bordered={false}>
          {renderPanel(
            trans("General"),
            trans("Manage basic platform settings and preferences"),
            SettingsIcon,
            GeneralSettings,
            "settings-general"
          )}
          {renderPanel(
            trans("Personal"),
            trans("Customize your personal profile and user experience"),
            SettingsAccountBoxIcon,
            PersonalSettings,
            "settings-personal"
          )}
          {renderPanel(
            trans("Accommodation"),
            trans("Customize your personal profile and user experience"),
            ApartmentIcon,
            AccommodationSettings,
            "settings-accommodation"
          )}
          {renderPanel(
            trans("Billing"),
            trans(
              "Review and manager your subscription, payment methods, an invoices."
            ),
            PaymentsIcon,
            BillingSettings,
            "settings-billing"
          )}
          {renderPanel(
            trans("Session"),
            trans(
              "Manage session settings, including timeout periods and active logins."
            ),
            LogoutIcon,
            SessionSettings,
            "settings-session"
          )}
        </PanelGroup>
        <Flex row gap={16} middle>
          <Icon Element={NoytrallSymbol} height={32} width={23.95} />
          <Flex column gap={4}>
            <InterTag
              text={trans("noytrall technologies")}
              size={12}
              color={COLORS.secondary}
            />
            <InterTag
              text={`${trans("version $0", { parameters: ["1.0.1"] })}`}
              size={12}
              color={COLORS.secondary}
            />
          </Flex>
        </Flex>
      </Flex>
      <PageBottomPadding />
    </div>
  );
};

export default Settings;
