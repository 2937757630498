import { isNull, replace, toString } from "lodash";
import React, { useCallback } from "react";
import { LocalizationStateContext } from "../";
import translation from "../translations";
import { tTrans } from "../utils";

const useLocalizationState = () => {
  const language = React.useContext(LocalizationStateContext);

  if (!language)
    throw Error(
      "useLocalizationState must be used within LocalizationStateContext"
    );

  const trans: tTrans = useCallback(
    (
      key: string,
      {
        lang,
        parameters = [],
      }: { lang?: string; parameters?: (string | number)[] } = {
        parameters: [],
      }
    ): string => {
      const l = lang || language;
      // @ts-ignore
      const word: string | null = (translation[l] || {})[key] || null;

      if (isNull(word) || !word) {
        for (let i = 0; i < parameters.length; i++) {
          const param = parameters[i];
          key = replace(key, `$${i}`, toString(param));
        }
        return key;
      }

      return word;
    },
    [language]
  );

  return { language, trans };
};

export default useLocalizationState;
