import React, { useContext, useEffect, useState } from "react";
import { Loader, Toggle } from "rsuite";
import Flex from "../../../components/Flex";
import ManualDataModal from "../../../components/Modals/ManualDataModal";
import PageBottomPadding from "../../../components/PageBottomPadding";
import InterTag from "../../../components/Text/Inter";
import TopBar from "../../../components/TopBar";
import useHotelState from "../../../context/Hotel/hooks/useHotelState";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import WithContextProvider from "../../../hoc/withProvider";
import { nRequestStatus } from "../../../interfaces/apiCalls";
import { REQUEST_STATUS } from "../../../utils/apiCall";
import { COLORS } from "../../../utils/colors";
import SustainabilityContextProvider, {
  SustainabilityContext,
} from "./context";
import ManualData from "./manualData";
import SensorData from "./sensorData";
import useHotelSubscriptionState from "../../../context/Hotel/hooks/hotelState/useHotelSubscriptionState";

interface iSustainabilityWrappedProps {}

const SustainabilityWrapped: React.FC<iSustainabilityWrappedProps> = () => {
  const { trans } = useLocalizationState();
  const { activeSubscriptionIsEnterprise } = useHotelSubscriptionState();
  const [dataTypeState, setDataTypeState] = useState<{
    status: nRequestStatus.tStatus;
    dataType: "archives" | "live" | null;
  }>({ status: REQUEST_STATUS.PENDING, dataType: null });
  const context = useContext(SustainabilityContext);

  useEffect(() => {
    if (activeSubscriptionIsEnterprise) {
      setTimeout(() => {
        const get = () => {
          const stored = localStorage.getItem("__s_dt__");

          switch (stored) {
            case "archives":
              return "archives";
            case "live":
              return "live";
          }
          return "archives";
        };
        const dataType = get();
        setDataTypeState({ status: REQUEST_STATUS.RESOLVED, dataType });
      }, 200);
    } else {
      setDataTypeState({
        status: REQUEST_STATUS.RESOLVED,
        dataType: "archives",
      });
    }
  }, [activeSubscriptionIsEnterprise]);

  const handleSetDataType = (dataType: "live" | "archives") => {
    setDataTypeState((prev) => ({ ...prev, dataType }));
    localStorage.setItem("__s_dt__", dataType);
  };

  const toggle = activeSubscriptionIsEnterprise ? (
    <Toggle
      loading={
        dataTypeState.status === REQUEST_STATUS.PENDING ||
        Object.values(context.state.dataTypeToggleDisabled).some((v) => v)
      }
      className={"toggle-data-type"}
      onChange={(checked) => {
        if (
          !(
            dataTypeState.status === REQUEST_STATUS.PENDING ||
            Object.values(context.state.dataTypeToggleDisabled).some((v) => v)
          )
        )
          handleSetDataType(checked ? "live" : "archives");
      }}
      checked={dataTypeState.dataType === "live"}
      checkedChildren={trans("Live")}
      unCheckedChildren={trans("Archives")}
    />
  ) : null;

  if (dataTypeState.status !== REQUEST_STATUS.RESOLVED) return null;

  return (
    <Flex column gap={40}>
      {dataTypeState.dataType === "live" ? (
        <SensorData dataTypeToggle={toggle} />
      ) : (
        <ManualData dataTypeToggle={toggle} />
      )}
    </Flex>
  );
};

const Sustainability: React.FC = () => {
  const { trans } = useLocalizationState();
  const { hotelIsLoaded } = useHotelState();
  const [manualDataModal, setManualDataModal] = useState(false);

  return (
    <>
      <ManualDataModal
        open={manualDataModal}
        onClose={() => {
          setManualDataModal(false);
        }}
      />
      <TopBar>
        <InterTag
          text={trans("Sustainability")}
          bold
          color={COLORS.secondary}
          size={24}
        />
      </TopBar>
      {hotelIsLoaded ? (
        <>
          <SustainabilityWrapped />
          <PageBottomPadding />
        </>
      ) : (
        <Flex middle center style={{ height: "70%" }}>
          <Loader size="lg" />
        </Flex>
      )}
    </>
  );
};

export default WithContextProvider(
  SustainabilityContextProvider,
  Sustainability
);
