import { configureMeasurements } from "./convert";
import measureEnergy from "./definitions/energy";
import massMeasure from "./definitions/mass";
import volumeMeasure from "./definitions/volume";
import { nUnitConverter } from "./interfaces";

export {
  Converter,
  IncompatibleUnitError,
  MeasureStructureError,
  OperationOrderError,
  UnknownMeasureError,
  UnknownUnitError,
  configureMeasurements,
} from "./convert";
export type {
  Anchor,
  BestResult,
  Conversion,
  Measure,
  Unit,
  UnitCache,
  UnitDescription,
} from "./convert";

export const DEFAULT_WATER_MEASURE_UNIT = "l";
export const FORCE_WATER_MEASURE_UNIT = "m3";
export const DEFAULT_ENERGY_MEASURE_UNIT = "Wh";
export const FORCE_ENERGY_MEASURE_UNIT = "kWh";
export const DEFAULT_MASS_MEASURE_UNIT = "kg";
export const FORCE_FOOTPRINT_MEASURE_UNIT = "kg";

const convertUnit = ({
  value,
  from,
  forceUnit,
  unitToHTML = (unit: any) => unit,
  measures,
}: {
  value: number;
  from: string;
  forceUnit?: string | null;
  unitToHTML?: (unit: string) => string;
  measures: any;
}) => {
  const converter = configureMeasurements(measures);
  let unit;
  let val;

  if (forceUnit && forceUnit.length) {
    val = converter(value).from(from).to(forceUnit);
    unit = forceUnit;
  } else {
    const obj = converter(value).from(from).toBest();
    if (!obj)
      return {
        string: "",
        unit: "",
        value: 0,
        unitInHTML: "",
        stringInHTML: "",
      };
    unit = obj.unit;
    val = obj.val;
  }

  let returnValue = val;

  const unitInHTML = unitToHTML(unit);

  const obj = {
    string: `${returnValue} ${unit}`,
    unit,
    value: returnValue,
    unitInHTML,
    stringInHTML: `${returnValue} ${unitInHTML}`,
  };

  return obj;
};

export const convertEnergyUnit = (
  value: number,
  {
    from = DEFAULT_ENERGY_MEASURE_UNIT,
    forceUnit = FORCE_ENERGY_MEASURE_UNIT,
  }: nUnitConverter.tUnitConverterFunctionParamsObj = {
    from: DEFAULT_ENERGY_MEASURE_UNIT,
    forceUnit: FORCE_ENERGY_MEASURE_UNIT,
  }
) => {
  return convertUnit({
    value,
    from,
    forceUnit,
    measures: { energy: measureEnergy },
  });
};
export const convertMassUnit = (
  value: number,
  {
    from = DEFAULT_MASS_MEASURE_UNIT,
    forceUnit = FORCE_FOOTPRINT_MEASURE_UNIT,
  }: nUnitConverter.tUnitConverterFunctionParamsObj = {
    from: DEFAULT_MASS_MEASURE_UNIT,
    forceUnit: FORCE_FOOTPRINT_MEASURE_UNIT,
  }
) => {
  const data = convertUnit({
    value,
    from,
    forceUnit,
    measures: { mass: massMeasure },
  });

  return { ...data };
};
export const convertVolumeUnit = (
  value: number,
  {
    from = DEFAULT_WATER_MEASURE_UNIT,
    forceUnit = FORCE_WATER_MEASURE_UNIT,
  }: nUnitConverter.tUnitConverterFunctionParamsObj = {
    from: DEFAULT_WATER_MEASURE_UNIT,
    forceUnit: FORCE_WATER_MEASURE_UNIT,
  }
) => {
  return convertUnit({
    value,
    from,
    forceUnit,
    measures: { volume: volumeMeasure },
    unitToHTML: (unit: string) => {
      if (unit === "cm3") return "cm<sup>3</sup>";
      if (unit === "dm3") return "dm<sup>3</sup>";
      if (unit === "m3") return "m<sup>3</sup>";
      if (unit === "km3") return "km<sup>3</sup>";
      if (unit === "l") return "L";
      return `${unit}`;
    },
  });
};
