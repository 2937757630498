import React, { Fragment, useEffect, useState } from "react";
import { Button } from "rsuite";
import Flex from "../../../../../components/Flex";
import InterTag from "../../../../../components/Text/Inter";
import useLocalizationState from "../../../../../context/Localization/hooks/useLocalizationState";
import useParseSearchParams from "../../../../../hooks/useParseSearchParams";
import { COLORS } from "../../../../../utils/colors";
import styles from "../../styles.module.css";
import AddPaymentMethodModal from "./addPaymentMethodModal";
import ManagePaymentMethodsModal from "./managePaymentMethodsModal";
import { useSearchParams } from "react-router-dom";
import queryString from "query-string";
import { omit } from "lodash";

const PaymentMethods: React.FC = () => {
  const { trans } = useLocalizationState();
  const [manageModal, setManageModal] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const [, setSearchParams] = useSearchParams();

  const searchParams = useParseSearchParams();

  useEffect(() => {
    if (searchParams.paymentMethods) setManageModal(true);
  }, [searchParams.paymentMethods]);

  return (
    <Fragment>
      <ManagePaymentMethodsModal
        openAddModal={() => {
          setManageModal(false);
          setAddModal(true);
        }}
        open={manageModal}
        onClose={() => {
          setSearchParams(
            queryString.stringify(omit(searchParams, ["paymentMethods"]))
          );
          setManageModal(false);
        }}
      />
      <AddPaymentMethodModal
        open={addModal}
        onClose={(action: "create" | undefined) => {
          setAddModal(false);
          setManageModal(true);
        }}
      />
      <Flex
        id="settings-billing-payment-methods"
        row
        between
        middle
        className={[
          styles["settings-item"],
          styles["settings-item--not-last"],
        ].join(" ")}
      >
        <InterTag
          size={14}
          bold
          text={trans("Payment Methods")}
          color={COLORS.secondary}
        />
        <Button
          appearance="subtle"
          className="inter"
          style={{ color: COLORS.primary }}
          onClick={() => setManageModal(true)}
        >
          {trans("Manage")}
        </Button>
      </Flex>
    </Fragment>
  );
};

export default PaymentMethods;
