import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Input, InputGroup, Loader, useToaster } from "rsuite";
import { ReactComponent as MailIcon } from "../../../assets/icons/mail.svg";
import { ReactComponent as PasswordIcon } from "../../../assets/icons/password.svg";
import { ReactComponent as VisibilityOn } from "../../../assets/icons/visibility.svg";
import { ReactComponent as VisibilityOff } from "../../../assets/icons/visibility_off.svg";
import Flex from "../../../components/Flex";
import Icon from "../../../components/Icon";
import InterTag from "../../../components/Text/Inter";
import useAuthDispatch from "../../../context/Auth/hooks/useAuthDispatch";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import useProfileDispatch from "../../../context/Profile/hooks/useProfileDispatch";
import useProfileState from "../../../context/Profile/hooks/useProfileState";
import { apiAddressV2 } from "../../../utils/apiCall";
import { COLORS } from "../../../utils/colors";
import { getErrorMessage } from "../../../utils/httpResponses/others";
import { notification } from "../../../utils/notifications";

const LoginWrapped: React.FC = () => {
  const navigate = useNavigate();
  const toaster = useToaster();
  const { trans } = useLocalizationState();

  const { login } = useAuthDispatch();
  const { setProfile } = useProfileDispatch();
  const { profileLoaded } = useProfileState();

  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [visibility, setVisibility] = useState(false);

  const [preToken, setPreToken] = useState<null | string>(null);

  useEffect(() => {
    if (preToken && profileLoaded) login(preToken);
  }, [preToken, profileLoaded, login]);

  const onClickForgot = () => {};

  const onClickLogin = () => {
    setLoading(true);
    axios
      .post(`${apiAddressV2(false)}/v2/managers/login`, { email, password })
      .then((res) => {
        const {
          data: { token, account },
        } = res;

        setProfile(account);
        setPreToken(token);
        toaster.push(notification("success", trans("Logged in")), {
          placement: "topEnd",
        });
      })
      .catch((err) => {
        const error = getErrorMessage(err, trans);
        toaster.push(notification("error", error), { placement: "topEnd" });
        setLoading(false);
      });
  };

  return (
    <Fragment>
      {loading && <Loader center backdrop size="lg" />}
      <Flex column middle gap={16}>
        <Flex
          column
          center
          style={{
            backgroundColor: COLORS.light_white,
            borderRadius: "16px",
            padding: "64px",
            width: "40%",
          }}
        >
          <Flex column center middle>
            <Flex gap={8} column middle>
              <InterTag
                bold
                text={trans("Great to see you again!")}
                size={32}
                color={COLORS.secondary}
              />
              <InterTag
                bold
                text={trans("Please sign in to continue")}
                size={32}
                color={COLORS.secondary}
              />
            </Flex>
            <div className="width-100" style={{ marginTop: "32px" }}>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  onClickLogin();
                }}
              >
                <Flex column gap={16}>
                  <Flex gap={4} className="width-100" column>
                    <InterTag
                      text={trans("Email")}
                      bold
                      color={COLORS.secondary}
                    />
                    <InputGroup>
                      <InputGroup.Addon>
                        <Icon
                          Element={MailIcon}
                          width={24}
                          height={24}
                          fill={COLORS.secondary}
                        />
                      </InputGroup.Addon>
                      <Input
                        value={email}
                        onChange={setEmail}
                        type="text"
                        placeholder={trans("user@youremail.com")}
                        size="lg"
                      />
                    </InputGroup>
                  </Flex>
                  <Flex gap={4} column className="width-100">
                    <InterTag
                      text={trans("Password")}
                      bold
                      color={COLORS.secondary}
                    />
                    <InputGroup>
                      <InputGroup.Addon>
                        <Icon
                          Element={PasswordIcon}
                          width={24}
                          height={24}
                          fill={COLORS.secondary}
                        />
                      </InputGroup.Addon>
                      <Input
                        value={password}
                        onChange={setPassword}
                        type={visibility ? "text" : "password"}
                        placeholder={trans("MySecureP@ssw0rd")}
                        size="lg"
                      />
                      <InputGroup.Button
                        className={`hover-gray-100 bg-white`}
                        onClick={() => setVisibility((prev) => !prev)}
                      >
                        {visibility ? (
                          <Icon
                            Element={VisibilityOn}
                            width={24}
                            height={24}
                            fill={COLORS.gray}
                          />
                        ) : (
                          <Icon
                            Element={VisibilityOff}
                            width={24}
                            height={24}
                            fill={COLORS.gray}
                          />
                        )}
                      </InputGroup.Button>
                    </InputGroup>
                  </Flex>
                  <Flex right style={{ marginTop: "4px" }}>
                    <InterTag
                      onClick={onClickForgot}
                      hoverUnderline
                      text={trans("Forgot your password?")}
                      color={COLORS.primary}
                      size={16}
                    />
                  </Flex>
                </Flex>
                <input type="submit" hidden />
              </form>
            </div>
            <div className="width-100" style={{ marginTop: "24px" }}>
              <Button onClick={onClickLogin} block appearance="primary">
                <InterTag
                  text={trans("Sign In")}
                  bold
                  color={COLORS.white}
                  size={20}
                />
              </Button>
            </div>
          </Flex>
        </Flex>
        <Flex row gap={4} center>
          <InterTag color={COLORS.white} text={trans("New to noytrall?")} />
          <InterTag
            color={COLORS.primary}
            text={trans("Sign up")}
            hoverUnderline
            onClick={() => {
              navigate("/register");
            }}
          />
          <InterTag color={COLORS.white} text={trans("today!")} />
        </Flex>
      </Flex>
    </Fragment>
  );
};

const Login: React.FC = () => {
  return <LoginWrapped />;
};

export default Login;
