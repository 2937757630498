import axios from "axios";
import { useEffect } from "react";
import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
import "rsuite/dist/rsuite.min.css";
import { AuthContextProvider } from "./context/Auth";
import useAuthState from "./context/Auth/hooks/useAuthState";
import { LocalizationContextProvider } from "./context/Localization";
import { ProfileContextProvider } from "./context/Profile";
import ActivitiesHistory from "./pages/Auth/activitiesHistory";
import Awards from "./pages/Auth/awards";
import Challenges from "./pages/Auth/challenges";
import Consumables from "./pages/Auth/consumables";
import Consumption from "./pages/Auth/consumption";
import Dashboard from "./pages/Auth/dashboard";
import Electricity from "./pages/Auth/electricity";
import Fleet from "./pages/Auth/fleet";
import Fuels from "./pages/Auth/fuels";
import GotoSetupPassword from "./pages/Auth/gotoSetupPassword";
import Guest from "./pages/Auth/guest";
import Laundry from "./pages/Auth/laundry";
import Loyalty from "./pages/Auth/loyalty";
import Occupancy from "./pages/Auth/occupancy";
import Reports from "./pages/Auth/reports";
import Reservation from "./pages/Auth/reservation";
import Reservations from "./pages/Auth/reservations";
import RootAuth from "./pages/Auth/root";
import Settings from "./pages/Auth/settings/";
import SettingsBillingInvoices from "./pages/Auth/settingsBillingInvoices";
import SettingsBillingPlan from "./pages/Auth/settingsBillingPlan";
import Space from "./pages/Auth/space";
import SpaceAggregate from "./pages/Auth/spaceAggregate";
import Spaces from "./pages/Auth/spaces";
import Sustainability from "./pages/Auth/sustainability";
import User from "./pages/Auth/user";
import Users from "./pages/Auth/users";
import Waste from "./pages/Auth/waste";
import Water from "./pages/Auth/water";
import Login from "./pages/noAuth/login";
import Register from "./pages/noAuth/register";
import RootNoAuth from "./pages/noAuth/root";
import SelectPlan from "./pages/noAuth/selectPlan";
import SetupPassword from "./pages/noAuth/setupPassword";
import "./styles/background.css";
import "./styles/global.css";
import "./styles/rsuite/index.css";
import { serverAddress } from "./utils/apiCall";

const routerNoAuth = createBrowserRouter([
  {
    path: "/",
    element: <RootNoAuth />,
    children: [
      { index: true, element: <Login /> },
      { path: "login", element: <Login /> },
      { path: "setup-password/:token", element: <SetupPassword /> },
      { path: "register", element: <Register /> },
      { path: "plan", element: <SelectPlan /> },
      { path: "*", element: <Navigate to="/login" replace /> },
    ],
  },
]);

const routerAuth = createBrowserRouter([
  {
    path: "/",
    element: <RootAuth />,
    children: [
      { path: "activities-history", element: <ActivitiesHistory /> },
      { path: "awards", element: <Awards /> },
      { path: "challenges", element: <Challenges /> },
      { path: "consumption", element: <Consumption /> },
      { path: "consumption/consumables", element: <Consumables /> },
      { path: "consumption/electricity", element: <Electricity /> },
      { path: "consumption/fleet", element: <Fleet /> },
      { path: "consumption/fuels", element: <Fuels /> },
      { path: "consumption/laundry", element: <Laundry /> },
      { path: "consumption/waste", element: <Waste /> },
      { path: "consumption/water", element: <Water /> },
      { path: "consumption/fleet", element: <Fleet /> },
      { path: "overview", element: <Dashboard /> },
      { path: "guests", element: <Reservations /> },
      { path: "guests/:idName", element: <Guest /> },
      { path: "loyalty", element: <Loyalty /> },
      { path: "occupancy", element: <Occupancy /> },
      { path: "reports", element: <Reports /> },
      { path: "reservations/:idCode", element: <Reservation /> },
      { path: "settings", element: <Settings /> },
      {
        path: "settings/billing/invoices",
        element: <SettingsBillingInvoices />,
      },
      { path: "settings/billing/plan", element: <SettingsBillingPlan /> },
      { path: "spaces", element: <Spaces /> },
      { path: "spaces/:spaceId", element: <Space /> },
      {
        path: "space-aggregates/:spaceAggregateId",
        element: <SpaceAggregate />,
      },
      { path: "users/:staffId", element: <User /> },
      { path: "users", element: <Users /> },
      { path: "sustainability", element: <Sustainability /> },
      { path: "setup-password/:token", element: <GotoSetupPassword /> },

      {
        index: true,
        element: <Dashboard />,
      },
      { path: "*", element: <Navigate to="/overview" replace /> },
    ],
  },
]);

const AppWrapped: React.FC = () => {
  const { token, isResolved } = useAuthState();
  // const { logout } = useAuthDispatch();

  // useEffect(() => {
  //   const stored = localStorage.getItem(LS_VERSION);
  //   if (stored !== "1") logout();
  // }, [logout]);

  if (!isResolved) return null;

  if (!token) return <RouterProvider router={routerNoAuth} />;

  return <RouterProvider router={routerAuth} />;
};

function App() {
  useEffect(() => {
    axios.defaults.baseURL = serverAddress();
  }, []);

  return (
    <div id="noytrall" style={{ height: "100%" }}>
      <LocalizationContextProvider>
        <AuthContextProvider>
          <ProfileContextProvider>
            <AppWrapped />
          </ProfileContextProvider>
        </AuthContextProvider>
      </LocalizationContextProvider>
    </div>
  );
}

export default App;
