import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "rsuite";
import Flex from "../../../components/Flex";
import DMSeriffTag from "../../../components/Text/DMSeriff";
import InterTag from "../../../components/Text/Inter";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import { COLORS } from "../../../utils/colors";

const Unlock: React.FC = () => {
  const navigate = useNavigate();
  const { trans } = useLocalizationState();

  return (
    <Flex column middle gap={32} style={{ margin: "0 100px" }}>
      <DMSeriffTag
        size={36}
        color={COLORS.secondary}
        text={trans("Boost Guest Loyalty with Customizable Reward Programs")}
        style={{ textWrap: "wrap", textAlign: "center" }}
        bold
      />
      {[
        "Unlock the full potential of guest loyalty at your hotel with our comprehensive 'Loyalty' feature, available exclusively for premium users.",
        "This powerful feature enables you to design and manage tailored loyalty programs that rewaerd guests based on their stays and engagement, driving repeat business and deepening guest relationships.",
      ].map((t) => (
        <InterTag
          size={20}
          color={COLORS.secondary}
          text={trans(t)}
          style={{ textWrap: "wrap", textAlign: "center" }}
        />
      ))}
      <Button
        style={{
          backgroundColor: COLORS.primary_900,
          color: COLORS.white,
          fontWeight: "bold",
        }}
        onClick={() => navigate("/settings/billing/plan")}
      >
        {trans("Upgrade Now")}
      </Button>
      {[
        {
          title: "Customizable Loyalty Programs",
          description:
            "Design loyalty programs that align perfectly with your hotel's branding and guest expectations, whether based on stay frquency, expenditure, or custom criteria.",
        },
        {
          title: "Enhanced Guest Retention",
          description:
            "Implement targeted rewards that encourage repeat visits and extend guest lifetime value, significantly boosting your hotel's occupancy and profitability.",
        },
        {
          title: "Data-Driven Insights",
          description:
            "Utilize advanced analytics to track the effectiveness of different loyalty initiatives, allowing you to refine and optimize your offerings for maximum impact.",
        },
      ].map(({ title, description }) => (
        <Flex column gap={16}>
          <DMSeriffTag
            text={trans(title)}
            color={COLORS.secondary}
            size={24}
            style={{ textWrap: "wrap", textAlign: "center" }}
            bold
          />
          <InterTag
            text={trans(description)}
            color={COLORS.secondary}
            size={20}
            style={{ textWrap: "wrap", textAlign: "center" }}
          />
        </Flex>
      ))}
    </Flex>
  );
};

export default Unlock;
