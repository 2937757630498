import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "rsuite";
import Flex from "../../../components/Flex";
import DMSeriffTag from "../../../components/Text/DMSeriff";
import InterTag from "../../../components/Text/Inter";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import { COLORS } from "../../../utils/colors";

const Unlock: React.FC = () => {
  const navigate = useNavigate();
  const { trans } = useLocalizationState();

  return (
    <Flex column middle gap={32} style={{ margin: "0 100px" }}>
      <DMSeriffTag
        size={36}
        color={COLORS.secondary}
        text={trans(
          "Optimize Your Energy Use with Comprehensive Fuel tracking"
        )}
        style={{ textWrap: "wrap", textAlign: "center" }}
        bold
      />
      {[
        "Take control of your hotel's energy management by accurately tracking different types of fuel consumption, such as natural gas, heating oil, or biomass",
        "Our Fuel Tracking feature provides a detailed analysis of usage accross your hotel's operations, helping you identify opportunities for cost reduction and enhance your sustainability efforts.",
      ].map((t) => (
        <InterTag
          size={20}
          color={COLORS.secondary}
          text={trans(t)}
          style={{ textWrap: "wrap", textAlign: "center" }}
        />
      ))}
      <Button
        style={{
          backgroundColor: COLORS.primary_900,
          color: COLORS.white,
          fontWeight: "bold",
        }}
        onClick={() => navigate("/settings/billing/plan")}
      >
        {trans("Upgrade Now")}
      </Button>
      {[
        {
          title: "Comprehensive Monitoring",
          description:
            "Gain insights into the consumption patterns of various fuels used within the hotel infrastructure, from boilers and heaters to kitchen facilities.",
        },
        {
          title: "Cost Efficiency",
          description:
            "Pinpoint areas of excessive use and potential leaks, and implement measures to reduce fuel consumption, directly impacting cost savings.",
        },
        {
          title: "Sustainability Improvement",
          description:
            "Detailed fuel usage data supports your sustainability objectives, aiding in the reduction of your carbon footprint and compliance with environemntal regulations.",
        },
      ].map(({ title, description }) => (
        <Flex column gap={16}>
          <DMSeriffTag
            text={trans(title)}
            color={COLORS.secondary}
            size={24}
            style={{ textWrap: "wrap", textAlign: "center" }}
            bold
          />
          <InterTag
            text={trans(description)}
            color={COLORS.secondary}
            size={20}
            style={{ textWrap: "wrap", textAlign: "center" }}
          />
        </Flex>
      ))}
    </Flex>
  );
};

export default Unlock;
