import React, { Fragment, useState } from "react";
import { ReactComponent as AddIcon } from "../../../../assets/icons/add.svg";
import { ReactComponent as GroupsIcon } from "../../../../assets/icons/groups.svg";
import { ReactComponent as PersonAddIcon } from "../../../../assets/icons/person_add.svg";
import PrimaryButton from "../../../../components/Buttons/PrimaryButton";
import SimpleWhisperPopoverDropdown, {
  tSimpleWhisperPopoverDropdownOptions,
} from "../../../../components/RsuiteWrapper/SimpleWhisperPopoverDropdown";
import useLocalizationState from "../../../../context/Localization/hooks/useLocalizationState";
import useListStaff from "../../../../hooks/useListStaff";
import useProfilePermissions from "../../../../hooks/useProfilePermissions";
import CreateEditRole from "./createEditRole";
import CreateEditUserModal from "./createEditUserModal";

interface iProps {
  staffRequest: ReturnType<typeof useListStaff>;
}

const AddButton: React.FC<iProps> = ({ staffRequest }) => {
  const { trans } = useLocalizationState();
  const [modalsState, setModalsState] = useState<{
    user: boolean;
    staffRoles: boolean;
  }>({ user: false, staffRoles: false });
  const profilePermissions = useProfilePermissions();

  const options: tSimpleWhisperPopoverDropdownOptions[] = [];

  if (profilePermissions.writeUsers)
    options.push({
      key: "users",
      label: trans("User"),
      onClick() {
        setModalsState((prev) => ({ ...prev, user: true, staffRoles: false }));
      },
      icon: PersonAddIcon,
    });

  if (profilePermissions.writeRoles)
    options.push({
      key: "roles",
      label: trans("Role"),
      onClick() {
        setModalsState((prev) => ({ ...prev, user: false, staffRoles: true }));
      },
      icon: GroupsIcon,
    });

  if (options.length === 0) return null;

  return (
    <Fragment>
      <CreateEditUserModal
        open={modalsState.user}
        onClose={() => setModalsState((prev) => ({ ...prev, user: false }))}
      />
      <CreateEditRole
        staffRequest={staffRequest}
        open={modalsState.staffRoles}
        onClose={() =>
          setModalsState((prev) => ({ ...prev, staffRoles: false }))
        }
      />
      <SimpleWhisperPopoverDropdown options={options} placement="bottomEnd">
        <PrimaryButton
          label={trans("Add").toUpperCase()}
          icon={AddIcon}
          size="sm"
        />
      </SimpleWhisperPopoverDropdown>
    </Fragment>
  );
};

export default AddButton;
