import React from "react";
import { Button, Modal, ModalProps } from "rsuite";
import { ReactComponent as BuildIcon } from "../../assets/icons/build.svg";
import useLocalizationState from "../../context/Localization/hooks/useLocalizationState";
import { COLORS } from "../../utils/colors";
import Flex from "../Flex";
import Icon from "../Icon";
import InterTag from "../Text/Inter";

interface iInitModalWrappedProps
  extends Pick<iProps, "onClose" | "onContinue"> {}

const InitModalWrapped: React.FC<iInitModalWrappedProps> = ({
  onClose,
  onContinue,
}) => {
  const { trans } = useLocalizationState();

  return (
    <>
      <Modal.Header></Modal.Header>
      <Modal.Body>
        <Flex column gap={8}>
          <InterTag
            text={trans("Welcome to noytrall!")}
            bold
            color={COLORS.secondary}
            size={32}
          />
          <InterTag
            style={{ textWrap: "wrap", lineHeight: "24px", marginTop: "6px" }}
            text={trans(
              "Congratulations on setting up your noytrall account! You've taken the first step torwards sustainable hotel management. We're excited to help you make a positive environmental impact"
            )}
            color={COLORS.secondary}
            size={16}
          />
          <div
            style={{
              width: "100%",
              height: "1px",
              margin: "6px 0",
              backgroundColor: COLORS.gray_400,
            }}
          />
          <InterTag
            style={{ textWrap: "wrap", lineHeight: "24px" }}
            text={trans(
              "To get started, we recomment going though our quick setuo wizard. It is specially designed to guide you though a comprehensive sustainability evaluation of your hotel."
            )}
            color={COLORS.secondary}
            size={16}
          />
          <InterTag
            style={{ textWrap: "wrap", lineHeight: "24px" }}
            text={trans(
              "Our setup wizard is an easy way to understand and input the essential information needed for an accurate sustainability analysis"
            )}
            color={COLORS.secondary}
            size={16}
          />
          <InterTag
            style={{ textWrap: "wrap", lineHeight: "24px" }}
            text={trans(
              "By completing this step-by-step guide, you'll lay the groundwork for tracking and improving your hotels's environment footprint. This is where your journey to sustainability gets real and measurable."
            )}
            color={COLORS.secondary}
            size={16}
          />
        </Flex>
        <Flex row gap={40} style={{ marginTop: "24px" }}>
          <div>
            <InterTag
              style={{ textWrap: "wrap", lineHeight: "24px" }}
              text={trans(
                "Let's dive in and start making a difference. Step by Step, we'll show you how to assess and input your hotel's sustainability data."
              )}
              color={COLORS.secondary}
              size={16}
            />
          </div>
          <div>
            <Button appearance="primary" onClick={onContinue}>
              <Flex middle row gap={8}>
                <InterTag
                  size={20}
                  color={COLORS.white}
                  bold
                  text={trans("Start Now")}
                />
                <Icon Element={BuildIcon} size={24} fill={COLORS.white} />
              </Flex>
            </Button>
          </div>
        </Flex>
        <div
          style={{
            width: "100%",
            height: "1px",
            margin: "16px 0",
            backgroundColor: COLORS.gray_400,
          }}
        />
        <Flex row gap={30}>
          <div>
            <InterTag
              style={{ textWrap: "wrap", lineHeight: "24px" }}
              text={trans(
                "Prefer to look around first? Feel free to explore. You can start the sustainability evaluation anytime from the dashboard."
              )}
              color={COLORS.secondary}
              size={12}
            />
          </div>
          <div>
            <Button appearance="link" onClick={onClose}>
              <InterTag
                size={20}
                color={COLORS.primary}
                text={trans("Skip and explore on your own")}
              />
            </Button>
          </div>
        </Flex>
      </Modal.Body>
    </>
  );
};

interface iProps extends ModalProps {
  onClose(): void;
  onContinue(): void;
}

const InitModal: React.FC<iProps> = ({ onClose, onContinue, ...rest }) => {
  return (
    <Modal
      {...{
        onClose,
        size: "md",
        overflow: false,
        keyboard: false,
        backdrop: "static",
        ...rest,
      }}
    >
      <InitModalWrapped {...{ onClose, onContinue }} />
    </Modal>
  );
};

export default InitModal;
