import { find } from "lodash";
import React, { useMemo } from "react";
import { HotelStateContext } from "../../";
import tHotel, { tHotelSubscriptionModel } from "../../../../models/hotel";
import useHotelState from "./useHotelState";

const useHotelSubscriptionState = () => {
  const state = React.useContext(HotelStateContext);

  if (!state)
    throw Error("useHotelState must be used within HotelStateContext");

  const { errorLoading, hotel, hotelId, hotelIsLoaded, hotelLoading } =
    useHotelState();

  const activeSubscription: tHotelSubscriptionModel | undefined = find(
    hotel.subscriptions,
    (s) => s.active
  );
  const activeSubscriptionType: tHotelSubscriptionModel["type"] =
    activeSubscription?.type || "starter";

  const activeSubscriptionIsStarter = activeSubscriptionType === "starter";
  const activeSubscriptionIsBusiness = activeSubscriptionType === "business";
  const activeSubscriptionIsEnterprise =
    activeSubscriptionType === "enterprise";

  const activeBillingInfo: tHotel["billing"][0] | undefined = useMemo(() => {
    return find(hotel.billing, (b) => b.active);
  }, [hotel.billing]);

  return {
    hotelIsLoaded,
    hotelLoading,
    errorLoading,
    hotel,
    hotelId,
    activeSubscription,
    activeSubscriptionType,
    activeBillingInfo,
    activeSubscriptionIsStarter,
    activeSubscriptionIsBusiness,
    activeSubscriptionIsEnterprise,
  };
};

export default useHotelSubscriptionState;
