import {
  nRequestStatus,
  tDeleteRequest,
  tGetRequest,
  tPostRequest,
  tPutRequest,
} from "../interfaces/apiCalls";

// const IPV4 = "192.168.1.192";
const IPV4 = "127.0.0.1";
// const IPV4 = "192.168.1.160";

export const LOCAL_NODE_SERVER = `http://${IPV4}:5000`;
export const SERVERLESS_SERVER =
  "https://54bks5i4g4.execute-api.eu-west-1.amazonaws.com";

export const LOCAL_NODE_SERVER_V2 = `http://${IPV4}:5001`;
export const SERVERLESS_SERVER_V2 =
  "https://xzj3frhsdk.execute-api.eu-west-1.amazonaws.com";

export const serverAddress = () => {
  if (window.location.hostname !== "localhost") return SERVERLESS_SERVER;

  // return LOCAL_NODE_SERVER;
  return SERVERLESS_SERVER;
};

export const apiAddress = (useLocalApi = true) => {
  if (!useLocalApi) return SERVERLESS_SERVER;
  if (window.location.hostname !== "localhost") return SERVERLESS_SERVER;

  return LOCAL_NODE_SERVER;
};

export const apiAddressV2 = (useLocalApi = true) => {
  if (!useLocalApi) return SERVERLESS_SERVER_V2;
  if (window.location.hostname !== "localhost") return SERVERLESS_SERVER_V2;

  return LOCAL_NODE_SERVER_V2;
};

export const fakeApiCall = (
  data: any,
  { timeout = 1000 }: { timeout?: number } = {}
): Promise<any> => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      return resolve(data);
    }, timeout);
  });
};

export const REQUEST_STATUS: {
  IDLE: nRequestStatus.tIdle;
  PENDING: nRequestStatus.tPending;
  RESOLVED: nRequestStatus.tResolved;
  REJECTED: nRequestStatus.tRejected;
} = {
  IDLE: "idle",
  PENDING: "pending",
  RESOLVED: "resolved",
  REJECTED: "rejected",
};

export const requestStatusIsFinal = (
  requestStatus: nRequestStatus.tStatus
): boolean => {
  return (
    [
      REQUEST_STATUS.RESOLVED,
      REQUEST_STATUS.REJECTED,
    ] as nRequestStatus.tStatus[]
  ).includes(requestStatus);
};

export const requestStatusIsLoading = (
  requestStatus: nRequestStatus.tStatus
) => {
  return requestStatus === REQUEST_STATUS.PENDING;
};

export const setGetRequestStateResolved = (
  setState: React.Dispatch<React.SetStateAction<tGetRequest<any>>>,
  data: any
) => {
  setState({
    data,
    error: null,
    status: REQUEST_STATUS.RESOLVED,
  });
};

export const setRequestStateRejected = (
  setState:
    | React.Dispatch<React.SetStateAction<tGetRequest<any>>>
    | React.Dispatch<React.SetStateAction<tPutRequest>>
    | React.Dispatch<React.SetStateAction<tPostRequest>>
    | React.Dispatch<React.SetStateAction<tDeleteRequest>>,
  error?: string | null
) => {
  setState((prev: any) => ({
    ...prev,
    error: error || null,
    status: REQUEST_STATUS.REJECTED,
  }));
};

export const setRequestStateResolved = (
  setState:
    | React.Dispatch<React.SetStateAction<tGetRequest<any>>>
    | React.Dispatch<React.SetStateAction<tPutRequest>>
    | React.Dispatch<React.SetStateAction<tPostRequest>>
    | React.Dispatch<React.SetStateAction<tDeleteRequest>>
) => {
  setState((prev: any) => ({
    ...prev,
    error: null,
    status: REQUEST_STATUS.RESOLVED,
  }));
};

export const setRequestStatePending = (
  setState:
    | React.Dispatch<React.SetStateAction<tGetRequest<any>>>
    | React.Dispatch<React.SetStateAction<tPutRequest>>
    | React.Dispatch<React.SetStateAction<tPostRequest>>
    | React.Dispatch<React.SetStateAction<tDeleteRequest>>
) => {
  setState((prev: any) => ({
    ...prev,
    status: REQUEST_STATUS.PENDING,
  }));
};
