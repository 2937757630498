import React, { useContext, useEffect, useState } from "react";
import { Button } from "rsuite";
import { ReactComponent as HotelIcon } from "../../../../assets/icons/hotel.svg";
import { ReactComponent as InsightsIcon } from "../../../../assets/icons/insights.svg";
import Flex from "../../../../components/Flex";
import ManualDataModal from "../../../../components/Modals/ManualDataModal";
import PageBottomPadding from "../../../../components/PageBottomPadding";
import PageSectionTitle from "../../../../components/PageSectionTitle";
import useHotelState from "../../../../context/Hotel/hooks/useHotelState";
import useLocalizationState from "../../../../context/Localization/hooks/useLocalizationState";
import { tProfilePermissions } from "../../../../hooks/useProfilePermissions";
import { COLORS } from "../../../../utils/colors";
import { OccupancyContext } from "../context";
import Graph from "./graph";
import List from "./list";

interface iProps {
  dataTypeToggle: JSX.Element;
  profilePermissions: tProfilePermissions;
}

const Archives: React.FC<iProps> = ({ dataTypeToggle, profilePermissions }) => {
  const { trans } = useLocalizationState();
  const { hotelLoading } = useHotelState();
  const { setDataTypeToggleDisabled } = useContext(OccupancyContext);
  const [manualDataModal, setManualDataModal] = useState({ open: false });

  useEffect(() => {
    setDataTypeToggleDisabled({ hotel: hotelLoading });
  }, [hotelLoading, setDataTypeToggleDisabled]);

  return (
    <div>
      {profilePermissions.writeManualData && (
        <ManualDataModal
          open={manualDataModal.open}
          initialStep="occupancy"
          singleStep
          onClose={() => setManualDataModal({ open: false })}
        />
      )}
      <div
        style={{
          position: "fixed",
          top: 70,
          width: "100%",
          zIndex: 3,
          paddingBottom: "8px",
          backgroundColor: COLORS.app_background,
        }}
      >
        <Flex row middle gap={12} style={{ height: "36px" }}>
          {dataTypeToggle}
          {profilePermissions.writeManualData && (
            <Button
              appearance="primary"
              onClick={() => setManualDataModal({ open: true })}
            >
              {trans("Add Entry")}
            </Button>
          )}
        </Flex>
      </div>
      <Flex column gap={40} style={{ marginTop: "40px" }}>
        <Flex column gap={16}>
          <PageSectionTitle
            title={trans("Data")}
            description={trans(
              "Analyze how your guest numbers have changed in time."
            )}
            icon={InsightsIcon}
          />
          <Graph />
        </Flex>
        <Flex column gap={16}>
          <PageSectionTitle
            title={trans("History")}
            description={trans(
              "All your occupancy figures, sorted and ready for action."
            )}
            icon={HotelIcon}
          />
          <List />
        </Flex>
      </Flex>
      <PageBottomPadding />
    </div>
  );
};

export default Archives;
