import React, { useCallback } from "react";
import { ReactComponent as HotelIcon } from "../../../../assets/icons/hotel.svg";
import { ReactComponent as HelpIcon } from "../../../../assets/icons/help.svg";
import useLocalizationState from "../../../../context/Localization/hooks/useLocalizationState";
import { useNavigate } from "react-router-dom";
import useGetRequest, {
  tUseGetRequest,
} from "../../../../hooks/apiRequests/useGetRequest";
import { tHotelId, tHotelSpaceId } from "../../../../models/hotel";
import moment, { MomentInput } from "moment";
import useHotelSubscriptionState from "../../../../context/Hotel/hooks/hotelState/useHotelSubscriptionState";
import axios from "axios";
import { apiAddressV2 } from "../../../../utils/apiCall";
import { getErrorMessage } from "../../../../utils/httpResponses/others";
import Flex from "../../../../components/Flex";
import { Tooltip, Whisper } from "rsuite";
import Icon from "../../../../components/Icon";
import { COLORS } from "../../../../utils/colors";
import InterTag from "../../../../components/Text/Inter";
import { renderRequestedData } from "../../../../utils/render";
import SkeletonText from "../../../../components/Skeleton/SkeletonText";
import TrendingComparison from "../../../../components/Comparions/trendingComparison";
import useEffectSafe from "../../../../hooks/useEffectSafe";
import { round } from "lodash";
import { thousandsSeparation } from "../../../../utils/numbers";

type tOccupancyData = {
  numberOfGuests: number;
  occupancy: number;
  spacesOccupied: tHotelSpaceId[];
};

interface iProps {
  period: [Date, Date];
}

const OccupancyCard: React.FC<iProps> = ({ period }) => {
  const navigate = useNavigate();
  const { trans } = useLocalizationState();
  const { hotelId, activeSubscriptionType, hotelIsLoaded } =
    useHotelSubscriptionState();

  const occupancyRequest = useGetRequest<tOccupancyData>({
    numberOfGuests: 0,
    occupancy: 0,
    spacesOccupied: [],
  });
  const homologueOccupancyRequest = useGetRequest<tOccupancyData>({
    numberOfGuests: 0,
    occupancy: 0,
    spacesOccupied: [],
  });

  const runOccupancy = useCallback(
    (
      request: tUseGetRequest<tOccupancyData>,
      hotelId: tHotelId,
      ...period: [MomentInput, MomentInput]
    ) => {
      if (!hotelIsLoaded) return;
      if (activeSubscriptionType === "starter") return;
      request.pending();
      axios
        .get(
          `${apiAddressV2(
            false
          )}/v2/hotels/${hotelId}/occupancy/guests-spaces-occupancy`,
          {
            params: {
              from: moment(period[0]).toISOString(),
              to: moment(period[1]).toISOString(),
            },
          }
        )
        .then((res) => {
          const {
            data: { data },
          } = res;
          request.resolve(data);
        })
        .catch((err) => {
          const error = getErrorMessage(err, trans);
          request.reject(error);
        });
    },
    [hotelIsLoaded, activeSubscriptionType, trans]
  );

  const runOccupancyMain = useCallback(
    (hotelId: tHotelId, ...period: [MomentInput, MomentInput]) => {
      runOccupancy(occupancyRequest, hotelId, ...period);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [trans]
  );

  const runOccupancyHomologue = useCallback(
    (hotelId: tHotelId, ...period: [MomentInput, MomentInput]) => {
      runOccupancy(homologueOccupancyRequest, hotelId, ...period);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [trans]
  );

  useEffectSafe(() => {
    if (hotelId) {
      const periodArgs: [MomentInput, MomentInput] = [
        moment(period[0]).toISOString(),
        moment(period[1]).toISOString(),
      ];
      const homologuePeriodArgs: [MomentInput, MomentInput] = [
        moment(period[0]).subtract(1, "year").toISOString(),
        moment(period[1]).subtract(1, "year").toISOString(),
      ];

      runOccupancyMain(hotelId, ...periodArgs);
      runOccupancyHomologue(hotelId, ...homologuePeriodArgs);
    }
  }, [hotelId, period, trans]);

  const list: {
    key: keyof Pick<tOccupancyData, "numberOfGuests" | "occupancy">;
    label: string;
    transformer(value: number): number;
    unit?: string;
  }[] = [
    { key: "numberOfGuests", label: trans("Guests"), transformer: (v) => v },
    {
      key: "occupancy",
      label: trans("Occupancy"),
      transformer: (v) => v * 100,
      unit: "%",
    },
  ];

  return (
    <Flex
      style={{ cursor: "pointer" }}
      column
      className="card-m hover-box-shadow"
      gap={20}
      onClick={() => {
        navigate("/occupancy");
      }}
    >
      <Whisper
        speaker={
          <Tooltip>
            {trans("Check out days are not taken into account")}
          </Tooltip>
        }
        trigger="hover"
        placement="right"
      >
        <Flex row left middle gap={8} style={{ width: "fit-content" }}>
          <Icon {...{ fill: COLORS.secondary, size: 24, Element: HotelIcon }} />
          <Flex row gap={2}>
            <InterTag
              text={trans("Occupancy")}
              size={20}
              color={COLORS.secondary}
            />
            <Icon Element={HelpIcon} fill={COLORS.secondary} size={12} />
          </Flex>
        </Flex>
      </Whisper>
      {renderRequestedData(occupancyRequest.status, {
        pending: () => (
          <Flex row around>
            {list.map(({ key, label }) => {
              return (
                <Flex key={key} column gap={4}>
                  <InterTag text={label} size={12} color={COLORS.gray_400} />
                  <SkeletonText height={36} width={100} />
                  <SkeletonText height={16} width={100} />
                </Flex>
              );
            })}
          </Flex>
        ),
        resolved: () => (
          <Flex row around>
            {list.map(({ key, label, transformer, unit }) => {
              const value = occupancyRequest.data[key];
              const transformed = transformer(value);

              return (
                <Flex key={key} column gap={4}>
                  <InterTag text={label} size={12} color={COLORS.gray_400} />
                  <Flex row gap={4} bottom>
                    <InterTag
                      size={36}
                      color={COLORS.secondary}
                      text={thousandsSeparation(round(transformed, 2))}
                    />
                    {unit && (
                      <InterTag
                        size={16}
                        color={COLORS.secondary}
                        text={unit}
                      />
                    )}
                  </Flex>
                  {renderRequestedData(homologueOccupancyRequest.status, {
                    pending: () => <SkeletonText height={16} width={100} />,
                    resolved: () => (
                      <TrendingComparison
                        baseValue={homologueOccupancyRequest.data[key]}
                        value={value}
                      />
                    ),
                  })}
                </Flex>
              );
            })}
          </Flex>
        ),
      })}
    </Flex>
  );
};

export default OccupancyCard;
