import axios from "axios";
import { floor, has, min, pick, round, some, sum } from "lodash";
import React from "react";
import { useToaster } from "rsuite";
import { ReactComponent as RoomPreferencesIcon } from "../../../../assets/icons/room_preferences.svg";
import Flex from "../../../../components/Flex";
import PageSectionTitle from "../../../../components/PageSectionTitle";
import useHotelDispatch from "../../../../context/Hotel/hooks/useHotelDispatch";
import useHotelState from "../../../../context/Hotel/hooks/useHotelState";
import useLocalizationState from "../../../../context/Localization/hooks/useLocalizationState";
import usePostRequest from "../../../../hooks/apiRequests/usePostRequest";
import useScreenSize from "../../../../hooks/useScreenSize";
import { tGetRequest } from "../../../../interfaces/apiCalls";
import {
  tHotelSpace,
  tHotelSpaceAggregate,
  tHotelSpaceId,
} from "../../../../models/hotel";
import { apiAddressV2 } from "../../../../utils/apiCall";
import { COLORS } from "../../../../utils/colors";
import { getErrorMessage } from "../../../../utils/httpResponses/others";
import { notification } from "../../../../utils/notifications";
import { tData } from "../spaceAggregate";
import Renderer, { tTableDataItem } from "./renderer";

interface iListWrappedProps
  extends Pick<iProps, "measuresRequest" | "spaceAggregate"> {
  outletContainerWidth: number;
}

const ListWrapped: React.FC<iListWrappedProps> = ({
  measuresRequest,
  outletContainerWidth,
  spaceAggregate,
}) => {
  const toaster = useToaster();
  const { trans } = useLocalizationState();
  const { findSpaces, hotelIsLoaded, hotelLoading, hotelId } = useHotelState();
  const { updateHotel } = useHotelDispatch();

  const postRequest = usePostRequest();

  const dissociateSpace = (spaceId: tHotelSpaceId) => {
    postRequest.pending();
    axios
      .post(
        `${apiAddressV2(false)}/v2/hotels/${hotelId}/space-aggregates/${
          spaceAggregate._id
        }`
      )
      .then((res) => {
        const {
          data: { hotel },
        } = res;
        updateHotel(hotelId, hotel);
        postRequest.resolve();
      })
      .catch((err) => {
        const error = getErrorMessage(err, trans);
        toaster.push(notification("error", error), { placement: "topEnd" });
        postRequest.reject(error);
      });
  };

  const options = (dataKey: string, space: tHotelSpace) => {
    return [
      {
        key: "uncouple",
        label: {
          text: trans("Dissociate space"),
          color: COLORS.error,
        },
        onClick() {
          dissociateSpace(space._id);
        },
      },
    ];
  };

  const tableData: tTableDataItem[] = findSpaces(spaceAggregate.spaces).map(
    (s) => {
      if (has(measuresRequest.data.bySpace, s._id)) {
        const te = round(
          sum(measuresRequest.data.bySpace[s._id].te?.values || []),
          1
        );
        const tw = round(
          sum(
            measuresRequest.data.bySpace[s._id].tw?.values || ([] as number[])
          ),
          1
        );
        const costs = round(sum(measuresRequest.data.bySpace[s._id].costs), 1);
        const co2 = round(sum(measuresRequest.data.bySpace[s._id].co2), 1);

        return {
          ...pick(s, ["_id", "name"]),
          waterIsMeasured: s.measures.tw.isMeasured,
          energyIsMeasured: s.measures.te.isMeasured,
          te,
          tw,
          co2,
          costs,
        };
      }
      return {
        ...pick(s, ["_id", "name"]),
        waterIsMeasured: s.measures.tw.isMeasured,
        energyIsMeasured: s.measures.te.isMeasured,
        te: 0,
        tw: 0,
        co2: 0,
        costs: 0,
      };
    }
  );

  const waterIsMeasured = some(tableData, (s) => s.waterIsMeasured);
  const energyIsMeasured = some(tableData, (s) => s.energyIsMeasured);
  const rowHeight = 60;
  const headerHeight = 50;
  const tableHeight = !hotelIsLoaded
    ? rowHeight + headerHeight
    : (min([5, tableData.length]) as number) * rowHeight + headerHeight;

  const spaceWidth = floor(0.17 * outletContainerWidth);
  const teWidth = floor(0.17 * outletContainerWidth);
  const twWidth = floor(0.17 * outletContainerWidth);
  const costsWidth = floor(0.17 * outletContainerWidth);
  const co2Width = floor(0.17 * outletContainerWidth);

  return (
    <div className="table-wrapper">
      <Renderer
        height={tableHeight}
        data={tableData}
        loading={hotelLoading || postRequest.isLoading}
        dataStatus={measuresRequest.status}
        {...{
          rowHeight,
          headerHeight,
          options,
          waterIsMeasured,
          energyIsMeasured,
          spaceWidth,
          teWidth,
          twWidth,
          costsWidth,
          co2Width,
        }}
      />
    </div>
  );
};

interface iProps {
  label: string;
  spaceAggregate: tHotelSpaceAggregate;
  measuresRequest: tGetRequest<tData>;
}

const List: React.FC<iProps> = ({ label, spaceAggregate, measuresRequest }) => {
  const { trans } = useLocalizationState();
  const { outlet: oc } = useScreenSize();

  if (!oc) return null;

  return (
    <Flex column gap={16}>
      <PageSectionTitle
        icon={{ Element: RoomPreferencesIcon, fill: COLORS.secondary }}
        title={{ text: trans(`${label} Management`) }}
        description={{ text: trans("Organize and oversee your spaces.") }}
      />
      <ListWrapped
        {...{
          outletContainerWidth: oc.width,
          spaceAggregate,
          measuresRequest,
        }}
      />
    </Flex>
  );
};

export default List;
