import React, { useState } from "react";
import { NavLink, Outlet, useLocation, useNavigate } from "react-router-dom";
import { Avatar, IconButton, Tooltip, Whisper } from "rsuite";
import { ReactComponent as BadgeIcon } from "../../../assets/icons/badge.svg";
import { ReactComponent as DashbaordIcon } from "../../../assets/icons/dashboard.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/icons/delete.svg";
import { ReactComponent as DirectionsCarIcon } from "../../../assets/icons/directions_car.svg";
import { ReactComponent as ElectricBoltIcon } from "../../../assets/icons/electric_bolt.svg";
import { ReactComponent as ElectricMeterIcon } from "../../../assets/icons/electric_meter.svg";
import { ReactComponent as EnergySavingIcon } from "../../../assets/icons/energy_program_saving.svg";
import { ReactComponent as FavoriteIcon } from "../../../assets/icons/favorite.svg";
import { ReactComponent as GroupIcon } from "../../../assets/icons/group.svg";
import { ReactComponent as HotelIcon } from "../../../assets/icons/hotel.svg";
import { ReactComponent as KeyboardArrowDownIcon } from "../../../assets/icons/keyboard_arrow_down.svg";
import { ReactComponent as KeyboardArrowUpIcon } from "../../../assets/icons/keyboard_arrow_up.svg";
import { ReactComponent as KeyboardDoubleArrowLeftIcon } from "../../../assets/icons/keyboard_double_arrow_left.svg";
import { ReactComponent as KeyboardDoubleArrowRightIcon } from "../../../assets/icons/keyboard_double_arrow_right.svg";
import { ReactComponent as LocalLaundryServiceIcon } from "../../../assets/icons/local_laundry_service.svg";
import { ReactComponent as MeetingRoomIcon } from "../../../assets/icons/meeting_room.svg";
import { ReactComponent as ModeHeatIcon } from "../../../assets/icons/mode_heat.svg";
import { ReactComponent as StadiaControllerIcon } from "../../../assets/icons/stadia_controller.svg";
import { ReactComponent as VerifiedIcon } from "../../../assets/icons/verified.svg";
import { ReactComponent as WaterDropIcon } from "../../../assets/icons/water_drop.svg";
import { ReactComponent as NoytrallLogo } from "../../../assets/noytrall_logo.svg";
import { ReactComponent as NoytrallSymbol } from "../../../assets/noytrall_symbol.svg";
import Flex from "../../../components/Flex";
import Icon from "../../../components/Icon";
import InterTag from "../../../components/Text/Inter";
import { HotelContextProvider } from "../../../context/Hotel";
import useHotelState from "../../../context/Hotel/hooks/hotelState/useHotelState";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import { ProfileContextProvider } from "../../../context/Profile";
import useProfileState from "../../../context/Profile/hooks/useProfileState";
import { tIcon } from "../../../interfaces/icon";
import { COLORS } from "../../../utils/colors";
import styles from "./styles.module.css";

const SIDEBAR_EXPANDED_LS = "__sbe__";

const CONTENT_WIDTH = 1128;
const TOTAL_WIDTH = CONTENT_WIDTH;
const MIN_MARGIN_HORIZONTAL = 16;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const WIDTH = TOTAL_WIDTH + 2 * MIN_MARGIN_HORIZONTAL;

type tMenuElement = {
  key: string;
  icon: tIcon;
  label: string;
  to: string;
  index?: boolean;
  children?: {
    key: string;
    icon: tIcon;
    label: string;
    to: string;
  }[];
};

const RootWrapped: React.FC = () => {
  const navigate = useNavigate();
  const { trans } = useLocalizationState();
  const location = useLocation();

  const {
    profile: { name, images },
  } = useProfileState();

  const {
    hotel: { name: hotelName },
    hotelIsLoaded,
  } = useHotelState();

  const [expanded, setExpanded] = useState<{ [key: string]: boolean }>({});
  const [sidebarExpanded, setSidebarExpanded] = useState<boolean>(() => {
    const stored = localStorage.getItem(SIDEBAR_EXPANDED_LS);
    return !(stored === "false");
  });

  const toggleSidebarExpanded = () => {
    localStorage.setItem(
      SIDEBAR_EXPANDED_LS,
      `${sidebarExpanded ? "false" : "true"}`
    );
    setSidebarExpanded((prev) => !prev);
    setExpanded({});
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 200);
  };

  const divider = (props: any = {}) => (
    <div {...props} style={{ margin: "8px 0" }}>
      <div
        style={{
          width: "100%",
          height: "1px",
          backgroundColor: COLORS.gray_400,
        }}
      />
    </div>
  );

  const LIST: tMenuElement[][] = [
    [
      {
        key: "overview",
        icon: { Element: DashbaordIcon },
        label: "Overview",
        to: "overview",
        index: true,
      },
      {
        key: "sustainability",
        icon: { Element: EnergySavingIcon },
        label: "Sustainability",
        to: "sustainability",
      },
      // {
      //   key: "reports",
      //   icon: { Element: ArticleIcon },
      //   label: "Reports",
      //   to: "reports",
      // },
    ],
    [
      {
        key: "spaces",
        icon: { Element: MeetingRoomIcon },
        label: "Spaces",
        to: "spaces",
      },
      // {
      //   key: "guests",
      //   icon: { Element: GroupIcon },
      //   label: "Guests",
      //   to: "guests",
      // },
      {
        key: "guests",
        icon: { Element: GroupIcon },
        label: "Guests",
        to: "guests",
      },
      {
        key: "occupancy",
        icon: { Element: HotelIcon },
        label: "Occupancy",
        to: "occupancy",
      },
      {
        key: "consumption",
        icon: { Element: ElectricMeterIcon },
        label: "Consumption",
        to: "consumption",
        children: [
          {
            key: "consumption.electricity",
            icon: { Element: ElectricBoltIcon },
            label: "Electricity",
            to: "consumption/electricity",
          },
          {
            key: "consumption.water",
            icon: { Element: WaterDropIcon },
            label: "Water",
            to: "consumption/water",
          },
          {
            key: "consumption.fuels",
            icon: { Element: ModeHeatIcon },
            label: "Fuels",
            to: "consumption/fuels",
          },
          {
            key: "consumption.waste",
            icon: { Element: DeleteIcon },
            label: "Waste",
            to: "consumption/waste",
          },
          {
            key: "consumption.laundry",
            icon: { Element: LocalLaundryServiceIcon },
            label: "Laundry",
            to: "consumption/laundry",
          },
          // {
          //   key: "consumption.consumables",
          //   icon: { Element: CleaningServicesIcon },
          //   label: "Consumables",
          //   to: "consumption/consumables",
          // },
          {
            key: "consumption.fleet",
            icon: { Element: DirectionsCarIcon },
            label: "Fleet",
            to: "consumption/fleet",
          },
        ],
      },
    ],
    [
      {
        key: "awards",
        icon: { Element: VerifiedIcon },
        label: "Awards",
        to: "awards",
      },
      {
        key: "challenges",
        icon: { Element: StadiaControllerIcon },
        label: "Challenges",
        to: "challenges",
      },
      {
        key: "loyalty",
        icon: { Element: FavoriteIcon },
        label: "Loyalty",
        to: "loyalty",
      },
    ],
    [
      {
        key: "users",
        icon: { Element: BadgeIcon },
        label: "Users",
        to: "users",
      },
    ],
  ];

  const renderMenu = () => {
    return LIST.flatMap((compartment, i) =>
      [
        divider({ key: i }),
        (compartment as tMenuElement[]).map((routeElement, i) => {
          const { icon, key, label, children, to, index } = routeElement;
          const color = COLORS.light_white;

          if (children && children.length)
            return (
              <Flex
                onMouseEnter={() => {
                  if (!sidebarExpanded)
                    setExpanded((prev) => ({
                      ...prev,
                      [key]: true,
                    }));
                }}
                onMouseLeave={() => {
                  if (!sidebarExpanded)
                    setExpanded((prev) => ({
                      ...prev,
                      [key]: false,
                    }));
                }}
                column
                key={`${label}.has-child`}
              >
                <Whisper
                  trigger="hover"
                  disabled={sidebarExpanded}
                  placement="right"
                  speaker={<Tooltip>{trans(label)}</Tooltip>}
                >
                  <Flex
                    key={key}
                    center={!sidebarExpanded}
                    className={`${styles["navlink"]} ${
                      styles["navlink-content"]
                    } ${
                      sidebarExpanded
                        ? styles["navlink-content-expanded"]
                        : styles["navlink-content-not-expanded"]
                    } `}
                    style={{
                      cursor: "pointer",
                      padding: 0,
                      ...(location.pathname.slice(1) === to
                        ? {
                            backgroundColor: COLORS.primary,
                          }
                        : {}),
                    }}
                  >
                    {sidebarExpanded ? (
                      <Flex
                        row
                        style={{
                          width: "100%",
                        }}
                      >
                        <Flex
                          row
                          middle
                          basis={80}
                          style={{
                            paddingTop: "8px",
                            paddingBottom: "8px",
                            paddingLeft: "24px",
                          }}
                          gap={16}
                          onClick={() => {
                            hotelIsLoaded && navigate("/consumption");
                          }}
                        >
                          <Icon
                            Element={icon.Element}
                            fill={color}
                            width={24}
                            height={24}
                          />
                          <InterTag
                            text={trans(label)}
                            color={color}
                            size={16}
                            bold
                          />
                        </Flex>
                        <Flex
                          center
                          style={{
                            paddingTop: "8px",
                            paddingBottom: "8px",
                            borderTopRightRadius: "8px",
                            borderBottomRightRadius: "8px",
                          }}
                          basis={20}
                          className={`${styles["expandable-menu-item-arrow"]}`}
                          onClick={() =>
                            setExpanded((prev) => ({
                              ...prev,
                              [key]: !!!expanded[key],
                            }))
                          }
                        >
                          <Icon
                            Element={
                              expanded[key]
                                ? KeyboardArrowUpIcon
                                : KeyboardArrowDownIcon
                            }
                            fill={COLORS.light_white}
                            size={24}
                          />
                        </Flex>
                      </Flex>
                    ) : (
                      <Flex
                        center
                        id="AQUI"
                        onClick={() => {
                          hotelIsLoaded && navigate("/consumption");
                        }}
                        style={{
                          paddingTop: "8px",
                          paddingBottom: "8px",
                          width: "100%",
                          borderRadius: "8px",
                        }}
                      >
                        <Icon
                          Element={icon.Element}
                          fill={color}
                          width={24}
                          height={24}
                        />
                      </Flex>
                    )}
                  </Flex>
                </Whisper>
                {expanded[key] === true &&
                  children.map((routeElement) => {
                    const { icon, key, label, to } = routeElement;
                    return (
                      <NavLink
                        key={key}
                        to={to}
                        className={({ isActive }) => {
                          return `${styles["navlink"]} ${
                            isActive || (location.pathname === "/" && index)
                              ? ` ${styles["active-link"]}`
                              : ""
                          }`;
                        }}
                      >
                        <Whisper
                          speaker={<Tooltip>{trans(label)}</Tooltip>}
                          disabled={sidebarExpanded}
                          trigger="hover"
                          placement="right"
                        >
                          <Flex
                            row
                            gap={16}
                            middle
                            center={!sidebarExpanded}
                            className={`${styles["navlink-content"]} ${
                              sidebarExpanded
                                ? styles["navlink-content-child-expanded"]
                                : styles["navlink-content-not-expanded"]
                            }`}
                          >
                            <Icon
                              Element={icon.Element}
                              fill={color}
                              width={24}
                              height={24}
                            />
                            {sidebarExpanded && (
                              <InterTag
                                bold
                                text={trans(label)}
                                color={color}
                                size={16}
                              />
                            )}
                          </Flex>
                        </Whisper>
                      </NavLink>
                    );
                  })}
              </Flex>
            );

          if (to)
            return (
              <NavLink
                key={key}
                to={to}
                className={({ isActive }) => {
                  return `${styles["navlink"]}${
                    isActive || (location.pathname === "/" && index)
                      ? ` ${styles["active-link"]}`
                      : ""
                  }`;
                }}
              >
                <Whisper
                  trigger="hover"
                  placement="right"
                  disabled={sidebarExpanded}
                  speaker={<Tooltip>{trans(label)}</Tooltip>}
                >
                  <Flex
                    row
                    gap={16}
                    middle
                    center={!sidebarExpanded}
                    className={`${styles["navlink-content"]} ${
                      sidebarExpanded
                        ? styles["navlink-content-expanded"]
                        : styles["navlink-content-not-expanded"]
                    }`}
                  >
                    <Icon
                      Element={icon.Element}
                      fill={color}
                      width={24}
                      height={24}
                    />
                    {sidebarExpanded && (
                      <InterTag
                        bold
                        text={trans(label)}
                        color={color}
                        size={16}
                      />
                    )}
                  </Flex>
                </Whisper>
              </NavLink>
            );

          return null;
        }),
      ].flatMap((e) => e)
    );
  };

  const expandedSidemenu = () => {
    return (
      <Flex
        className={`${styles["sidemenu"]} ${styles["sidemenu-expanded"]}`}
        column
        color={COLORS.secondary}
      >
        <Flex gap={30} row middle center style={{ marginTop: "16px" }}>
          <Icon Element={NoytrallLogo} width={120} height={40} />
          <IconButton
            className={styles["sidemenu-toggle-button"]}
            onClick={toggleSidebarExpanded}
            icon={
              <Icon
                size={24}
                fill={COLORS.light_white}
                Element={KeyboardDoubleArrowLeftIcon}
              />
            }
            circle
            size="xs"
            appearance="subtle"
          />
        </Flex>
        <div style={{ margin: "0 16px" }}>{divider()}</div>
        <Flex column center middle>
          {images && images.length > 0 && (
            <Avatar circle src={images[0]} alt="" size={"lg"} />
          )}
          <Flex style={{ marginTop: "12px" }} column middle gap={4}>
            <InterTag size={16} text={name} bold color={COLORS.light_white} />
            <InterTag size={14} text={hotelName} color={COLORS.light_white} />
          </Flex>
        </Flex>
        <Flex column gap={4} style={{ margin: "0 16px" }}>
          {renderMenu()}
        </Flex>
        <div style={{ width: "100%", padding: "8px 0" }}></div>
      </Flex>
    );
  };

  const notExpandedSidemenu = () => {
    return (
      <Flex
        className={`${styles["sidemenu"]} ${styles["sidemenu-not-expanded"]}`}
        column
        color={COLORS.secondary}
      >
        <Flex middle center style={{ marginTop: "16px" }}>
          <Icon Element={NoytrallSymbol} width={120} height={40} />
        </Flex>
        <div style={{ margin: "0 16px" }}>{divider()}</div>
        <Flex gap={12} column center middle>
          {images && images.length > 0 && (
            <Avatar circle src={images[0]} alt="" size={"lg"} />
          )}
          <IconButton
            className={styles["sidemenu-toggle-button"]}
            onClick={toggleSidebarExpanded}
            icon={
              <Icon
                size={24}
                fill={COLORS.light_white}
                Element={KeyboardDoubleArrowRightIcon}
              />
            }
            circle
            size="xs"
            appearance="subtle"
          />
        </Flex>
        <div style={{ margin: "0 16px" }}>{renderMenu()}</div>
      </Flex>
    );
  };

  return (
    <Flex className={styles.app}>
      {sidebarExpanded ? expandedSidemenu() : notExpandedSidemenu()}
      <Flex
        grow={1}
        className={styles.main}
        color={COLORS.app_background}
        style={{
          marginLeft: `${sidebarExpanded ? 255 : 88}px`,
          height: "100%",
        }}
      >
        <Flex
          style={{ width: "100%", height: "100%" }}
          center
          id="root-outlet-container"
        >
          <div
            id="outlet"
            style={{
              width: `90%`,
            }}
          >
            <Outlet />
          </div>
        </Flex>
      </Flex>
    </Flex>
  );
};

const Root: React.FC = () => {
  return (
    <ProfileContextProvider>
      <HotelContextProvider>
        <RootWrapped />
      </HotelContextProvider>
    </ProfileContextProvider>
  );
};

export default Root;
