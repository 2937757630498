import React from "react";
import { HotelStateContext } from "../..";
import { REQUEST_STATUS } from "../../../../utils/apiCall";

const useHotelStatusState = () => {
  const state = React.useContext(HotelStateContext);

  if (!state)
    throw Error("useHotelStatusState must be used within HotelStateContext");

  const { status } = state;

  const hotelLoading = status === REQUEST_STATUS.PENDING;
  const hotelIsLoaded = status === REQUEST_STATUS.RESOLVED;
  const errorLoading = status === REQUEST_STATUS.REJECTED;

  return {
    hotelIsLoaded,
    hotelLoading,
    errorLoading,
  };
};

export default useHotelStatusState;
