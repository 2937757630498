import React from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as KeyboardArrowRightIcon } from "../../../assets/icons/keyboard_arrow_right.svg";
import Flex from "../../../components/Flex";
import Icon from "../../../components/Icon";
import InterTag from "../../../components/Text/Inter";
import TopBar from "../../../components/TopBar";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import { COLORS } from "../../../utils/colors";

const SettingsBillingInvoices: React.FC = () => {
  const navigate = useNavigate();
  const { trans } = useLocalizationState();
  return (
    <div>
      <TopBar>
        <Flex gap={8} center column style={{ height: "100%" }}>
          <Flex row gap={6} middle>
            <InterTag
              hoverUnderline
              onClick={() => {
                navigate("/settings");
              }}
              text={trans("Settings")}
              size={12}
              color={COLORS.secondary}
            />
            <Icon Element={KeyboardArrowRightIcon} size={16} />
            <InterTag
              text={trans("Billing")}
              size={12}
              color={COLORS.secondary}
            />
          </Flex>
          <InterTag
            text={trans("Invoices")}
            size={24}
            bold
            color={COLORS.secondary}
          />
        </Flex>
      </TopBar>
    </div>
  );
};

export default SettingsBillingInvoices;
