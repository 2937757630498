import { find, toNumber, uniq } from "lodash";
import { MomentInput } from "moment";
import React, { useEffect, useState } from "react";
import { Button, IconButton, InputGroup, InputNumber } from "rsuite";
import { tState2 } from ".";
import { ReactComponent as DeleteIcon } from "../../../assets/icons/delete.svg";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import { nRequestStatus } from "../../../interfaces/apiCalls";
import { tManualDataFuel } from "../../../interfaces/manualData";
import { tHotelManualData } from "../../../models/hotel";
import { REQUEST_STATUS } from "../../../utils/apiCall";
import { COLORS } from "../../../utils/colors";
import Flex from "../../Flex";
import Icon from "../../Icon";
import InputWrapper from "../../InputWrapper";
import SimpleWhisperPopoverDropdown from "../../RsuiteWrapper/SimpleWhisperPopoverDropdown";

const fuelTypes: ({ wrapperProps: { label: string; description: string } } & (
  | {
      key: Extract<tManualDataFuel, "naturalGas">;
      label: string;
      valueKey: "totalKWh";
      unit: "kWh";
    }
  | {
      key: Extract<tManualDataFuel, "biomass" | "butane" | "propane">;
      label: string;
      valueKey: "totalKg";
      unit: "kg";
    }
  | {
      key: Extract<tManualDataFuel, "diesel" | "ethanol" | "gasoline">;
      label: string;
      valueKey: "totalLiters";
      unit: "L";
    }
))[] = [
  {
    key: "naturalGas",
    label: "Natural Gas",
    valueKey: "totalKWh",
    wrapperProps: {
      label: "Natural Gas Consumption",
      description:
        "Enter your total natural gas usage for the selected time period.",
    },
    unit: "kWh",
  },
  {
    key: "biomass",
    label: "Biomass",
    valueKey: "totalKg",
    wrapperProps: {
      label: "Biomass Consumption",
      description:
        "Enter your total biomass usage for the selected time period.",
    },
    unit: "kg",
  },
  {
    key: "butane",
    label: "Butane",
    valueKey: "totalKg",
    wrapperProps: {
      label: "Butane Consumption",
      description:
        "Enter your total butane usage for the selected time period.",
    },
    unit: "kg",
  },
  {
    label: "Propane",
    key: "propane",
    valueKey: "totalKg",
    wrapperProps: {
      label: "Propane Consumption",
      description:
        "Enter your total propane usage for the selected time period.",
    },
    unit: "kg",
  },
  {
    key: "diesel",
    label: "Diesel",
    valueKey: "totalLiters",
    wrapperProps: {
      label: "Diesel Consumption",
      description:
        "Enter your total diesel usage for the selected time period.",
    },
    unit: "L",
  },
  {
    key: "gasoline",
    label: "Gasoline",
    valueKey: "totalLiters",
    wrapperProps: {
      label: "Gasoline Consumption",
      description:
        "Enter your total gasoline usage for the selected time period.",
    },
    unit: "L",
  },
  {
    key: "ethanol",
    label: "Ethanol",
    valueKey: "totalLiters",
    wrapperProps: {
      label: "Ethanol Consumption",
      description:
        "Enter your total ethanol usage for the selected time period.",
    },
    unit: "L",
  },
];

interface iProps {
  from: MomentInput;
  to: MomentInput;
  status: nRequestStatus.tStatus;
  state: Pick<tState2["data"], tManualDataFuel>;
  errors: Pick<tState2["errors"], tManualDataFuel>;
  onChange: <
    T extends keyof Pick<
      Required<tHotelManualData>,
      tManualDataFuel
    > = keyof Pick<tState2["data"], tManualDataFuel>
  >(
    key: T
  ) => (subKey: keyof tState2["data"][T]) => (value: number) => void;
}

const Fuels: React.FC<iProps> = ({
  state,
  errors,
  onChange,
  status,
  from,
  to,
}) => {
  const { trans } = useLocalizationState();
  const [selectedFuelTypes, setSelectedFuelTypes] = useState<tManualDataFuel[]>(
    []
  );

  useEffect(() => {
    const keys = (Object.keys(state) as tManualDataFuel[]).filter(
      (k) => state[k].totalKWh || state[k].price
    );
    setSelectedFuelTypes(keys.length ? keys : ["naturalGas"]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [from, to]);

  const unselectedFuelTypes = fuelTypes.filter(
    (ft) => !selectedFuelTypes.includes(ft.key)
  );

  return (
    <Flex column gap={20}>
      {selectedFuelTypes.map((type) => {
        const {
          key,
          valueKey,
          wrapperProps: { label, description },
          unit,
        } = find(fuelTypes, (ft) => ft.key === type)!;

        // @ts-expect-error
        const value = (state[key] as tState2["data"][tManualDataFuel])[
          valueKey
        ];
        const priceValue = (state[key] as tState2["data"][tManualDataFuel])[
          "price"
        ];

        return (
          <InputWrapper
            key={key}
            {...{ label: trans(label), description: trans(description) }}
          >
            <Flex row gap={12} style={{ width: "70%" }}>
              <InputGroup>
                <InputNumber
                  disabled={status === REQUEST_STATUS.PENDING}
                  min={0}
                  value={value}
                  onChange={(value) => {
                    if (value !== null)
                      // @ts-expect-error
                      onChange(key)(valueKey)(toNumber(value));
                  }}
                />
                <InputGroup.Addon>{unit}</InputGroup.Addon>
              </InputGroup>
              <InputGroup>
                <InputNumber
                  disabled={status === REQUEST_STATUS.PENDING}
                  min={0}
                  value={priceValue}
                  onChange={(value) => {
                    if (value !== null)
                      onChange<typeof key>(key)("price")(toNumber(value));
                  }}
                />
                <InputGroup.Addon>€</InputGroup.Addon>
              </InputGroup>
              <div>
                <IconButton
                  onClick={() => {
                    setSelectedFuelTypes((prev) =>
                      prev.filter((k) => k !== key)
                    );
                  }}
                  icon={
                    <Icon Element={DeleteIcon} fill={COLORS.error} size={16} />
                  }
                />
              </div>
            </Flex>
          </InputWrapper>
        );
      })}
      {unselectedFuelTypes.length > 0 && (
        <Flex left>
          <SimpleWhisperPopoverDropdown
            options={unselectedFuelTypes.map(({ key, label }) => ({
              key,
              label,
              onClick() {
                setSelectedFuelTypes((prev) => uniq([...prev, key]));
              },
            }))}
          >
            <Button className="button-gray">{trans("Add Type of Fuel")}</Button>
          </SimpleWhisperPopoverDropdown>
        </Flex>
      )}
    </Flex>
  );
};

export default Fuels;
