import { find, groupBy, has, orderBy, sortBy, toInteger } from "lodash";
import moment, { MomentInput } from "moment";
import React, { useCallback, useMemo } from "react";
import { HotelStateContext } from "../..";
import { tHotelManualData } from "../../../../models/hotel";
import useHotelState from "./useHotelState";

const useHotelManualDataState = () => {
  const state = React.useContext(HotelStateContext);

  if (!state)
    throw Error("useHotelState must be used within HotelStateContext");

  const { errorLoading, hotel, hotelId, hotelIsLoaded, hotelLoading } =
    useHotelState();

  const manualDataOrdered = useMemo(() => {
    return orderBy(
      hotel.manualData.map((md) => ({
        ...md,
        from: moment(md.from).valueOf(),
        to: moment(md.to).valueOf(),
      })),
      ["from", "to"],
      ["asc", "asc"]
    );
  }, [hotel]);

  const manualDataByYear: Record<string, tHotelManualData[]> = useMemo(
    () => groupBy(manualDataOrdered, (mdo) => moment(mdo.from).year()),
    [manualDataOrdered]
  );

  console.log("manualDataByYear :>> ", manualDataByYear);

  const measuresTrackedInManualData = useMemo(() => {
    const measuresTracked = { electricity: false, water: false, fuels: false };
    for (const { electricity, water, naturalGas } of manualDataOrdered) {
      if (electricity) {
        measuresTracked.electricity = true;
      }
      if (water) {
        measuresTracked.water = true;
      }
      if (naturalGas) {
        measuresTracked.fuels = true;
      }
      if (Object.values(measuresTracked).filter((v) => !v).length === 0) {
        break;
      }
    }
    return measuresTracked;
  }, [manualDataOrdered]);

  const getHotelDimensionsInTimeframe = useCallback(
    (date: MomentInput) => {
      let i = 0;
      for (; i < manualDataOrdered.length; i++) {
        const entry = manualDataOrdered[i];
        if (!moment(date).isBetween(moment(entry.from), moment(entry.to)))
          continue;

        let dimensions = entry.space;
        if (dimensions) return dimensions;

        let j = i - 1;
        while (!dimensions && j >= 0) {
          dimensions = manualDataOrdered[j].space;
          j--;
        }
        if (dimensions) return dimensions;
        j = i + 1;
        while (!dimensions && j < manualDataOrdered.length) {
          dimensions = manualDataOrdered[j].space;
          j++;
        }
        if (dimensions) return dimensions;
        break;
      }

      i = manualDataOrdered.length - 1;
      for (; i >= 0; i--) {
        if (
          manualDataOrdered[i].space &&
          manualDataOrdered[i].space!.totalAreaM2
        )
          return manualDataOrdered[i].space;
      }

      return undefined;
    },
    [manualDataOrdered]
  );
  const getManualDataEntryWithDimensionsInTimeframe = useCallback(
    (date: MomentInput) => {
      let i = 0;
      for (; i < manualDataOrdered.length; i++) {
        const entry = manualDataOrdered[i];
        if (!moment(date).isBetween(moment(entry.from), moment(entry.to)))
          continue;

        let dimensions = entry.space;
        if (dimensions) return { ...entry };

        let j = i - 1;
        while (j >= 0) {
          dimensions = manualDataOrdered[j].space;
          if (dimensions) return { ...manualDataOrdered[j] };
          j--;
        }
        j = i + 1;
        while (j < manualDataOrdered.length) {
          dimensions = manualDataOrdered[j].space;
          if (dimensions) return { ...manualDataOrdered[j] };
          j++;
        }
        break;
      }

      i = manualDataOrdered.length - 1;
      for (; i >= 0; i--) {
        if (
          manualDataOrdered[i].space &&
          manualDataOrdered[i].space!.totalAreaM2
        ) {
          return { ...manualDataOrdered[i] };
        }
      }

      return undefined;
    },
    [manualDataOrdered]
  );

  const findHomologueManualDataEntry = useCallback(
    (entry: tHotelManualData) => {
      const homologueEntry = find(
        hotel.manualData,
        (md) =>
          moment(md.from).isSame(moment(entry.from).subtract(1, "year")) &&
          moment(md.to).isSame(moment(entry.to).subtract(1, "year"))
      );
      return homologueEntry;
    },
    [hotel.manualData]
  );

  const findPreviousAvailableYearManualDataEntry = useCallback(
    (entry: tHotelManualData, property?: keyof tHotelManualData) => {
      const entryMoment = moment(
        (moment(entry.from).valueOf() + moment(entry.to).valueOf()) / 2
      );
      const entryYear = entryMoment.year();
      const entryMonth = entryMoment.month();

      const yearsDescendingOrdered = sortBy(
        Object.keys(manualDataByYear).map((y) => toInteger(y)),
        (y) => -y
      );

      if (property) {
        for (const year of yearsDescendingOrdered) {
          if (year >= entryYear) continue;

          const monthEntry = find(
            manualDataByYear[year],
            (md) =>
              moment(
                (moment(md.from).valueOf() + moment(md.to).valueOf()) / 2
              ).month() === entryMonth
          );

          if (monthEntry) {
            if (has(monthEntry, property)) return monthEntry;
            continue;
          }
        }
      } else {
        for (const year of yearsDescendingOrdered) {
          if (year >= entryYear) continue;

          const monthEntry = find(
            manualDataByYear[year],
            (md) =>
              moment(
                (moment(md.from).valueOf() + moment(md.to).valueOf()) / 2
              ).month() === entryMonth
          );

          if (monthEntry) return monthEntry;
        }
      }

      return undefined;
    },
    [manualDataByYear]
  );

  return {
    hotelIsLoaded,
    hotelLoading,
    errorLoading,
    hotel,
    hotelId,

    manualDataOrdered,
    getHotelDimensionsInTimeframe,
    measuresTrackedInManualData,
    manualDataByYear,
    getManualDataEntryWithDimensionsInTimeframe,
    findHomologueManualDataEntry,
    findPreviousAvailableYearManualDataEntry,
  };
};

export default useHotelManualDataState;
