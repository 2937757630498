import { findIndex } from "lodash";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Flex from "../../../components/Flex";
import PageBottomPadding from "../../../components/PageBottomPadding";
import Segmentation from "../../../components/Segmentation";
import InterTag from "../../../components/Text/Inter";
import TopBar from "../../../components/TopBar";
import useHotelSubscriptionState from "../../../context/Hotel/hooks/hotelState/useHotelSubscriptionState";
import useHotelState from "../../../context/Hotel/hooks/useHotelState";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import { COLORS } from "../../../utils/colors";
import Add from "./add";
import Entries from "./entries";
import Unlock from "./unlock";
import Vehicles from "./vehicles";

const FleetWrapped2: React.FC = () => {
  const { hotel, hotelLoading } = useHotelState();
  const { trans } = useLocalizationState();
  const [selectedSegmentIndex, setSelectedSegmentIndex] = useState(0);

  const segments: {
    key: string;
    label: string;
    Component: React.FC;
    count: number;
  }[] = [
    {
      key: "vehicles",
      label: "Vehicles",
      Component: Vehicles,
      count: Object.keys(hotel.fleet).length,
    },
    {
      key: "usage",
      label: "Usage",
      Component: Entries,
      count: Object.values(hotel.fleet).flatMap((f) => f.entries).length,
    },
  ];

  const renderSegmentation = () => {
    return (
      <Flex row between>
        <Flex grow={1}>
          <Segmentation
            appearance="subtle"
            active={segments[selectedSegmentIndex].key}
            onSelect={(key) => {
              const index = findIndex(segments, (s) => s.key === key);
              if (index >= 0) setSelectedSegmentIndex(index);
            }}
            options={segments.map((f) => {
              const { key, label, count } = f;
              return {
                key,
                label: { text: trans(label) },
                count,
                disabled: hotelLoading,
              };
            })}
          />
        </Flex>
        <Flex
          middle
          style={{
            borderBottom: `2px ${COLORS.gray} solid`,
          }}
        >
          <Add />
        </Flex>
      </Flex>
    );
  };

  const { Component } = segments[selectedSegmentIndex];

  return (
    <>
      <Flex column gap={16}>
        {renderSegmentation()}
        <Component />
      </Flex>
    </>
  );
};

const FleetWrapped: React.FC = () => {
  const { activeSubscriptionType, hotelIsLoaded, hotelLoading } =
    useHotelSubscriptionState();

  if (hotelLoading) return null;

  if (!hotelIsLoaded) return null;

  if (activeSubscriptionType === "starter") return <Unlock />;

  return <FleetWrapped2 />;
};

const Fleet: React.FC = () => {
  const { trans } = useLocalizationState();
  const navigate = useNavigate();

  return (
    <div>
      <TopBar>
        <Flex row gap={12}>
          <Flex gap={8} column style={{ height: "100%" }}>
            <InterTag
              hoverUnderline
              onClick={() => {
                navigate("/consumption");
              }}
              text={trans("Consumption")}
              size={12}
              color={COLORS.secondary}
            />
            <InterTag
              text={trans("Fleet")}
              size={24}
              bold
              color={COLORS.secondary}
            />
          </Flex>
        </Flex>
      </TopBar>
      <FleetWrapped />
      <PageBottomPadding />
    </div>
  );
};

export default Fleet;
