import React from "react";
import { tIcon, tIconElement } from "../../interfaces/icon";
import { COLORS } from "../../utils/colors";
import Flex from "../Flex";
import Icon from "../Icon";
import { toIconProps } from "../Icon/utils";
import { iTextProps } from "../Text";
import InterTag from "../Text/Inter";
import { toTextProps } from "../Text/utils";

export interface iPageSectionTitleProps {
  icon?: tIcon | tIconElement;
  title: string | iTextProps;
  description?: string | iTextProps;
  [key: string]: any;
}

const PageSectionTitle: React.FC<iPageSectionTitleProps> = ({
  icon,
  title,
  description,
  ...rest
}) => {
  const renderDescription = () => {
    if (!description) return null;
    const descriptionProps = toTextProps(description);
    return (
      <InterTag
        {...{
          size: 14,
          color: COLORS.gray_600,
          ...descriptionProps,
          style: {
            ...descriptionProps.style,
            textAlign: "left",
            textWrap: "wrap",
            lineHeight: "18px",
          },
        }}
      />
    );
  };

  return (
    <Flex column gap={8} style={{ ...rest.style }}>
      <Flex row gap={8}>
        {icon && (
          <Icon
            {...{ fill: COLORS.secondary, size: 24, ...toIconProps(icon) }}
          />
        )}
        <InterTag
          {...{
            size: 24,
            color: COLORS.secondary,
            ...toTextProps(title),
          }}
        />
      </Flex>
      {description && renderDescription()}
    </Flex>
  );
};

export default React.memo(PageSectionTitle);
