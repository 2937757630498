import axios from "axios";
import React, { useState } from "react";
import { useToaster } from "rsuite";
import { ReactComponent as AddIcon } from "../../../assets/icons/add.svg";
import PrimaryButton from "../../../components/Buttons/PrimaryButton";
import useHotelDispatch from "../../../context/Hotel/hooks/useHotelDispatch";
import useHotelState from "../../../context/Hotel/hooks/useHotelState";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import usePostRequest from "../../../hooks/apiRequests/usePostRequest";
import { apiAddressV2 } from "../../../utils/apiCall";
import { COLORS } from "../../../utils/colors";
import { getErrorMessage } from "../../../utils/httpResponses/others";
import { notification } from "../../../utils/notifications";
import CreateEditChallengeModal, {
  tDataOnSubmit,
} from "./createEditChallengeModal";

interface iProps {}

const AddChallenge: React.FC<iProps> = () => {
  const toaster = useToaster();
  const { trans } = useLocalizationState();
  const { hotelId } = useHotelState();
  const { updateHotel } = useHotelDispatch();
  const [modal, setModal] = useState<{ open: boolean }>({ open: false });
  const postRequest = usePostRequest();

  const createChallenge = (data: Omit<tDataOnSubmit, "_id">) => {
    postRequest.pending();
    axios
      .post(`${apiAddressV2(false)}/v2/hotels/${hotelId}/challenges/`, data)
      .then((res) => {
        const {
          data: { hotel },
        } = res;
        updateHotel(hotelId, hotel);
        toaster.push(notification("success", trans("Challenge created")), {
          placement: "topEnd",
        });
        postRequest.resolve();
        setModal({ open: false });
      })
      .catch((err) => {
        const error = getErrorMessage(err, trans);
        toaster.push(notification("error", trans(error)), {
          placement: "topEnd",
        });
        postRequest.reject(error);
      });
  };

  return (
    <>
      <CreateEditChallengeModal
        onRequest={createChallenge}
        requestStatus={postRequest.status}
        requestError={postRequest.error}
        open={modal.open}
        onClose={() => setModal({ open: false })}
      />
      <PrimaryButton
        label={{
          text: trans("Add").toUpperCase(),
          color: COLORS.white,
          size: 12,
        }}
        icon={{ Element: AddIcon, fill: COLORS.white, size: 20 }}
        size="sm"
        onClick={() => setModal({ open: true })}
      />
    </>
  );
};

export default AddChallenge;
