import React from "react";
import { SelectPicker } from "rsuite";
import Flex from "../../../components/Flex";
import InterTag from "../../../components/Text/Inter";
import useLocalizationDispatch from "../../../context/Localization/hooks/useLocalizationDispatch";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import { COLORS } from "../../../utils/colors";
import { LANGAUGES } from "../../../utils/languages";
import styles from "./styles.module.css";

const GeneralSettingsWrapped: React.FC = () => {
  const { trans, language } = useLocalizationState();
  const { setLanguage } = useLocalizationDispatch();

  const list = LANGAUGES.filter(({ code }) => ["en", "pt"].includes(code)).map(
    ({ code, language }) => ({
      label: language,
      value: code,
    })
  );

  return (
    <Flex
      row
      between
      className={[styles["settings-item"], styles["settings-select-item"]].join(
        " "
      )}
    >
      <Flex one middle>
        <InterTag
          size={14}
          text={trans("Language")}
          bold
          color={COLORS.secondary}
        />
      </Flex>
      <Flex one right middle>
        <SelectPicker
          appearance="subtle"
          data={list}
          value={language}
          caretAs={undefined}
          cleanable={false}
          placement="leftStart"
          onChange={(value) => {
            if (value) setLanguage(value);
          }}
        />
      </Flex>
    </Flex>
  );
};

const GeneralSettings: React.FC = () => {
  return <GeneralSettingsWrapped />;
};

export default GeneralSettings;
