import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "rsuite";
import Flex from "../../../components/Flex";
import DMSeriffTag from "../../../components/Text/DMSeriff";
import InterTag from "../../../components/Text/Inter";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import { COLORS } from "../../../utils/colors";

const Unlock: React.FC = () => {
  const navigate = useNavigate();
  const { trans } = useLocalizationState();

  return (
    <Flex column middle gap={32} style={{ margin: "0 100px" }}>
      <DMSeriffTag
        size={36}
        color={COLORS.secondary}
        text={trans("Drive Guest Engagement with Customizable Awards")}
        style={{ textWrap: "wrap", textAlign: "center" }}
        bold
      />
      {[
        "Unlock the full potential of your guest relations with our 'Awards' feature, exclusively available for premium users.",
        "This feature allows you to create, manage, and offer personalized awards and incentives that enhance guest satisfaction and loyalty.",
        "From automated rewards based on guest performance to manually applied specials for repeat guests, this tool equips you to craft memorable experiences that encourage repeat visits and positive reviews.",
      ].map((t) => (
        <InterTag
          size={20}
          color={COLORS.secondary}
          text={trans(t)}
          style={{ textWrap: "wrap", textAlign: "center" }}
        />
      ))}
      <Button
        style={{
          backgroundColor: COLORS.primary_900,
          color: COLORS.white,
          fontWeight: "bold",
        }}
        onClick={() => navigate("/settings/billing/plan")}
      >
        {trans("Upgrade Now")}
      </Button>
      {[
        {
          title: "Enhanced Guest Loyalty",
          description:
            "Foster deeper connections with guests by offering tailored rewards and recognition, which can significantly boost guest retention rates.",
        },
        {
          title: "Flexible Reward Schemes",
          description:
            "Implement a variety of reward schemes, including perfomance-based loyalty and stay-based loyalty programs, which can be customized to align with specific marketing goals ro guest preferences.",
        },
        {
          title: "Operational Efficiency",
          description:
            "Streamline the process of award management with easy-to-use tools that automate reward distribution based on guest behavior, saving time and reducing manual effort.",
        },
      ].map(({ title, description }) => (
        <Flex column gap={16}>
          <DMSeriffTag
            text={trans(title)}
            color={COLORS.secondary}
            size={24}
            style={{ textWrap: "wrap", textAlign: "center" }}
            bold
          />
          <InterTag
            text={trans(description)}
            color={COLORS.secondary}
            size={20}
            style={{ textWrap: "wrap", textAlign: "center" }}
          />
        </Flex>
      ))}
    </Flex>
  );
};

export default Unlock;
