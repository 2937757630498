import React, { useContext, useEffect, useState } from "react";
import { Toggle } from "rsuite";
import PageBottomPadding from "../../../components/PageBottomPadding";
import InterTag from "../../../components/Text/Inter";
import TopBar from "../../../components/TopBar";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import WithContextProvider from "../../../hoc/withProvider";
import { nRequestStatus } from "../../../interfaces/apiCalls";
import { REQUEST_STATUS } from "../../../utils/apiCall";
import { COLORS } from "../../../utils/colors";
import ConsumptionContextProvider, { ConsumptionContext } from "./context";
import HistoricData from "./historicData";
import LiveData from "./liveData";

const Consumption: React.FC = () => {
  const { trans } = useLocalizationState();
  const [dataTypeState, setDataTypeState] = useState<{
    status: nRequestStatus.tStatus;
    dataType: "archives" | "live" | null;
  }>({ status: REQUEST_STATUS.PENDING, dataType: null });
  const context = useContext(ConsumptionContext);

  useEffect(() => {
    setTimeout(() => {
      const get = () => {
        const stored = localStorage.getItem("__c_dt__");

        switch (stored) {
          case "archives":
            return "archives";
          case "live":
            return "live";
        }
        return "archives";
      };
      const dataType = get();
      setDataTypeState({ status: REQUEST_STATUS.RESOLVED, dataType });
    }, 200);
  }, []);

  const handleSetDataType = (dataType: "live" | "archives") => {
    setDataTypeState((prev) => ({ ...prev, dataType }));
    localStorage.setItem("__c_dt__", dataType);
  };

  const toggle = (
    <Toggle
      loading={
        dataTypeState.status === REQUEST_STATUS.PENDING ||
        Object.values(context.state.dataTypeToggleDisabled).some((v) => v)
      }
      className={"toggle-data-type"}
      onChange={(checked) => {
        if (
          !(
            dataTypeState.status === REQUEST_STATUS.PENDING ||
            Object.values(context.state.dataTypeToggleDisabled).some((v) => v)
          )
        )
          handleSetDataType(checked ? "live" : "archives");
      }}
      checked={dataTypeState.dataType === "live"}
      checkedChildren={trans("Live")}
      unCheckedChildren={trans("Archives")}
    />
  );

  const renderMain = () => {
    switch (dataTypeState.dataType) {
      case "archives":
        return (
          <div>
            <HistoricData dataTypeToggle={toggle} />
            <PageBottomPadding />
          </div>
        );
      case "live":
        return (
          <div>
            <LiveData dataTypeToggle={toggle} />
            <PageBottomPadding />
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div>
      <TopBar>
        <InterTag
          text={trans("Consumption")}
          bold
          color={COLORS.secondary}
          size={24}
        />
      </TopBar>
      {renderMain()}
    </div>
  );
};

export default WithContextProvider(ConsumptionContextProvider, Consumption);
