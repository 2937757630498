import { has, keys, remove, toNumber, values } from "lodash";
import moment from "moment";
import React, { useEffect, useReducer } from "react";
import {
  Button,
  Checkbox,
  Input,
  InputNumber,
  Modal,
  ModalProps,
  SelectPicker,
  Tag,
  Toggle,
  useToaster,
} from "rsuite";
import { ReactComponent as AddIcon } from "../../../assets/icons/add.svg";
import { ReactComponent as ArrowForwardIcon } from "../../../assets/icons/arrow_forward.svg";
import { ReactComponent as CheckSmallIcon } from "../../../assets/icons/check_small.svg";
import { ReactComponent as VerifiedIcon } from "../../../assets/icons/verified.svg";
import PrimaryButton from "../../../components/Buttons/PrimaryButton";
import Flex from "../../../components/Flex";
import Icon from "../../../components/Icon";
import InputWrapper from "../../../components/InputWrapper";
import PageSectionTitle from "../../../components/PageSectionTitle";
import InterTag from "../../../components/Text/Inter";
import useHotelState from "../../../context/Hotel/hooks/useHotelState";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import { nRequestStatus } from "../../../interfaces/apiCalls";
import { tHotelAwardId } from "../../../models/hotel";
import { HOTEL_AWARD_TYPES, tHotelAward } from "../../../models/hotel/awards";
import { REQUEST_STATUS } from "../../../utils/apiCall";
import { COLORS } from "../../../utils/colors";
import { getValidity } from "../../../utils/dates";
import { LANGAUGES, tLanguageCode } from "../../../utils/languages";
import { notification } from "../../../utils/notifications";

type tData = {
  type: tHotelAward["type"] | "" | null;
  descriptions: Partial<
    Record<tLanguageCode, { shortDescription: string; longDescription: string }>
  >;
  currentLanguage: tLanguageCode | "";
  offer: boolean;
  discount: number;
  discountType: tHotelAward["discountType"];
  hours: number;
  days: number;
  months: number;
  years: number;
  expiry: boolean;
};

type tErrors = Record<keyof tData, string>;

type tState = {
  data: tData;
  errors: tErrors;
};

type tAction =
  | { type: "reset" }
  | { type: "errors"; errors: Partial<tErrors> }
  | { type: "data"; data: Partial<tData> }
  | { type: "key value"; key: keyof tData; value: any };

const initialState: tState = {
  data: {
    type: "",
    offer: false,
    discount: 0,
    discountType: "percentage",
    hours: 0,
    days: 0,
    months: 0,
    years: 0,
    descriptions: {},
    currentLanguage: "",
    expiry: false,
  },
  errors: {
    type: "",
    offer: "",
    discount: "",
    discountType: "",
    hours: "",
    days: "",
    months: "",
    years: "",
    descriptions: "",
    currentLanguage: "",
    expiry: "",
  },
};

const reducer = (state: tState, action: tAction): tState => {
  switch (action.type) {
    case "reset": {
      return { ...initialState };
    }
    case "errors": {
      const { errors } = action;
      return { ...state, errors: { ...state.errors, ...errors } };
    }
    case "data": {
      const { data } = action;
      return {
        ...state,
        errors: {
          ...state.errors,
          ...Object.fromEntries(Object.keys(data).map((key) => [key, ""])),
        },
        data: { ...state.data, ...data },
      };
    }
    case "key value": {
      const { key, value } = action;
      return {
        ...state,
        errors: { ...state.errors, [key]: "" },
        data: { ...state.data, [key]: value },
      };
    }
    default: {
      return { ...state };
    }
  }
};

export type tDataOnSubmit = Pick<
  tHotelAward,
  | "type"
  | "descriptions"
  | "discount"
  | "discountType"
  | "validityAfterAwarding"
> &
  Partial<Pick<tHotelAward, "_id">>;

interface iCreateEditAwardModalWrappedProps {
  onClose(): void;
  awardId?: tHotelAwardId;
  requestStatus: nRequestStatus.tStatus;
  requestError: string | null;
  onRequest(award: tDataOnSubmit): void;
}

const CreateEditAwardModalWrapped: React.FC<
  iCreateEditAwardModalWrappedProps
> = ({ onClose, awardId, requestStatus, onRequest, requestError }) => {
  const toaster = useToaster();
  const { trans } = useLocalizationState();
  const { findAward } = useHotelState();
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (awardId) {
      const award = findAward(awardId);

      if (!award) {
        toaster.push(notification("error", trans("Award not found")), {
          placement: "topEnd",
        });
        onClose();
        return;
      }
      const {
        type,
        descriptions,
        discount,
        discountType,
        validityAfterAwarding,
      } = award;

      const { years, months, days } = getValidity(validityAfterAwarding);

      const descriptionsObj = Object.fromEntries(
        descriptions.map(({ language, longDescription, shortDescription }) => [
          language,
          { shortDescription, longDescription },
        ])
      );

      dispatch({
        type: "data",
        data: {
          type,
          discount: toNumber(discount),
          discountType,
          years,
          months,
          days,
          descriptions: descriptionsObj,
          offer: discountType === "percentage" && toNumber(discount) === 100,
        },
      });
    }
  }, [awardId, findAward, onClose, toaster, trans]);

  const {
    type,
    offer,
    discount,
    discountType,
    years,
    days,
    hours,
    months,
    currentLanguage,
    descriptions,
  } = state.data;

  const isLoading = requestStatus === REQUEST_STATUS.PENDING;

  function onChange<T>(
    key: keyof Pick<
      tData,
      "type" | "offer" | "discountType" | "discount" | "currentLanguage"
    >,
    data: Partial<tData> = {}
  ) {
    return (value: T) => {
      dispatch({ type: "data", data: { [key]: value, ...data } });
    };
  }

  const onToggleOffer = (offer: boolean) => {
    dispatch({ type: "data", data: { discount: state.data.discount, offer } });
  };
  const changeExpiry =
    (key: keyof Pick<tData, "years" | "days" | "hours" | "months">) =>
    (value: any) => {
      dispatch({
        type: "data",
        data: { expiry: true, [key]: toNumber(value) },
      });
    };

  const onChangeDescription =
    (key: "shortDescription" | "longDescription") => (value: string) => {
      if (currentLanguage)
        dispatch({
          type: "data",
          data: {
            descriptions: {
              ...state.data.descriptions,
              [currentLanguage]: {
                shortDescription: "",
                longDescription: "",
                ...state.data.descriptions[currentLanguage],
                [key]: value,
              },
            },
          },
        });
    };

  const handleConfirm = () => {
    const errors: Partial<tErrors> = {};
    if (!type) {
      errors.type = trans("Select a type");
    }

    if (!offer) {
      const discountToNumber = toNumber(discount);
      if (discountType === "percentage") {
        if (!(0 < discountToNumber && discountToNumber <= 100))
          errors.discount = trans(
            "Enter a number greater than 0 and less than or equal to 100"
          );
      } else {
        if (!(discountToNumber > 0)) {
          errors.discount = trans("Cannot be 0");
        }
      }
    }

    if (!(years || months || days || hours)) {
      errors.expiry = trans("Enter a valid expiration value");
    }

    if (
      values(descriptions).filter(
        ({ longDescription, shortDescription }) =>
          longDescription.length && shortDescription.length
      ).length === 0
    ) {
      errors.descriptions = trans("At least one description");
    }

    if (keys(errors).length) {
      dispatch({ type: "errors", errors });
      return;
    } else {
      onRequest({
        ...(awardId ? { _id: awardId } : {}),
        // @ts-ignore
        type: type,
        ...(offer ||
        (discountType === "percentage" && toNumber(discount) === 100)
          ? { discountType: "percentage", discount: 100 }
          : { discount, discountType }),
        descriptions: Object.entries(descriptions).map(
          ([language, { shortDescription, longDescription }]) => ({
            language: language as tLanguageCode,
            shortDescription,
            longDescription,
          })
        ),
        validityAfterAwarding: moment(0)
          .add(years, "years")
          .add(months, "months")
          .add(days, "days")
          .add(hours, "hours")
          .valueOf(),
      });
    }
  };

  const availableLanguages = LANGAUGES.filter(({ code }) =>
    ["en", "pt"].includes(code)
  );
  const notSelectedLanguagesList = [...availableLanguages].map((l) => ({
    ...l,
    selected: false,
  }));
  const selectedLanguagesList = remove(notSelectedLanguagesList, (l) => {
    const descriptions = state.data.descriptions[l.code as tLanguageCode];
    if (
      descriptions &&
      (descriptions?.shortDescription.length ||
        descriptions?.longDescription.length)
    ) {
      l.selected = true;
      return true;
    }
    return false;
  });

  return (
    <>
      <Modal.Body>
        <Flex column gap={32}>
          <PageSectionTitle
            title={{
              text: awardId ? trans("Update Award") : trans("New Award"),
            }}
            icon={{ Element: VerifiedIcon }}
          />
          <Flex row gap={32}>
            <Flex column gap={32} basis={50}>
              <InputWrapper
                label={{ text: trans("Type") }}
                error={state.errors.type}
              >
                <SelectPicker
                  value={type || undefined}
                  onChange={onChange<tHotelAward["type"] | null>("type")}
                  data={HOTEL_AWARD_TYPES.map((t) => ({ label: t, value: t }))}
                  searchable={false}
                  placeholder={trans("Select award type")}
                  disabled={isLoading}
                />
              </InputWrapper>
              <InputWrapper
                label={{
                  text: `${trans("Discount")} (${
                    discountType === "nominal" ? "€" : "%"
                  })`,
                }}
                error={state.errors.discount}
              >
                <Flex row gap={20} middle>
                  <InputNumber
                    min={0}
                    value={
                      offer ? (discountType === "nominal" ? "" : 100) : discount
                    }
                    disabled={offer || isLoading}
                    onChange={(value) => {
                      onChange<number>("discount")(toNumber(value));
                    }}
                  />
                  <Flex row middle>
                    <Checkbox
                      checked={offer}
                      onChange={(...args) => {
                        onToggleOffer(args[1]);
                      }}
                      disabled={isLoading}
                    />
                    <InterTag
                      text={trans("Offer")}
                      size={12}
                      color={COLORS.secondary}
                    />
                  </Flex>
                  <Toggle
                    size="md"
                    checkedChildren={trans("Nominal")}
                    unCheckedChildren={trans("Percentage")}
                    checked={discountType === "nominal"}
                    onChange={(checked: boolean) =>
                      onChange("discountType", {
                        discount: state.data.discount,
                      })(checked ? "nominal" : "percentage")
                    }
                    disabled={isLoading}
                  />
                </Flex>
              </InputWrapper>
              <InputWrapper
                label={{ text: trans("Expiry") }}
                error={state.errors.expiry}
              >
                <Flex row gap={16}>
                  <InputWrapper
                    label={{ bold: false, text: trans("Years"), size: 12 }}
                  >
                    <InputNumber
                      min={0}
                      onChange={changeExpiry("years")}
                      value={years}
                      disabled={isLoading}
                    />
                  </InputWrapper>
                  <InputWrapper
                    label={{ bold: false, text: trans("Months"), size: 12 }}
                  >
                    <InputNumber
                      min={0}
                      onChange={changeExpiry("months")}
                      value={months}
                      disabled={isLoading}
                    />
                  </InputWrapper>
                  <InputWrapper
                    label={{ bold: false, text: trans("Days"), size: 12 }}
                  >
                    <InputNumber
                      min={0}
                      onChange={changeExpiry("days")}
                      value={days}
                      disabled={isLoading}
                    />
                  </InputWrapper>
                </Flex>
              </InputWrapper>
            </Flex>
            <Flex column gap={32} basis={50}>
              <InputWrapper
                label={{ text: trans("Description") }}
                error={state.errors.descriptions}
              >
                <Flex column gap={4}>
                  <InputWrapper
                    label={{ text: "Language", size: 12, bold: false }}
                  >
                    <SelectPicker
                      data={[
                        ...selectedLanguagesList,
                        ...notSelectedLanguagesList,
                      ].map(({ language, code, selected }) => ({
                        value: code,
                        label: (
                          <Flex row gap={4}>
                            {language}
                            {selected && (
                              <Icon size={12} Element={CheckSmallIcon} />
                            )}
                          </Flex>
                        ),
                      }))}
                      value={currentLanguage}
                      placeholder={trans("Select language")}
                      onChange={onChange("currentLanguage")}
                      disabled={isLoading}
                    />
                  </InputWrapper>
                  <Flex
                    row
                    gap={4}
                    style={{ maxWidth: "448px", overflowX: "auto" }}
                  >
                    {selectedLanguagesList.map(({ code, language }) => (
                      <Tag
                        key={code}
                        style={{ cursor: isLoading ? "default" : "pointer" }}
                        onClick={() => {
                          if (!isLoading) onChange("currentLanguage")(code);
                        }}
                        closable={!isLoading}
                        color="cyan"
                        onClose={() => {
                          if (!isLoading)
                            dispatch({
                              type: "data",
                              data: {
                                descriptions: {
                                  ...descriptions,
                                  [code]: {
                                    shortDescription: "",
                                    longDescription: "",
                                  },
                                },
                              },
                            });
                        }}
                      >
                        {language}
                      </Tag>
                    ))}
                  </Flex>
                </Flex>
                <InputWrapper
                  label={{
                    text: `${trans(
                      "Short Description"
                    )} <span style="font-size: 8px">(${trans("Max length: $0", {
                      parameters: ["40"],
                    })})</span>`,
                    size: 12,
                    bold: false,
                    asHTML: true,
                  }}
                >
                  <Input
                    as="textarea"
                    value={
                      currentLanguage &&
                      has(state.data.descriptions, currentLanguage)
                        ? state.data.descriptions[currentLanguage]!
                            .shortDescription
                        : ""
                    }
                    maxLength={100}
                    rows={2}
                    disabled={!currentLanguage || isLoading}
                    onChange={onChangeDescription("shortDescription")}
                  />
                </InputWrapper>
                <InputWrapper
                  label={{
                    text: trans("Long Description"),
                    size: 12,
                    bold: false,
                  }}
                >
                  <Input
                    as="textarea"
                    value={
                      currentLanguage &&
                      has(state.data.descriptions, currentLanguage)
                        ? state.data.descriptions[currentLanguage]!
                            .longDescription
                        : ""
                    }
                    disabled={!currentLanguage || isLoading}
                    onChange={onChangeDescription("longDescription")}
                    rows={3}
                  />
                </InputWrapper>
              </InputWrapper>
            </Flex>
          </Flex>

          <Flex row center>
            <Button onClick={onClose} appearance="link" disabled={isLoading}>
              <InterTag
                text={trans("Discard and Close")}
                color={COLORS.primary}
                size={14}
              />
            </Button>
            <PrimaryButton
              loading={isLoading}
              onClick={handleConfirm}
              disabled={isLoading}
              label={{
                text: awardId ? trans("Update Award") : trans("Create Award"),
                color: COLORS.white,
              }}
              icon={{
                Element: awardId ? ArrowForwardIcon : AddIcon,
                fill: COLORS.white,
                size: 20,
              }}
            />
          </Flex>
        </Flex>
      </Modal.Body>
    </>
  );
};

interface iProps
  extends Omit<ModalProps, "onClose">,
    iCreateEditAwardModalWrappedProps {}

const CreateEditAwardModal: React.FC<iProps> = ({
  onClose,
  requestStatus,
  requestError,
  onRequest,
  awardId,
  ...props
}) => {
  return (
    <Modal
      size="lg"
      id="CREATE.EDIT.AWARD.MODAL"
      overflow={false}
      {...{ ...props, onClose, keyboard: false, backdrop: "static" }}
    >
      <CreateEditAwardModalWrapped
        {...{ onClose, awardId, requestStatus, requestError, onRequest }}
      />
    </Modal>
  );
};

export default CreateEditAwardModal;
