import { first, last, max } from "lodash";
import moment, { MomentInput } from "moment";
import React, { useContext, useEffect, useMemo, useState } from "react";
import Flex from "../../../../components/Flex";
import GettingStarted, {
  GettingStartedFinalModal,
} from "../../../../components/GettingStarted";
import RoadmapTable from "../../../../components/RoadmapTable";
import SimpleDateRangePicker from "../../../../components/RsuiteWrapper/SimpleDateRangePicker";
import useHotelState from "../../../../context/Hotel/hooks/useHotelState";
import useGetDefaultConverionFactors from "../../../../hooks/useGetDefaultConverionFactors";
import { COLORS } from "../../../../utils/colors";
import { SustainabilityContext } from "../context";
import Benchmark from "./benchmark";
import Indicators from "./indicators";
import ManualDataModal from "../../../../components/Modals/ManualDataModal";
import PrimaryButton from "../../../../components/Buttons/PrimaryButton";
import useLocalizationState from "../../../../context/Localization/hooks/useLocalizationState";
import useProfilePermissions from "../../../../hooks/useProfilePermissions";
import { REQUEST_STATUS } from "../../../../utils/apiCall";

interface iProps {
  dataTypeToggle: any;
}

const ManualData: React.FC<iProps> = ({ dataTypeToggle }) => {
  const { trans } = useLocalizationState();
  const { setDataTypeToggleDisabled } = useContext(SustainabilityContext);
  const [manualDataModal, setManualDataModal] = useState(false);
  const profilePermissions = useProfilePermissions();
  const {
    hotel: { manualData },
    manualDataOrdered,
  } = useHotelState();
  const conversionFactorsRequest = useGetDefaultConverionFactors("PT", {
    doRequest: manualData.length > 0,
    initialStatus: manualData.length
      ? REQUEST_STATUS.PENDING
      : REQUEST_STATUS.IDLE,
  });

  const [manualDataIndex, setManualDataIndex] = useState(0);
  const [gettingStartedFinalModal, setGettingStartedFinalModal] =
    useState(false);

  useEffect(() => {
    setDataTypeToggleDisabled({
      conversionFactorsRequest: conversionFactorsRequest.isLoading,
    });
  }, [conversionFactorsRequest.isLoading, setDataTypeToggleDisabled]);

  useEffect(() => {
    if (manualDataIndex + 1 > manualData.length) setManualDataIndex(0);
  }, [manualData.length, manualDataIndex]);

  const selectedManualData = manualData[manualDataIndex];

  const availablePeriods = useMemo(() => {
    const periods: [MomentInput, MomentInput][] = [];

    for (const { from, to } of manualDataOrdered) {
      if (periods.length) {
        if (
          moment(last(periods)![1]).add(1, "day").format("DD/MM/YYYY") ===
          moment(from).format("DD/MM/YYYY")
        ) {
          periods[periods.length - 1][1] = to;
          continue;
        }
      }

      periods.push([from, to]);
    }

    return periods;
  }, [manualDataOrdered]);

  return (
    <>
      <GettingStartedFinalModal
        open={gettingStartedFinalModal}
        onClose={() => setGettingStartedFinalModal(false)}
      />
      <ManualDataModal
        open={manualDataModal}
        onClose={() => {
          setManualDataModal(false);
        }}
      />
      {manualData.length === 0 ? (
        <GettingStarted
          startModalOpen={true}
          onInsert={() => setGettingStartedFinalModal(true)}
        />
      ) : (
        <div>
          <div
            style={{
              position: "fixed",
              top: 70,
              width: "100%",
              zIndex: 3,
              paddingBottom: "8px",
              backgroundColor: COLORS.app_background,
            }}
          >
            <Flex row gap={16}>
              {dataTypeToggle && <Flex>{dataTypeToggle}</Flex>}
              <SimpleDateRangePicker
                {...{
                  ...(manualDataOrdered.length
                    ? {
                        limitEndYear: max([
                          moment(last(manualDataOrdered)!.to).year() -
                            moment().year() +
                            1,
                          0,
                        ]),
                        limitStartYear:
                          moment().year() -
                          moment(first(manualDataOrdered)!.from).year() +
                          1,
                      }
                    : {}),
                }}
                showOneCalendar
                oneTap
                hoverRange={"month"}
                shouldDisableDate={(
                  date,
                  selectedDate,
                  selectedDone,
                  target
                ) => {
                  for (const [from, to] of availablePeriods) {
                    if (
                      moment(date).isSameOrAfter(moment(from)) &&
                      moment(date).isSameOrBefore(moment(to))
                    ) {
                      return false;
                    }
                  }
                  return true;
                }}
                onChange={(range) => {
                  if (range) {
                    for (const [i, { from, to }] of manualData.entries()) {
                      if (
                        moment(range[0])
                          .startOf("day")
                          .isSame(moment(from).startOf("day")) &&
                        moment(range[1])
                          .endOf("day")
                          .isSame(moment(to).endOf("day"))
                      ) {
                        setManualDataIndex(i);
                      }
                    }
                  }
                }}
                value={[selectedManualData.from, selectedManualData.to]}
                ranges={[]}
                cleanable={false}
              />
              {profilePermissions.writeManualData && (
                <PrimaryButton
                  onClick={() => setManualDataModal(true)}
                  size="xs"
                  label={trans("Add Manual Data")}
                />
              )}
            </Flex>
          </div>
          <Flex column gap={40} style={{ marginTop: "40px" }}>
            <Benchmark {...{ manualDataIndex, conversionFactorsRequest }} />
            <Indicators {...{ manualDataIndex, conversionFactorsRequest }} />
            <RoadmapTable />
          </Flex>
        </div>
      )}
    </>
  );
};

export default ManualData;
