import React from "react";
import { tIcon } from "../../interfaces/icon";

export interface tIconProps extends tIcon {
  [key: string]: any;
}

const Icon: React.FC<tIconProps> = ({
  Element,
  fill,
  size,
  width,
  height,
  ...rest
}) => {
  return (
    <Element
      fill={fill}
      width={width || size}
      height={height || size}
      {...{ ...rest }}
    />
  );
};

export default Icon;
