import moment from "moment";

export const TODAY = "Today";
export const THIS_WEEK = "This week";
export const LAST_WEEK = "Last week";
export const THIS_MONTH = "This month";
export const LAST_30_DAYS = "Last 30 days";
export const LAST_MONTH = "Last Month";
export const LAST_6_MONTHS = "Last 6 months";
export const THIS_YEAR = "This year";
export const LAST_YEAR = "Last year";

export const DEFAULT_RANGE: [Date, Date] = [
  moment().subtract(29, "days").startOf("day").toDate(),
  moment().startOf("hour").toDate(),
];

export const TODAY_RANGE: [Date, Date] = [
  moment().startOf("day").toDate(),
  moment().endOf("day").toDate(),
];

export type tDateRangeListItem = {
  label: string;
  value: [Date, Date];
} & any;

export const DATE_RANGE_PICKER_RANGES: tDateRangeListItem[] = [
  {
    label: TODAY,
    value: TODAY_RANGE,
  },
  {
    label: LAST_WEEK,
    value: [
      moment().subtract(1, "week").startOf("week").toDate(),
      moment().subtract(1, "week").endOf("week").toDate(),
    ],
  },
  {
    label: THIS_WEEK,
    value: [moment().startOf("week").toDate(), moment().endOf("week").toDate()],
  },
  {
    label: THIS_MONTH,
    value: [
      moment().startOf("month").toDate(),
      moment().endOf("month").toDate(),
    ],
  },
  {
    label: LAST_30_DAYS,
    value: DEFAULT_RANGE,
  },
  {
    label: LAST_MONTH,
    value: [
      moment().subtract(1, "month").startOf("month").toDate(),
      moment().subtract(1, "month").endOf("month").toDate(),
    ],
  },
  {
    label: LAST_6_MONTHS,
    value: [
      moment().subtract(6, "month").startOf("month").toDate(),
      moment().endOf("month").toDate(),
    ],
  },
  {
    label: THIS_YEAR,
    value: [moment().startOf("year").toDate(), moment().endOf("year").toDate()],
  },
  {
    label: LAST_YEAR,
    value: [
      moment().subtract(1, "year").startOf("year").toDate(),
      moment().subtract(1, "year").endOf("year").toDate(),
    ],
  },
];

export const DATE_RANGE_PICKER_RANGES_YEARLY: tDateRangeListItem[] = [
  {
    label: THIS_YEAR,
    value: [moment().startOf("year").toDate(), moment().endOf("year").toDate()],
  },
  {
    label: LAST_YEAR,
    value: [
      moment().subtract(1, "year").startOf("year").toDate(),
      moment().subtract(1, "year").endOf("year").toDate(),
    ],
  },
];
