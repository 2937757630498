import axios from "axios";
import React, { useState } from "react";
import { Button, InlineEdit, Loader } from "rsuite";
import Flex from "../../../../components/Flex";
import InterTag from "../../../../components/Text/Inter";
import useLocalizationState from "../../../../context/Localization/hooks/useLocalizationState";
import useProfileDispatch from "../../../../context/Profile/hooks/useProfileDispatch";
import useProfileState from "../../../../context/Profile/hooks/useProfileState";
import usePutRequest from "../../../../hooks/apiRequests/usePutRequest";
import useEffectSafe from "../../../../hooks/useEffectSafe";
import { apiAddressV2 } from "../../../../utils/apiCall";
import { COLORS } from "../../../../utils/colors";
import { getErrorMessage } from "../../../../utils/httpResponses/others";
import styles from "../styles.module.css";
import ChangePasswordModal from "./changePasswordModal";

const PersonalSettingsWrapped: React.FC = () => {
  const putRequest = usePutRequest();
  const { trans } = useLocalizationState();
  const {
    profile: { name, email },
  } = useProfileState();
  const { updateProfile } = useProfileDispatch();
  const [nameInput, setNameInput] = useState("");
  const [emailInput, setEmailInput] = useState("");
  const [passwordModal, setPasswordModal] = useState(false);

  useEffectSafe(() => {
    setNameInput(name);
  }, [name]);
  useEffectSafe(() => {
    setEmailInput(email);
  }, [email]);

  const onSave = (key: "name" | "email", value: string) => {
    putRequest.pending();
    axios
      .put(`${apiAddressV2(false)}/v2/managers/manager`, { [key]: value })
      .then((res) => {
        const {
          data: { account },
        } = res;
        updateProfile(account);
        putRequest.resolve();
      })
      .catch((err) => {
        const error = getErrorMessage(err, trans);
        putRequest.reject(error);
      });
  };

  const onSaveName = (event: any) => {
    if (nameInput.length < 3) {
      return setNameInput(name);
    }
    if (
      event.type === "click" ||
      (event.type === "keydown" && event.key === "Enter")
    ) {
      if (nameInput !== name) onSave("name", nameInput);
      return;
    }
    return setNameInput(name);
  };
  const onSaveEmail = (event: any) => {
    if (emailInput.length < 3) {
      return setEmailInput(email);
    }
    if (
      event.type === "click" ||
      (event.type === "keydown" && event.key === "Enter")
    ) {
      if (emailInput !== email) onSave("email", emailInput);
      return;
    }
    return setEmailInput(email);
  };

  return (
    <>
      <ChangePasswordModal
        open={passwordModal}
        onClose={() => setPasswordModal(false)}
      />
      <div>
        <Flex
          row
          between
          middle
          className={[
            styles["settings-input-item"],
            styles["settings-item"],
            styles["settings-item--not-last"],
          ].join(" ")}
        >
          <InterTag
            size={14}
            text={trans("Name")}
            bold
            color={COLORS.secondary}
          />
          {putRequest.isLoading ? (
            <Loader size="xs" />
          ) : (
            <InlineEdit
              className="inter"
              value={nameInput}
              onChange={setNameInput}
              onCancel={() => setNameInput(name)}
              onSave={onSaveName}
              disabled={putRequest.isLoading}
            />
          )}
        </Flex>
        <Flex
          row
          between
          middle
          className={[
            styles["settings-input-item"],
            styles["settings-item"],
            styles["settings-item--not-last"],
          ].join(" ")}
        >
          <InterTag
            size={14}
            text={trans("Email")}
            bold
            color={COLORS.secondary}
          />
          {putRequest.isLoading ? (
            <Loader size="xs" />
          ) : (
            <InlineEdit
              className="inter"
              value={emailInput}
              onChange={setEmailInput}
              onCancel={() => setEmailInput(name)}
              onSave={onSaveEmail}
              disabled={putRequest.isLoading}
            />
          )}
        </Flex>
        <Flex
          row
          between
          middle
          className={[
            styles["settings-input-item"],
            styles["settings-item"],
          ].join(" ")}
        >
          <InterTag
            size={14}
            text={trans("Password")}
            bold
            color={COLORS.secondary}
          />
          <Button onClick={() => setPasswordModal(true)} appearance="ghost">
            {trans("Change")}
          </Button>
        </Flex>
      </div>
    </>
  );
};

const PersonalSettings: React.FC = () => {
  return <PersonalSettingsWrapped />;
};

export default PersonalSettings;
