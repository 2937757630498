import { sortBy } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "rsuite";
import { ReactComponent as LocalLaundryServiceIcon } from "../../../assets/icons/local_laundry_service.svg";
import { ReactComponent as QueryStatsIcon } from "../../../assets/icons/query_stats.svg";
import Flex from "../../../components/Flex";
import ManualDataModal from "../../../components/Modals/ManualDataModal";
import PageBottomPadding from "../../../components/PageBottomPadding";
import PageSectionTitle from "../../../components/PageSectionTitle";
import InterTag from "../../../components/Text/Inter";
import TopBar from "../../../components/TopBar";
import useHotelSubscriptionState from "../../../context/Hotel/hooks/hotelState/useHotelSubscriptionState";
import useHotelState from "../../../context/Hotel/hooks/useHotelState";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import useGetRequest from "../../../hooks/apiRequests/useGetRequest";
import useProfilePermissions from "../../../hooks/useProfilePermissions";
import { tWithRequired } from "../../../interfaces/others";
import { tHotelManualData } from "../../../models/hotel";
import { COLORS } from "../../../utils/colors";
import Graph from "./graph";
import List from "./list";
import Unlock from "./unlock";

const LaundryWrapped2: React.FC = () => {
  const { hotel, hotelIsLoaded } = useHotelState();
  const { trans } = useLocalizationState();
  const [manualDataModal, setManualDataModal] = useState({ open: false });
  const profilePermissions = useProfilePermissions();
  const readyManualData = useGetRequest<{
    list: tWithRequired<tHotelManualData, "laundry">[];
  }>({ list: [] });

  useEffect(() => {
    if (hotelIsLoaded) {
      const parsed = hotel.manualData.filter(
        (md) => md.laundry
      ) as tWithRequired<tHotelManualData, "laundry">[];

      const sorted = sortBy(parsed, (pmd) => moment(pmd.from).valueOf());

      readyManualData.resolve({ list: sorted });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hotel.manualData, hotelIsLoaded]);

  const segments = [
    {
      key: "overview",
      label: "Overview",
      pageSectionProps: {
        title: trans("Laundry Overview"),
        description: trans(
          "A snapshot of your current and historical laundry usage patterns"
        ),
        icon: QueryStatsIcon,
      },
      component: {
        Element: Graph,
        props: {},
      },
    },
    {
      key: "data",
      label: "Data",
      pageSectionProps: {
        title: trans("Laundry Data"),
        description: trans(
          "Comprehensive data showcasing detailed laundry usage accross your property."
        ),
        icon: LocalLaundryServiceIcon,
      },
      component: {
        Element: List,
        props: { readyManualData },
      },
    },
  ];

  return (
    <>
      {profilePermissions.writeManualData && (
        <ManualDataModal
          open={manualDataModal.open}
          initialStep="laundry"
          singleStep
          onClose={() => setManualDataModal({ open: false })}
        />
      )}
      <Flex column gap={20}>
        <Flex row between>
          {profilePermissions.writeManualData && (
            <Button
              appearance="primary"
              onClick={() => setManualDataModal({ open: true })}
            >
              {trans("Add Entry")}
            </Button>
          )}
        </Flex>
        <Flex column gap={40}>
          {segments.map(
            ({ component: { Element, props }, key, pageSectionProps }) => (
              <Flex column gap={16} key={key}>
                <PageSectionTitle {...pageSectionProps} />
                {/* @ts-ignore */}
                <Element {...props} />
              </Flex>
            )
          )}
        </Flex>
      </Flex>
    </>
  );
};

const LaundryWrapped: React.FC = () => {
  const { activeSubscriptionType, hotelIsLoaded, hotelLoading } =
    useHotelSubscriptionState();

  if (hotelLoading) return null;

  if (!hotelIsLoaded) return null;

  if (activeSubscriptionType === "starter") return <Unlock />;

  return <LaundryWrapped2 />;
};

const Laundry: React.FC = () => {
  const { trans } = useLocalizationState();
  const navigate = useNavigate();

  return (
    <div>
      <TopBar>
        <Flex row gap={12}>
          <Flex gap={8} column style={{ height: "100%" }}>
            <InterTag
              hoverUnderline
              onClick={() => {
                navigate("/consumption");
              }}
              text={trans("Consumption")}
              size={12}
              color={COLORS.secondary}
            />
            <InterTag
              text={trans("Laundry")}
              size={24}
              bold
              color={COLORS.secondary}
            />
          </Flex>
        </Flex>
      </TopBar>
      <LaundryWrapped />
      <PageBottomPadding />
    </div>
  );
};

export default Laundry;
