import { ReactComponent as AccountBalanceIcon } from "../../assets/icons/account_balance.svg";
import { ReactComponent as DeleteIcon } from "../../assets/icons/delete.svg";
import { ReactComponent as ElectricBoltIcon } from "../../assets/icons/electric_bolt.svg";
import { ReactComponent as HandshakeIcon } from "../../assets/icons/handshake.svg";
import { ReactComponent as PsychiatryIcon } from "../../assets/icons/psychiatry.svg";
import { ReactComponent as ShoppingCartIcon } from "../../assets/icons/shopping_cart.svg";
import { ReactComponent as WaterDropIcon } from "../../assets/icons/water_drop.svg";
import { ReactComponent as ModeHeatIcon } from "../../assets/icons/mode_heat.svg";
import { tIcon } from "../../interfaces/icon";
import { tHotelRoadmap } from "../../models/hotel";
import { COLORS, tColor } from "../../utils/colors";

export const typeTranslation = (
  type: string
): [tColor, string, tIcon] | [tColor] => {
  switch (type) {
    case "environment":
      return [
        COLORS.esg_environment,
        "Environment",
        { Element: PsychiatryIcon },
      ];
    case "governance":
      return [
        COLORS.esg_governance,
        "Governance",
        { Element: AccountBalanceIcon },
      ];
    case "social":
      return [COLORS.esg_social, "Social", { Element: HandshakeIcon }];
    case "supply chain":
      return [
        COLORS.esg_supply_chain,
        "Supply Chain",
        { Element: ShoppingCartIcon },
      ];
    case "electricity":
      return [COLORS.energy, "Electricity", { Element: ElectricBoltIcon }];
    case "water":
      return [COLORS.water, "Water", { Element: WaterDropIcon }];
    case "waste":
      return [COLORS.waste, "Waste", { Element: DeleteIcon }];
    case "fuels":
      return [COLORS.fuels, "Fuels", { Element: ModeHeatIcon }];
    default:
      return [COLORS.white];
  }
};

export const difficultyTranslation = (
  difficulty: tHotelRoadmap["difficulty"]
) => {
  switch (difficulty) {
    case "low":
      return ["green", "Low"];
    case "medium":
      return ["yellow", "Medium"];
    case "high":
      return ["red", "High"];
  }
};

export const statusTranslation = (
  status: tHotelRoadmap["status"]
): [string, tColor, tColor] /** [text, textColor, backgroundColor] */ => {
  switch (status) {
    case "in progress":
      return ["In Progress", COLORS.info, "rgba(33, 150, 243, 0.15)"];
    case "completed":
      return ["Completed", COLORS.success, "rgba(76, 175, 80, 0.15)"];
    case "idle":
      return ["Idle", COLORS.esg_supply_chain, "rgba(237, 134, 84, 0.21)"];
  }
};
