import { isString } from "lodash";
import React, { useCallback } from "react";
import { HotelStateContext } from "../..";
import { tMeasure } from "../../../../models/measures";
import useHotelState from "./useHotelState";

const useHotelMeasuresTrackedState = () => {
  const state = React.useContext(HotelStateContext);

  if (!state)
    throw Error(
      "useHotelMeasuresTrackedState must be used within HotelStateContext"
    );

  const { errorLoading, hotel, hotelId, hotelIsLoaded, hotelLoading } =
    useHotelState();

  //** Returns measures being tracked in specific spaces
  // * If no spaceIds are provided, returns information about all spaces
  // */
  const getMeasuresTracked = useCallback(
    (_spaceIds?: string | string[]) => {
      const spaceNames = isString(_spaceIds) ? [_spaceIds] : _spaceIds;
      const spaces = hotel
        ? spaceNames
          ? hotel.spaces.filter((r) => spaceNames.includes(r._id))
          : hotel.spaces
        : [];

      let measureIsMeasured: Record<tMeasure, boolean> = {
        te: false,
        es: false,
        el: false,
        ac: false,
        wh: false,
        tw: false,
        cw: false,
        hw: false,
        gw: false,
      };

      spaces.forEach((space) => {
        Object.keys(space.measures).forEach((key) => {
          const measureName = key as tMeasure;
          measureIsMeasured[measureName] ||=
            space.measures[measureName].isMeasured;
        });
      });

      return measureIsMeasured;
    },
    [hotel]
  );

  return {
    hotelIsLoaded,
    hotelLoading,
    errorLoading,
    hotel,
    hotelId,
    getMeasuresTracked,
  };
};

export default useHotelMeasuresTrackedState;
