import axios from "axios";
import { find, orderBy, round, sum, zip } from "lodash";
import { MomentInput } from "moment";
import React, { useCallback, useMemo, useState } from "react";
import ReactApexChart from "react-apexcharts";
import ReloadButton from "../../../components/Buttons/ReloadButton";
import Flex from "../../../components/Flex";
import PageSectionTitle, {
  iPageSectionTitleProps,
} from "../../../components/PageSectionTitle";
import SimpleDateRangePicker from "../../../components/RsuiteWrapper/SimpleDateRangePicker";
import SkeletonText from "../../../components/Skeleton/SkeletonText";
import InterTag from "../../../components/Text/Inter";
import useHotelState from "../../../context/Hotel/hooks/useHotelState";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import useGetRequest from "../../../hooks/apiRequests/useGetRequest";
import useEffectSafe from "../../../hooks/useEffectSafe";
import usePeriodState from "../../../hooks/usePeriodState";
import {
  tHotel,
  tHotelSpaceAggregate,
  tHotelSpaceAggregateId,
  tHotelSpaceId,
} from "../../../models/hotel";
import { TE, tMeasure, tMeasureTotal, TW } from "../../../models/measures";
import { apiAddressV2 } from "../../../utils/apiCall";
import { COLORS } from "../../../utils/colors";
import {
  convertEnergyUnit,
  convertMassUnit,
  convertVolumeUnit,
} from "../../../utils/convertUnits/";
import { getErrorMessage } from "../../../utils/httpResponses/others";
import {
  get_co2_costs_total_fromMeasures,
  groupDataBySpace,
} from "../../../utils/measures";
import { thousandsSeparation } from "../../../utils/numbers";

type tData = {
  byAgg: (Record<tMeasureTotal | "co2" | "costs", number> & {
    _id: tHotelSpaceAggregateId | tHotelSpaceAggregateId;
  })[];
  total: Record<tMeasureTotal | "co2" | "costs", number>;
};

interface iSpaceAggregateConsumptionWrappedProps {
  category: tHotelSpaceAggregate["category"];
}

const SpaceAggregateConsumptionWrapped: React.FC<
  iSpaceAggregateConsumptionWrappedProps
> = ({ category }) => {
  const { trans } = useLocalizationState();
  const [period, setPeriod] = usePeriodState();

  const [selectedSegment, setSelectedSegment] = useState(0);

  const [noSpacesAttachedText] = useMemo(() => {
    switch (category) {
      case "group":
        return [trans("No group has spaces attached")];
      case "zone":
        return [trans("No zone has spaces attached")];
      case "type":
        return [trans("No type has spaces attached")];
    }
  }, [category, trans]);

  const getRequest = useGetRequest<tData>({
    byAgg: [],
    total: { costs: 0, co2: 0, te: 0, tw: 0 },
  });

  const { hotel, hotelIsLoaded, hotelLoading, spaceAggregatesByCategory } =
    useHotelState();
  useEffectSafe(() => {
    if (hotelLoading && !getRequest.isLoading) {
      getRequest.pending();
    }
  }, [hotelLoading]);

  const run = useCallback(
    (hotel: tHotel, from?: MomentInput, to?: MomentInput) => {
      const filtered = spaceAggregatesByCategory[category];
      if (filtered.length) {
        getRequest.pending();
        const params = {
          spaceAggs: filtered.map((unit) => unit._id),
          measures: [TE, TW],
          from,
          to,
        };
        axios
          .get(
            `${apiAddressV2(false)}/v2/hotels/${
              hotel._id
            }/space-aggregates/consumption`,
            {
              params,
            }
          )
          .then((res) => {
            const list = res.data.spaceAggs as {
              _id: tHotelSpaceAggregateId;
              data: {
                dataBySpace: Record<tHotelSpaceId, Record<tMeasure, number[]>>;
              };
            }[];

            const total: tData["total"] = { costs: 0, co2: 0, te: 0, tw: 0 };

            const byAgg: tData["byAgg"] = list.map(({ _id, data }) => {
              const populateBySpace = Object.fromEntries(
                Object.entries(data.dataBySpace).map((entry: any) => {
                  const [space, measurements] = entry as [
                    tHotelSpaceId,
                    Partial<Record<tMeasure, number[]>>
                  ];
                  return [space, { te: [], tw: [], ...measurements }];
                })
              );

              const grouped = groupDataBySpace(populateBySpace);

              const { co2: co2List, costs: costsList } =
                get_co2_costs_total_fromMeasures(hotel, {
                  te: grouped.te,
                  tw: grouped.tw,
                });

              const te = sum(grouped.te);
              const tw = sum(grouped.tw);
              const co2 = sum(co2List);
              const costs = sum(costsList);

              total.te += te;
              total.tw += tw;
              total.co2 += co2;
              total.costs += costs;

              return {
                _id,
                te,
                tw,
                co2,
                costs,
              };
            });

            getRequest.resolve({ byAgg, total });
          })
          .catch((err) => {
            const error = getErrorMessage(err, trans);
            getRequest.reject(error);
          });
      } else {
        getRequest.resolve();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [category, spaceAggregatesByCategory, trans]
  );

  useEffectSafe(() => {
    if (hotelIsLoaded) {
      if (period) run(hotel, ...period);
      else run(hotel);
    }
  }, [hotel, hotelIsLoaded, period, run]);

  const spaceAggregatesFiltered = spaceAggregatesByCategory[category];

  if (hotel.spaceAggregates.length === 0)
    return (
      <InterTag
        text={trans("No data to show")}
        size={14}
        color={COLORS.secondary}
      />
    );

  const co2 = convertMassUnit(getRequest.data.total.co2);
  const te = convertEnergyUnit(getRequest.data.total.te);
  const tw = convertVolumeUnit(getRequest.data.total.tw);

  const segments: {
    label: string;
    value: number;
    unit: string;
    loading: boolean;
    key: keyof Omit<tData["byAgg"][0], "_id">;
  }[] = [
    {
      key: "co2",
      label: "CO<sub>2</sub> Emissions",
      value: round(co2.value, 2),
      unit: co2.unitInHTML,
      loading: !getRequest.isFinal,
    },
    {
      key: "te",
      label: "Electricity",
      value: round(te.value, 2),
      unit: te.unitInHTML,
      loading: !getRequest.isFinal,
    },
    {
      key: "tw",
      label: "Water",
      value: tw.value,
      unit: tw.unitInHTML,
      loading: !getRequest.isFinal,
    },
    {
      key: "costs",
      label: "Costs",
      value: round(getRequest.data.total.costs, 2),
      unit: "€",
      loading: !getRequest.isFinal,
    },
  ];

  const [series, labels] = (() => {
    const selectedKey = segments[selectedSegment].key;

    const mixed: { label: string; value: number }[] = [];
    getRequest.data.byAgg.forEach(({ _id, ...measures }) => {
      const units = find(spaceAggregatesFiltered, (u) => u._id === _id);
      if (units) {
        const label = units!.name;
        mixed.push({ label, value: measures[selectedKey] });
      }
    });
    const sorted = orderBy(mixed, ["value"], ["desc"]).map(
      ({ label, value }) => [label, value]
    );
    const [labels, series] = zip(...sorted) as [string[], number[]];

    return [series, labels];
  })();

  if (
    sum(
      spaceAggregatesFiltered.flatMap((agg) => {
        return agg.spaces.length;
      })
    ) === 0
  )
    return (
      <Flex>
        <InterTag
          size={14}
          text={noSpacesAttachedText}
          color={COLORS.secondary}
        />
      </Flex>
    );

  const graph = () => {
    if (getRequest.isLoading)
      return <SkeletonText width={"100%"} height={311} />;

    if (getRequest.isResolved)
      return (
        <ReactApexChart
          type="donut"
          {...{
            series,
            options: {
              theme: {
                monochrome: {
                  enabled: true,
                  color: COLORS.primary_500,
                  shadeIntensity: 1,
                },
              },
              chart: {
                type: "donut",
              },
              tooltip: {
                enabled: false,
              },
              labels,
              responsive: [
                {
                  breakpoint: 10000,
                  options: {
                    chart: {
                      width: 600,
                      height: 311,
                    },
                  },
                },
              ],
              dataLabels: {
                enabled: false,
              },
              legend: {
                position: "right",
                fontFamily: "Inter",

                formatter(legendName, opts) {
                  const name = opts.w.globals.labels[opts.seriesIndex];
                  const val = opts.w.globals.series[opts.seriesIndex];

                  const percentage = (val / sum(opts.w.globals.series)) * 100;

                  return `
            <span>${name}<span/>
            <span style="margin-left: 8px">${round(percentage, 1)} %<span/>
            `;
                },
              },
              stroke: {
                width: 0,
              },
            },
          }}
        />
      );
  };

  return (
    <Flex column className="card-m">
      {getRequest.isRejected ? (
        <Flex row middle gap={16}>
          <ReloadButton
            onClick={() => {
              getRequest.pending();
              if (period) run(hotel, ...period);
              else run(hotel);
            }}
          />
          <InterTag size={16} color={COLORS.error} text={getRequest.error} />
        </Flex>
      ) : (
        <>
          <Flex row right>
            <SimpleDateRangePicker
              value={period}
              onChange={setPeriod}
              shadowOnHover={false}
              darkenOnHover
              placement="left"
              icon={{ fill: COLORS.primary }}
            />
          </Flex>
          <Flex column gap={24}>
            <Flex row gap={16}>
              {segments.map(({ label, loading, unit, value, key }, i) => {
                let labelColor = COLORS.gray_400;
                let textColor = COLORS.secondary;

                if (i === selectedSegment) {
                  labelColor = COLORS.primary;
                  textColor = COLORS.primary;
                }

                return (
                  <Flex
                    key={key}
                    column
                    gap={8}
                    basis={15}
                    style={{
                      cursor: "pointer",
                      padding: "6px",
                      borderRadius: "8px",
                    }}
                    className="hover-darken-white-bg"
                    onClick={() => {
                      if (!getRequest.isLoading)
                        if (i !== selectedSegment) setSelectedSegment(i);
                    }}
                  >
                    <InterTag
                      text={trans(label)}
                      size={12}
                      color={labelColor}
                      asHTML
                    />
                    {loading ? (
                      <SkeletonText width={80} height={36} />
                    ) : (
                      <Flex row gap={4} bottom>
                        <InterTag
                          text={thousandsSeparation(round(value, 2))}
                          size={36}
                          color={textColor}
                        />
                        <InterTag
                          text={unit}
                          size={16}
                          color={textColor}
                          asHTML
                        />
                      </Flex>
                    )}
                  </Flex>
                );
              })}
            </Flex>
            <Flex center>{graph()}</Flex>
          </Flex>
        </>
      )}
    </Flex>
  );
};

export interface iSpaceAggregateConsumptionProps
  extends iSpaceAggregateConsumptionWrappedProps {
  pageSectionTitleProps: iPageSectionTitleProps;
}

const SpaceAggregateConsumption: React.FC<iSpaceAggregateConsumptionProps> = ({
  pageSectionTitleProps,
  ...props
}) => {
  return (
    <Flex column gap={16}>
      <PageSectionTitle {...{ ...pageSectionTitleProps }} />
      <SpaceAggregateConsumptionWrapped {...{ ...props }} />
    </Flex>
  );
};

export default SpaceAggregateConsumption;
