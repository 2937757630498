import React, { useState } from "react";
import { Button } from "rsuite";
import { ReactComponent as SettingsBro } from "../../assets/bros/Settings-bro 1.svg";
import { ReactComponent as BuildIcon } from "../../assets/icons/build.svg";
import useLocalizationState from "../../context/Localization/hooks/useLocalizationState";
import { COLORS } from "../../utils/colors";
import Flex from "../Flex";
import Icon from "../Icon";
import ManualDataModal from "../Modals/ManualDataModal";
import PageSectionTitle from "../PageSectionTitle";
import InterTag from "../Text/Inter";
import InitModal from "./initModal";

interface iGettingStartedWrappedProps
  extends Pick<iProps, "onInsert" | "startModalOpen"> {}

const GettingStartedWrapped: React.FC<iGettingStartedWrappedProps> = ({
  onInsert,
  startModalOpen = false,
}) => {
  const { trans } = useLocalizationState();
  const [modals, setModals] = useState({
    init: startModalOpen,
    insert: false,
    final: false,
  });

  return (
    <>
      <InitModal
        open={modals.init}
        onClose={() => setModals({ init: false, insert: false, final: false })}
        onContinue={() =>
          setModals({ init: false, insert: true, final: false })
        }
      />
      <ManualDataModal
        open={modals.insert}
        onClose={(action) => {
          switch (action) {
            case "created":
              setModals({ init: false, insert: false, final: true });
              onInsert();
              break;
            case "back":
              setModals({ init: true, insert: false, final: false });
              break;
            case "exit":
              setModals({ init: false, insert: false, final: false });
              break;
          }
        }}
      />
      <Flex center middle>
        <Button
          appearance="primary"
          onClick={() => setModals({ init: true, insert: false, final: false })}
        >
          <Flex row gap={8} middle>
            <InterTag
              bold
              size={20}
              text={trans("Start Setup")}
              color={COLORS.white}
            />
            <Icon Element={BuildIcon} fill={COLORS.white} size={24} />
          </Flex>
        </Button>
      </Flex>
    </>
  );
};

interface iProps {
  onInsert(): void;
  startModalOpen: boolean;
}

const GettingStarted: React.FC<iProps> = ({ onInsert, startModalOpen }) => {
  const { trans } = useLocalizationState();

  return (
    <Flex column gap={16}>
      <PageSectionTitle
        title={trans("Getting Started")}
        description={trans(
          "Complete our easy sustainability evaluation to start seeing data and insights here"
        )}
        icon={BuildIcon}
      />
      <Flex column middle gap={12}>
        <Flex center style={{ marginTop: "16px" }}>
          <Icon Element={SettingsBro} size={250} fill={COLORS.white} />
        </Flex>
        <InterTag
          text={trans(
            "Before we dive into the exciting journey ahead, there's a quick setup to complete"
          )}
        />
        <InterTag
          text={`<strong>${trans("Begin Setup:")}</strong> ${trans(
            "Click the 'Start 'Setup' button below to launch the setup wizard."
          )}`}
          asHTML
        />
        <InterTag
          text={`<strong>${trans("Follow the Steps:")}</strong> ${trans(
            "the wizard will guide you though each step. Fon't worry, it's quick and easy!"
          )}`}
          asHTML
        />
        <InterTag
          text={`<strong>${trans("Need Assistance?:")}</strong> ${trans(
            "If you have any questions or need help during the setup, our support team is just a click away"
          )}`}
          asHTML
        />
        <InterTag
          text={trans(
            "Completing this swill unlock all the features and possibilities noytrall has to offer."
          )}
        />
        <InterTag text={trans("Let's get your journey started!")} />
      </Flex>
      <GettingStartedWrapped {...{ onInsert, startModalOpen }} />
    </Flex>
  );
};

export default GettingStarted;
