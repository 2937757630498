import { round, sortBy, toInteger, toNumber, uniq } from "lodash";
import moment from "moment";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { ReactComponent as ElectricBoltIcon } from "../../assets/icons/electric_bolt.svg";
import { ReactComponent as ModeHeatIcon } from "../../assets/icons/mode_heat.svg";
import { ReactComponent as WaterDropIcon } from "../../assets/icons/water_drop.svg";
import consumptionPlaceholder from "../../assets/placeholders/consumption-palceholder.png";
import useHotelManualDataState from "../../context/Hotel/hooks/hotelState/useHotelManualDataState";
import useHotelSubscriptionState from "../../context/Hotel/hooks/hotelState/useHotelSubscriptionState";
import useLocalizationState from "../../context/Localization/hooks/useLocalizationState";
import useGetDefaultConverionFactors from "../../hooks/useGetDefaultConverionFactors";
import { tHotelManualData } from "../../models/hotel";
import { COLORS, hexLuminance } from "../../utils/colors";
import {
  convertEnergyUnit,
  convertMassUnit,
  convertVolumeUnit,
} from "../../utils/convertUnits";
import { nUnitConverter } from "../../utils/convertUnits/interfaces";
import { generateGraphCategories } from "../../utils/graphs";
import { thousandsSeparation } from "../../utils/numbers";
import Flex from "../Flex";
import ApexChart from "../Graphs/Apex/Chart";
import PageSectionTitle from "../PageSectionTitle";
import SkeletonText from "../Skeleton/SkeletonText";
import { iTextProps } from "../Text";
import InterTag from "../Text/Inter";
import { toTextProps } from "../Text/utils";
import Unlock from "../Unlock";

interface iHistoricDataConsumptionGraphSectionWrappedProps {
  manualDataByYear: Record<string, tHotelManualData[]>;
  conversionFactorsRequest: ReturnType<typeof useGetDefaultConverionFactors>;
  calc: (md: tHotelManualData) => number[];
  measureConverter: nUnitConverter.tFunction;
  measureMainUnit: string;
  graphColors: string[];
}

const HistoricDataConsumptionGraphSectionWrapped: React.FC<
  iHistoricDataConsumptionGraphSectionWrappedProps
> = ({
  manualDataByYear,
  conversionFactorsRequest,
  calc,
  graphColors,
  measureConverter,
  measureMainUnit,
}) => {
  const [graphType, setGraphType] = useState<"line" | "bar">("line");
  const { trans } = useLocalizationState();
  const [selectedSegment, setSelectedSegment] = useState(0);
  const [selectedYears, setSelectedYears] = useState<number[]>([
    ...sortBy(Object.keys(manualDataByYear).map((y) => toInteger(y))).slice(
      Object.keys(manualDataByYear).length - 3
    ),
  ]);
  const selectedYearsRef = useRef<number[]>([]);

  useEffect(() => {
    selectedYearsRef.current = selectedYears;
  }, [selectedYears]);

  useEffect(() => {
    setGraphType("bar");
  }, []);

  const { consumptionSeries, co2Series, costsSeries } = useMemo(() => {
    const consumptionSeries: any[] = [];
    const co2Series: any[] = [];
    const costsSeries: any[] = [];

    Object.entries(manualDataByYear).forEach((entry) => {
      const [year, list] = entry;

      const consumptionData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      const co2Data = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      const costsData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

      list.forEach((md) => {
        const { from } = md;
        // index 0 does not exist to fit in graph
        const index = moment(from).month() + 1;
        const [consumption, co2, costs] = calc(md);
        consumptionData[index] = consumption;
        co2Data[index] = co2;
        costsData[index] = costs;
      });

      consumptionSeries.push({ name: year, data: consumptionData });
      co2Series.push({ name: year, data: co2Data });
      costsSeries.push({ name: year, data: costsData });
    });

    return { consumptionSeries, co2Series, costsSeries };
  }, [calc, manualDataByYear]);

  const [consumptionValue, co2Value, costsValue] = useMemo(() => {
    let consumptionValue = 0;
    let co2Value = 0;
    let costsValue = 0;

    selectedYears.forEach((year) => {
      manualDataByYear[year].forEach((md) => {
        const [value, co2, costs] = calc(md);
        consumptionValue += value;
        co2Value += co2;
        costsValue += costs;
      });
    });

    return [consumptionValue, co2Value, costsValue];
  }, [calc, manualDataByYear, selectedYears]);

  const consumptionConverted = measureConverter(consumptionValue, {
    from: measureMainUnit,
    forceUnit: null,
  });
  const co2Converted = convertMassUnit(co2Value, {
    from: "kg",
    forceUnit: null,
  });

  const segments: {
    label: iTextProps | string;
    key: string;
    value: number;
    valueUnit: string;
    mainUnit: string;
    series: any[];
    loading: boolean;
  }[] = [
    {
      label: trans("Consumption"),
      key: "consumption",
      value: consumptionConverted.value,
      valueUnit: consumptionConverted.unitInHTML,
      mainUnit: measureMainUnit,
      series: consumptionSeries,
      loading: false,
    },
    {
      label: {
        text: trans("CO<sub>2</sub> Emissions"),
        asHTML: true,
      },
      key: "co2",
      value: co2Converted.value,
      valueUnit: co2Converted.unitInHTML,
      mainUnit: "kg",
      series: co2Series,
      loading: !conversionFactorsRequest.isResolved,
    },
    {
      label: trans("Costs"),
      key: "costs",
      value: costsValue,
      valueUnit: "€",
      mainUnit: "€",
      series: costsSeries,
      loading: false,
    },
  ];

  const renderSegments = () => {
    return (
      <Flex row gap={40}>
        {segments.map(({ key, label, valueUnit, value, loading }, i) => {
          let labelColor = COLORS.gray_400;
          let valueColor = COLORS.secondary;

          if (selectedSegment === i) {
            labelColor = valueColor = COLORS.primary;
          }

          return (
            <Flex
              column
              gap={4}
              key={key}
              basis={15}
              style={{ cursor: "pointer", padding: "6px", borderRadius: "8px" }}
              className="hover-darken-white-bg"
              onClick={() => {
                if (selectedSegment !== i) setSelectedSegment(i);
              }}
            >
              <InterTag
                size={12}
                color={labelColor}
                {...{ ...toTextProps(label) }}
              />
              {loading ? (
                <SkeletonText width={100} height={36} />
              ) : (
                <Flex row gap={4} bottom>
                  <InterTag
                    text={thousandsSeparation(round(value, 2))}
                    color={valueColor}
                    size={36}
                  />
                  <InterTag
                    text={valueUnit}
                    asHTML
                    color={valueColor}
                    size={16}
                  />
                </Flex>
              )}
            </Flex>
          );
        })}
      </Flex>
    );
  };

  const categories = useMemo(
    () =>
      generateGraphCategories(
        "month",
        1,
        moment().startOf("year"),
        moment().endOf("year")
      ),
    []
  );

  const renderGraph = () => {
    const { series, mainUnit } = segments[selectedSegment];

    return (
      <ApexChart
        {...{
          type: graphType,
          colors: graphColors,
          categories: [null, ...categories],
          series,
          yaxis: {
            labels: {
              show: true,
              formatter(val: any, opts: any) {
                return `${round(val, 2)} ${mainUnit}`;
              },
            },
          },
          xaxis: {
            categories: [null, ...categories],
            labels: {
              show: true,
              formatter(value: any, timestamp: any, opts: any) {
                if (value === null) return "";
                return moment(value).format("MMM");
              },
            },
          },

          legend: {
            show: true,
            showForSingleSeries: true,
          },
          chart: {
            events: {
              legendClick(chart, seriesIndex, options) {
                if (seriesIndex !== undefined) {
                  const year = toNumber(
                    options.globals.seriesNames[seriesIndex]
                  );
                  if (selectedYearsRef.current.includes(year)) {
                    setSelectedYears((prev) => [
                      ...prev.filter((y) => y !== year),
                    ]);
                  } else {
                    setSelectedYears((prev) => sortBy(uniq([...prev, year])));
                  }
                }
              },
            },
          },
        }}
      />
    );
  };

  return (
    <Flex className="card-m" column gap={16}>
      {renderSegments()}
      {renderGraph()}
    </Flex>
  );
};

interface iProps
  extends Pick<
    iHistoricDataConsumptionGraphSectionWrappedProps,
    "conversionFactorsRequest"
  > {
  measure: "electricity" | "water" | "fuels";
}

const HistoricDataConsumptionGraphSection: React.FC<iProps> = ({
  conversionFactorsRequest,
  measure,
}) => {
  const { trans } = useLocalizationState();
  const { manualDataByYear, hotelIsLoaded } = useHotelManualDataState();
  const { activeSubscriptionIsStarter } = useHotelSubscriptionState();

  const calc = useMemo(() => {
    switch (measure) {
      case "electricity":
        return (md: tHotelManualData) =>
          md.electricity
            ? [
                md.electricity.totalKWh,
                md.electricity.totalKWh *
                  conversionFactorsRequest.data.electricity_kWh_to_kgCO2e,
                md.electricity.price,
              ]
            : [0, 0, 0];
      case "water":
        return (md: tHotelManualData) =>
          md.water
            ? [
                md.water.totalM3,
                md.water.totalM3 *
                  conversionFactorsRequest.data.water_m3_to_kgCO2e,
                md.water.price,
              ]
            : [0, 0, 0];
      case "fuels":
        return (md: tHotelManualData) => {
          return md.naturalGas
            ? [
                md.naturalGas?.totalKWh,
                md.naturalGas?.totalKWh *
                  conversionFactorsRequest.data.natural_gas_kWh_to_kgCO2e,
                md.naturalGas.price,
              ]
            : [0, 0, 0];
        };
    }
  }, [
    conversionFactorsRequest.data.electricity_kWh_to_kgCO2e,
    conversionFactorsRequest.data.natural_gas_kWh_to_kgCO2e,
    conversionFactorsRequest.data.water_m3_to_kgCO2e,
    measure,
  ]);

  const [
    measureConverter,
    measureMainUnit,
    graphColors,
    pageSectionTitleProps,
    unlockComponentProperties,
  ] = useMemo(() => {
    switch (measure) {
      case "electricity":
        return [
          convertEnergyUnit,
          "kWh",
          [
            hexLuminance(COLORS.energy, 0.15),
            COLORS.energy,
            hexLuminance(COLORS.energy, -0.15),
            hexLuminance(COLORS.energy, -0.3),
            hexLuminance(COLORS.energy, -0.45),
          ],
          {
            title: trans("Electricity Consumption Insights"),
            description: trans(
              "Explore electricity usage metrics by unit, emissions, and associated costs."
            ),
            icon: { Element: ElectricBoltIcon, fill: COLORS.energy },
          },
          null,
        ];
      case "water":
        return [
          convertVolumeUnit,
          "m3",
          [
            hexLuminance(COLORS.water, -0.5),
            hexLuminance(COLORS.water, -0.25),
            COLORS.water,
            hexLuminance(COLORS.water, 0.5),
            hexLuminance(COLORS.water, 0.8),
            hexLuminance(COLORS.water, 1),
          ],
          {
            title: trans("Water Consumption Insights"),
            description: trans(
              "Explore water usage metrics by unit, emissions, and associated costs."
            ),
            icon: { Element: WaterDropIcon, fill: COLORS.water },
          },
          null,
        ];
      case "fuels":
        return [
          convertEnergyUnit,
          "kWh",
          [
            hexLuminance(COLORS.fuels, -0.5),
            COLORS.fuels,
            hexLuminance(COLORS.fuels, 0.5),
          ],
          {
            title: trans("Fuels Consumption Insights"),
            description: trans(
              "Explore fuel usage metrics by unit, emissions, and associated costs."
            ),
            icon: { Element: ModeHeatIcon, fill: COLORS.fuels },
          },
          {
            title: trans(
              "Optimize Your Energy with Comprehensive Fuel Tracking"
            ),
            description: [
              trans(
                "Take control of your hotel's energy management by accurately tracking different types of fuel consumption, such natural gas, heating oil, or biomass."
              ),
              trans(
                "Our Fuel Tracking feature provides a detailed analysis of usage accross your hotel's operations, helping you identify opportunities for cost reduction and enhance your sustainability efforts."
              ),
            ],
            image: consumptionPlaceholder,
            onClick: () => {},
          },
        ];
    }
  }, [measure, trans]);

  return (
    <Flex column gap={16}>
      <PageSectionTitle {...{ ...pageSectionTitleProps }} />
      {hotelIsLoaded &&
        (activeSubscriptionIsStarter && unlockComponentProperties ? (
          <Unlock {...{ ...unlockComponentProperties }} />
        ) : (
          <HistoricDataConsumptionGraphSectionWrapped
            {...{
              manualDataByYear,
              conversionFactorsRequest,
              measure,
              calc,
              graphColors,
              measureConverter,
              measureMainUnit,
            }}
          />
        ))}
    </Flex>
  );
};

export default HistoricDataConsumptionGraphSection;
